import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { ProfileType } from "../../types/userTypes";
import { checkTokenValidation } from "../auth/tokenReq";
import {
  CandidateCardsetType,
  CandidateInInfoType,
  CandidateInfoType,
  CandidateOfferType,
  CandidateType,
  UserInInfoType,
} from "../../types/candidateTypes";
import { ResourceCreateType, ResourceGetType } from "../../types/otherTypes";

export const updateCandidatePrsnDetailsReq = async (
  params: { candidateID: number },
  data?: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidateDetailsReq->updateCandidatePrsnDetailsReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    //  logfun(body, "body");

    const res = await authAxios.post(
      `${ApiKey.updateCandidateDetailsKey}/${params?.candidateID}/updateCandidateDetails`,
      body
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCandidateOfferDetailsReq = async (
  params: { candidateID: number },
  data?: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidateDetailsReq->updateCandidateOfferDetailsReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    //  logfun(body, "body");

    const res = await authAxios.post(
      `${ApiKey.updateCandidateOfferDetailsKey}/${params?.candidateID}/updateCandidateOffer`,
      body
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCandidateResourceReq = async (data?: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidateDetailsReq->updateCandidateResourceReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    //  logfun(body, "body");

    const res = await authAxios.post(
      `${ApiKey.updateCandidateResourceKey}`,
      body
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const deleteCandidateResourceReq = async (
  params: { candidateID: number; resourceID: number },
  data?: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidateDetailsReq->deleteCandidateResourceReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    //  logfun(body, "body");

    const res = await authAxios.delete(
      `${ApiKey.deleteCandidateResourceKey}/${params?.candidateID}/resources/${params?.resourceID}`
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
