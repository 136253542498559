import React, { memo, useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import { ROUTE_NAMES } from "../constants/routeNames";
import { LoginPage } from "../pages/auth";

const PublicRoute = () => {
  const authToken = localStorage.getItem("@auth");
  return !authToken ? <Navigate to={ROUTE_NAMES.INITIAL} /> : <Outlet />;
};

export default PublicRoute;
