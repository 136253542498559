import React, { useState } from "react";
import { useMemo } from "react";
import { useTable } from "react-table";

import styles from "./table.module.scss";
import dayjs from "dayjs";

function Table({ columns, data }) {
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th className="sm-text" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.slice(0, 100).map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  <td
                    className={idx === 0 ? `sm-text` : "sm-text"}
                    key={idx}
                    {...cell.getCellProps}
                  >
                    {cell?.column?.Header === "Date"
                      ? !dayjs(cell.value).isValid()
                        ? "NA"
                        : dayjs(cell.value).format("MMM DD, YYYY")
                      : cell.value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function BasicTable(props) {
  const { tableData, tableColumns } = props;
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(() => tableColumns, [tableColumns]);
  //   const columnsold = useMemo(() => {
  //     const columnsData = Object.keys(fakeData[0]).map((item) => {
  //       if (item === "Status") {
  //         return {
  //           Header: item.replace("_", " "),
  //           accessor: item,
  //           Cell: ({ cell }) => {
  //             return (
  //               <select defaultValue={cell.value}>
  //                 <option value={"New"}>New</option>
  //                 <option value={"In Progress"}>In Progress</option>
  //               </select>
  //             );
  //           },
  //         };
  //       }
  //       if (item === "Remarks") {
  //         return {
  //           Header: item.replace("_", " "),
  //           accessor: item,
  //           Cell: ({ cell }) => {
  //             const value = cell?.value;
  //             const [inputValue, setinputValue] = useState(value || "");
  //             return (
  //               <div className={`d-flex align-items-center`}>
  //                 <input placeholder="type.." value={inputValue} />
  //                 <BsCheckLg
  //                   className="mx-2"
  //                   color={value ? "#3A93F4" : "#A9ACAC"}
  //                   size={20}
  //                 />
  //                 {!!inputValue && (
  //                   <BsX className="mx-2" color="#D92D20" size={20} />
  //                 )}
  //                 {/* <BsPencil className="mx-2" color="#3A93F4" size={15} /> */}
  //               </div>
  //             );
  //           },
  //         };
  //       }
  //       return {
  //         Header: item.replace("_", " "),
  //         accessor: item,
  //       };
  //     });

  //     return columnsData;
  //   }, []);

  console.log(columns, "columns");

  return (
    <div className={`${styles.BasicTable}`}>
      {data?.length && columns?.length > 0 ? (
        <Table columns={columns} data={data} />
      ) : (
        <p>Add table data and column data</p>
      )}
    </div>
  );
}

export default BasicTable;
