import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

import styles from "./helpPage.module.scss";
import { ROUTE_NAMES } from "../../constants/routeNames";

const menuListGroupOne = [
  {
    label: "How it works",
    icon: "",
    route: ROUTE_NAMES.HowItWorksPage,
    role: "admin",
  },
  {
    label: "FAQ",
    icon: "",
    route: ROUTE_NAMES.faq,
  },
  {
    label: "Contact Us",
    icon: "",
    route: ROUTE_NAMES.contact_us,
  },
];

const HelpPage = () => {
  const navigation = useNavigate();
  const [activeMenu, setactiveMenu] = useState("How it works");
  const _isAdmin = 301 == localStorage.getItem("@role");

  const _onPress = ({ key, data }) => {
    const fun = {
      null: () => {},
      menuBtn: (data) => {
        setactiveMenu(data?.label);
        navigation(data?.route);
      },
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.Help} h-100`}>
      <div className="d-flex h-100">
        <div className={`${styles.nav_bar_block} h-100`}>
          &nbsp;
          <p></p>
          &nbsp;
          {menuListGroupOne?.map((opt, idx) => {
            const active = activeMenu === opt.label;
            if (opt.role === "admin" && _isAdmin) {
              return (
                <button
                  className={`${styles.opt_box} ${active && styles.active}`}
                  key={idx}
                  disabled={!opt.route}
                  onClick={() =>
                    !opt.route ? {} : _onPress({ key: "menuBtn", data: opt })
                  }
                >
                  <div className="d-flex align-items-center">
                    <label className={`md-text ${!active && "light-text"}`}>
                      {opt.label}
                    </label>
                  </div>
                </button>
              );
            }
            if (opt.role !== "admin" || !_isAdmin) {
              return (
                <button
                  className={`${styles.opt_box} ${active && styles.active}`}
                  key={idx}
                  onClick={() =>
                    !opt ? {} : _onPress({ key: "menuBtn", data: opt })
                  }
                >
                  <div className="d-flex align-items-center">
                    <label className={`md-text ${!active && "light-text"}`}>
                      {opt.label}
                    </label>
                  </div>
                </button>
              );
            }
          })}
        </div>
        <div className={`${styles.content_block} h-100`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
