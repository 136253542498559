import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { checkTokenValidation } from "../auth/tokenReq";

export const updateCandidateStatusReq = async (
  params: { candidateID: number },
  data?: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidatesReq->publishCandidateReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    //  logfun(body, "body");

    const res = await authAxios.post(
      `${ApiKey.updateCandidateStatusKey}/${params?.candidateID}/updateStatus`,
      body
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
