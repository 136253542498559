import React, { useState, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { requestPermission, onMessageListener } from "./webNotification";
requestPermission();

function Notification() {
  const [notification, setNotification] = useState({ title: "", body: "" });

  onMessageListener()?.then((payload) => {
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });
  });
  useEffect(() => {
    if (notification?.title) {
      toast.success(<ToasterBody />);
    }
  }, [notification]);

  const ToasterBody = () => {
    return (
      <div className="" style={{ width: "130%" }}>
        <p className="md-text text-white">{notification?.title}</p>
        <p className="x-sm-text text-white">{notification?.body}</p>
      </div>
    );
  };

  return (
    <div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
            },
          },
        }}
        position="bottom-right"
        reverseOrder={false}
      />
    </div>
  );
}
export default Notification;
