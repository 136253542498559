import { createSlice } from "@reduxjs/toolkit";
import { ApiResType } from "../../../types/apiTypes";
import { call, put, select } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { getCardSetCatReq } from "../../../api/cardsetReq/cardSetCategoryReq";
import { getAllCardSetReq } from "../../../api/cardsetReq/cardSetReq";
import {
  CandidateType,
  CandidateInfoType,
  UserInInfoType,
  CandidateInInfoType,
  CandidateActivityType,
  CandidateEngagementType,
  CandidateOfferType,
  CandidateCardsetType,
} from "../../../types/candidateTypes";
import { ResourceGetType } from "../../../types/otherTypes";
import { getCandidatesReq } from "../../../api/candidateReq/candidateReq";
import { getCandidateAllDetailsReq } from "../../../api/candidateReq/candidateReq";
import { SagaActions } from "../../actionTypes";
import { RootState } from "../../store";

type payloadType = {
  data: CandidateType[];
};

const initialUserDetails = {
  email: "",
  phoneCountryCode: "",
  mobileNumber: "",
  isActive: false,
};

const initialCandidateInfo = {
  candidateID: 0,
  firstName: "",
  lastName: "",
  profilePicURL: "",
  isPublished: false,
  employeeID: 0,
  companyID: 0,
  userID: 0,
  engagementScore: 0,
};

const initialCandidateOffer = {
  offerID: 0,
  yearOfExp: 0,
  jobLevel: 0,
  dateOfJoining: "",
  designation: "",
  department: "",
  team: "",
  baseLocation: "",
  workLocation: "",
  workModel: "",
  offerExpiryDate: "",
  offerStatus: "",
  offerLetterURL: "",
  signatureURL: "",
  salaryCtcPA: 0,
  fixedComponentPA: 0,
  variableComponentPA: 0,
  variablePayoutFreq: 0,
  approxTakeHomePM: 0,
  userID: 0,
  employeeID: 0,
  companyID: 0,
  createdAt: "",
};
const activityActionsList = {
  candidateRemark: "",
};
const initialCandCardSets = {
  candidateCardSetID: 0,
  weekNumber: 0,
  dateFrom: "",
  dateTo: "",
  cardSetOrder: "",
  cardSetID: 0,
  companyID: 0,
  userID: 0,
  CardSet: {
    cardSetID: 0,
    cardSetName: "",
    cardSetURL: "",
    isActive: false,
    categoryID: 0,
    companyID: 0,
    CardSetCategory: {
      cardSetURL: "",
    },
  },
  ActivityActions: [activityActionsList],
};
const initialCandResources = {
  resourceID: 0,
  resourceName: "",
  resourceType: "",
  resourceURL: "",
  companyID: 0,
  userID: 0,
};
const initialPersonalityMap = {
  categoryName: "",
  averageFeedback: 0,
}
const initialCandEngagement = {
  cardSetTotalCount: 0,
  cardSetCompletedCount: 0,
  completedPercentage: 0,
  claimedRewards: 0,
  unClaimedRewards: 0,
  engagementScore: 0,
  personalityMapScore: 0,
  personalityMap: [initialPersonalityMap],
};
const initialCandActivity = {
  activityID: 0,
  activityName: "",
  candidateActionDate: "",
  candidateStatus: "",
  candidateRemark: "",
  recruiterStatus: "",
  recruiterRemark: "",
  createdAt: "",
  updatedAt: "",
  offerID: 0,
  candidateCardSetID: "",
  candidateID: 0,
  employeeID: 0,
};

export interface CandidateSlicetypes {
  error: boolean;
  message: string;
  data: CandidateType[];
  selectedCandidateId: number;
  selectedCandidatesDetails: CandidateInfoType;
  loading: boolean;
  candidateDetailsLoading: boolean;
}

const initialState: CandidateSlicetypes = {
  error: false,
  message: "",
  data: [],
  selectedCandidateId: 0,
  selectedCandidatesDetails: {
    User: initialUserDetails,
    Candidate: initialCandidateInfo,
    CandidateOffer: initialCandidateOffer,
    CandidateCardSets: [initialCandCardSets],
    CandidateResources: [initialCandResources],
    CandidateEngagement: initialCandEngagement,
    CandidateActivity: [initialCandActivity],
  },
  loading: false,
  candidateDetailsLoading: false,
};

const CandidateSlice = createSlice({
  name: "Candidate",
  initialState: initialState,
  reducers: {
    AddCandidatesList: (
      state: CandidateSlicetypes,
      { payload }: { payload: payloadType }
    ) => {
      state.error = false;
      state.data = payload?.data || [];
      state.loading = false;
      return state;
    },
    AddSelectedCandId: (
      state: CandidateSlicetypes,
      { payload }: { payload: any }
    ) => {
      state.error = false;
      state.selectedCandidateId = payload?.data;
      state.candidateDetailsLoading = false;
      return state;
    },
    GetSelectedCandidateDetails: (
      state: CandidateSlicetypes,
      { payload }: { payload: any }
    ) => {
      state.error = false;
      state.selectedCandidatesDetails = payload?.data;
      state.candidateDetailsLoading = false;
      return state;
    },
    StartDetailsLoading: (state: CandidateSlicetypes) => {
      state.candidateDetailsLoading = true;
      return state;
    },
    StopDetailsLoading: (state: CandidateSlicetypes) => {
      state.candidateDetailsLoading = false;
      return state;
    },
    StartLoading: (state: CandidateSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: CandidateSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: CandidateSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  AddCandidatesList,
  AddSelectedCandId,
  GetSelectedCandidateDetails,
  StartLoading,
  StopLoading,
  StartDetailsLoading,
  StopDetailsLoading,
  ClearData,
} = CandidateSlice.actions;

export default CandidateSlice.reducer;

export function* fetchCandidateList() {
  interface ApiResType2 extends ApiResType {
    recent: any;
  }
  try {
    yield put(StartLoading());
    const res: ApiResType2 = yield call(getCandidatesReq);
    const state: RootState = yield select();
    const { candidates } = state;
    const candidateSelected = candidates?.selectedCandidateId || null;
    if (!candidateSelected && res?.data?.length > 0) {
      yield put(AddSelectedCandId({ data: res?.data[0].candidateID }));
    }
    yield put(AddCandidatesList({ data: res?.data }));
  } catch (error: any) {
    yield put(AddCandidatesList({ data: [] }));
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* addSelectedCandId({ payload }: any) {
  try {
    console.log("payload.selectedCandidateId", payload.selectedCandidateId);
    yield put(StartDetailsLoading());
    yield put(AddSelectedCandId({ data: payload.selectedCandidateId }));
  } catch (error: any) {
    yield put(AddSelectedCandId({ data: 0 }));
    yield put(StopDetailsLoading());
  }
}

export function* getSelectedCandidateDetails({ payload }: any) {
  interface ApiResType2 extends ApiResType {
    recent: any;
  }
  try {
    yield put(StartDetailsLoading());
    const res: ApiResType2 = yield call(getCandidateAllDetailsReq, {
      candidateID: payload.selectedCandidateId,
    });
    yield put(GetSelectedCandidateDetails({ data: res?.data }));
  } catch (error: any) {
    yield put(GetSelectedCandidateDetails({ data: {} }));
    yield put(StopDetailsLoading());
  }
}

export function* clearCandidateSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
