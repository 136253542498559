import { createSlice } from "@reduxjs/toolkit";
import { ProfileType } from "../../../types/userTypes";
import { getProfileReq } from "../../../api/user/profileReq";
import { ApiResType } from "../../../types/apiTypes";
import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";

export interface ProfileSlicetypes {
  error: boolean;
  message: string;
  data: ProfileType | null;
  loading: boolean;
}

const initialState: ProfileSlicetypes = {
  error: false,
  message: "",
  data: null,
  loading: false,
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState: initialState,
  reducers: {
    AddProfileDetails: (
      state: ProfileSlicetypes,
      { payload }: { payload: ProfileType | null }
    ) => {
      state.data = payload ? { ...payload } : null;
      state.loading = false;
      state.error = false;
      return state;
    },
    StartLoading: (state: ProfileSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: ProfileSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: ProfileSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const { AddProfileDetails, StartLoading, StopLoading, ClearData } =
  ProfileSlice.actions;

export default ProfileSlice.reducer;

export function* fetchProfileDetails() {
  try {
    yield put(StartLoading());
    const res: ApiResType = yield call(getProfileReq);
    yield put(AddProfileDetails(res?.data));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(AddProfileDetails(null));
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
  }
}

export function* clearProfileSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
