import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { ProfileType } from "../../types/userTypes";
import { checkTokenValidation } from "../auth/tokenReq";
import { CardsetWeekType } from "../../types/candidateTypes";

type getProfileReqPropType = {};

export const getCandidateCardsetReq = async (
  data?: getProfileReqPropType,
  cancleProfileToken?: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidateCardSetReq->getCandidateCardsetReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.getCandidatesKey}`);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = {
      firstName: resData?.user?.firstName || "",
      lastName: resData?.user?.lastName || "",
      designation: resData?.user?.designation || "",
      workLocation: resData?.user?.workLocation || "",
      companyID: resData?.user?.companyID || "",
      userID: resData?.user?.userID || "",
      email: resData?.user?.email || "",
      mobile: resData?.user?.mobile || "",
      role: resData?.user?.role || "",
    };
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const addCandidateCardSetReq = async (data: {
  weekNumber: number;
  dateFrom: string;
  dateTo: string;
  cardSetIDs: any;
  candidateID: number;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": cardSetCategoryReq->getCardSetCatReq " + t);
  try {
    await checkTokenValidation();
    const body = {
      weekNumber: data?.weekNumber,
      dateFrom: data?.dateFrom,
      dateTo: data?.dateTo,
      cardSetIDs: data?.cardSetIDs,
      candidateID: data?.candidateID,
    };
    const res = await authAxios.post(`${ApiKey.addCandidateCardSetKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "Candidate cardSet added successfull*";
    const _data = null;

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCandidateCardSetReq = async (data: CardsetWeekType[]) => {
  const logfun = (d: any, t: string) =>
    console.log(
      d,
      new Date() + ": candidateCardSetReq->updateCandidateCardSetReq " + t
    );
  try {
    await checkTokenValidation();
    const body = { cardSets: data };
    const res = await authAxios.post(
      `${ApiKey.updateCandidateCardSetKey}`,
      body
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "Updated candidate cardSet successfull*";
    const _data = null;

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const deleteCandidateCardSetReq = async (data: {
  weekNumber: number;
  dateFrom: string;
  dateTo: string;
  cardSetID: number;
  candidateID: number;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": cardSetCategoryReq->getCardSetCatReq " + t);
  try {
    await checkTokenValidation();
    const body = {
      weekNumber: data?.weekNumber,
      dateFrom: data?.dateFrom,
      dateTo: data?.dateTo,
      cardSetID: data?.cardSetID,
      candidateID: data?.candidateID,
    };
    const res = await authAxios.post(
      `${ApiKey.deleteCandidateCardSetKey}`,
      body
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "Deleted candidate cardSet successfull*";
    const _data = null;

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
