import { ApiKey } from "../apiKeys";
import { checkTokenValidation } from "../auth/tokenReq";
import { authAxios } from "../config";

type getPlansListReqPropType = {};

export const getPlansListReq = async (data?: getPlansListReqPropType) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": plansReq->getPlansListReq " + t);
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.plansListKey}`);
    //  logfun(res?.data, "res");
    const resData = res?.data;
    const _data = {
      plans: resData?.plansObj?.map((item: any) => ({
        subscriptionID: item?.subscriptionID || "",
        subscriptionName: item?.subscriptionName || "",
        subscriptionTagLine: item?.subscriptionTagLine || "",
        subscriptionFees: item?.subscriptionFees || "",
        EVPCost: item?.EVPCost || "",
        recruitersLimit: item?.recruitersLimit || "",
        expertsLimit: item?.expertsLimit || "",
        candidatesLimit: item?.candidatesLimit || "",
        subscriptionDuration: item?.subscriptionDuration || "",
        createdAt: item?.createdAt || "",
        updatedAt: item?.updatedAt || "",
      })),
      CurrentSubscriptionDetails: {
        lastPaymentDate:
          resData?.CurrentSubscriptionDetails?.lastPaymentDate || "",
        subscriptionValidUpto:
          resData?.CurrentSubscriptionDetails?.subscriptionValidUpto || "",
        Status: resData?.CurrentSubscriptionDetails?.Status || "",
        lastAmtPaid: resData?.CurrentSubscriptionDetails?.lastAmtPaid || "",
        paymentLink: resData?.CurrentSubscriptionDetails?.paymentLink || "",
      },
    };
    // const _data = resData?.plansObj?.map((item: any) => ({
    //   subscriptionID: item?.subscriptionID || "",
    //   subscriptionName: item?.subscriptionName || "",
    //   subscriptionTagLine: item?.subscriptionTagLine || "",
    //   subscriptionFees: item?.subscriptionFees || "",
    //   EVPCost: item?.EVPCost || "",
    //   recruitersLimit: item?.recruitersLimit || "",
    //   expertsLimit: item?.expertsLimit || "",
    //   candidatesLimit: item?.candidatesLimit || "",
    //   subscriptionDuration: item?.subscriptionDuration || "",
    //   createdAt: item?.createdAt || "",
    //   updatedAt: item?.updatedAt || "",
    // }));
    return { error: false, data: _data, message: "", errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
