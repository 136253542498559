import React, { useRef, useState, useEffect, memo } from "react";
import AWS from "aws-sdk";
import Spinner from "react-bootstrap/Spinner";

import fileUploadImg from "../../assets/svgImages/upload_file.svg";

import styles from "./uploadFileBlock.module.scss";
import RenderIf from "../renderIf";
import ActivityIndicator from "../activityIndicator/activityIndicator";
import { toast } from "react-toastify";

const FileInputBlock = (props) => {
  const { discription, uploadIcon, path, onUpload, reset, accept } = props;

  const [selectedFilesIs, setselectedFilesIs] = useState([]);
  const [imageLoder, setimageLoder] = useState(false);
  const [uploadError, setuploadError] = useState("");
  const [uploadedPath, setuploadedPath] = useState("");
  const [hideFile, sethideFile] = useState("");

  const fileInputRef = useRef();
  // ------------------------------------------------------------
  const S3_BUCKET = process.env.REACT_APP__S3_BUCKET_DEV;
  const REGION = process.env.REACT_APP__S3_REGION_DEV;

  console.log(S3_BUCKET, REGION, "S3_BUCKET", "REGION");

  AWS.config.update({
    accessKeyId: atob(process.env.REACT_APP__ACCESS_KEY_ID_DEV),
    secretAccessKey: atob(process.env.REACT_APP__SECRET_ACCESS_KEY_DEV),
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadFile = (fileIs) => {
    const _file = fileIs;

    const _imagePath = path ? `${path}/${_file.name}` : `files/${_file.name}`;

    if (!_file?.name) return toast.error("Choose a file to upload");
    setimageLoder(true);
    const params = {
      ACL: "public-read",
      Body: _file,
      Bucket: S3_BUCKET,
      Key: _imagePath,
    };
    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
        console.log(evt, "evt of file upload");
      })
      .send((err) => {
        if (err) {
          setimageLoder(false);
          setuploadError(`${err}`);
          setuploadedPath("");
          console.log(err, "uploadFile/image error");
          return;
        }
        onUpload({
          path: process.env.REACT_APP__S3_FILE_CDNA + _imagePath,
        });
        setuploadedPath(process.env.REACT_APP__S3_FILE_CDNA + _imagePath);
      });
    setimageLoder(false);

    // setimageLoder(false);
  };

  // ------------------------------------------------------------
  const onDragOverFun = (e) => {
    e.preventDefault();
  };

  const onDropFun = (e) => {
    e.preventDefault();
    // setselectedFilesIs(e.dataTransfer.files[0]);
    uploadFile(e.dataTransfer.files[0]);

    console.log(e.dataTransfer?.files);
  };

  const onSelectFileFun = (e) => {
    if (!e?.target?.files[0]) return;
    // setuploadedPath("");
    // setselectedFilesIs(e.target.files[0]);
    uploadFile(e.target.files[0]);

    console.log(e.target.files);
  };

  useEffect(() => {
    if (reset === undefined || reset === null) return;
    if (!reset) {
      // sethideFile(reset);
      setuploadedPath("");
    }
  }, [reset]);

  return (
    <div className={`${styles.FileInputBlock} `}>
      <RenderIf isShow={imageLoder}>
        <div
          className={`${styles.file_input_block1} flex-grow-1`}
          style={{ minHeight: "100px" }}
        >
          <Spinner animation="border" variant="primary" size="md" />
        </div>
      </RenderIf>
      <RenderIf isShow={!imageLoder}>
        <div
          className={`${styles.file_input_block1}  h-100`}
          onDragOver={onDragOverFun}
          onDrop={onDropFun}
        >
          <button
            type="button"
            className={`${styles.select_btn} flex-grow-1 `}
            onClick={(event) => {
              event.stopPropagation();
              fileInputRef.current.click();
            }}
          >
            {uploadedPath ? (
              <>
                <div
                  className="border"
                  style={{ height: "auto", width: "100%" }}
                >
                  <img
                    src={uploadedPath}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={`${styles.img_block}`}>
                <img src={uploadIcon || fileUploadImg} />
              </div>
            )}
            <RenderIf isShow={!uploadedPath}>
              {!!discription ? (
                <p className="xx-sm-text dark-text" style={{ margin: "0px" }}>
                  {discription}
                </p>
              ) : (
                <p className="xx-sm-text">
                  Browse or Drag and Drop
                  <br /> to Upload file
                  <br /> Pdf, Max 5mb
                </p>
              )}
            </RenderIf>
            <input
              type="file"
              accept={accept ? accept : ""}
              hidden
              ref={fileInputRef}
              onChange={onSelectFileFun}
            />
          </button>
          {!!uploadError && (
            <h6 className="sm-text text-danger" onClick={uploadFile}>
              Error occured
            </h6>
          )}
          {!uploadError && (
            <h6 className="upload-btn sm-text" onClick={uploadFile}>
              {!!uploadedPath && "Click on photo to replace"}
              {!uploadedPath && "Upload Now"}
            </h6>
          )}
        </div>
      </RenderIf>
    </div>
  );
};

export default memo(FileInputBlock);
