import React, { useCallback, useEffect, useState } from "react";
// import {useFocusEffect} from '@react-navigation/native';
import dayjs from "dayjs";

type TimerPropsType = {
  runTimer: boolean;
  duration?: number;
  reset?: boolean;
  onFinish: () => void;
  onStart: () => void;
  customeTimer?: any;
};

const Timer = (props: TimerPropsType) => {
  const { runTimer, duration, reset, onFinish, onStart, customeTimer } = props;

  const SECONDS = 59;
  const DURATION = duration || 5;
  const MINUITES = DURATION - 1;

  const [secondsIs, setsecondsIs] = useState(SECONDS);
  const [minutesIs, setminutesIs] = useState<number>(MINUITES);

  const [startTimer, setstartTimer] = useState(runTimer);
  const [resetTimer, setresetTimer] = useState(false);
  const [endTimeIs, setendTimeIs] = useState(dayjs().add(DURATION, "m"));

  const onReset = () => {
    setendTimeIs(dayjs().add(DURATION, "m"));
  };

  useEffect(() => {
    if (!startTimer) return;
    const timerId = setInterval(() => {
      const timeDiffrence = dayjs(endTimeIs).diff(new Date(), "s");
      if (timeDiffrence <= 0) {
        onFinish();
        clearInterval(timerId);
      }
      const minutes = Math.floor(timeDiffrence / 60);
      const seconds = timeDiffrence - minutes * 60;
      setminutesIs(minutes);
      setsecondsIs(seconds);
      console.log(timeDiffrence, minutes, seconds, "timer is running");
    }, 1000);
    return () => {
      clearInterval(timerId);
      console.log("cleared Interval", timerId);
    };
  }, [startTimer, endTimeIs]);

  // useFocusEffect(
  //   useCallback(() => {
  //     if (!startTimer) return;
  //     const timerId = setInterval(() => {
  //       const timeDiffrence = dayjs(endTimeIs).diff(new Date(), 's');
  //       if (timeDiffrence <= 0) {
  //         onFinish();
  //         clearInterval(timerId);
  //       }
  //       const minutes = Math.floor(timeDiffrence / 60);
  //       const seconds = timeDiffrence - minutes * 60;
  //       setminutesIs(minutes);
  //       setsecondsIs(seconds);
  //       console.log(timeDiffrence, minutes, seconds, 'timer is running');
  //     }, 1000);
  //     return () => {
  //       clearInterval(timerId);
  //       console.log('cleared Interval', timerId);
  //     };
  //   }, [startTimer, endTimeIs]),
  // );

  useEffect(() => {
    onReset();
  }, [reset]);

  useEffect(() => {
    if (startTimer) return onStart();
  }, [startTimer]);

  return (
    <>
      {customeTimer ? (
        customeTimer(minutesIs, secondsIs)
      ) : (
        <p onClick={() => setstartTimer(!startTimer)}>{`0${minutesIs}:${
          secondsIs < 10 ? "0" + secondsIs : secondsIs
        }`}</p>
      )}
    </>
  );
};

export default React.memo(Timer);
