import React from "react";

const LoginLog = () => {
  const loggedinDate = localStorage.getItem("@loggedDate");
  return (
    <div className={`d-flex justify-content-end me-2`}>
      <p className="sm-text x-light-text">Logged In</p>
      <p className="sm-text">: {loggedinDate}</p>
    </div>
  );
};

export default LoginLog;
