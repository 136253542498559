import React, { useEffect, useState } from "react";

import styles from "../helpPage.module.scss";
import { Card, Container } from "react-bootstrap";
import { SagaActions } from "../../../redux/actionTypes";

import mailIcon from "../../../assets/svgIcons/mail-icon.svg";
import phoneIcon from "../../../assets/svgIcons/telephone-icon.svg";
import LoginLog from "../../../components/microComponents/loginLog";
import { getContactUsReq } from "../../../api/other/contactUsReq";
import { useDispatch, useSelector } from "react-redux";

const ContactUsPage = () => {
  const Dispatch = useDispatch();
  const { profile } = useSelector((_state) => _state);
  const _companyID = profile?.data?.companyID || null;
  const _profile = profile?.data || null;

  const [contactList, setcontactList] = useState([]);

  const fetchContactUsDetails = async () => {
    try {
      if (!_companyID) return;
      const res = await getContactUsReq({ companyID: _companyID });
      setcontactList(res?.data);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "contactUsPage",
          methodName: "fetchContactUsDetails",
          type: "info",
        },
      });
    } catch (error) {
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "contactUsPage",
          methodName: "fetchContactUsDetails",
          type: "error",
        },
      });
    }
  };

  useEffect(() => {
    fetchContactUsDetails();
  }, []);

  const ContactDetailsCard = ({ item }) => {
    const _position = item?.designation || "";
    const _name = `${item?.firstName} ${item?.lastName}` || "";
    const _email = item?.email || "";
    const _phone = item?.mobileNumber || "";
    return (
      <Card
        className={`shadow-block border-0 py-4 px-5 my-2 ${styles.card_block}`}
      >
        {!!_position && <h6 className={`md-text b`}>{_position}</h6>}
        {!!_name && <p className={`py-2 md-text`}>{_name}</p>}
        {!!_email && (
          <div className={`d-flex align-items-center py-2`}>
            <div className={`${styles.icon_box}`}>
              <img src={mailIcon} />
            </div>
            <p className={`md-text`}>{_email}</p>
          </div>
        )}
        {!!_phone && (
          <div className={`d-flex align-items-center py-2`}>
            <div className={`${styles.icon_box}`}>
              <img src={phoneIcon} />
            </div>
            <p className={`md-text`}>+91 {_phone}</p>
          </div>
        )}
      </Card>
    );
  };

  return (
    <div className={`${styles.ContactUsPage}`}>
      <LoginLog />
      <p>&nbsp;</p>
      <Container>
        <div className={`ps-5`}>
          <h3 className={`x-lg-text`}>
            Hi {_profile?.firstName} {_profile?.lastName} !
          </h3>
        </div>
        <div className={`ps-5 py-3`}>
          <p className={`md-text`}>
            We’re available over email, chat and calls.
          </p>
        </div>
        {contactList?.slice(0, 1).map((item, idx) => (
          <div key={idx}>
            <ContactDetailsCard item={item} />
            {!!item?.CEPPartnerInfo && (
              <ContactDetailsCard item={item?.CEPPartnerInfo} />
            )}
          </div>
        ))}
        {/* <Card
          className={`shadow-block border-0 py-4 px-5 my-2 ${styles.card_block}`}
        >
          <h6 className={`md-text b`}>E-Admin of your company</h6>
          <p className={`py-2`}>Mary Jane</p>
          <div className={`d-flex align-items-center py-2`}>
            <div className={`${styles.icon_box}`}>
              <img src={mailIcon} />
            </div>
            <p className={`md-text`}>mary.j@amazon.in</p>
          </div>
          <div className={`d-flex align-items-center py-2`}>
            <div className={`${styles.icon_box}`}>
              <img src={phoneIcon} />
            </div>
            <p className={`md-text`}>+91 989804176</p>
          </div>
        </Card> */}
      </Container>
    </div>
  );
};

export default ContactUsPage;
