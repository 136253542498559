import React, { useState, useEffect } from "react";
import { IoClose, IoChevronDown, IoChevronUp } from "react-icons/io5";

import "./dropdownMenu.scss";

const DropdownMenu = (props) => {
  const {
    headerComponent,
    renderListItem,
    listData,
    headerClass,
    expand = false,
    onClick,
  } = props;

  const [showList, setshowList] = useState(false);

  useEffect(() => {
    setshowList(expand);
  }, [expand]);

  return (
    <div className="DropdownMenu">
      <header
        className={`menu-header ${
          headerClass && headerClass({ showList: showList })
        }`}
        onClick={() => {
          if (showList) {
            setshowList(!showList);
          }
          onClick();
        }}
      >
        {headerComponent ? (
          headerComponent({ showList: showList })
        ) : (
          <p className="bold">Mention Header Component</p>
        )}
        {showList ? (
          <IoChevronUp color="#002C71" />
        ) : (
          <IoChevronDown color="#002C71" />
        )}
      </header>
      {showList ? (
        <>{listData?.map((item, idx) => renderListItem({ item: item }))}</>
      ) : null}
    </div>
  );
};

export default DropdownMenu;
