import { createSlice } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

type stateType = {
  modal: boolean;
  showAddCardSet: boolean;
  showCardSetPicker: boolean;
  showCandidateStatus: boolean;
  showCandidatePublish: boolean;
  showDefaultCardset: boolean;
  showNotifications: boolean;
  showLogoutModal: boolean;
};

const initialState: stateType = {
  modal: false,
  showAddCardSet: false,
  showCardSetPicker: false,
  showCandidateStatus: false,
  showCandidatePublish: false,
  showDefaultCardset: false,
  showNotifications: false,
  showLogoutModal: false,
};
const ModalSlice = createSlice({
  name: "Modal",
  initialState: initialState,
  reducers: {
    ShowModal: (state, { payload }) => {
      const _state = { ...state, ...payload };
      return _state;
    },
    CloseModal: (state) => {
      const _state = initialState;
      return _state;
    },
  },
});

export const { ShowModal, CloseModal } = ModalSlice.actions;

export default ModalSlice.reducer;

export function* closeModal() {
  try {
    yield put(CloseModal());
  } catch (error) {
    yield put(CloseModal());
  }
}
export function* addCardSetModalShow() {
  try {
    yield put(ShowModal({ showAddCardSet: true, modal: true }));
  } catch (error) {
    yield put(ShowModal({ showAddCardSet: true, modal: true }));
  }
}
export function* notificationsModalShow() {
  try {
    yield put(ShowModal({ showNotifications: true, modal: true }));
  } catch (error) {
    yield put(ShowModal({ showNotifications: true, modal: true }));
  }
}

export function* cardSetPickerModalShow() {
  try {
    yield put(ShowModal({ showCardSetPicker: true, modal: true }));
  } catch (error) {
    yield put(ShowModal({ showCardSetPicker: true, modal: true }));
  }
}

export function* defaultCardsetModalShow() {
  try {
    yield put(ShowModal({ showDefaultCardset: true, modal: true }));
  } catch (error) {
    yield put(ShowModal({ showDefaultCardset: true, modal: true }));
  }
}

export function* candidateStatusModalShow() {
  try {
    yield put(ShowModal({ showCandidateStatus: true, modal: true }));
  } catch (error) {
    yield put(ShowModal({ showCandidateStatus: true, modal: true }));
  }
}
export function* candidatePublishModalShow() {
  try {
    yield put(ShowModal({ showCandidatePublish: true, modal: true }));
  } catch (error) {
    yield put(ShowModal({ showCandidatePublish: true, modal: true }));
  }
}
export function* logoutModalShow() {
  try {
    yield put(ShowModal({ showLogoutModal: true, modal: true }));
  } catch (error) {
    yield put(ShowModal({ showLogoutModal: true, modal: true }));
  }
}
