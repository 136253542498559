export const ROUTE_NAMES = {
  FROM_PADMIN: "/from-padmin/loading/:token",
  INITIAL: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  HOME: "/home",
  mainLayout: "app",
  dashboard: "dashboard",
  CANDIDATE: "candidates",
  addCandidate: "new",
  allCandidate: "all",
  basic_info_form: "basic-info",
  cardSetForm: "card-sets",
  resourcesForm: "resources",
  reviewForm: "review",
  cardSetsPage: "cardsets",
  companyIntro: "company",
  report: "report",
  help: "help",
  HowItWorksPage: "how-it-works",
  contact_us: "contact",
  faq: "faq",
  howItWorks: "onboard",
  myAccount: "account",
  welcome: "welcome",
  table: "actions",
  companyProfile: "company-profile",
  plan: "subscription",
  addRecruiter: "recruiter",
};
