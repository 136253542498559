import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import saga from "./saga";

import profileSlice from "./slices/userSlices/profileSlice";
import modalSlice from "./slices/modalSlice/modalSlice";
import notificationSlice from "./slices/notificationSlice/notificationSlice";
import cardSetCatSlice from "./slices/cardSetSlices/cardSetCatSlice";
import cardSetSlice from "./slices/cardSetSlices/cardSetSlice";
import cepCompanyProfileSlice from "./slices/companySclice/cepCompanyProfileSlice";
import companyProfileSlice from "./slices/companySclice/companyProfileSlice";
import userSettingSlice from "./slices/userSlices/userSettingSlice";
import candidateSlice from "./slices/candidateSlice/candidateSlice";
import candidateAddCardsetSlice from "./slices/candidateSlice/candidateAddCardsetSlice";
import candidateFormSlice from "./slices/candidateSlice/candidateFormSlice";
import hrActionSlice from "./slices/hrSlice/hrActionSlice";
import logSlice from "./slices/logsSlice/logSlice";

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["modals", "candidateCardsetList", "candidateForm", "candidates"],
};

const rootReducer = combineReducers({
  profile: profileSlice,
  modals: modalSlice,
  notification: notificationSlice,
  cardsetCat: cardSetCatSlice,
  cardset: cardSetSlice,
  cepCompanyProfile: cepCompanyProfileSlice,
  companyProfile: companyProfileSlice,
  userSettings: userSettingSlice,
  candidates: candidateSlice,
  candidateCardsetList: candidateAddCardsetSlice,
  candidateForm: candidateFormSlice,
  hrActions: hrActionSlice,
  appLogs: logSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middleware),
});

sagaMiddleware.run(saga);

export const PersistorStore = persistStore(Store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;
