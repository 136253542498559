import { createSlice } from "@reduxjs/toolkit";
import { ApiResType } from "../../../types/apiTypes";
import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";

import { CardsetWeekType } from "../../../types/candidateTypes";
import { CardSetType } from "../../../types/cardsTpes";
import { ResourceCreateType } from "../../../types/otherTypes";

type personalDetailsType = {
  email: string | number;
  firstName: string | number;
  lastName: string | number;
  mobile: string | number;
  designation: string | number;
  engagementScore: string | number;
  yearOfExperience: string | number;
  jobLevel: string | number;
  dateOfJoining: string | number;
  department: string | number;
  team: string | number;
  baseLocation: string | number;
  workLocation: string | number;
  workModel: string | number;
  offerExpiryDays: string | number;
  fixedComponentPA: string | number;
  variableComponentPA: string | number;
  variablePayoutFreq: string | number;
  salaryCtcPA: string | number;
  approxTakeHomePM: string | number;
  offerLetterURL: string;
  countryCode: string | number;
  TimeZone: string;
};

const initialPersonalDetails = {
  email: "",
  firstName: "",
  lastName: "",
  mobile: "",
  designation: "",
  engagementScore: "",
  yearOfExperience: "",
  jobLevel: "",
  dateOfJoining: "",
  department: "",
  team: "",
  baseLocation: "",
  workLocation: "",
  workModel: "",
  offerExpiryDays: "",
  fixedComponentPA: "",
  variableComponentPA: "",
  variablePayoutFreq: "",
  salaryCtcPA: "",
  approxTakeHomePM: "",
  offerLetterURL: "",
  countryCode: "",
  TimeZone: "",
};

export interface initalStateType {
  error: boolean;
  message: string;
  data: {
    personalDetails: personalDetailsType | null;
    resources: ResourceCreateType[];
  };
  loading: boolean;
}

const initialState: initalStateType = {
  error: false,
  message: "",
  data: {
    personalDetails: initialPersonalDetails,
    resources: [],
  },
  loading: false,
};

const CandidateFormSlice = createSlice({
  name: "CandidateForm",
  initialState: initialState,
  reducers: {
    AddCandidatePersonalDetails: (
      state: initalStateType,
      { payload }: { payload: personalDetailsType }
    ) => {
      state.loading = false;
      state.data.personalDetails = {
        ...state.data.personalDetails,
        ...payload,
      };
      state.error = false;
      return state;
    },
    AddCandidateResourcesDetails: (
      state: initalStateType,
      { payload }: { payload: ResourceCreateType[] }
    ) => {
      state.loading = false;
      state.data.resources = payload;
      state.error = false;
      return state;
    },

    StartLoading: (state: initalStateType) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: initalStateType) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: initalStateType) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  AddCandidatePersonalDetails,
  AddCandidateResourcesDetails,
  StartLoading,
  StopLoading,
  ClearData,
} = CandidateFormSlice.actions;

export default CandidateFormSlice.reducer;

export function* addCandidatePersonalDetails(data: any) {
  try {
    yield put(AddCandidatePersonalDetails(data?.payload));
  } catch (error) {}
}
export function* addCandidateResourcesDetails(data: any) {
  try {
    yield put(AddCandidateResourcesDetails(data?.payload));
  } catch (error) {}
}

export function* clearCandidateFormSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
