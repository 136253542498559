import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Scatter, Doughnut } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import React from "react";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const ScatterData = {
  datasets: [
    {
      data: Array.from({ length: 50 }, () => ({
        x: faker.datatype.number({ min: -100, max: 100 }),
        y: faker.datatype.number({ min: -100, max: 100 }),
      })),
      backgroundColor: ["#20E19B", "#3A93F4", "#FFB964", "#FF8982"],
    },
  ],
};

const ScatterChart = () => {
  return (
    <Scatter options={options} data={ScatterData} style={{ height: "100%" }} />
  );
};

export default ScatterChart;
