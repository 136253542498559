import { ApiKey } from "../apiKeys";
import { publicAxios } from "../config";
import dayjs from "dayjs";

export const registerUserReq = async (data: {
  firstName: string;
  lastName?: string;
  email: string;
  mobile: number | string;
  password: string;
  role: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": registerReq->registerUserReq " + t);
  try {
    const body = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      mobile: data?.mobile,
      password: data?.password,
      role: data?.role,
    };
    //  logfun(body, "body");
    const res = await publicAxios.post(`${ApiKey.registerUserKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "Registration successfull*";
    const _data = {
      id: resData?.user?.id || "",
      email: resData?.user?.email || "",
      phoneNumber: resData?.user?.mobile || "",
      role: resData?.user?.role || "",
      userConsent: resData?.user?.userConsent || false,
      token: resData?.accessToken || "",
    };
    await localStorage.setItem("token", resData?.accessToken);
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
export const registerWithPswdReq = async (data: {
  firstName: string;
  lastName?: string;
  emailVerified: number | string;
  SMSVerified: number | string;
  password: string;
  userID: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": registerReq->registerWithPswdReq " + t);
  try {
    const FMCToken = localStorage.getItem("@deviceToken");
    const body = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      password: data?.password,
      EmailVerified: data?.emailVerified,
      SMSVerified: data?.SMSVerified,
      userID: data?.userID,
      deviceID: FMCToken || "",
      deviceType: "Web",
    };
    //  logfun(body, "body");
    const res = await publicAxios.post(`${ApiKey.setPasswordKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "Registration successfull*";
    const _data = {
      id: resData?.user?.id || "",
      email: resData?.user?.email || "",
      phoneNumber: resData?.user?.mobile || "",
      role: resData?.user?.role || "",
      userConsent: resData?.user?.userConsent || false,
      token: resData?.accessToken || "",
      accessTokenExpireTime: resData?.accessTokenExpireTime || "",
      refreshToken: resData?.refreshToken || "",
    };

    if (!_data?.token)
      return { error: false, data: null, message: _msg, errorMsg: "" };
    const _expireDate = dayjs().add(
      Number(resData?.accessTokenExpireTime) - 60,
      "s"
    );

    await localStorage.setItem("token", resData?.accessToken);
    await localStorage.setItem("tokenExpiresOn", JSON.stringify(_expireDate));
    await localStorage.setItem("refreshToken", resData?.refreshToken);
    await localStorage.setItem(
      "tokenExpireDuration",
      `${resData?.accessTokenExpireTime - 60}`
    );

    await localStorage.setItem("@role", resData?.user?.role);
    await localStorage.setItem("userID", `${resData?.user?.id}`);
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
