export const validate = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  phone: /^[5-9]\d{9}$/,
  gstNo: /^.{15}$/,
  // gstNo: /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
  password: {
    length: "",
    specialChar: /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~ ]/,
  },
  blockedEmailDomains: [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "inbox.com",
    "mail.com",
  ],
  url: "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)",
  cardTitle: /^\s{0,1}\s.{4,}$/,
};
