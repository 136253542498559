import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { LogsType } from "../../../types/logTypes";
import { call, put } from "redux-saga/effects";

type sliceStateType = {
  loading: boolean;
  uuid: string;
  userID: string;
  deviceType: string;
  data: LogsType[];
  error: boolean;
  message: string;
};

const initialState: sliceStateType = {
  loading: false,
  uuid: "",
  userID: "",
  deviceType: "Web",
  data: [],
  error: false,
  message: "",
};

type payloadType = {
  env: "prod" | "dev";
  message: any;
  method: string;
  fileName: string;
  userAction?: string;
  type: "warn" | "error" | "info" | "action";
};

const LogSlice = createSlice({
  name: "logs",
  initialState: initialState,
  reducers: {
    AddLogs: (state: sliceStateType, { payload }: { payload: payloadType }) => {
      const body = {
        uuid: state?.uuid || "",
        userID: state?.userID || "",
        date: new Date(),
        device: state?.deviceType,
        message: `${payload?.message}`,
        methodName: payload?.method,
        userAction: payload?.userAction || "",
        fileName: payload?.fileName,
        type: payload?.type,
      };
      state.data.push(body);
      return state;
    },
    AddErrorLogs: (
      state: sliceStateType,
      { payload }: { payload: payloadType }
    ) => {
      const body = {
        uuid: state?.uuid || "",
        userID: state?.userID || "",
        date: new Date(),
        device: state?.deviceType,
        message: `${payload?.message}`,
        methodName: payload?.method,
        userAction: payload?.userAction || "",

        fileName: payload?.fileName,
        type: "error",
      };
      state.data.push(body);
      return state;
    },
    AddInfoLogs: (
      state: sliceStateType,
      { payload }: { payload: payloadType }
    ) => {
      const body = {
        uuid: state?.uuid || "",
        userID: state?.userID || "",
        date: new Date(),
        device: state?.deviceType,
        message: `${payload?.message}`,
        methodName: payload?.method,
        userAction: payload?.userAction || "",

        fileName: payload?.fileName,
        type: "info",
      };
      state.data.push(body);
      return state;
    },
    AddWarnLogs: (
      state: sliceStateType,
      { payload }: { payload: payloadType }
    ) => {
      const body = {
        uuid: state?.uuid || "",
        userID: state?.userID || "",
        date: new Date(),
        device: state?.deviceType,
        message: `${payload?.message}`,
        methodName: payload?.method,
        userAction: payload?.userAction || "",

        fileName: payload?.fileName,
        type: "warn",
      };
      state.data.push(body);
      return state;
    },
    AddNewUUID: (state) => {
      state.uuid = `${uuidv4()}`;
      return state;
    },
    AddUserID: (state, { payload }) => {
      state.userID = payload;
      return state;
    },
    ClearLogs: (state) => {
      state.data = [];
      state.uuid = `${uuidv4()}`;
      return state;
    },
  },
});

export const {
  AddLogs,
  AddErrorLogs,
  AddInfoLogs,
  AddWarnLogs,
  ClearLogs,
  AddNewUUID,
  AddUserID,
} = LogSlice.actions;

export default LogSlice.reducer;

export function* addErrorLogsAction({ payload }: any) {
  yield put(AddErrorLogs(payload));
}
export function* addWarnLogsAction({ payload }: any) {
  yield put(AddWarnLogs(payload));
}
export function* addInfoLogsAction({ payload }: any) {
  yield put(AddInfoLogs(payload));
}

export function* addNewUUIDAction() {
  yield put(AddNewUUID());
}
export function* addUserIDAction({ payload }: any) {
  yield put(AddUserID(payload));
}

export function* clearLogs() {
  yield put(ClearLogs());
}
