import React from "react";
import styled from "styled-components";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import RenderIf from "../renderIf";
// import candidateIcon from "../../assets/svgIcons/candidate-icon.svg";
import candidateIcon from "../../assets/svgImages/company-card.svg";
import active_candidateIcon from "../../assets/svgIcons/candidate-icon-active.svg";

type StatsBoxOneType = {
  label: string;
  numbers: number | string;
  desc?: string;
  inPercentage?: boolean;
  inRupees?: boolean;
  className?: string;
  totalValue?: number | string;
  presentValue?: number | string;
  totalValueLabel?: string;
  presentValueLabel?: string;
};
const StatsBoxOne = (props: StatsBoxOneType) => {
  const {
    label,
    numbers,
    desc,
    inPercentage,
    inRupees,
    className,

    totalValue,
    presentValue,
    totalValueLabel,
    presentValueLabel,
  } = props;
  return (
    <StatsBoxOneBox className={`${className || ""} shadow-block`}>
      <p className="">{label}</p>
      <div className="inside_box">
        <RenderIf isShow={inPercentage}>
          <div className="progress_circle_box">
            <CircularProgressbarWithChildren
              value={Number(numbers)}
              styles={{
                path: {
                  stroke: "#002C71",
                },
              }}
            >
              <div style={{ fontSize: 15 }}>
                <strong className="primary-color">{`${Number(
                  numbers
                )}%`}</strong>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </RenderIf>
        <RenderIf isShow={!inPercentage && !inRupees}>
          <div className="image_box ">
            <img src={candidateIcon} />
          </div>
        </RenderIf>

        <RenderIf isShow={inRupees}>
          <h1 className="primary-color" style={{ fontSize: "4em" }}>
            ₹
          </h1>
          <p className="numbers_text secondary-color">{`₹ ${Number(
            numbers
          ).formatRupee()}`}</p>
        </RenderIf>
        <RenderIf
          isShow={!inRupees && !inPercentage && (numbers || numbers === 0)}
        >
          <p className="numbers_text secondary-color">{`${Number(numbers)}`}</p>
        </RenderIf>

        {/* <p className="numbers_text">
        {inRupees && `₹ ${Number(numbers).formatRupee()}`}
        {!inRupees && numbers}
        {inPercentage && "%"}
      </p> */}
        <RenderIf
          isShow={
            !!totalValue ||
            !!presentValue ||
            totalValue === 0 ||
            presentValue === 0
          }
        >
          <p className="sm-text">
            {presentValueLabel}:
            <span className="secondary-color lg-text"> {presentValue}</span>
          </p>
          <p className="sm-text">
            {totalValueLabel}:
            <span className="secondary-color lg-text"> {totalValue}</span>
          </p>
        </RenderIf>
        {!!desc && <p className="primary-color desc_text">{desc}</p>}
      </div>
    </StatsBoxOneBox>
  );
};

export default StatsBoxOne;

const StatsBoxOneBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* border: 0.5px solid rgb(200, 200, 200); */
  background-color: white;
  border-radius: 6px;
  padding: 5px 2px;
  .inside_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  p {
    margin: 0px;
    padding: 5px;
  }
  .numbers_text {
    font-size: 1.6rem;
    font-weight: bolder;
  }
  .desc_text {
    color: #c19400;
  }
  .progress_circle_box {
    width: 80px;
  }
  .image_box {
    height: 80px;
    width: 70px;
  }
  .image_box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
