import indiaFlagIcon from "../assets/icons/indian_flag.png";
import usaFlagIcon from "../assets/icons/usa_flag.png";
import ukFlagIcon from "../assets/icons/uk_flag.png";

export const COUNTRY_CODES = [
    {
        countryID: 1,
        countryName: "India",
        countryCode: "+91",
        countryImg: indiaFlagIcon,
        countryTimeZone: [
            {
                zone: "India (GMT+5:30)",
                timeZone: "GMT+5:30"
            }
        ]
    },
    {
        countryID: 2,
        countryName: "USA",
        countryCode: "+1",
        countryImg: usaFlagIcon,
        countryTimeZone: [
            {
                zone: "Eastern Standard Time (GMT-5)",
                timeZone: "GMT-5"
            },
            {
                zone: "Central Standard Time (GMT-6)",
                timeZone: "GMT-6"
            },
            {
                zone: "Mountain Standard Time (GMT-7)",
                timeZone: "GMT-7"
            },
            {
                zone: "Pacific Standard Time (GMT-8)",
                timeZone: "GMT-8"
            },
        ]
    },
    {
        countryID: 3,
        countryName: "UK",
        countryCode: "+44",
        countryImg: ukFlagIcon,
        countryTimeZone: [
            {
                zone: "Greenwich Mean Time (UTC +0)",
                timeZone: "UTC +0"
            },
            {
                zone: "British Summer Time (UTC +1)",
                timeZone: "UTC +1"
            }
        ]
    },
]