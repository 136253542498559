import React, { useState } from "react";

import styles from "../loginPage.module.scss";
import mailIcon from "../../../assets/svgIcons/mail-icon.svg";
import phoneIcon from "../../../assets/svgIcons/telephone-icon.svg";
import inactive_check_circle from "../../../assets/icons/inactive-check-circle.png";
import active_check_circle from "../../../assets/icons/active-check-circle.png";

import { validate } from "../../../constants/validationExp";
import {
  getOtpByMailReq,
  getSignUpOtpReq,
} from "../../../api/auth/otpRequests";
import ActivityIndicator from "../../../components/activityIndicator/activityIndicator";
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useDispatch } from "react-redux";
import { SagaActions } from "../../../redux/actionTypes";
import MobileNoLayout from "../../../components/compLayouts/mobileNoLayout";
import { COUNTRY_CODES } from "../../../constants/countryCodes";
import MobileNoLayout2 from "../../../components/compLayouts/mobileNoLayout2";
type Inputs = {
  emailIs: string;
  phoneNumberIs: string;
};

type SignUpBlockProps = {
  onSubmit: any;
  forgotPassword: boolean;
};

const SignUpBlock = (props: SignUpBlockProps) => {
  const { onSubmit, forgotPassword } = props;
  const navigation = useNavigate();
  const Dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const email = watch("emailIs");
  const phNo = watch("phoneNumberIs");
  const _numberError = errors.phoneNumberIs?.message;

  // const [emailIs, setemailIs] = useState("");
  // const [phoneNumberIs, setphoneNumberIs] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [selectedTimeZone, setSelectedTimeZone] = useState("India (GMT+5:30)");

  const handleCountryCodeChange = (e: any) => {
    const selectedCountryCode = e.countryCode;
    const selectedCountry = COUNTRY_CODES.find(
      (country) => country.countryCode === selectedCountryCode
    );
    if (selectedCountry) {
      setSelectedCountryCode(selectedCountry.countryCode);
      setSelectedTimeZone(selectedCountry.countryTimeZone[0].timeZone);
    }
  };

  const getOtpFun = async (data: any) => {
    try {
      setbtnLoading(true);
      const body = {
        role: "EAdmin",
        email: data.emailIs,
        mobile: data.phoneNumberIs,
        forgotPassword: forgotPassword,
        type: forgotPassword ? "" : "SignUp",
      };
      const res = await getSignUpOtpReq(body);
      setbtnLoading(false);
      toast.success(res?.message);
      localStorage.setItem("@smsOtp", res.data.smsOtp);
      localStorage.setItem("@emailOtp", res.data.emailOtp);
      localStorage.setItem("@ph", data.phoneNumberIs);
      localStorage.setItem("@email", data.emailIs);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "signUpBlock",
          methodName: "getOtpFun",
          type: "info",
        },
      });
      onSubmit({ emailIs: data.emailIs });
    } catch (error: any) {
      console.log(error, "loginPage->LoginPage->getOtpFun error");
      setbtnLoading(false);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "signUpBlock",
          methodName: "getOtpFun",
          type: "error",
        },
      });
    }
  };

  const loginFun = () => {
    navigation(`${ROUTE_NAMES.INITIAL}`);
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      null: () => {},
      login: () => {
        loginFun();
      },
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.SignUpBlock} flex-1 d-flex flex-column`}>
      <form
        onSubmit={handleSubmit((data) => {
          getOtpFun(data);
        })}
        className={`flex-1 d-flex flex-column`}
      >
        <div className={` flex-1 d-flex flex-column`}>
          {/* <h4 className={`lg-text`}> */}
          <h4 style={{ fontSize: 25 }}>
            {forgotPassword ? "Reset Password" : "Setup Password"}
          </h4>
          {/* <p>&nbsp;</p> */}
          <div className="mb-2"></div>

          <div>
            <div className={`${styles.input_block}`}>
              <label className={`md-text mb-1`}>Email ID</label>
              <div
                className={`${styles.input_box}  ${
                  errors?.emailIs?.message && "border-danger"
                } `}
              >
                <div
                  className={`${styles.icon_box}  ms-2 d-flexalign-items-center justify-content-center`}
                >
                  <img
                    src={mailIcon}
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
                <input
                  {...register("emailIs", {
                    required: "Please enter valid email",
                    pattern: {
                      value: validate.email,
                      message: "Entered incorrect email format",
                    },
                  })}
                  className={`md-text flex-1  ms-2 `}
                  placeholder=""
                  style={{ border: "none", outline: "none" }}
                />
                {/* <input
                  type={"email"}
                  value={emailIs}
                  placeholder={""}
                  className={`flex-1`}
                  onChange={(e) => {
                    setemailIs(e.target.value);
                    seterrorMsg("");
                  }}
                /> */}
                <div
                  className={`${styles.icon_box} me-2 d-flex align-items-center justify-content-center`}
                >
                  {validate.email.test(email) ? (
                    <div className={``} style={{ padding: "2px" }}>
                      <img src={active_check_circle} />
                    </div>
                  ) : (
                    <img src={inactive_check_circle} />
                  )}
                </div>
              </div>
              {errors?.emailIs?.message ? (
                <p className={`x-sm-text mt-1 text-danger`}>
                  {errors?.emailIs?.message}
                </p>
              ) : (
                <p className={`x-sm-text mt-1`}>Corporate email ID.</p>
              )}
            </div>
            {/* <Col className={`${styles._col} mt-3`} sm={12} lg={12}>
              <p className={`mb-1 md-text`}>Mobile Number</p>

              <div className={`d-flex w-100`}>
                <div className={`ps-2 me-2 ${styles.country_code_field}`}>
                  <div className={`${styles.flag_icon_box}`}></div>
                  <input
                    disabled
                    className={`md-text border-0 `}
                    placeholder="+91"
                    value={`+91`}
                    id="code"
                  />
                </div>
                <div
                  className={`${styles.input_box} flex-grow-1 ${
                    errors?.phoneNumberIs?.message && "border-danger"
                  }`}
                >
                  <div
                    className={`${styles.icon_box}  ms-2 d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={phoneIcon}
                      style={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                  <input
                    className={`md-text ${styles.mobileNumBox} d-flex ms-2 flex-grow-1 px-1`}
                    placeholder=""
                    type="tel"
                    maxLength={10}
                    {...register("phoneNumberIs", {
                      required: "Enter 10 digit mobile number",
                      maxLength: {
                        value: 10,
                        message: "Enter 10 digit mobile number",
                      },
                      minLength: {
                        value: 10,
                        message: "Enter 10 digit mobile number",
                      },
                    })}
                  />
                  <div
                    className={`${styles.icon_box} me-2 d-flex align-items-center justify-content-center`}
                  >
                    {validate.phone.test(phNo) ? (
                      <div className={``} style={{ padding: "2px" }}>
                        <img src={active_check_circle} />
                      </div>
                    ) : (
                      <img src={inactive_check_circle} />
                    )}
                  </div>
                </div>
              </div>
              <div>
                {errors?.phoneNumberIs?.message ? (
                  <p className={`x-sm-text mt-1 text-danger`}>
                    {errors?.phoneNumberIs?.message}
                  </p>
                ) : (
                  <p className={`x-sm-text mt-1`}>10 digit mobile number</p>
                )}
              </div>
            </Col> */}
            <MobileNoLayout2
                isError={_numberError}
                mobileNumber={phNo}
                required
                onCountryCodeChange={handleCountryCodeChange}
                // countryCodeOptions={COUNTRY_CODES}
                selectedCountryCode={selectedCountryCode}
              >
                <input
                  className={`flex-grow-1`}
                  placeholder=""
                  type="tel"
                  maxLength={10}
                  {...register("phoneNumberIs", {
                    required: "Enter 10 digit mobile number",
                    maxLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                    minLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                    pattern: {
                      value: validate.phone,
                      message: "Incorrect mobile number",
                    },
                  })}
                />
              </MobileNoLayout2>
          </div>
          {/* <div className={`d-flex center`}>
            <p className={`sm-text text-danger`}>{errorMsg ? errorMsg : ``}</p>
          </div> */}
        </div>
        <div className={`d-flex align-items-center`}>
          <p className={`x-sm-text`}>
            Access to CareeX is by invite only. You can create an account and
            setup it's password only if pre-approved
          </p>
        </div>
        <button
          className={`${styles.button} md-text`}
          type={`submit`}
          // onClick={() => _onPress({ key: "submit" })}
        >
          Send OTP
          <div className={`ms-2`}>
            {btnLoading && <ActivityIndicator size={20} />}
          </div>
        </button>
        {/* <div
          className={`d-flex justify-content-between align-items-center mb-0`}
        >
          <button className={`${styles.forgot_password_btn} `}>
            <div className={`d-flex align-items-center`}>
              <p onClick={() => {}} className={`md-text primary-color`}>
            Hhave an account? Login.
              </p>
            </div>
          </button>
        </div> */}
      </form>
      <div className={`d-flex align-items-center`}>
        <p className={`x-sm-text`}>Already have account?</p>
        <button className={`${styles.forgot_password_btn} `}>
          <div className={`d-flex align-items-center`}>
            {/* <input type={"checkbox"} className={`me-2 ${styles.checkbox}`} /> */}
            {/* <p className={`md-text primary-color`}>Remember me</p> */}
            <p
              onClick={() => _onPress({ key: "login" })}
              className={`x-sm-text primary-color`}
            >
              Login
            </p>
          </div>
        </button>
      </div>
      <div className={`d-flex align-items-center`}>
        <p className={`x-sm-text`}>Create account?</p>
        <button className={`${styles.forgot_password_btn} `}>
          <div className={`d-flex align-items-start`}>
            {/* <input type={"checkbox"} className={`me-2 ${styles.checkbox}`} /> */}
            {/* <p className={`md-text primary-color`}>Remember me</p> */}
            <p
              // onClick={() => _onPress({ key: "login" })}
              className={`x-sm-text primary-color`}
            >
              <Link to="https://www.peoplex.tech/careerx" className="text-decoration-none primary-color">Sign-Up</Link>
            </p>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SignUpBlock;
