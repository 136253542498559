import React from "react";
import styled from "styled-components";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import RenderIf from "../renderIf";
// import candidateIcon from "../../assets/svgIcons/candidate-icon.svg";
import candidateIcon from "../../assets/svgImages/company-card.svg";
import active_candidateIcon from "../../assets/svgIcons/candidate-icon-active.svg";

type StatsBoxOneType = {
  label: string;
  status: string;
  numbers: number | string;
  desc?: string;
  inPercentage?: boolean;
  inRupees?: boolean;
  color: string;
  className?: string;
  totalValue?: number | string;
  presentValue?: number | string;
  totalValueLabel?: string;
  presentValueLabel?: string;
};
const StatsBoxTwo = (props: StatsBoxOneType) => {
  const {
    label,
    status,
    numbers,
    desc,
    inPercentage,
    inRupees,
    color,
    className,

    totalValue,
    presentValue,
    totalValueLabel,
    presentValueLabel,
  } = props;
  return (
    <StatsBoxOneBox className={`${className || ""}`}>
      <div>
        <div className="mb-3">
          <p style={{ fontWeight: 900}} className="m-0">{label}</p>
          {!!desc && <p style={{fontSize: 15}} className="desc_text fw-normal m-0">{desc}</p>}
        </div>
        <div>
          <RenderIf
            isShow={
              !!totalValue ||
              !!presentValue ||
              totalValue === 0 ||
              presentValue === 0
            }
          >
            <p className="sm-text">
              <span className="fw-bold lg-text m-0"> {presentValue}/{totalValue}</span>
            </p>
          </RenderIf>
        </div>
      </div>
      <div className="inside_box">
        <RenderIf isShow={inPercentage}>
          <div className="progress_circle_box">
            <CircularProgressbarWithChildren
              value={Number(numbers)}
              styles={{
                path: {
                  stroke: color,
                },
              }}
            >
              <div
                style={{ fontSize: 20, margin:0 }}
                className="d-flex flex-column align-items-center"
              >
                <strong className="m-0">{`${Math.floor(Number(numbers))}%`}</strong>
                <p style={{ fontSize: 10, fontWeight: 1000, margin:0}}>{status}</p>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </RenderIf>
        {/* <RenderIf isShow={!inPercentage && !inRupees}>
          <div className="image_box ">
            <img src={candidateIcon} />
          </div>
        </RenderIf> */}

        {/* <RenderIf isShow={inRupees}>
          <h1 className="primary-color" style={{ fontSize: "4em" }}>
            ₹
          </h1>
          <p className="numbers_text secondary-color">{`₹ ${Number(
            numbers
          ).formatRupee()}`}</p>
        </RenderIf>
        <RenderIf
          isShow={!inRupees && !inPercentage && (numbers || numbers === 0)}
        >
          <p className="numbers_text secondary-color">{`${Number(numbers)}`}</p>
        </RenderIf> */}
        {/* <RenderIf
          isShow={
            !!totalValue ||
            !!presentValue ||
            totalValue === 0 ||
            presentValue === 0
          }
        >
          <p className="sm-text">
            {presentValueLabel}:
            <span className="secondary-color lg-text"> {presentValue}</span>
          </p>
          <p className="sm-text">
            {totalValueLabel}:
            <span className="secondary-color lg-text"> {totalValue}</span>
          </p>
        </RenderIf> */}
        {/* {!!desc && <p className="primary-color desc_text">{desc}</p>} */}
      </div>
    </StatsBoxOneBox>
  );
};

export default StatsBoxTwo;

const StatsBoxOneBox = styled.div`
//   flex: 1;
  width: 350px;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* border: 1.5px solid #cecece; */
  background-color: white;
  border-radius: 6px;
  padding: 15px 18px;
  .inside_box {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
  }
  p {
    margin: 0px;
    // padding: 5px;
  }
  .numbers_text {
    font-size: 1.6rem;
    font-weight: bolder;
  }
  .progress_circle_box {
    width: 95px;
  }
  .image_box {
    height: 80px;
    width: 70px;
  }
  .image_box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
