import React from "react";
import RenderIf from "../renderIf";
import styled from "styled-components";

import { validate } from "../../constants/validationExp";

import phoneIcon from "../../assets/svgIcons/telephone-icon.svg";
import flagIcon from "../../assets/icons/indian_flag.png";
import inactive_check_circle from "../../assets/icons/inactive-check-circle.png";
import active_check_circle from "../../assets/icons/active-check-circle.png";
import { COUNTRY_CODES } from "../../constants/countryCodes";
import CountryCodeLayout from "./countryCodeLayout";
import { FaAngleDown } from "react-icons/fa6";

const MobileNoLayout = (props: any) => {
  const {
    children,
    isError,
    mobileNumber,
    checkMark,
    referenceText,
    className,
    required,
    mobileIcon,
    onCountryCodeChange,
    // countryCodeOptions,
    selectedCountryCode,
  } = props;

  const selectedCountry = COUNTRY_CODES?.find(
    (country: any) => country.countryCode === selectedCountryCode
  );

  const handleChange = (e: any) => {
    console.log("e", e);
    onCountryCodeChange(e);
  };

  return (
    <MobileInputWrapper className={`mb-2 ${className}`}>
      <p className={`md-text mb-1`}>
        {"Mobile Number"}
        {required && <span className="text-danger">*</span>}
      </p>
      <div className="d-flex">
        <div className="dropdown_menu_container flex-grow">
          <CountryCodeLayout
            selectedCountryCode={props.selectedCountryCode}
            onCountryCodeChange={props.onCountryCodeChange}
          />
        </div>
        {/* <div className=" pe-1"> */}
        {/* <div
            className={`d-flex country_code_box ${
              isError && "border-danger"
            } bg-white`}
          > */}
        {/* <div className={`icon_box ms-2`}>
              <img src={flagIcon} className="image_icon" />
            </div> */}
        {/* <div className={`icon_box ms-2`}>
              <img
                src={selectedCountry ? selectedCountry?.countryImg : flagIcon}
                className="image_icon"
              />
            </div> */}

        {/* <input
              disabled
              className={`md-text `}
              placeholder="+91"
              value={`+91`}
              id="code"
            /> */}
        {/* <select
              onChange={onCountryCodeChange}
              className={`sm-text border-0`}
              style={{ outline: "none" }}
              // placeholder="+91"
              value={props.selectedCountryCode}
              id="code"
            >
              {COUNTRY_CODES.map((codeOption: any) => (
                <option
                  key={codeOption.countryCode}
                  value={codeOption.countryCode}
                >
                  <img src={codeOption.countryImg} className="image_icon" />
                  {codeOption.countryCode}
                </option>
              ))}
            </select> */}
        {/* </div> */}
        {/* </div> */}
        <div className="flex-grow-1">
          <div className={`input_box bg-white ${isError && "border-danger"}`}>
            {/* <div className={`icon_box ms-2`}>
              {!!mobileIcon && <img src={phoneIcon} className="" />}
            </div> */}
            {children}
            <RenderIf isShow={checkMark}>
              <div className={`icon_box me-2`}>
                {validate.phone.test(mobileNumber) ? (
                  <div className={`px-1 py-1`}>
                    <img className="image_icon" src={active_check_circle} />
                  </div>
                ) : (
                  <img src={inactive_check_circle} />
                )}
              </div>
            </RenderIf>
          </div>
        </div>
      </div>
      <RenderIf isShow={!!isError}>
        <p className={`x-sm-text mt-1 text-danger`}>{isError}</p>
      </RenderIf>
      <RenderIf isShow={!isError && referenceText}>
        <p className={`x-sm-text mt-1`}>{referenceText}</p>
      </RenderIf>
    </MobileInputWrapper>
  );
};

export default MobileNoLayout;

const MobileInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .country_code_block {
    border-radius: 8px;
    border: 1px solid rgb(200, 200, 200);
    height: 99%;
    align-items: center;
  }
  input {
    border: none;
    outline: none;
    padding: 0px 10px;
    background: none;
  }
  .input_box {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    // border: 1px solid rgb(200, 200, 200);
  }
  .icon_box {
    height: 25px;
    width: 25px;
    border: 0px solid;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .image_icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 0px solid;
  }
  .country_code_box {
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    height: 100%;
    border: 1px solid rgb(200, 200, 200);
  }
  .country_code_box input {
    width: 30px;
    padding: 0px;
    margin-left: 10px;
  }
`;
