import { CardSetCatType } from "../../types/cardsTpes";
import { ApiKey } from "../apiKeys";
import { checkTokenValidation } from "../auth/tokenReq";
import { authAxios } from "../config";
import axios from "axios";

export const getCardSetCatReq = async () => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCategoryReq->getCardSetCatReq " + t
    );

  try {
    await checkTokenValidation();
    const res = await authAxios.get(`${ApiKey.getCardSetCatKey}`);
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = resData?.map((itm: CardSetCatType) => ({
      categoryID: itm?.categoryID || "",
      categoryName: itm?.categoryName || "",
      cardSetURL: itm?.cardSetURL || "",
      categoryDesc: itm?.categoryDesc || "",
      companyID: itm?.companyID || "",
    }));

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const addCardSetCatReq = async (data: {
  categoryName: string;
  categoryDesc?: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCategoryReq->addCardSetCatReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      categoryName: data?.categoryName,
      categoryDesc: data?.categoryDesc,
    };
    const res = await authAxios.post(`${ApiKey.addCardSetCatKey}`, body);
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {
      categoryID: resData?.categoryID || "",
      categoryName: resData?.categoryName || "",
      cardSetURL: resData?.cardSetURL || "",
      categoryDesc: resData?.categoryDesc || "",
      companyID: resData?.companyID || "",
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCardSetCatReq = async (data: {
  categoryName: string;
  categoryID: number;
  categoryDesc?: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCategoryReq->updateCardSetCatReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      categoryName: data?.categoryName,
      categoryDesc: data?.categoryDesc,
      categoryID: data?.categoryID,
    };
    const res = await authAxios.patch(`${ApiKey.updateCardSetCatKey}`, body);
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    // const resData = res.data?.data || [];
    const _data = {
      // categoryID: resData?.categoryID || "",
      // categoryName: resData?.categoryName || "",
      // cardSetURL: resData?.cardSetURL || "",
      // categoryDesc: resData?.categoryDesc || "",
      // companyID: resData?.companyID || "",
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
