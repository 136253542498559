import React, { useState, useRef, useEffect } from "react";
import Switch from "react-switch";
import { useDrag, useDrop } from "react-dnd";
import { BsCheckLg, BsX, BsPencil, BsTrash } from "react-icons/bs";
import { SagaActions } from "../../redux/actionTypes";

import deleteIcon from "../../assets/svgIcons/delete-icon.svg";
import swapIcon from "../../assets/svgIcons/swap-icon.svg";
import editIcon from "../../assets/svgIcons/edit.svg";
import addPhotoIcon from "../../assets/svgIcons/add_photo.svg";
import linkIcon from "../../assets/svgIcons/link-icon.svg";

import styles from "./stackedCard.module.scss";
import RenderIf from "../renderIf";
import { toast } from "react-toastify";
import { updateCardSetCardReq } from "../../api/cardsetReq/cardSetReq";
import FileInputBlock from "../uploadFileBlock/fileInputBlock";
import { fileUploadPaths } from "../../constants/fileUploadPaths";
import { useDispatch } from "react-redux";

type StackcardType = {
  data?: any;
  index?: string | number;
  moveCard?: any;
  emptyState: boolean;
  resetCard?: string | undefined;
  onDelete?: (data: any) => void;
  onChange?: (data: any) => void;
  deleteBtn: boolean;
  swapBtn: boolean;
};

const EmptyStackCard = (props: StackcardType) => {
  const {
    data = {},
    index = 0,
    moveCard,
    emptyState,
    onDelete,
    onChange,
    resetCard,
    deleteBtn,
    swapBtn,
  } = props;
  const {
    cardSetID,
    cardID,
    cardTitle,
    cardImage,
    cardHeading,
    cardDescription,
    cardLinkURL,
  } = data;

  // -------------------------------------
  const ref = useRef<any>(null);
  const Dispatch = useDispatch();

  const [addLinkChecked, setaddLinkChecked] = useState(false);

  const [editCard, seteditCard] = useState(false);

  const [cardTitleIs, setcardTitleIs] = useState("");
  const [cardImgURLIs, setcardImgURLIs] = useState("");
  const [cardHeadingIs, setcardHeadingIs] = useState("");
  const [cardDscIs, setcardDscIs] = useState("");
  const [cardLinkIs, setcardLinkIs] = useState("");

  const id = cardID || "";

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const updatedValue = cardDscIs + "\n";
      setcardDscIs(updatedValue);
      console.log("cardDscIs", cardDscIs);
    }
  };

  const updateCardFun = async () => {
    try {
      const params = {
        cardSetID: cardSetID,
        cardID: cardID,
      };
      const body = {
        cardTitle: cardTitleIs,
        cardImage: cardImgURLIs,
        cardDescription: cardDscIs,
        cardHeading: cardHeadingIs,
        cardLinkURL: cardLinkIs,
      };
      const res = await updateCardSetCardReq(params, body);
      toast.success(res?.message);
      seteditCard(false);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "emptyStackCard",
          methodName: "updateCardFun",
          type: "info",
        },
      });
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "emptyStackCard",
          methodName: "updateCardFun",
          type: "error",
        },
      });
    }
  };

  const onCancelEdit = () => {
    setcardTitleIs(cardTitle);
    setcardImgURLIs(cardImage);
    setcardHeadingIs(cardHeading);
    setcardDscIs(cardDescription);
    setcardLinkIs(cardLinkURL);
    seteditCard(false);
  };

  const [{ handlerId }, drop] = useDrop({
    accept: "Div",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex: any = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "Div",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.2 : 1;

  drag(drop(ref));

  useEffect(() => {
    if (!onChange) return;
    if (!emptyState) return;
    // const timerId = setTimeout(() => {
    onChange({
      cardTitle: cardTitleIs || "",
      cardImage: cardImgURLIs || "",
      cardDescription: cardDscIs || "",
      cardHeading: cardHeadingIs || "",
      cardLinkURL: cardLinkIs || "",
    });
    // }, 1000);
    // return () => clearTimeout(timerId);
  }, [cardTitleIs, cardImgURLIs, cardDscIs, cardHeadingIs, cardLinkIs]);

  useEffect(() => {
    if (!!resetCard) {
      setcardTitleIs("");
      setcardImgURLIs("");
      setcardHeadingIs("");
      setcardDscIs("");
      setcardLinkIs("");
    }
  }, [resetCard]);

  return (
    <div
      ref={ref}
      className={`${styles.StackedCard} shadow-block`}
      style={{ opacity }}
    >
      <div className={`d-flex align-items-center justify-content-end px-2`}>
        <RenderIf isShow={!emptyState}>
          <RenderIf isShow={!editCard}>
            <div onClick={() => seteditCard(true)}>
              <img src={editIcon} />
            </div>
          </RenderIf>
        </RenderIf>
        <RenderIf isShow={!emptyState && editCard}>
          <div onClick={updateCardFun}>
            <BsCheckLg className="mx-2" color={"#3A93F4"} size={20} />
          </div>
          <div onClick={onCancelEdit}>
            <BsX className="me-2" color="#D92D20" size={20} />
          </div>
        </RenderIf>
        <div className={`${styles.small_icon_box} d-flex`}>
          {/* <RenderIf isShow={!emptyState}>
            {swapBtn && <img src={swapIcon} />}
            {deleteBtn && index > 1 && (
              <img src={deleteIcon} onClick={onDelete} />
            )}
          </RenderIf> */}
        </div>
      </div>
      <div className={`${styles.line_indicator}`}></div>
      <div className={`my-2`}>
        {editCard || emptyState ? (
          <input
            maxLength={40}
            type={"text"}
            placeholder="Card Title (Upto 40 Chars)"
            className={`w-100  sm-text`}
            value={cardTitleIs}
            onChange={(e) => setcardTitleIs(e.target.value)}
          />
        ) : (
          <div
            className={`d-flex align-items-center justify-content-between my-2`}
          >
            <p>{cardTitleIs}</p>
          </div>
        )}
      </div>
      <RenderIf isShow={!!cardImgURLIs && !emptyState}>
        <RenderIf isShow={!editCard}>
          <div className={`${styles.image_container}`}>
            <img src={cardImgURLIs} />
          </div>
        </RenderIf>
      </RenderIf>
      <RenderIf isShow={!editCard && !emptyState && !cardImgURLIs}>
        <div className={`${styles.image_container}`}>
          <p className="x-sm-text">No image selected</p>
          <p className="xx-sm-text">Edit card to upload image</p>
        </div>
      </RenderIf>
      <RenderIf isShow={emptyState || editCard}>
        <div className={`${styles.file_input_container}`}>
          <FileInputBlock
            reset={cardImgURLIs}
            discription={`Browse or Drag and Drop
             \n to Upload image`}
            path={fileUploadPaths.cardsetsImg}
            uploadIcon={addPhotoIcon}
            onUpload={({ path }: { path: string }) => setcardImgURLIs(path)}
          />
        </div>
      </RenderIf>
      {/* <RenderIf isShow={editCard}>
        <div className={`d-flex align-items-center justify-content-end p-2`}>
          <p className={`x-sm-text`} style={{ color: "#3a93f4" }}>
            Re-upload
          </p>
        </div>
      </RenderIf> */}
      <div className={`my-2`}>
        {editCard || emptyState ? (
          <input
            maxLength={40}
            type={"text"}
            placeholder="Card Tagline (Upto 40 Chars)"
            className={`w-100  sm-text`}
            value={cardHeadingIs}
            onChange={(e) => setcardHeadingIs(e.target.value)}
          />
        ) : (
          <div
            className={`d-flex align-items-center justify-content-between my-2`}
          >
            <p className={`sm-text`}>{cardHeadingIs}</p>
          </div>
        )}
      </div>
      <div className={`my-2`}>
        {editCard || emptyState ? (
          <textarea
            maxLength={400}
            rows={5}
            placeholder="Card Description (Upto 400 Chars)"
            className={`w-100  sm-text text-area border rounded p-2`}
            value={cardDscIs}
            onChange={(e) => setcardDscIs(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        ) : (
          <div className="" style={{ minHeight: "105px" }}>
            <div
              className={`d-flex  align-items-end justify-content-between my-2 pb-2`}
            >
              <p className={`me-2 sm-text`}>{cardDscIs}</p>
            </div>
          </div>
        )}
      </div>
      <div className={`d-flex align-items-center justify-content-between`}>
        <p className={`md-text`}>Add a Link/Url</p>
        {/* <Switch
          height={18}
          width={35}
          uncheckedIcon={false}
          checkedIcon={false}
          checked={addLinkChecked}
          onChange={() => setaddLinkChecked(!addLinkChecked)}
          onColor="#3a93f4"
          handleDiameter={15}
        /> */}
      </div>

      <div className="pt-2" style={{ height: "80px" }}>
        <div className={`my-2 ${styles.link_block}`}>
          <RenderIf isShow={!emptyState}>
            <input
              disabled={!editCard}
              type={"text"}
              className={"d-flex sm-text  border-0"}
              placeholder="Copy and paste the external Url"
              value={cardLinkIs}
              onChange={(e) => {
                setcardLinkIs(e.target.value);
              }}
            />
          </RenderIf>
          <RenderIf isShow={emptyState}>
            <input
              type={"text"}
              className={"d-flex sm-text  border-0"}
              placeholder="Copy and paste the external Url"
              value={cardLinkIs}
              onChange={(e) => {
                setcardLinkIs(e.target.value);
              }}
            />
          </RenderIf>
          <div className={`${styles.link_icon_box}`}>
            <img src={linkIcon} />
          </div>
        </div>
      </div>
      <div className={`flex-1 flex-column`}></div>
      <div className={`d-flex align-items-center justify-content-end `}>
        <div className={`${styles.line_indicator}`}></div>
      </div>
    </div>
  );
};

export default EmptyStackCard;
