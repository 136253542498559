import React, { Children, useState, useEffect } from "react";
import { IoClose, IoChevronDown, IoChevronUp } from "react-icons/io5";

import styles from "./dropDown.module.scss";

const Dropdown = (props) => {
  const {
    headerComponent,
    renderListItem,
    listData,
    children,
    headerClass,
    expand,
    onExpand,
  } = props;

  const [showList, setshowList] = useState(expand);

  useEffect(() => {
    setshowList(expand);
  }, [expand]);

  const handleHeaderClick = () => {
    setshowList((prevShowList) => !prevShowList);
    if (!expand) {
      onExpand();
    }
  };

  return (
    <div className={`${styles.Dropdown} my-1`}>
      <header
        className={`menu-header ${
          headerClass && headerClass({ showList: showList })
        }`}
        // onClick={() => (expand ? setshowList(false) : onExpand())}
        onClick={handleHeaderClick}
      >
        {headerComponent ? (
          headerComponent({ showList: showList })
        ) : (
          <p className="bold">Mention Header Component</p>
        )}
        {showList ? (
          <IoChevronUp color="#002C71" />
        ) : (
          <IoChevronDown color="#002C71" />
        )}
      </header>
      {showList ? <>{children}</> : null}
    </div>
  );
};

export default Dropdown;
