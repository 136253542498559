import React, { useState } from "react";
import { Checkbox } from "pretty-checkbox-react";

import styles from "../loginPage.module.scss";
import { validate } from "../../../constants/validationExp";
import { registerWithPswdReq } from "../../../api/auth/registerReq";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch } from "react-redux";

type Inputs = {
  firstNameIs: string;
  lastNameIs: string;
  passwordIs: string;
  confPasswordIs: string;
};

type CreatePassBlockProps = {
  onSubmit: any;
  forgotPassword: boolean;
};

const CreatePswdBlock = (props: CreatePassBlockProps) => {
  const { onSubmit, forgotPassword } = props;
  const navigation = useNavigate();
  const Dispatch = useDispatch();
  const currentPath = useLocation().pathname;

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const pwd = watch("passwordIs");
  const passLengthVal = !!pwd && pwd?.length > 7;
  const passSpecialCharVal = validate.password.specialChar.test(pwd);

  const registerUser = async (data: Inputs) => {
    try {
      const body = {
        firstName: data?.firstNameIs,
        lastName: data?.lastNameIs,
        emailVerified: 1,
        SMSVerified: 1,
        password: data?.passwordIs,
        userID: localStorage.getItem("@userId") || "",
      };
      const res = await registerWithPswdReq(body);
      toast.success(res?.message);
      if (!res?.data) {
        onSubmit();
        reset();
        return;
      }
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "createPswdBlock",
          methodName: "registerUser",
          type: "info",
        },
      });
      Dispatch({ type: SagaActions.ADD_USERID, payload: res?.data?.id });

      const parentPath = currentPath.substring(
        0,
        currentPath.lastIndexOf(`${ROUTE_NAMES.SIGNUP}`)
      );

      if (res?.data?.role == 102) {
        Dispatch({ type: SagaActions.FETCH_CEP_COMPANY_PROFILE });
        Dispatch({ type: SagaActions.DEFAULT_CEP_COMPANY });
        navigation(`${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.cardSetsPage}`);
      }
      if (res?.data?.role == 301 || res?.data?.role == 303) {
        Dispatch({
          type: SagaActions.UPDATE_NAVBAR_SEETINGS,
          payload: { clickableMenu: forgotPassword ? true : false },
        });
        if (forgotPassword) {
          localStorage.setItem("isSignup", "false");
          navigation(
            `${parentPath}/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.dashboard}`
          );
        } else {
          localStorage.setItem("isSignup", "true");
          navigation(
            `${parentPath}/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.myAccount}/${ROUTE_NAMES.welcome}`
          );
        }
        reset();
        return;
      }
      if (res?.data?.role == 200) {
        toast.error("You are not authorised to access this");
        return
      }
      navigation(
        `${parentPath}/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.dashboard}`
      );
      reset();
    } catch (error: any) {
      // seterrorMsg(error.errorMsg || "");
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "createPswdBlock",
          methodName: "registerUser",
          type: "error",
        },
      });
    }
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      null: () => {},
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.CreatePswdBlock} flex-1 d-flex flex-column`}>
      <form
        onSubmit={handleSubmit((data) => {
          registerUser(data);
        })}
        className={` flex-1 d-flex flex-column `}
      >
        <div className={` flex-1 d-flex flex-column `}>
          <h4 className={`x-lg-text`}>Set up password</h4>
          <p className={`md-text light-text`}>
            Both your email and mobile number is verified. Please go ahead and
            set your password
          </p>
          <div className={`mt-3`}>
            <div className={`d-flex`}>
              <div
                className={`${styles.input_block} me-2 flex-1 flex-column `}
                style={{ width: "40%" }}
              >
                <label className={`md-text`}>First Name</label>
                <div
                  className={`${styles.input_box} border-0 ${
                    errors?.firstNameIs?.message && "border-danger"
                  }`}
                >
                  <input
                    className=""
                    style={{ width: "100%" }}
                    disabled
                    id="firstName"
                    type={"text"}
                    value={localStorage.getItem("@firstName") || ""}
                  />
                </div>
                {errors?.firstNameIs?.message && (
                  <p className={`xx-sm-text text-danger`}>
                    {errors?.firstNameIs?.message}
                  </p>
                )}
              </div>
              <div
                className={`${styles.input_block} me-2 flex-1 flex-column`}
                style={{ width: "40%" }}
              >
                <label className={`md-text`}>Last Name</label>
                <div className={`${styles.input_box} border-0`}>
                  <input
                    className=""
                    id="lastName"
                    style={{ width: "100%" }}
                    type={"text"}
                    disabled
                    value={localStorage.getItem("@lastName") || ""}
                  />
                </div>
                {errors?.lastNameIs?.message && (
                  <p className={`xx-sm-text text-danger`}>
                    {errors?.lastNameIs?.message}
                  </p>
                )}
              </div>
            </div>
            <div className={`${styles.input_block} me-2`}>
              <label className={`md-text mb-1`}>Create Password</label>
              <div
                className={`${styles.input_box} ${
                  errors?.passwordIs?.message && "border-danger"
                }`}
              >
                <input
                  type={"password"}
                  placeholder=""
                  {...register("passwordIs", {
                    required: "Password required",
                    minLength: {
                      value: 8,
                      message: "Minimum 8 characters",
                    },
                    pattern: {
                      value: validate.password.specialChar,
                      message: "Alphanumeric with 1 special character #%^.",
                    },
                  })}
                />
              </div>
            </div>
            <div className={``}>
              <p
                className={`xx-sm-text light-text ${
                  !!errors.passwordIs?.message && "text-danger"
                }`}
              >
                <span className={`${!!passLengthVal && "text-success"}`}>
                  {/* Minimum 8 characters */}
                  Min 8 characters,
                </span>
                <span className={`${!!passSpecialCharVal && "text-success"}`}>
                  {/* Alphanumeric with 1 special character #%^. */}
                  special characters
                </span>
              </p>
            </div>
            <div className={`${styles.input_block} mb-0 mt-2`}>
              <label className={`md-text mb-1`}>Confirm Password</label>
              <div
                className={`${styles.input_box} ${
                  errors?.confPasswordIs?.message && "border-danger"
                }`}
              >
                <input
                  type={"password"}
                  placeholder=""
                  {...register("confPasswordIs", {
                    required: "Confirm password required",
                    validate: (value) =>
                      value === pwd || "The passwords didn't match",
                  })}
                />
              </div>
              {errors?.confPasswordIs?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.confPasswordIs?.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <button
          className={`${styles.button} md-text`}
          type="submit"
          // onClick={() => _onPress({ key: "submit" })}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreatePswdBlock;
