import React, { memo, useState } from "react";
import { CandidateStatusContainer } from "./candidateStatus.styles";
import RenderIf from "../../../_components/renderIf";
import { updateCandidateStatusReq } from "../../../api/candidateReq/candidateStatusReq";
import { toast } from "react-toastify";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const dropoutLists = [
  {
    label: "Candidate informed me proactively by email/call",
  },
  {
    label: "I had to follow-up and discover",
  },
  {
    label: "Candidate is not reachable. Hence marking as a dropout",
  },
];

const joinedLists = [
  {
    label: "Joined us in-person",
  },
  {
    label: "Virtual Onboarding",
  },
];

type selectedOptions = [
  {
    label: string;
  }
];

const CandidateStatusModal = ({ onClose }: any) => {
  const Dispatch = useDispatch();
  const { candidates } = useSelector((_state: RootState) => _state);
  const [dropoutOrJoined, setDropoutOrJoined] = useState("Joined");

  const [selectedDropoutOptions, setSelectedDropoutOptions] =
    useState<string>("");
  const [selectedJoinedOption, setSelectedJoinedOption] = useState<string>("");

  const [selectedOption, setSelectedOption] = useState<string>();

  const handleConfirm = async () => {
    let _remarks = "";
    if (dropoutOrJoined === "Dropout") {
      console.log("Selected Dropout Option:", selectedDropoutOptions);
      _remarks = selectedDropoutOptions;
    } else if (dropoutOrJoined === "Joined") {
      console.log("Selected Joined Option:", selectedJoinedOption);
      _remarks = selectedJoinedOption;
    }
    try {
      const res = await updateCandidateStatusReq(
        { candidateID: candidates?.selectedCandidateId },
        { status: dropoutOrJoined, remark: _remarks }
      );
      toast.success(res?.message);
      Dispatch({
        type: SagaActions.GET_SELECTED_CANDIDATE_DETAILS,
        payload: {
          selectedCandidateId: candidates?.selectedCandidateId,
        },
      });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "candidateStatus",
          methodName: "handleConfirm",
          type: "info",
        },
      });
      onClose();
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "candidateStatus",
          methodName: "handleConfirm",
          type: "error",
        },
      });
    }
  };

  const RadioButtons = memo(({ isChecked, onChange }: any) => {
    return (
      <input
        type="radio"
        className="radio"
        checked={isChecked}
        onChange={() => onChange()}
      />
    );
  });

  return (
    <CandidateStatusContainer className="">
      <div className="box">
        <div className="dialogue">
          <div className="overlap">
            <div className="overlap-wrapper">
              <div className="content-wrapper">
                <div className="content">
                  <div className="header">
                    <div className="frame-9">
                      <div className="text-medium-title">
                        <div className="notifications">Candidate Status</div>
                      </div>
                      <div className="div">
                        <div className="frame-2">
                          <div className="frame-3">
                            <RadioButtons
                              isChecked={dropoutOrJoined === "Joined"}
                              onChange={() => setDropoutOrJoined("Joined")}
                            />

                            <div className="div-wrapper">
                              <div
                                className={`text-wrapper ${
                                  dropoutOrJoined === "Joined" &&
                                  "highlighted-text"
                                }`}
                              >
                                Joined
                              </div>
                            </div>
                          </div>
                          <div className="frame-3">
                            <RadioButtons
                              isChecked={dropoutOrJoined === "Dropout"}
                              onChange={() => setDropoutOrJoined("Dropout")}
                            />

                            <div className="notifications-wrapper">
                              <div
                                className={`text-wrapper ${
                                  dropoutOrJoined === "Dropout" &&
                                  "highlighted-text"
                                }`}
                              >
                                Dropout
                              </div>
                            </div>
                          </div>
                        </div>
                        <RenderIf isShow={dropoutOrJoined === "Dropout"}>
                          <div className="frame-4">
                            <div className="text-medium">
                              <p className="name-2">
                                How did you learn about their decision?
                              </p>
                            </div>
                            <div className="frame-10">
                              {dropoutLists.map((item, index) => {
                                return (
                                  <div className="frame-6" key={index}>
                                    <RadioButtons
                                      isChecked={selectedDropoutOptions?.includes(
                                        item.label
                                      )}
                                      onChange={() =>
                                        setSelectedDropoutOptions(item.label)
                                      }
                                    />
                                    <div className="name-wrapper">
                                      <p className="p">{item.label}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </RenderIf>
                        {/* */}
                        <RenderIf isShow={dropoutOrJoined === "Joined"}>
                          <div className="frame-4">
                            <div className="text-medium">
                              <p className="name-2">How was the onboarding?</p>
                            </div>
                            <div className="frame-10">
                              {joinedLists.map((item, index) => {
                                return (
                                  <div className="frame-6" key={index}>
                                    <RadioButtons
                                      isChecked={selectedJoinedOption?.includes(
                                        item.label
                                      )}
                                      onChange={() =>
                                        setSelectedJoinedOption(item.label)
                                      }
                                    />

                                    <div className="name-wrapper">
                                      <p className="p">{item.label}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </RenderIf>
                      </div>
                    </div>
                  </div>
                  <div className="button">
                    <div className="frame-8">
                      <button
                        className="button-base-wrapper"
                        onClick={() => onClose()}
                      >
                        <div className="button-base border-primary">
                          <div className="text">Cancel</div>
                        </div>
                      </button>
                      <button
                        className={`
                        
                         sbmit_btn`}
                        onClick={handleConfirm}
                      >
                        <div className="button-base-2">
                          <div className="text-2">Confirm</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CandidateStatusContainer>
  );
};

export default CandidateStatusModal;
