import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Modal from "react-modal";

import { NavigationBar } from "../../components/navBar";
import { TopBar } from "../../components/topBar";

import "./mainLayout.scss";
import CustomModal from "../../components/modal";
import { ModalComponents } from "../../components/modalComponents";
import { useDispatch } from "react-redux";
import { SagaActions } from "../../redux/actionTypes";
import dayjs from "dayjs";

const MainLayout = () => {
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch({ type: SagaActions.FETCH_PROFILE });
    Dispatch({ type: SagaActions.FETCH_COMAPNY_PROFILE });
  }, []);
  return (
    <div className="mainLayout h-100">
      <div className="d-flex h-100">
        <div>
          <NavigationBar />
        </div>
        <div id="content-part" className="w-100 content-part position-relative">
          <ModalComponents />
          <TopBar />
          <div className="content-block">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
