import React, { useState } from "react";

import logoImage from "../../assets/images/LOGO.png";
import homeIcon from "../../assets/svgIcons/home-icon.svg";
import active_homeIcon from "../../assets/svgIcons/home-icon-active.svg";

import candidateIcon from "../../assets/svgIcons/candidate-icon.svg";
import active_candidateIcon from "../../assets/svgIcons/candidate-icon-active.svg";

import cardsetIcon from "../../assets/svgIcons/cardset-icon.svg";
import active_cardsetIcon from "../../assets/svgIcons/cardset-icon-active.svg";

import reportIcon from "../../assets/svgIcons/report-icon.svg";
import active_reportIcon from "../../assets/svgIcons/report-icon-active.svg";

import chatIcon from "../../assets/svgIcons/message-chat-icon.svg";
import active_chatIcon from "../../assets/svgIcons/message-chat-icon-active.svg";

import questionIcon from "../../assets/svgIcons/question-icon.svg";
import active_questionIcon from "../../assets/svgIcons/question-icon-active.svg";

import helpIcon from "../../assets/svgIcons/help-icon.svg";
import active_helpIcon from "../../assets/svgIcons/help-icon-active.svg";

import buildingIcon from "../../assets/svgIcons/building-icon.svg";
import active_buildingIcon from "../../assets/svgIcons/building-icon-active.svg";

import "./navBar.scss";
import {
  NavLink,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { ROUTE_NAMES } from "../../constants/routeNames";
import CustomNavLink from "./components/customNavLink";
import { useSelector } from "react-redux";

const menuListGroupOne = [
  {
    label: "Dashboard",
    icon: homeIcon,
    activeIcon: active_homeIcon,
    route: ROUTE_NAMES.dashboard,
    role: "all",
    show: true,
    disable: false,
  },
  {
    label: "Candidates",
    icon: candidateIcon,
    activeIcon: active_candidateIcon,
    route: ROUTE_NAMES.CANDIDATE + "/" + ROUTE_NAMES.allCandidate,
    role: "all",
    show: true,
    disable: false,
  },
  {
    label: "CardSets",
    icon: cardsetIcon,
    activeIcon: active_cardsetIcon,
    route: ROUTE_NAMES.cardSetsPage,
    role: "all",
    show: true,
    disable: false,
  },
  {
    label: "Reports",
    icon: reportIcon,
    activeIcon: active_reportIcon,
    route: ROUTE_NAMES.report,
    role: "all",
    show: true,
    disable: true,
  },
  {
    label: "Action for You",
    icon: chatIcon,
    activeIcon: active_chatIcon,
    route: ROUTE_NAMES.table,
    role: "all",
    show: true,
    disable: false,
  },
];
const menuListGroupTwo = [
  {
    label: "How it works",
    icon: questionIcon,
    activeIcon: active_questionIcon,
    route: ROUTE_NAMES.howItWorks + "/" + ROUTE_NAMES.welcome,
    role: "candidate",
    show: true,
    disable: false,
  },
  {
    label: "My Account",
    icon: buildingIcon,
    activeIcon: active_buildingIcon,
    route: ROUTE_NAMES.myAccount + "/" + ROUTE_NAMES.welcome,
    role: "admin",
    show: false,
    disable: false,
  },
  {
    label: "Help",
    icon: helpIcon,
    activeIcon: active_helpIcon,
    route: ROUTE_NAMES.help + "/" + ROUTE_NAMES.HowItWorksPage,
    role: "all",
    show: true,
    disable: false,
  },
];

const NavBar = () => {
  const navigation = useNavigate();
  const { companyProfile, cepCompanyProfile, userSettings } = useSelector(
    (_state) => _state
  );
  const [activeMenu, setactiveMenu] = useState("Dashboard");

  const _isAdmin = 301 == localStorage.getItem("@role");
  const _isAdminAndRecruiter = 303 == localStorage.getItem("@role");
  const _isCandidate = 200 == localStorage.getItem("@role");
  const _isCepAdmin = 102 == localStorage.getItem("@role");
  const _companyLogo = companyProfile?.data?.company?.companyWebLogoURL;
  const _cepCompanyProfile = cepCompanyProfile?.data || null;
  const selectedCompany = cepCompanyProfile?.companyID;
  const selectedCompanyID = _cepCompanyProfile?.company.find(
    (item) => item.companyID === selectedCompany
  );
  const _cepCompanyLogo = selectedCompanyID?.companyWebLogoURL;
  const _navBarClickable = userSettings?.data?.navBar?.clickableMenu;

  const _onPress = ({ key, data }) => {
    const fun = {
      null: (data) => {},
      menuBtn: (data) => {
        setactiveMenu(data?.label);
        navigation(data?.route);
      },
    };
    fun[key](data);
  };

  return (
    <div className="navBar">
      <div>&nbsp;</div>
      <div
        className="logo-block d-flex align-items-start flex-column"
        style={{
          maxHeight: "85px",
          height: "45px",
          width: "65%",
          maxWidthidth: "65%",
          overflow: "hidden",
          // border: "1px solid black",
        }}
      >
        {!!_companyLogo && (
          <img
            src={_companyLogo || logoImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        )}
        {!!_cepCompanyLogo && (
          <img
            src={_cepCompanyLogo || logoImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        )}
      </div>
      <div>&nbsp;</div>
      <div className="opt-block">
        {menuListGroupOne?.map((opt, idx) => {
          const isActive = activeMenu === opt.label;
          const clickable = _navBarClickable && !opt?.disable;
          const cepClickable =
            _navBarClickable && _isCepAdmin & (opt?.label == "CardSets");
          if (_isCandidate) {
            return <CustomNavLink opt={opt} clickable={false} />;
          }
          if (_isCepAdmin) {
            return <CustomNavLink opt={opt} clickable={cepClickable} />;
          } else {
            return <CustomNavLink opt={opt} clickable={clickable} />;
          }
        })}
      </div>
      <div className="border"></div>
      <div className="opt-block">
        {menuListGroupTwo?.map((opt, idx) => {
          const isActive = activeMenu === opt.label;
          const clickable = _navBarClickable && !opt?.disable;
          const cepClickable =
            _navBarClickable && _isCepAdmin & (opt?.label == "My Account");
          if (_isCandidate) {
            return <CustomNavLink opt={opt} clickable={false} />;
          }
          if (
            (opt?.label == "Help" ||
              opt?.label == "My Account" ||
              !opt?.label == "How it works") &&
            _isCepAdmin
          ) {
            return <CustomNavLink opt={opt} clickable={cepClickable} />;
          }
          if (
            opt.role === "admin" &&
            (_isAdmin || (_isAdminAndRecruiter && !_isCepAdmin))
          ) {
            return <CustomNavLink opt={opt} clickable={clickable} />;
          }
          if (
            opt.role === "candidate" &&
            !_isAdmin &&
            !_isAdminAndRecruiter &&
            !_isCepAdmin
          ) {
            return <CustomNavLink opt={opt} clickable={clickable} />;
          }
          if (opt.role === "all") {
            return <CustomNavLink opt={opt} clickable={clickable} />;
          }
        })}
      </div>
    </div>
  );
};

export default NavBar;
