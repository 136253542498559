import {
  NavLink,
  matchPath,
  useLocation,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import styles from "../candidatePage.module.scss";
import { FaCheck } from "react-icons/fa";
import { useEffect, useState } from "react";

const MenuBox = ({ opt, isActive, onClick, isChecked }) => {
  return (
    <button
      className={`${styles.opt_box} ${isActive && styles.active}`}
      onClick={onClick}
    >
      <div className="d-flex align-items-center">
        {/* replace this line to show check mark <div className={`icon-box ${active && "checked"}`}>  */}
        <div className={`${styles.icon_box} ${isChecked && styles.checked}`}>
          {isChecked ? <FaCheck size={9} color={"white"} /> : null}
        </div>
        <label className={`md-text ${!isActive && "light-text"}`}>
          {opt.label}
        </label>
      </div>
    </button>
  );
};

export default MenuBox;
