import { ApiKey } from "../apiKeys";
import { publicAxios } from "../config";
import dayjs from "dayjs";

export const loginWithOtpReq = async (data: {
  userId: string | number;
  otp: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": loginRequests->loginWithOtpReq " + t);
  try {
    const FMCToken = localStorage.getItem("@deviceToken");
    const body = {
      userID: Number(data?.userId),
      otp: data.otp,
      deviceID: FMCToken || "",
      deviceType: "Web",
    };
    //  logfun(body, "body");
    const res = await publicAxios.post(`${ApiKey.loginWithOtpKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "login successfull*";
    const _data = {
      id: resData?.user?.id || "",
      email: resData?.user?.email || "",
      phoneNumber: resData?.user?.mobile || "",
      role: resData?.user?.role || "",
      userConsent: resData?.user?.userConsent || false,
      token: resData?.accessToken || "",
    };
    await localStorage.setItem("token", resData?.accessToken);
    await localStorage.setItem("@role", resData?.user?.role);
    await localStorage.setItem("userID", `${resData?.user?.id}`);
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const loginWithMailReq = async (data: {
  emailIs: string | number;
  passwordIs: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": loginRequests->loginWithMailReq " + t);
  try {
    const FMCToken = localStorage.getItem("@deviceToken");
    const body = {
      email: data?.emailIs,
      password: data.passwordIs,
      deviceID: FMCToken || "",
      deviceType: "Web",
    };
    //  logfun(body, "body");
    const res = await publicAxios.post(`${ApiKey.loginWithMailKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "login successfull*";
    const _data = {
      id: resData?.user?.id || "",
      email: resData?.user?.email || "",
      phoneNumber: resData?.user?.mobile || "",
      role: resData?.user?.role || "",
      userConsent: resData?.user?.userConsent || false,
      token: resData?.accessToken || "",
      accessTokenExpireTime: resData?.accessTokenExpireTime || "",
      refreshToken: resData?.refreshToken || "",
    };
    const _expireDate = dayjs().add(
      Number(resData?.accessTokenExpireTime) - 60,
      "s"
    );

    await localStorage.setItem("token", resData?.accessToken);
    await localStorage.setItem("tokenExpiresOn", JSON.stringify(_expireDate));
    await localStorage.setItem("refreshToken", resData?.refreshToken);
    await localStorage.setItem(
      "tokenExpireDuration",
      `${resData?.accessTokenExpireTime - 60}`
    );

    await localStorage.setItem("@role", resData?.user?.role);
    await localStorage.setItem("userID", `${resData?.user?.id}`);

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(err, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
