import { createSlice } from "@reduxjs/toolkit";
import { CompanyProfileType, CepProfileType } from "../../../types/userTypes";
import { getProfileReq } from "../../../api/user/profileReq";
import { ApiResType } from "../../../types/apiTypes";
import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { getCompanyProfileReq } from "../../../api/company/companyProfileReq";
import { getCepAdminProfileReq } from "../../../api/user/cepCompanyReq";

export interface CompanyProfileSlicetypes {
  error: boolean;
  message: string;
  data: CepProfileType | null;
  companyID: number;
  loading: boolean;
}

const initialState: CompanyProfileSlicetypes = {
  error: false,
  message: "",
  data: null,
  companyID: 0,
  loading: false,
};

const CompanyProfileSlice = createSlice({
  name: "CompanyProfile",
  initialState: initialState,
  reducers: {
    AddCEPCompanyProfileDetails: (
      state: CompanyProfileSlicetypes,
      { payload }: { payload: CepProfileType | null }
    ) => {
      state.data = payload ? { ...payload } : null;
      state.loading = false;
      state.error = false;
      return state;
    },
    SelectedCompanyID: (
      state: CompanyProfileSlicetypes,
      { payload }: { payload: number }
    ) => {
      state.companyID = payload || 0;
      return state;
    },
    StartLoading: (state: CompanyProfileSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: CompanyProfileSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: CompanyProfileSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  AddCEPCompanyProfileDetails,
  SelectedCompanyID,
  StartLoading,
  StopLoading,
  ClearData,
} = CompanyProfileSlice.actions;

export default CompanyProfileSlice.reducer;

export function* defaultCEPCompany({ payload }: any) {
  try {
    yield put(StartLoading());
    const res: ApiResType = yield call(getCepAdminProfileReq);
    console.log("defaultCID", res?.data?.company?.[0]?.companyID);
    yield put(
      SelectedCompanyID(
        payload?.companyID || res?.data?.company?.[0]?.companyID
      )
    );
  } catch (error: any) {
    yield put(StopLoading());
    yield put(SelectedCompanyID(0));
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
  }
}

export function* fetchCEPComapnyProfileDetails() {
  try {
    yield put(StartLoading());
    const res: ApiResType = yield call(getCepAdminProfileReq);
    console.log("fetchCEPComapnyProfileDetails", res?.data);
    yield put(AddCEPCompanyProfileDetails(res?.data));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(AddCEPCompanyProfileDetails(null));
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
  }
}

export function* selectedCEPCompany({ payload }: any) {
  try {
    yield put(SelectedCompanyID(payload));
  } catch (error: any) {
    yield put(SelectedCompanyID(0));
  }
}

export function* clearCompanyProfileSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
