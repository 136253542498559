import { ApiKey } from "../apiKeys";
import { publicAxios } from "../config";

export const getOtpReq = async (data: {
  role: string;
  phoneNumber: string;
}) => {
  const logfun = (d: any, t?: string) =>
    console.log(d, new Date() + ": otpRequests->getOtpReq " + t);
  try {
    const body = {
      role: data.role,
      mobileNumber: Number(data.phoneNumber),
    };
    const res = await publicAxios.post(`${ApiKey.getOtpKey}`, body);
    const resData = res.data;
    const _msg = resData.message || "OTP Sent*";
    const _data = {
      userId: resData?.userID || "",
      otp: resData?.otp || "",
    };
    //  logfun(_data, "response");

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw {
      error: true,
      data: "",
      errorMsg:
        error === "User Not found."
          ? "Number is not registered with the organisation"
          : error,
    };
  }
};
export const getOtpByMailReq = async (data: {
  role: string;
  email: string;
}) => {
  const logfun = (d: any, t?: string) =>
    console.log(d, new Date() + ": otpRequests->getOtpByMailReq " + t);
  try {
    const body = {
      role: data.role,
      email: data.email,
    };
    const res = await publicAxios.post(`${ApiKey.getOtpByMailKey}`, body);
    const resData = res.data;
    const _msg = resData.message || "OTP Sent*";
    const _data = {
      userId: resData?.userID || "",
      otp: resData?.otp || "",
    };
    //  logfun(_data, "response");
    await localStorage.setItem("@userId", resData?.userID);

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw {
      error: true,
      data: "",
      errorMsg:
        error === "User Not found."
          ? "Number is not registered with the organisation"
          : error,
    };
  }
};

export const resendOtpReq = async (data: { userId: number; type: string }) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": otpRequests->resendOtpReq " + t);
  try {
    const body = {
      userID: data.userId,
      type: data.type,
    };
    const res = await publicAxios.post(`${ApiKey.resendOtpKey}`, body);
    const resData = res.data;
    const _msg = resData.message || "OTP Sent*";
    const _data = {
      userId: resData?.userID || "",
      otp: resData?.otp || "",
    };
    //  logfun(_data, "response");

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

// -------------- Sign-up OTP ---------------

export const getSignUpOtpReq = async (data: {
  role: string;
  email: string;
  mobile: string | number;
  forgotPassword: boolean;
  type: string;
}) => {
  const logfun = (d: any, t?: string) =>
    console.log(d, new Date() + ": otpRequests->getSignUpOtpReq " + t);
  try {
    const body = {
      role: data.role,
      email: data.email,
      mobile: data.mobile,
      forgotPassword: data.forgotPassword,
      type: data.type,
    };
    const res = await publicAxios.post(`${ApiKey.getSignUpOtpkey}`, body);
    const resData = res.data;
    const _msg = resData.message || "OTP Sent*";
    const _data = {
      userId: resData?.userID || "",
      otp: resData?.otp || "",
      smsOtp: resData?.SMSOTP || "",
      emailOtp: resData?.EmailOTP || "",
    };
    //  logfun(_data, "response");
    await localStorage.setItem("@userId", resData?.userID);

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw {
      error: true,
      data: "",
      errorMsg:
        error === "User Not found."
          ? "Number is not registered with the organisation"
          : error,
    };
  }
};

export const resendSignUpOtpReq = async (data: {
  userId: number;
  type: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": otpRequests->resendSignUpOtpReq " + t);
  try {
    const body = {
      userID: data.userId,
      type: data.type,
    };
    const res = await publicAxios.post(`${ApiKey.resendSignUpOtpkey}`, body);
    const resData = res.data;
    const _msg = resData.message || "OTP Sent*";
    const _data = {
      userId: resData?.userID || "",
      otp: resData?.otp || "",
    };
    //  logfun(_data, "response");

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const verifyOtpReq = async (data: {
  userID: number;
  otp: string;
  typeIs: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": otpRequests->verifyOtpReq " + t);
  try {
    const body = {
      userID: data.userID,
      otp: data.otp,
      type: data.typeIs,
    };
    const res = await publicAxios.post(`${ApiKey.verifyOtpKey}`, body);

    //  logfun(res?.data, "response");

    const resData = res.data;
    const _msg = resData.message || "OTP Verified*";
    const _data = {
      userId: resData?.user?.id || "",
      email: resData?.user?.email || "",
      mobile: resData?.user?.mobile || "",
      firstName: resData?.user?.firstName || "",
      lastName: resData?.user?.lastName || "",
    };
    //  logfun(_data, "_data");
    localStorage?.setItem("@firstName", `${resData?.user?.firstName}`);
    localStorage?.setItem("@lastName", `${resData?.user?.lastName}`);

    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
