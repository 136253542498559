import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { BsCheckLg, BsX, BsPencil, BsTrash } from "react-icons/bs";

import deleteIcon from "../../assets/svgIcons/delete-icon.svg";
import swapIcon from "../../assets/svgIcons/swap-icon.svg";
import editIcon from "../../assets/svgIcons/edit.svg";
import addPhotoIcon from "../../assets/svgIcons/add_photo.svg";
import linkIcon from "../../assets/svgIcons/link-icon.svg";

import noEmoji from "../../assets/emoji/no-emoji.svg";
import hmmEmoji from "../../assets/emoji/hmm-emoji.svg";
import maybeEmoji from "../../assets/emoji/maybe-emoji.svg";
import yesEmoji from "../../assets/emoji/yes-emoji.svg";
import verymuchEmoji from "../../assets/emoji/verymuch-emoji.svg";

import styles from "./emojiCard.module.scss";
import RenderIf from "../renderIf";
import { toast } from "react-toastify";
import { updateCardSetCardReq, updateCEPCardSetCardReq } from "../../api/cardsetReq/cardSetReq";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../redux/actionTypes";

const emojiesList = [
  {
    label: "NO",
    emoji: noEmoji,
  },
  {
    label: "Hmm",
    emoji: hmmEmoji,
  },
  {
    label: "May be",
    emoji: maybeEmoji,
  },
  {
    label: "Yes",
    emoji: yesEmoji,
  },
  {
    label: "very much",
    emoji: verymuchEmoji,
  },
];

const EmojiCard = (props) => {
  const {
    data = {},
    editable,
    deleteBtn,
    swapBtn,
    onChange,
    emptyState,
  } = props;
  const { cardSetID, cardID, cardTitle, cardDescription, cardHeading } = data;

  const Dispatch = useDispatch();
  const { cardset, cardsetCat, cepCompanyProfile, profile } = useSelector(
    (_state) => _state
  );

  const selectedCompany = cepCompanyProfile?.companyID;
  const roleID = localStorage.getItem("@role");
  const _isCepAdmin = 102 == Number(roleID);

  const _profileCompanyID = profile?.data?.companyID || null;

  const [cardTitleIs, setcardTitleIs] = useState(cardTitle || "");
  const [cardHeadingIs, setcardHeadingIs] = useState(cardHeading || "");
  const [editCard, seteditCard] = useState(editable || false);

  const saveCardData = () => {
    if (!cardTitleIs) return toast.error("Please give the card a title");
    if (!onChange) return;
    onChange({
      title: cardTitleIs,
      cardHeading: cardHeadingIs,
      feedback: "",
    });
    seteditCard(false);
  };
  const onCancelEdit = () => {
    setcardTitleIs((pre) => pre);
    setcardHeadingIs((pre) => pre);
    seteditCard(false);
  };

  const updateCardFun = async () => {
    try {
      const params = {
        cardSetID: cardSetID,
        cardID: cardID,
      };
      const body = {
        cardTitle: cardTitleIs,
        cardImage: "",
        cardDescription: "",
        cardHeading: cardHeadingIs,
        cardLinkURL: "",
      };
      const cepBody = {
        cardTitle: cardTitleIs,
        cardImage: "",
        cardDescription: "",
        cardHeading: cardHeadingIs,
        cardLinkURL: "",
        companyID: selectedCompany,
      };
      // const res = await updateCardSetCardReq(params, body);
      // toast.success(res?.message);
      if (_isCepAdmin) {
        const res = await updateCEPCardSetCardReq(params, cepBody);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "prod",
            message: res?.message,
            fileName: "stackedCard",
            methodName: "updateCardFun",
            type: "info",
          },
        });
      } else {
        const res = await updateCardSetCardReq(params, body);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "prod",
            message: res?.message,
            fileName: "stackedCard",
            methodName: "updateCardFun",
            type: "info",
          },
        });
      }
      Dispatch({
        type: SagaActions.GET_CARDSET,
        payload: _isCepAdmin ? selectedCompany : _profileCompanyID,
      });
      seteditCard(false);
    } catch (error) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "emojiCard",
          methodName: "updateCardFun",
          type: "error",
        },
      });
    }
  };

  useEffect(() => {
    if (!onChange) return;
    const timerId = setTimeout(() => {
      onChange({
        title: cardTitleIs,
        cardHeading: cardHeadingIs,
        feedback: "",
      });
    }, 1500);
    return () => clearTimeout(timerId);
  }, [cardTitleIs, cardHeadingIs]);

  useEffect(() => {
    if (emptyState) return;
    setcardTitleIs(cardTitle);
    setcardHeadingIs(cardHeading);
  }, [data]);

  console.log(data, cardTitleIs, cardHeadingIs, "selected cardset card data");

  return (
    <div className={`${styles.EmojiCard} shadow-block`}>
      <div className={`d-flex align-items-center justify-content-end px-2`}>
        <div style={{ height: "20px" }}></div>
        <RenderIf isShow={!editCard}>
          <div onClick={() => seteditCard(true)}>
            <img src={editIcon} />
          </div>
        </RenderIf>
        <RenderIf isShow={editCard && !emptyState}>
          <div onClick={() => updateCardFun()}>
            <BsCheckLg className="mx-2" color={"#002c71"} size={20} />
          </div>
          <div onClick={onCancelEdit}>
            <BsX className="me-2" color="#D92D20" size={20} />
          </div>
        </RenderIf>
      </div>
      <div className={`${styles.line_indicator}`}></div>

      <div className={`my-2`}>
        {editCard ? (
          <input
            type={"text"}
            placeholder="Card Title (Upto 40 Chars)"
            className={`w-100  x-small-text`}
            value={cardTitleIs}
            onChange={(e) => setcardTitleIs(e.target.value)}
          />
        ) : (
          <div
            className={`d-flex align-items-center justify-content-between my-2`}
          >
            <p>{cardTitleIs}</p>
            {/* <div>
              <img src={editIcon} />
            </div> */}
          </div>
        )}
      </div>

      <div className={`my-0`}>
        {editCard ? (
          <textarea
            rows={5}
            placeholder="Card Heading (Upto 400 Chars)"
            className={`w-100 small-text text-area border rounded p-2`}
            value={cardHeadingIs}
            onChange={(e) => setcardHeadingIs(e.target.value)}
          />
        ) : (
          <>
            <div className={`d-flex  my-2`}>
              <p className={`me-2`}>{cardHeadingIs}</p>
            </div>
            &nbsp;
          </>
        )}
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>

      <div className={`${styles.emoji_container}`}>
        <div className={`${styles.emoji_block}`}>
          {emojiesList?.map((item, idx) => (
            <div className={`${styles.emoji_box} shadow-block`}>
              <div className={`${styles.icon_box}`}>
                <img src={item.emoji} />
              </div>
              <label className={`xx-sm-text`}>{item.label}</label>
            </div>
          ))}
        </div>
      </div>
      <div className={`flex-1 flex-column`}></div>
      <div className={`d-flex align-items-center justify-content-end `}>
        <div className={`${styles.line_indicator}`}></div>
      </div>
    </div>
  );
};

export default EmojiCard;
