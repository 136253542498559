import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";

import styles from "../loginPage.module.scss";
import mailIcon from "../../../assets/svgIcons/mail-icon.svg";
import KeyIcon from "../../../assets/svgIcons/key-icon.svg";
import inactive_check_circle from "../../../assets/icons/inactive-check-circle.png";
import active_check_circle from "../../../assets/icons/active-check-circle.png";
import { validate } from "../../../constants/validationExp";
import ActivityIndicator from "../../../components/activityIndicator/activityIndicator";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { loginWithMailReq } from "../../../api/auth/loginRequest";
import { useForm } from "react-hook-form";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SagaActions } from "../../../redux/actionTypes";
import { getCompanyProfileReq } from "../../../api/company/companyProfileReq";

type Inputs = {
  emailIs: string;
  passwordIs: string;
};
const LoginBlock = (props: any) => {
  const { onSubmit, onRegister } = props;
  const navigation = useNavigate();
  const Dispatch = useDispatch();
  const { companyProfile } = useSelector((_state: RootState) => _state);
  const forgotPassword = true;

  const eadminStats = companyProfile?.data;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const email = watch("emailIs");

  const [emailIs, setemailIs] = useState("");
  const [passwordIs, setpasswordIs] = useState("");
  const [btnLoading, setbtnLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [userId, setuserId] = useState();

  const loginFun = async (data: Inputs) => {
    try {
      seterrorMsg("");
      setbtnLoading(true);
      const body = {
        emailIs: data.emailIs,
        passwordIs: data.passwordIs,
      };
      const res = await loginWithMailReq(body);
      if (res?.data?.role !== 102 && res?.data?.role !== 101) {
        const res = await getCompanyProfileReq();
        const companyStatus = res?.data?.company?.Status;
        if (companyStatus === "Inactive") {
          toast.error(
            "Your company account has been deactivated. Please contact support@peoplex.tech."
          );
          setbtnLoading(false);
          return;
        }
      }
      setuserId(res.data.id);
      setbtnLoading(false);
      if (res?.data?.role == 200) {
        toast.error("You are not authorised to access this");
        return;
      } else {
        toast.success(res?.message);
      }
      if (res?.data?.role == 102) {
        Dispatch({ type: SagaActions.FETCH_CEP_COMPANY_PROFILE });
        Dispatch({ type: SagaActions.DEFAULT_CEP_COMPANY });
      }
      if (res?.data?.role == 301 || res?.data?.role == 303) {
        Dispatch({
          type: SagaActions.UPDATE_NAVBAR_SEETINGS,
          payload: { clickableMenu: true },
        });
      }
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "loginBlock",
          methodName: "loginFun",
          type: "info",
        },
      });
      // Dispatch({
      //   type: SagaActions.UPDATE_NAVBAR_SEETINGS,
      //   payload: { clickableMenu: false },
      // });
      // navigation(
      //   `${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.myAccount}/${ROUTE_NAMES.companyProfile}`
      //   );
      localStorage.setItem("isSignup", "false");
      if (res?.data?.role == 200) {
        navigation(`${ROUTE_NAMES.mainLayout}`);
      }
      if (res?.data?.role == 102) {
        navigation(`${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.cardSetsPage}`);
      } else {
        navigation(`${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.dashboard}`);
      }
    } catch (error: any) {
      console.log(error, "loginPage->LoginPage->resendOtpFun error");
      setbtnLoading(false);
      // seterrorMsg(error.errorMsg);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "loginBlock",
          methodName: "loginFun",
          type: "error",
        },
      });
    }
  };

  const onForgot = () => {
    navigation(`${ROUTE_NAMES.SIGNUP}`, { state: forgotPassword });
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      null: () => {},
      submit: () => {},
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.LoginBlock} flex-1 d-flex flex-column`}>
      <form
        onSubmit={handleSubmit((data) => {
          loginFun(data);
        })}
        className={` flex-1 d-flex flex-column`}
      >
        <div className={` flex-1 d-flex flex-column`}>
          <h4 style={{ fontSize: 25 }} className={``}>
            Employers Login
          </h4>
          <p></p>
          <div>
            <div className={`${styles.input_block}`}>
              <label className={`md-text mb-1`}>Email ID</label>
              <div
                className={`${styles.input_box} ${
                  errors?.emailIs?.message && "border-danger"
                }`}
              >
                <div
                  className={`${styles.icon_box}  ms-2 d-flex align-items-center justify-content-center`}
                >
                  <img
                    src={mailIcon}
                    style={{
                      objectFit: "contain",
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
                <input
                  type={"email"}
                  placeholder=""
                  className={`flex-1`}
                  // autoComplete="off"
                  {...register("emailIs", {
                    required: " Please enter Email",
                    pattern: {
                      value: validate.email,
                      message: "Entered incorrect email format",
                    },
                    // validate: (value) => {
                    //   const domain = value.split("@")[1];
                    //   if (
                    //     validate.blockedEmailDomains.some((blockedDomain) =>
                    //       domain.includes(blockedDomain)
                    //     )
                    //   ) {
                    //     return "Use corporate email addresses";
                    //   }
                    //   return true;
                    // },
                  })}
                  // value={emailIs}
                  // onChange={(e) => {
                  //   setemailIs(e.target.value);
                  //   seterrorMsg("");
                  // }}
                />
                <div
                  className={`${styles.icon_box} me-2 d-flex align-items-center justify-content-center`}
                >
                  {validate.email.test(email) ? (
                    <div className={``} style={{ margin: "0px 2px" }}>
                      <img src={active_check_circle} />
                    </div>
                  ) : (
                    <img src={inactive_check_circle} />
                  )}
                </div>
              </div>
              {errors?.emailIs?.message ? (
                <p className={`x-sm-text mt-1 text-danger`}>
                  {errors?.emailIs?.message}
                </p>
              ) : (
                <p className={`x-sm-text mt-1`}>Corporate email ID.</p>
              )}
            </div>
            <div
              className={`${styles.input_block} ${
                errors?.passwordIs?.message && "border-danger"
              }`}
            >
              <label className={`md-text mb-1`}>Password</label>
              <div
                className={`${styles.input_box} ${
                  errors?.passwordIs?.message && "border-danger"
                }`}
              >
                <div
                  className={`${styles.icon_box} ms-2 d-flex  align-items-center justify-content-center`}
                  style={{
                    height: "20px",
                    width: "25px",
                  }}
                >
                  <img src={KeyIcon} style={{ marginTop: "3px" }} />
                </div>
                <input
                  type={"password"}
                  placeholder=""
                  {...register("passwordIs", {
                    required: "Please enter password",
                  })}
                  // value={passwordIs}
                  // onChange={(e) => setpasswordIs(e.target.value)}
                />
              </div>
              {errors?.passwordIs?.message ? (
                <p className={`x-sm-text mt-1 text-danger`}>
                  {errors?.passwordIs?.message}
                </p>
              ) : (
                <p className={`x-sm-text mt-1`}>Password required</p>
              )}
            </div>
          </div>
          {/* <div className={`d-flex center`}>
            <p className={`sm-text text-danger`}>{errorMsg ? errorMsg : ``}</p>
          </div> */}
        </div>
        <div
          className={`d-flex justify-content-between align-items-center mb-4`}
        >
          <button className={`${styles.forgot_password_btn} `}>
            <div className={`d-flex align-items-center`}>
              {/* <input type={"checkbox"} className={`me-2 ${styles.checkbox}`} /> */}
              {/* <p className={`md-text primary-color`}>Remember me</p> */}
              <p onClick={onRegister} className={`md-text primary-color`}>
                SetUp Password
                {/* <Link to="https://www.peoplex.tech/careerx" className="text-decoration-none primary-color">Sign-Up</Link> */}
              </p>
            </div>
          </button>
          <button className={`${styles.forgot_password_btn}`}>
            <p onClick={onForgot} className={`md-text primary-color`}>
              Forgot Password?
            </p>
          </button>
        </div>
        <button className={`${styles.button} md-text`} type="submit">
          Login
          <div className={`ms-2`}>
            {btnLoading && <ActivityIndicator size={20} />}
          </div>
        </button>
      </form>
    </div>
  );
};

export default LoginBlock;
