import React, { useState, useEffect, useRef } from "react";
import RenderIf from "../renderIf";
import styled from "styled-components";

import { validate } from "../../constants/validationExp";

import phoneIcon from "../../assets/svgIcons/telephone-icon.svg";
import flagIcon from "../../assets/icons/indian_flag.png";
import inactive_check_circle from "../../assets/icons/inactive-check-circle.png";
import active_check_circle from "../../assets/icons/active-check-circle.png";
import { COUNTRY_CODES } from "../../constants/countryCodes";
import { FaAngleDown } from "react-icons/fa6";

const CountryCodeLayout = (props: any) => {
  const {
    children,
    isError,
    mobileNumber,
    checkMark,
    referenceText,
    className,
    required,
    mobileIcon,
    onCountryCodeChange,
    // countryCodeOptions,
    selectedCountryCode,
  } = props;
  const popupRef = useRef<any>();

  const [countryCode, setcountryCode] = useState(selectedCountryCode);
  const [showMenu, setshowMenu] = useState(false);

  const selectedCountry = COUNTRY_CODES?.find(
    (country: any) => country.countryCode === selectedCountryCode
  );

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (popupRef.current && !popupRef.current?.contains(event.target)) {
        setshowMenu(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleOutsideClick);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleChange = (e: any) => {
    console.log("e", e);
    setcountryCode(e.countryCode);
    onCountryCodeChange(e);
    setshowMenu(false)
  };

  return (
    <MobileInputWrapper className={`mb-2 ${className}`}>
      <div className="dropdown_menu_container d-flex">
        <div className=" pe-1">
          <div
            className={`d-flex country_code_box ${
              isError && "border-danger"
            } bg-white`}
            onClick={() => setshowMenu(!showMenu)}
          >
            <div className={`icon_box ms-2`}>
              <img src={selectedCountry ? selectedCountry?.countryImg : flagIcon} className="image_icon" />
            </div>

            <input
              disabled
            //   style={{ outline: "none"}}
              className={`md-text border-0`}
              placeholder="+91"
              value={countryCode}
              onChange={(txt) => handleChange(txt)}
              id="code"
            />
            <FaAngleDown size={23}/>
          </div>
        </div>
        <div
          ref={popupRef}
          className={`menu_box ${
            showMenu && "show_menu"
          } border border-secondary-subtle me-2`}
        >
          {COUNTRY_CODES.map((codeOption: any) => (
            <div
              className="country_code_row"
              onClick={() => handleChange(codeOption)}
            >
              <div className={`icon_box_1 ms-1 me-1`}>
                <img src={codeOption.countryImg} className="image_icon" />
              </div>
              <label>{codeOption.countryCode}</label>
            </div>
          ))}
        </div>
      </div>
    </MobileInputWrapper>
  );
};

export default CountryCodeLayout;

const MobileInputWrapper = styled.div`
  display: flex;
  height: 100%;
  .country_code_block {
    border-radius: 5px;
    // border: 1px solid rgb(200, 200, 200);
    height: 99%;
    align-items: center;
  }

  .dropdown_menu_container {
    overflow: visible;
    position: relative;
  }
  .menu_box {
    display: none;
    position: absolute;
    top: 110%;
    right: -5px;
    background-color: white;
    z-index: 500;
    min-width: 80px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  }
  .show_menu {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
  .country_code_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    width: 100%;
  }
  // .country_code_row:hover {
  //   background-color: #1967d2;
  //   width: 100%;
  //   text-color: white;
  // }
  // .country_code_row:hover label {
  //   text-color: white;
  // }
  .icon_box_1 {
    height: 20px;
    width: 20px;
    border: 0px solid;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  input {
    border: none;
    outline: none;
    padding: 0px 10px;
    background: none;
  }
  .input_box {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    border: 1px solid rgb(200, 200, 200);
  }
  .icon_box {
    height: 25px;
    width: 45px;
    border: 0px solid;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .image_icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 0px solid;
  }
  .country_code_box {
    width: 80px;
    display: flex;
    padding-right: 5px;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    height: 100%;
    border: 1px solid rgb(200, 200, 200);
  }
  .country_code_box input {
    width: 30px;
    padding: 0px;
    margin-left: 10px;
  }
`;
