import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { ProfileType } from "../../types/userTypes";
import { checkTokenValidation } from "../auth/tokenReq";

type getProfileReqPropType = {};

export const getContactUsReq = async (params: { companyID: number }) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": contactUsReq->getContactUsReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(
      `${ApiKey.contactUskey}/${params?.companyID}`
    );
    //  logfun(res.data, "res");
    const resData = res.data?.data;
    const _msg = resData.message || "";
    const _data = resData?.map((item: any) => ({
      employeeID: item?.employeeID || "",
      firstName: item?.firstName || "",
      lastName: item?.lastName || "",
      designation: item?.designation || "",
      email: item?.email || "",
      mobileNumber: item?.mobileNumber || "",
      workLocation: item?.workLocation || "",
      companyID: item?.companyID || "",
      userID: item?.userID || "",
      CEPPartnerInfo: {
        userID: item?.CEPPartnerInfo?.userID || "",
        email: item?.CEPPartnerInfo?.email || "",
        mobileNumber: item?.CEPPartnerInfo?.mobileNumber || "",
        firstName: item?.CEPPartnerInfo?.firstName || "",
        lastName: item?.CEPPartnerInfo?.lastName || "",
        designation: item?.CEPPartnerInfo?.designation || "",
        workLocation: item?.CEPPartnerInfo?.workLocation || "",
      },
    }));
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
