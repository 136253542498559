import React, { useEffect, useState } from "react";

const CustomModal = ({ children, isShow, onCloseModal }) => {
  //   const [showModal, setshowModal] = useState(isShow);

  const closeModal = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      console.log("close modal");
      onCloseModal();
    }
  };

  return isShow ? (
    <div onClick={(e) => closeModal(e)} style={styles.modalContainer}>
      {children}
    </div>
  ) : null;
};

export default CustomModal;
const styles = {
  modalContainer: {
    position: "absolute",
    top: "0px",
    zIndex: 50,
    left: "0px",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(150,150,150,0.5)",
  },
};
