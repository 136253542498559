import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

import styles from "./candidatePage.module.scss";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../redux/actionTypes";

const menuListGroupOne = [
  {
    label: "Basic Information",
    icon: "",
    route: ROUTE_NAMES.basic_info_form,
  },
  {
    label: "Add Card Sets",
    icon: "",
    route: ROUTE_NAMES.cardSetForm,
  },
  {
    label: "Resources",
    icon: "",
    route: ROUTE_NAMES.resourcesForm,
  },
  {
    label: "Review and Update",
    icon: "",
    route: ROUTE_NAMES.reviewForm,
  },
];

const CandidatePage = () => {
  const navigation = useNavigate();
  const Dispatch = useDispatch();
  const { cardset, cardsetCat, cepCompanyProfile, profile } = useSelector(
    (_state) => _state
  );

  const _profileCompanyID = profile?.data?.companyID || null;

  useEffect(() => {
    Dispatch({ type: SagaActions.GET_CARDSET, payload: _profileCompanyID });
  }, []);

  return (
    <div className={`${styles.candidate} h-100`}>
      <Outlet />
    </div>
  );
};

export default CandidatePage;
