import React, { useCallback, useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { IoIosSearch, IoMdAdd } from "react-icons/io";

import styles from "../cardSetsPage.module.scss";
import LoginLog from "../../../components/microComponents/loginLog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SagaActions } from "../../../redux/actionTypes";
import CardsetDetails from "./cardsetDetails";
import RenderIf from "../../../components/renderIf";
import {
  getAllCardSetReq,
  getCardSetByIdReq,
} from "../../../api/cardsetReq/cardSetReq";
import { toast } from "react-toastify";
import { useRoutes, useParams, useLocation } from "react-router-dom";

const EmptyCardSet = () => {
  const { modals } = useSelector((_state: RootState) => _state);
  const Dispatch = useDispatch();

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      addNewCardset: () => {
        Dispatch({ type: SagaActions.SHOW_ADD_CARDSET_MODAL });
      },
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.CompanyIntro} px-3`}>
      <LoginLog />
      <Container>
        <Row className={`my-2`}>
          <Col sm={12} md={12} lg={5}>
            <h5 className={`lg-text`}>Add Card Sets</h5>
          </Col>
          <Col sm={12} md={12} lg={5}>
            <div className={`border d-flex ${styles.search_block}`}>
              <input
                placeholder="Search by card set name"
                className={`x-sm-text `}
              />
              <button className={`${styles.search_icon} p-1`}>
                <IoIosSearch size={20} />
              </button>
            </div>
          </Col>
          <Col sm={12} md={12} lg={2} className={`px-0`}>
            <button
              type="button"
              className={`d-flex align-items-center btn btn-primary py-2`}
              onClick={() => _onPress({ key: "addNewCardset" })}
            >
              <IoMdAdd />
              <p className={`mx-2 sm-text`}>New Card Sets</p>
            </button>
          </Col>
        </Row>
      </Container>
      <div className="d-flex flex-1 align-items-center justify-content-center flex-column flex-grow-1 h-100">
        <p>-:There are no card sets:-</p>
      </div>
    </div>
  );
};

export default EmptyCardSet;
