import AsyncStorage from "@react-native-async-storage/async-storage";
import { ApiKey } from "../apiKeys";
import { publicAxios } from "../config";
import { Platform } from "react-native";
import * as RootNavigation from "../../routes";
import dayjs from "dayjs";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { globalNavigate } from "../../routes/rootNavigation";

export const refreshTokenForMobileReq = async (data?: {
  refreshToken: string | number;
  userID: string;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": tokenReq->refreshTokenForMobileReq " + t);
  try {
    const _userID = localStorage.getItem("userID");
    const _refreshToken = localStorage.getItem("refreshToken");
    const body = {
      refreshToken: _refreshToken || data?.refreshToken || "",
      userID: Number(_userID) || data?.userID || "",
    };
    //  logfun(body, "body");
    const res = await publicAxios.post(`${ApiKey.refreshTokenKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const msg = res.data.message;
    const _data: any = {
      token: resData?.accessToken || "",
    };
    localStorage.setItem("token", resData?.accessToken);
    return { error: false, data: _data, message: msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    globalNavigate(ROUTE_NAMES.LOGIN);
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const checkTokenValidation = async () => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": tokenReq->checkTokenValidation " + t);
  try {
    const expireTimeObj = localStorage.getItem("tokenExpiresOn");
    const expireTime = expireTimeObj ? JSON.parse(expireTimeObj) : null;
    const duration = localStorage.getItem("tokenExpireDuration");
    const remainingTime = dayjs(expireTime).diff(new Date(), "s");

    if (Number(remainingTime) > 1) {
      //  logfun(remainingTime + "-remainingTime in seconds", "res");
      return { data: "" };
    }
    const res = await refreshTokenForMobileReq();
    const _expireDate = dayjs().add(Number(duration), "s");
    localStorage.setItem("tokenExpiresOn", JSON.stringify(_expireDate));
    //  logfun("Token expired", "res");
    return { error: false, data: "", message: "", errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
