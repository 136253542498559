import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { TbDots } from "react-icons/tb";
import { Col, Container, Row } from "react-bootstrap";

import styles from "../candidatePage.module.scss";
import infoIcon from "../../../assets/svgIcons/Info-icon.svg";
import warningIcon from "../../../assets/svgIcons/warning-icon.svg";
import editIcon from "../../../assets/svgIcons/edit.svg";
import deleteIcon from "../../../assets/svgIcons/delete-icon.svg";
import compayCard from "../../../assets/svgImages/company-card.svg";
import careerCard from "../../../assets/svgImages/career-card.svg";
import roleClarityCard from "../../../assets/svgImages/role-clarity-card.svg";
import salaryCard from "../../../assets/svgImages/salary-card.svg";
import giftImage from "../../../assets/svgImages/gift.svg";
import addIcon from "../../../assets/svgIcons/add-icon.svg";
import LoginLog from "../../../components/microComponents/loginLog";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../../redux/actionTypes";
import dayjs from "dayjs";
import { RootState } from "../../../redux/store";
import {
  CardsetWeekType,
  DefaultCompanyCardsetsListType,
} from "../../../types/candidateTypes";

import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { generateCardSetWeeks } from "../../../constants/weeksDateGenerate";
import {
  addDefaultCardSetReq,
  getDefaultCardSetReq,
} from "../../../api/cardsetReq/cardSetReq";
import { toast } from "react-toastify";

const AddCardSetForm = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  const { profile, candidateCardsetList, cardset, candidateForm } = useSelector(
    (_state: RootState) => _state
  );
  console.log("candidateCardsetList", candidateCardsetList);
  const _cardset = cardset?.data || [];
  const companyID = profile?.data?.companyID || 0;
  const isClicked = candidateCardsetList?.defaultCardsetClicked;
  const addedDefaultCardset = candidateCardsetList?.addedDefaultCardset;
  const _DOJ = candidateForm?.data?.personalDetails?.dateOfJoining || null;
  const _candidateName = candidateForm?.data?.personalDetails?.firstName || "";
  const dateOfJoining = candidateForm?.data?.personalDetails?.dateOfJoining;
  const _daysToJoin = dateOfJoining
    ? dayjs(new Date(dateOfJoining)).diff(dayjs(new Date()), "day")
    : 0;
  console.log("check addedDefaultCardset", addedDefaultCardset);
  const [weeksData, setweeksData] = useState<any>([]);
  const [weeksLabels, setweeksLabels] = useState<any>([]);
  const [cardsetList, setcardsetList] = useState<any>({});
  const [defaultCardsetList, setdefaultCardsetList] = useState<any>({});
  const [isCheck, setisCheck] = useState(addedDefaultCardset);
  const [modifiedDefaultCardset, setmodifiedDefaultCardset] =
    useState<DefaultCompanyCardsetsListType>();
  const [isDefaultCardsetsPresent, setisDefaultCardsetsPresent] =
    useState(false);

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      addCardset: (data: CardsetWeekType) => {
        Dispatch({ type: SagaActions.SHOW_CARDSET_PICKER_MODAL });
        Dispatch({
          type: SagaActions.SELECT_CARDSET_WEEK,
          payload: data,
        });
      },
      getDefaultCardSet: (data: any) => {
        defaultCardSetsLists();
      },
      editCardset: (data: any) => {},
      deleteCardset: (data: any) => {
        Dispatch({
          type: SagaActions.DELETE_CANDIDATES_CARDSET_LIST,
          payload: data,
        });
        Dispatch({
          type: SagaActions.ADDED_DEFAULT_CANDIDATES_CARDSET_LIST,
          payload: false,
        });
      },
      save: () => {
        if (addedDefaultCardset) {
          Dispatch({ type: SagaActions.SHOW_DEFAULT_CARDSET_MODAL });
        } else {
          const parentPath = currentPath.substring(
            0,
            currentPath.lastIndexOf("/")
          );
          navigate(`${parentPath}/${ROUTE_NAMES.resourcesForm}`);
        }
      },
    };
    fun[key](data);
  };

  const addDefaultCardSetsLists = async () => {
    try {
      // const defaultCardsets = candidateCardsetList?.data?.map(
      //   ({ weekNumber, cardSetOrder, cardSetID }) => ({
      //     weekNumber,
      //     cardSetOrder,
      //     companyID,
      //     cardSetID,
      //   })
      // );
      // const requestData = {
      //   defaultCardSets: defaultCardsets,
      // };
      if (modifiedDefaultCardset?.defaultCardSets) {
        Dispatch({
          type: SagaActions.ADD_DEFAULT_COMPANY_CARDSET_LIST,
          payload: modifiedDefaultCardset,
        });
        Dispatch({
          type: SagaActions.ADDED_DEFAULT_CANDIDATES_CARDSET_LIST,
          payload: true,
        });
      } else {
        toast.error("Please add cardsets");
      }
      // console.log("default cardsets", res);
    } catch (error) {
      // toast.error(error?.errMessage);
    }
  };

  const defaultCardSetsLists = async () => {
    try {
      const res = await getDefaultCardSetReq({ companyID: companyID });
      // console.log("default cardsets", res);
      groupByWeekFun2(res, weeksLabels);
    } catch (error) {
      // toast.error(error?.errMessage);
    }
  };
  const checkDefaultCardSetsLists = async () => {
    try {
      const res = await getDefaultCardSetReq({ companyID: companyID });
      if (res?.data?.length > 0) {
        setisDefaultCardsetsPresent(true);
      } else {
        setisDefaultCardsetsPresent(false);
      }
    } catch (error) {
      // toast.error(error?.errMessage);
    }
  };

  const groupByWeekFun = () => {
    const defaultCardsets = candidateCardsetList?.data?.map(
      ({ weekNumber, cardSetOrder, cardSetID }) => ({
        weekNumber,
        cardSetOrder,
        companyID,
        cardSetID,
      })
    );
    const requestData = {
      defaultCardSets: defaultCardsets,
    };
    setmodifiedDefaultCardset(requestData);
    const result = candidateCardsetList?.data?.reduce((r: any, a) => {
      const fieldName = `${a?.weekNumber}`;
      r[fieldName] = r[fieldName] || [];
      r[fieldName].push(a);
      return r;
    }, {});
    setcardsetList({ ...result });
  };

  const groupByWeekFun2 = (res: any, weeksLabels: CardsetWeekType[]) => {
    const result = res?.data?.reduce((r: any, a: any) => {
      const fieldName = `${a?.weekNumber}`;
      r[fieldName] = r[fieldName] || [];
      r[fieldName].push({
        ...a,
        dateFrom: weeksLabels[a?.weekNumber]?.dateFrom,
        dateTo: weeksLabels[a?.weekNumber]?.dateTo,
      });
      return r;
    }, {});
    setdefaultCardsetList({ ...result });
  };

  useEffect(() => {
    const weeks = generateCardSetWeeks(
      _DOJ
        ? dayjs(_DOJ).format("YYYY/MM/DD")
        : dayjs().add(6, "weeks").format("YYYY/MM/DD")
    );
    setweeksLabels(weeks);
  }, []);

  useEffect(() => {
    checkDefaultCardSetsLists();
  }, []);

  useEffect(() => {
    if (defaultCardsetList) {
      Object.values(defaultCardsetList).forEach((objectArray: any, index) => {
        objectArray.forEach((object: any) => {
          Dispatch({
            type: SagaActions.DEFAULT_CANDIDATES_CARDSET_LIST,
            payload: {
              cardSetID: object.cardSetID,
              cardSetOrder: object.cardSetOrder,
              dateFrom: object.dateFrom,
              dateTo: object.dateTo,
              weekNumber: object.weekNumber,
            },
          });
        });
      });
    }
  }, [defaultCardsetList]);

  useEffect(() => {
    if (candidateCardsetList?.data?.length < 1) return;
    groupByWeekFun();
  }, [candidateCardsetList?.data]);

  console.log(cardsetList, "candidateCardsetList?.data");

  return (
    <div className={`${styles.addCardSetForm} px-3`}>
      <Container>
        <LoginLog />
        <div className={`d-flex align-item-center ${styles.path_view_block}`}>
          <a className={`link x-sm-text primary-color`}>Candidates</a>
          <BiChevronRight className={`primary-color`} />
          <a className={`link x-sm-text primary-color`}>Add Candidates</a>
        </div>
        <Row className={`my-2 `}>
          <Col className={`${styles._col}`}>
            {/* <h5 className={`my-1 lg-text`}>Journey and Card-Sets</h5> */}
            <h5 className={`my-1 lg-text`}>Post-Offer Journey</h5>
            {/* <p className={`sm-text`}>
              Recommended plan for <b>28 days: 17</b> Card Sets and <b>2 </b>
              Gifts
            </p> */}
            <p className={`sm-text`}>
              {_candidateName} has {_daysToJoin} days to join days
            </p>
            <div className={`d-flex align-items-start my-2`}>
              <img src={infoIcon} className={`me-2`} />
              {/* <p className={`x-sm-text light-text`}>
                <i>
                  Card Sets can be shuffled between any week through drag and
                  drop. Also a new card-set can be added in any particular week
                  <br />
                  Card Sets with warning sign <img src={warningIcon} /> need to
                  be reviewed before publishing the plan
                  <br />
                  Card Sets can be deleted by hovering on each set.
                </i>
              </p> */}
              <p className={`x-sm-text light-text`}>
                <i>
                  Use the ‘+’ button to add CardSets to the week.
                  <br />
                  Hover over a CardSet, to unveil Delete option
                  {/* <br />
                  Card Sets with warning sign <img src={warningIcon} /> need to
                  be reviewed before publishing the plan
                  <br />
                  Card Sets can be deleted by hovering on each set. */}
                </i>
              </p>
            </div>
          </Col>
          <Col className={`${styles._col}`} sm={12} md={6} lg={4}>
            {isDefaultCardsetsPresent && (
              <button
                className={`${
                  isClicked ? `btn btn-secondary w-100` : `sbmit_btn w-100`
                }`}
                // className={`btn btn-primary w-100`
                // }
                disabled={isClicked ? true : false}
                onClick={() => _onPress({ key: "getDefaultCardSet" })}
              >
                {!isClicked && (
                  <label className={`sm-text`}>Add default CardSets</label>
                )}
                {isClicked && (
                  <label className={`sm-text`}>Default CardSets added</label>
                )}
              </button>
            )}
          </Col>
        </Row>
        {weeksLabels?.length > 0 &&
          weeksLabels?.map((item: CardsetWeekType, idx: number) => (
            <Row className={`my-2 mx-2`} key={idx}>
              <div className={`${styles.card_block}`}>
                <div className={`${styles.stage_box}`}>
                  <div
                    className={`d-flex align-items-center justify-content-center p-1`}
                  >
                    <input
                      type={"checkbox"}
                      className={`me-2`}
                      id={item?.dateFrom}
                    />
                    <label className={`md-text`}>
                      {idx === 0
                        ? "Initial"
                        : idx > 5
                        ? "Last"
                        : "Week " + item?.weekNumber}
                    </label>
                  </div>
                  <p className={`x-sm-text`}>
                    {dayjs(item?.dateFrom).format("MMM DD")} -{" "}
                    {dayjs(item?.dateTo).format("MMM DD")}
                  </p>
                </div>
                {cardsetList[`${item?.weekNumber}`]?.length > 0 &&
                  cardsetList[`${item?.weekNumber}`]?.map(
                    (itm: CardsetWeekType, inx: number) => {
                      const cardSetIs = _cardset.find((cs) => {
                        return cs?.cardSetID === itm?.cardSetID;
                      });

                      return (
                        <div className={`${styles.card_box}`}>
                          <div className={`${styles.menu_icon}`}>
                            <TbDots color="#002C71" />
                          </div>

                          <div className={`${styles.img_block}`}>
                            <img src={compayCard} />
                          </div>
                          <label className={`x-sm-text text-center`}>
                            {cardSetIs?.cardSetName || "NaN"}
                          </label>
                          <div className={`${styles.hoverDeleteBlock}`}>
                            <div
                              className="d-flex"
                              style={{
                                backgroundColor: "white",
                                padding: "2px 5px",
                                borderRadius: "5px",
                              }}
                            >
                              {/* <div
                                className={`${styles.iconBox}`}
                                onClick={() =>
                                  _onPress({
                                    key: "editCardset",
                                    data: itm,
                                  })
                                }
                              >
                                <img src={editIcon} />
                              </div> */}
                              <div
                                className={`${styles.iconBox}`}
                                onClick={() =>
                                  _onPress({
                                    key: "deleteCardset",
                                    data: itm,
                                  })
                                }
                              >
                                <img src={deleteIcon} />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}

                {item?.weekNumber === 1 || idx === weeksLabels.length - 2 ? (
                  <div className={`${styles.card_box}`}>
                    <div className={`${styles.menu_icon}`}>
                      <TbDots color="#002C71" />
                    </div>
                    <div className={`${styles.img_block}`}>
                      <img src={giftImage} />
                    </div>
                    <label className={`x-sm-text`}>Gift</label>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={`${styles.add_box}`}
                  onClick={() => _onPress({ key: "addCardset", data: item })}
                >
                  <div className={`${styles.img_block}`}>
                    <img src={addIcon} />
                  </div>
                </div>
              </div>
            </Row>
          ))}

        <Row className={`my-3`}>
          <Col className={`${styles._col}`} sm={12} md={6} lg={5}>
            <div
              className={`ms-1 d-flex align-items-center justify-content-start p-1`}
            >
              <input
                type={"checkbox"}
                className={`me-2`}
                checked={addedDefaultCardset ? true : false}
                onClick={() => addDefaultCardSetsLists()}
                // id={item?.dateFrom}
              />
              {/* <label className={`md-text`}>Add to default cardsets</label> */}
              <label className={`md-text`}>
                Save this as default configuration
              </label>
            </div>
          </Col>
          <Col className={`${styles._col}`} sm={12} md={6} lg={2}></Col>
          <Col className={`${styles._col}`} sm={12} md={6} lg={2}>
            <button className={`sbmit_btn_outline w-100`}>
              <label className={`sm-text`}>cancel</label>
            </button>
          </Col>
          <Col
            className={`${styles._col} p-0 d-flex align-items-end justify-content-end`}
            sm={12}
            md={6}
            lg={3}
          >
            <button
              className={`me-3 sbmit_btn w-100`}
              onClick={() => _onPress({ key: "save" })}
            >
              <label className={`sm-text`}>Save & Continue</label>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddCardSetForm;
