import React, { useEffect, useState, useMemo } from "react";
import { GoMail } from "react-icons/go";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineModeEditOutline } from "react-icons/md";

import styles from "./rightSection.module.scss";
import VeryMuchEmoji from "../../../assets/emoji/verymuch-emoji.svg";
import YesEmoji from "../../../assets/emoji/yes-emoji.svg";
import MaybeEmoji from "../../../assets/emoji/maybe-emoji.svg";
import HmmEmoji from "../../../assets/emoji/hmm-emoji.svg";
import NoEmoji from "../../../assets/emoji/no-emoji.svg";
import Dropdown from "../../../components/dropdown";
import EngagementContent from "../components/engagementContent";
import CardSetsContent from "../components/cardSetsContent";
import ResourceContent from "../components/resourceContent";
import PersonalContent from "../components/personalContent";
import { BasicTable } from "../../../components/table";
import activitiesData from "./activitiesData.json";
import LoginLog from "../../../components/microComponents/loginLog";
import {
  CandidateInfoType,
  CandidateType,
} from "../../../types/candidateTypes";
import { getCandidateAllDetailsReq } from "../../../api/candidateReq/candidateReq";
import { Spinner } from "react-bootstrap";
import { updateCandidatePrsnDetailsReq } from "../../../api/candidateReq/candidateDetailsReq";
import { toast } from "react-toastify";
import activityTableHeader from "../constants/activityTableHeader";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import dayjs from "dayjs";

const RightSection = () => {
  const Dispatch = useDispatch();
  const { candidates } = useSelector((_state: RootState) => _state);

  const [candidateDetails, setcandidateDetails] = useState<CandidateInfoType>();
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({
    candidateName: "",
  });
  const [activeSection, setactiveSection] = useState("");

  /* foe tableColumns pass an object with {Header, accessor} keys */
  /* header -> is table header column name and accessor is key of that header */
  // A function to create dynamic header
  // const activityTableColumns = Object.keys(activitiesData[0]).map((item) => ({
  //   Header: item,
  //   accessor: item,
  // }));
  console.log(candidateDetails, "candidateDetails");

  const _candidateName = `${candidateDetails?.Candidate?.firstName} ${candidateDetails?.Candidate?.lastName}`;
  const _designation = candidateDetails?.CandidateOffer?.designation || "";
  const _engagementScore = candidateDetails?.Candidate?.engagementScore || "";
  const _mobileNumber = candidateDetails?.User?.mobileNumber || "";
  const _email = candidateDetails?.User?.email || "";
  const _noEngegScore = Number(_engagementScore)>=0 && Number(_engagementScore)<=20
  const _hmmEngegScore = Number(_engagementScore)>=21 && Number(_engagementScore)<=40
  const _meybeEngegScore = Number(_engagementScore)>=41 && Number(_engagementScore)<=60
  const _yesEngegScore = Number(_engagementScore)>=61 && Number(_engagementScore)<=80
  const _verymuchEngegScore = Number(_engagementScore)>=81 && Number(_engagementScore)<=100

  const _cardSets = candidateDetails?.CandidateCardSets || [];
  const _resources = candidateDetails?.CandidateResources || [];
  const _engagement = candidateDetails?.CandidateEngagement || null;
  const _activity = candidateDetails?.CandidateActivity || [];
  const _pageLoading = candidates?.candidateDetailsLoading;
  const isDropOut =
    candidates?.selectedCandidatesDetails?.CandidateOffer?.offerStatus ===
    "Dropout";
  const isJoined =
    candidates?.selectedCandidatesDetails?.CandidateOffer?.offerStatus ===
    "Joined";
  const _daysToJoin = dayjs(
    new Date(
      candidates?.selectedCandidatesDetails?.CandidateOffer?.dateOfJoining
    )
  ).diff(dayjs(new Date()), "day");

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedData({
      candidateName: _candidateName,
    });
  };

  const handleUpdateStatusClick = async () => {
    const trimedName = editedData.candidateName.trim();
    const [firstName, lastName] = trimedName.split(" ");
    if (!firstName) return toast.error("First Name is required");
    try {
      const res = await updateCandidatePrsnDetailsReq(
        { candidateID: candidates?.selectedCandidateId },
        { firstName: firstName, lastName: lastName }
      );
      setIsEditing(false);
      toast.success(res?.message);
      onUpdateDetails();
      Dispatch({ type: SagaActions.GET_CANDIDATES });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "rightSection",
          methodName: "handleUpdateStatusClick",
          type: "info",
        },
      });
    } catch (error: any) {
      setIsEditing(false);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "rightSection",
          methodName: "handleUpdateStatusClick",
          type: "error",
        },
      });
    }
  };

  const onUpdateDetails = () => {
    Dispatch({
      type: SagaActions.GET_SELECTED_CANDIDATE_DETAILS,
      payload: {
        selectedCandidateId: candidates?.selectedCandidateId,
      },
    });
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      updateStatus: () => {
        Dispatch({ type: SagaActions.SHOW_CANDIDATE_STATUS_MODAL });
      },
    };
    fun[key](data);
  };

  useEffect(() => {
    if (!candidates?.selectedCandidateId) return;
    Dispatch({
      type: SagaActions.GET_SELECTED_CANDIDATE_DETAILS,
      payload: {
        selectedCandidateId: candidates?.selectedCandidateId,
      },
    });
  }, [candidates?.selectedCandidateId]);

  useEffect(() => {
    if (!candidates?.selectedCandidatesDetails?.Candidate?.candidateID) return;
    setcandidateDetails(candidates?.selectedCandidatesDetails);
  }, [candidates?.selectedCandidatesDetails]);

  if (_pageLoading) {
    return (
      <div className={`${styles.rightSection} pt-2 h-100`}>
        <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
          <p className="x-sm-text">Loading..</p>
        </div>
      </div>
    );
  }

  if (
    !_pageLoading &&
    !candidates?.selectedCandidatesDetails?.Candidate?.candidateID
  ) {
    return (
      <div className={`${styles.rightSection} pt-2 h-100`}>
        <div className="d-flex align-items-center flex-column justify-content-center w-100 h-100">
          <p className="x-sm-text">-: No data found :-</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.rightSection} pt-2`}>
      <LoginLog />
      <p>&nbsp;</p>
      <div
        className={`d-flex align-items-start justify-content-between px-3 py-1`}
      >
        <div className={`d-flex`}>
          <div className={`${styles.small_icon}`}>
            {_verymuchEngegScore && <img src={VeryMuchEmoji} />}
            {_yesEngegScore && <img src={YesEmoji} />}
            {_meybeEngegScore && <img src={MaybeEmoji} />}
            {_hmmEngegScore && <img src={HmmEmoji} />}
            {_noEngegScore && <img src={NoEmoji} />}
          </div>
          <div className={`px-2`}>
            {/* <h5 className={`lg-text`}>{_candidateName}</h5>
            <div className={`${styles.flex_1} align-items-center`}>
              <IoCallOutline size={14} color="#002C71" />
              <span className={`x-sm-text ms-1`}>+91 {_mobileNumber}</span>
            </div>
            <div className={`${styles.flex_1} align-items-center`}>
              <GoMail size={14} color="#002C71" />
              <span className={`x-sm-text ms-1`}>{_email}</span>
            </div> */}
            {isEditing ? (
              <input
                type="text"
                className="md-text w-100 border border-dark-subtle rounded p-1"
                value={editedData.candidateName}
                onChange={(e) =>
                  setEditedData({
                    ...editedData,
                    candidateName: e.target.value,
                  })
                }
              />
            ) : (
              <h5 className={`lg-text`}>{_candidateName}</h5>
            )}
            <div className={`${styles.flex_1} align-items-center`}>
              <IoCallOutline size={14} color="#002C71" />
              <span className={`x-sm-text ms-1`}>+91 {_mobileNumber}</span>
            </div>
            <div className={`${styles.flex_1} align-items-center`}>
              <GoMail size={14} color="#002C71" />
              <span className={`x-sm-text ms-1`}>{_email}</span>
            </div>
            <p className={`x-sm-text my-1 light-text`}>
              {_daysToJoin > 0 ? _daysToJoin : 0} Days to Join
            </p>
          </div>
        </div>
        <div className={`d-flex align-items-center`}>
          {isEditing ? (
            <button
              className={`${styles.sbmit_btn} md-text mx-1 text-white`}
              onClick={handleUpdateStatusClick}
            >
              Update
            </button>
          ) : (
            <button
              className={`${styles.edit_btn} md-text`}
              onClick={handleEditClick}
            >
              <MdOutlineModeEditOutline className={`me-2`} />
              Edit
            </button>
          )}
          {!isEditing &&
            candidates.selectedCandidatesDetails.CandidateOffer.offerStatus && (
              <button
                // style={{
                //   backgroundColor: isDropOut ? "red" : "",
                //   // outline: isDropOut ? "none" : "",
                //   // borderColor: isDropOut ? "none" : "",
                //   borderWidth: isDropOut ? 0 : 1,
                //   color: isDropOut ? "white" : "",
                // }}
                className={`${!isDropOut && !isJoined && styles.update_status_btn} ${
                  isDropOut && styles.update_status_btn_2
                } ${
                  isJoined && styles.update_status_btn_3
                } sm-text px-2 mx-3`}
                onClick={() => {}}
              >
                {
                  candidates.selectedCandidatesDetails.CandidateOffer
                    .offerStatus
                }
              </button>
            )}
          {isEditing ? (
            <button
              className={`${styles.outline_btn} md-text btn-outline-primary`}
              onClick={() => setIsEditing(!isEditing)}
            >
              Cancel
            </button>
          ) : (
            <button
              className={`${styles.update_status_btn} sm-text px-2`}
              onClick={() => _onPress({ key: "updateStatus" })}
            >
              Update Candidate Status
            </button>
          )}
        </div>
      </div>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <div>
        <Dropdown
          keyId={"1"}
          onExpand={() => setactiveSection("engagement")}
          expand={activeSection === "engagement"}
          headerComponent={() => <p className={`md-text`}>Engagement</p>}
          headerClass={({ showList }: any) =>
            showList && "bg-white border-bottom"
          }
        >
          <EngagementContent data={_engagement} />
        </Dropdown>
        <Dropdown
          keyId={"2"}
          onExpand={() => setactiveSection("cardset")}
          expand={activeSection === "cardset"}
          headerComponent={() => <p className={`md-text`}>CardSets</p>}
          headerClass={({ showList }: any) =>
            showList && "bg-white border-bottom"
          }
        >
          <CardSetsContent
            data={_cardSets}
            candidateDetails={candidateDetails}
            onUpdate={onUpdateDetails}
          />
        </Dropdown>
        <Dropdown
          keyId={"3"}
          onExpand={() => setactiveSection("resource")}
          expand={activeSection === "resource"}
          headerComponent={() => <p className={`md-text`}>Resources</p>}
          headerClass={({ showList }: any) =>
            showList && "bg-white border-bottom"
          }
        >
          <ResourceContent
            data={_resources}
            candidateDetails={candidateDetails}
            onUpdate={onUpdateDetails}
          />
        </Dropdown>
        <Dropdown
          keyId={"4"}
          onExpand={() => setactiveSection("personalInfo")}
          expand={activeSection === "personalInfo"}
          headerComponent={() => <p className={`md-text`}>Offer Details</p>}
          headerClass={({ showList }: any) =>
            showList && "bg-white border-bottom"
          }
        >
          <PersonalContent data={candidateDetails} onUpdate={onUpdateDetails} />
        </Dropdown>
        <Dropdown
          keyId={"5"}
          onExpand={() => setactiveSection("activity")}
          expand={activeSection === "activity"}
          headerComponent={() => <p className={`md-text`}>Activity</p>}
          headerClass={({ showList }: any) =>
            showList && "bg-white border-bottom"
          }
        >
          <BasicTable
            tableData={_activity}
            tableColumns={activityTableHeader}
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default React.memo(RightSection);
