export const personalDetails = {
  firstNameIs: "",
  lastNameIs: "",
  phoneNumberIs: "",
  emailIs: "",
  designationIs: "",
};

export const recruiterDetails = {
  email: "",
  firstName: "",
  lastName: "",
  designation: "",
  companyID: "",
  mobile: "",
  password: "",
  role: "",
};
export const testRecruiterDetails = {
  email: "rakshith2@gmail.com",
  firstName: "rakshith",
  lastName: "r",
  designation: "recruiter",
  companyID: "202300002",
  mobile: "9876543111",
  password: "12345",
  role: "Recruiter",
};

export const companyProfile = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  email: "",
  designation: "",
  workLocation: "",
  workLocation2: "",
  roleID: "",
  companyName: "",
  GSTNo: "",
  companyAddressLine1: "",
  companyCountry: "",
  companyState: "",
  companyCity: "",
  companyPinCode: "",
};
export const TestCompanyProfile = {
  firstName: "rakshith",
  lastName: "s",
  mobileNumber: "9876543125",
  email: "rakshith125@gmail.com",
  designation: "Eadmin",
  workLocation: "india",
  workLocation2: "KA",
  roleID: "303",
  companyName: "Company125",
  GSTNo: "125",
  companyAddressLine1: "address",
  companyCountry: "india",
  companyState: "KA",
  companyCity: "Mysore",
  companyPinCode: "5800182",
};
