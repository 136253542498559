import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { BsLink45Deg, BsFileEarmarkText, BsCheckCircle } from "react-icons/bs";

import styles from "./notifications.module.scss";
import BellIcon from "../../../assets/svgIcons/bell-icon.svg";
import NotificationCard from "../../notificationCard";

const Notifications = (props) => {
  const { onClose } = props;
  const { notification } = useSelector((_state) => _state);
  const notificationLists = notification.data;
  return (
    <div
      className={`${styles.Notifications} h-100 align-items-end`}
    >
      <div className={`h-100 ${styles.content_block}`}>
        <header className={`${styles.header}`}>
          <div className={`${styles.icon_box}`}>
            <img src={BellIcon} />
          </div>
          <p>Notifications</p>
          <div style={{ flex: 1 }}></div>
          <IoClose onClick={() => onClose()} />
        </header>
        <div className={`p-1 ${styles.scroll_block}`}>
          {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((item, idx) => (
            <NotificationCard key={idx} />
          ))} */}
          <NotificationCard notifLists={notificationLists} />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
