import { createSlice } from "@reduxjs/toolkit";
import { ProfileType } from "../../../types/userTypes";
import { getProfileReq } from "../../../api/user/profileReq";
import { ApiResType } from "../../../types/apiTypes";
import { call, put, select } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { RootState } from "../../store";

export interface UserSettingSlicetypes {
  error: boolean;
  message: string;
  data: any;
  loading: boolean;
}

const initialState: UserSettingSlicetypes = {
  error: false,
  message: "",
  data: {
    navBar: {
      clickableMenu: true,
    },
  },
  loading: false,
};

const UserSettingSlice = createSlice({
  name: "UserSetting",
  initialState: initialState,
  reducers: {
    UpdateUserSettings: (
      state: UserSettingSlicetypes,
      { payload }: { payload: any }
    ) => {
      state.data = payload;

      state.loading = false;
      state.error = false;
      console.log(state, "state.data->userSettingSlice->UpdateUserSettings");
      return state;
    },
    StartLoading: (state: UserSettingSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: UserSettingSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: UserSettingSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const { UpdateUserSettings, StartLoading, StopLoading, ClearData } =
  UserSettingSlice.actions;

export default UserSettingSlice.reducer;

export function* updateNavBarSetting({ payload }: any) {
  try {
    const _modifiedData: any = {
      navBar: {
        clickableMenu: true,
      },
    };
    const state: RootState = yield select();
    const { userSettings } = state;

    Object.keys(payload).map((key) => {
      if (userSettings.data.navBar[key] !== undefined)
        _modifiedData.navBar[key] = payload[key];
    });

    yield put(StartLoading());
    yield put(UpdateUserSettings(_modifiedData));
  } catch (error: any) {
    yield put(StopLoading());
  }
}

export function* clearUserSettingSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
