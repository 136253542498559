import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SagaActions } from "../../../redux/actionTypes";

import styles from "../myAccount.module.scss";
import activeCheck from "../../../assets/icons/active-check-circle.png";
import LoginLog from "../../../components/microComponents/loginLog";
import { useDispatch } from "react-redux";
import { getPlansListReq } from "../../../api/subscriptions/plansReq";
import RenderIf from "../../../components/renderIf";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const PlanPage = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  const [plansList, setplansList] = useState({});
  const [selectedPlan, setselectedPlan] = useState();
  const [plansError, setplansError] = useState("");

  const getSubscriberPlansFun = async () => {
    setplansError("");
    try {
      const res = await getPlansListReq();
      setplansList(res.data);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "plan",
          methodName: "getSubscriberPlansFun",
          type: "info",
        },
      });
    } catch (error) {
      setplansError(error.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "plan",
          methodName: "getSubscriberPlansFun",
          type: "error",
        },
      });
    }
  };

  const _onPress = ({ key, data }) => {
    const fun = {
      submit: (data) => {},
      continue: (data) => {
        const parentPath = currentPath.substring(
          0,
          currentPath.lastIndexOf("/")
        );
        navigate(`${parentPath}/${ROUTE_NAMES.addRecruiter}`);
        toast.success("Subscription Preference Noted");
      },
      null: () => {},
    };
    fun[key](data);
  };

  useEffect(() => {
    getSubscriberPlansFun();
  }, []);

  useEffect(() => {
    if (plansList?.plans?.length > 1) {
      setselectedPlan(plansList?.plans[0]);
    }
  }, [plansList.plans]);

  return (
    <div className={`${styles.PlanPage} px-3`}>
      <Container>
        <LoginLog />
        <Row className={`my-2`}>
          <Col className={`${styles._col} mt-2`}>
            <p className={`mb-1 lg-text`}>Plan Summary</p>
          </Col>
        </Row>
        <RenderIf isShow={plansList?.plans?.length < 1}>
          <div className={` center d-flex`} style={{ height: "300px" }}>
            {!!plansError ? (
              <p className="x-sm-text text-danger">{plansError}</p>
            ) : (
              <p className="x-sm-text text-gray">
                {"Currently there are no plans available"}
              </p>
            )}
          </div>
        </RenderIf>
        <RenderIf isShow={plansList?.plans?.length > 0}>
          <Row>
            <Col lg={5} md={12} sm={12}>
              {plansList?.plans?.map((item, idx) => (
                <div
                  onClick={() => setselectedPlan(item)}
                  key={idx}
                  className={`${styles.card_box} ${
                    selectedPlan?.subscriptionID === item?.subscriptionID
                      ? styles.active_card
                      : ""
                  }`}
                >
                  <div className="d-flex">
                    <p className={`md-lg-text b mb-0`}>
                      {item?.subscriptionName}
                    </p>
                    <div className="d-flex flex-1"></div>
                    {idx === 0 && (
                      <div style={{ width: "15px", height: "15px" }}>
                        <img
                          src={activeCheck}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <p className={`x-sm-text mb-3 mt-1`}>
                    {item?.subscriptionTagLine}
                  </p>
                  <p className={`x-sm-text light-text`}>
                    {`${
                      Number(item?.recruitersLimit) > 1
                        ? `upto ${item?.recruitersLimit} Recruiter`
                        : "Recruiter"
                    }`}
                  </p>
                  <p className={`x-sm-text light-text`}>{`${
                    Number(item?.candidatesLimit) > 1
                      ? `upto ${item?.candidatesLimit} Active Candidate`
                      : "Active Candidate"
                  }`}</p>
                  <p className={`x-sm-text light-text`}>
                    Email and chat support only
                  </p>
                </div>
              ))}
            </Col>
            {/* ============================================= */}
            <Col lg={6} md={12} sm={12} className={``}>
              <div
                className={` d-flex flex-column p-2  flex-grow-1 h-100`}
                style={{ flexGrow: 1 }}
              >
                <div className={`mb-3`}>
                  <p className={`x-sm-text`}>
                    {plansList?.plans?.findIndex(
                      (i) => i?.subscriptionID === selectedPlan?.subscriptionID
                    ) === 0
                      ? "You have chosen:"
                      : "You can choose:"}
                  </p>
                  <p className={`md-lg-text text-primary my-1`}>
                    {selectedPlan?.subscriptionName}
                  </p>
                </div>
                <div className={`mb-3`}>
                  <p className={`x-sm-text`}>Applicable Subscription Fee:</p>
                  <p className={`md-lg-text text-primary my-1`}>
                    ₹{Number(selectedPlan?.subscriptionFees).formatRupee()} per
                    month
                  </p>
                </div>
                <div className={`mb-3`}>
                  <p className={`x-sm-text`}>Account Status & validity (i):</p>
                  <p
                    className={`${
                      plansList?.CurrentSubscriptionDetails?.Status === "Paused" && `text-danger`
                    } text-primary md-lg-text my-1`}
                  >
                    {/* Inactive (MMM DD, YYYY) */}
                    {plansList?.CurrentSubscriptionDetails?.Status}{" "}
                    {plansList?.CurrentSubscriptionDetails?.Status !==
                      "Paused" &&
                      `(${dayjs(
                        plansList?.CurrentSubscriptionDetails
                          ?.subscriptionValidUpto
                      ).format("MMM DD, YYYY")})`}
                    {plansList?.CurrentSubscriptionDetails?.Status ===
                      "Paused" && `(Cannot roll-out job offers)`}
                  </p>
                </div>
                <div className={`mb-3`}>
                  <p className={`x-sm-text`}>Payment link:</p>
                  <p
                    className={` text-primary my-1`}
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    {/* www.poeplex.tech/PayNow */}
                    {plansList?.CurrentSubscriptionDetails?.paymentLink}
                  </p>
                </div>
                <div className={`mb-3`}>
                  <p className={`x-sm-text`}>Last payment:</p>
                  {/* {(plansList?.CurrentSubscriptionDetails?.lastPaymentDate ===
                    "Invalid date" ||
                    plansList?.CurrentSubscriptionDetails?.lastPaymentDate ===
                      null) && (
                    <p className={`md-lg-text text-primary my-1`}>NA</p>
                  )} */}
                  {!dayjs(
                    plansList?.CurrentSubscriptionDetails?.lastPaymentDate
                  ).isValid() ? (
                    <p className={`md-lg-text text-primary my-1`}>NA</p>
                  ) : (
                    <p className={`md-lg-text text-primary my-1`}>
                      {plansList?.CurrentSubscriptionDetails?.lastAmtPaid} on{" "}
                      {dayjs(
                        plansList?.CurrentSubscriptionDetails?.lastPaymentDate
                      ).format("MMM DD,YYYY")}
                    </p>
                  )}
                  {/* {plansList?.CurrentSubscriptionDetails?.lastPaymentDate !==
                    "Invalid date" &&
                    plansList?.CurrentSubscriptionDetails?.lastPaymentDate !==
                      null && (
                      <p className={`md-lg-text text-primary my-1`}>
                        ₹ XXX.XX on MMM DD, YYYY
                        {
                          plansList?.CurrentSubscriptionDetails?.lastAmtPaid
                        } on{" "}
                        {!dayjs(plansList?.CurrentSubscriptionDetails?.lastPaymentDate).isValid() ? "NA" : dayjs(
                          plansList?.CurrentSubscriptionDetails?.lastPaymentDate
                        ).format("MMM DD,YYYY")}
                      </p>
                    )} */}
                </div>
                {/* <div className={`d-flex  flex-grow-1`} style={{ flexGrow: 1 }}>
                  <div
                    className={` flex-grow-1 align-items-start justify-content-end d-flex flex-column`}
                  >
                    <button className={`sbmit_btn_outline w-50`}>
                      <label className={`x-sm-text`}>
                        Request a status refresh
                      </label>
                    </button>
                    <p></p>
                    <p className={`x-sm-text text-primary`}>
                      * Payment confirmation and subscription activation/renewal
                      may take upto 24 hours. Contact Us in case of delay.
                    </p>
                  </div>
                </div> */}
              </div>
            </Col>
          </Row>
        </RenderIf>

        <RenderIf isShow={plansList?.plans?.length > 0}>
          <Row className={``}>
            <p>&nbsp;</p>
            <p></p>
            <Col lg={12} className={``}>
              <div className={`d-flex flex-column center w-100 flex-grow-1`}>
                <button
                  className={`sbmit_btn w-25 py-2 sm-text d-flex align-items-center justify-content-center`}
                  type="button"
                  onClick={() => _onPress({ key: "continue" })}
                >
                  <label className={`sm-text`}>Continue</label>
                </button>

                <p className={`x-sm-text text-primary my-1`}>
                  Your account is already created. You can continue after
                  payment is confirmed.
                </p>
              </div>
            </Col>
          </Row>
        </RenderIf>
      </Container>
    </div>
  );
};

export default PlanPage;
