import styled from "styled-components";

export const CandidateStatusContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .box {
    // width: 541px;
    background-color: white;
    border-radius: 8px;
  }

  .box .dialogue {
    // width: 541px;
    border-radius: 5px;
    overflow: hidden;
  }

  .box .overlap {
    width: 501px;
    height: 353px;
  }

  .box .overlap-wrapper {
    position: absolute;
    width: 501px;
    height: 353px;
    top: 0;
    left: 0;
  }

  .box .overlap-group {
    width: 511px;
    height: 365px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .box .content {
    display: flex;
    flex-direction: column;
    width: 501px;
    height: 353px;
    align-items: flex-start;
    gap: 24px;
    position: relative;
  }

  .box .header {
    position: relative;
    width: 501px;
    height: 243px;
  }

  .box .frame {
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
    padding: 24px 8px 0px 25px;
    display: inline-flex;
    position: relative;
  }

  .box .text-medium-title {
    position: relative;
    width: 358px;
    height: 24px;
  }

  .box .notifications {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: -1px;
    left: 0;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    white-space: nowrap;
  }

  .box .div {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .frame-2 {
    align-items: flex-start;
    gap: 26px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .frame-3 {
    align-items: center;
    gap: 14px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .radio {
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid;
    border-color: #c4c7c7;
  }

  .box .notifications-wrapper {
    position: relative;
    width: 74px;
    height: 24px;
  }

  .box .text-wrapper {
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    position: absolute;
    top: -1px;
    left: 0;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    white-space: nowrap;
  }

  .box .div-wrapper {
    position: relative;
    width: 69px;
    height: 24px;
  }

  .box .frame-4 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .text-medium {
    position: relative;
    align-self: stretch;
    width: 100%;
    height: 23px;
  }

  .box .name {
    width: 467px;
    position: absolute;
    top: -1px;
    left: 0;
    opacity: 0.5;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 16px;
  }

  .box .frame-5 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .frame-6 {
    align-items: flex-start;
    gap: 8px;
    padding: 0px 0px 0px 16px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .element-checkboxes {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .box .element-checkboxes-2 {
    position: relative;
    height: 24px;
  }

  .box .overlap-group-2 {
    top: 1567px;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 2880px;
  }

  .box .icon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 11px;
    left: 11px;
  }

  .box .states {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
  }

  .box .object {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }

  .box .name-wrapper {
    height: 24px;
    position: relative;
    width: 419px;
  }

  .box .p {
    width: 419px;
    position: absolute;
    top: -1px;
    left: 0;
    opacity: 0.5;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
  }

  .box .frame-7 {
    display: inline-flex;
    align-items: flex-end;
    gap: 8px;
    padding: 0px 0px 0px 16px;
    position: relative;
    flex: 0 0 auto;
  }

  .box .overlap-group-3 {
    top: 1535px;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 2880px;
  }

  .box .text-medium-2 {
    height: 21px;
    position: relative;
    width: 419px;
  }

  .box .overlap-group-4 {
    top: 1503px;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 2880px;
  }

  .box .button {
    flex-direction: column;
    width: 501px;
    align-items: flex-end;
    gap: 10px;
    padding: 14px 27px;
    flex: 0 0 auto;
    background-color: #ffffff;
    display: flex;
    position: relative;
  }

  .box .frame-8 {
    align-items: flex-start;
    gap: 12px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .button-base-wrapper {
    width: 120px;
    align-items: flex-start;
    border-radius: 8px;
    display: flex;
    position: relative;
    all: unset;
    box-sizing: border-box;
  }

  .box .button-base {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 18px;
    border-radius: 8px;
    border: 1px solid;
    min-width: 100px;

    // align-items: center;
    // justify-content: center;
    // gap: 8px;
    // padding: 10px 18px;
    // flex: 1;
    // flex-grow: 1;
    // border-radius: 8px;
    // overflow: hidden;
    // border-color: #3a93f4;
    // box-shadow: 0px 1px 2px #1018280d;
    // display: flex;
    // position: relative;
    // all: unset;
    // box-sizing: border-box;
  }

  .box .text {
    color: #1d1a22;
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 20px;
    white-space: nowrap;
  }

  .box .button-base-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 18px;
    border-radius: 8px;
    min-width: 100px;

    // gap: 8px;
    // position: relative;
    // flex: 1;
    // flex-grow: 1;
    // background-color: #dc393a;
    // overflow: hidden;
    // border: 1px solid;
    // box-shadow: 0px 1px 2px #1018280d;
  }

  input[type="checkbox"] {
    accent-color: rgb(255, 77, 132) !important;
  }
  input[type="radio"] {
    accent-color: rgb(255, 77, 132) !important;
  }

  .highlighted-text {
    color: rgb(255, 77, 132) !important;
  }

  .box .text-2 {
    color: #ffffff;
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 20px;
    white-space: nowrap;
  }

  .box .content-wrapper {
    width: 511px;
    height: 365px;
    background-image: url(./img/rectangle-5.svg);
    background-size: cover;
    background-position: 50% 50%;
  }

  .box .frame-9 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
    padding: 24px 15px 0px 25px;
    position: relative;
  }

  .box .name-2 {
    width: 459px;
    position: absolute;
    top: -1px;
    left: 0;
    opacity: 0.5;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
  }

  .box .frame-10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
`;
