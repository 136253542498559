import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
// import "./services/pushNotification.js";

import { Provider } from "react-redux";
// import { PersistorStore, Store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import PageRoutes from "./routes";
import { PersistorStore, Store } from "./redux/store";
import { ToastContainer } from "react-toastify";
import Notification from "./services/notification";
import { getCurrentToken } from "./services/webNotification";

function App() {
  const _env = process.env.REACT_APP__ENV_IS;
  const handleContextMenu = (e: any) => {
    if (_env !== "dev") e.preventDefault();
  };

  const handleKeyDown = (e: any) => {
    // Disable context menu when a specific shortcut key combination is pressed
    if (e.ctrlKey && e.shiftKey && e.key === "C") {
      if (_env !== "dev") e.preventDefault();
    }
  };

  const handleSubscribe = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: atob(
          "QlBDWXl6UHpDdlpidFVUeUk4NHNPVXp3VHlJcWhkUVBneU9LWWQ1ZldIVmVRZnctcDFfYUNaQ25ZZmkxM1F5X3VSeVpBUWVuSkd6eVJnVk5xc3NNUTc0"
        ),
      });
      console.log("Push subscription successful:", subscription);
      getCurrentToken();
    } catch (error) {
      console.error("Push subscription failed:", error);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    handleSubscribe();
  }, []);

  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={PersistorStore}>
        <DndProvider backend={HTML5Backend}>
          <div onContextMenu={handleContextMenu} className="App">
            <ToastContainer autoClose={3000} />
            <Notification />
            <PageRoutes />
          </div>
        </DndProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
