import React from "react";
import { Navigate } from "react-router";
import { ROUTE_NAMES } from "../constants/routeNames";

const ProtectiveRoutes = (props:{children: any}) => {
    const children = props.children;
    const accessToken = localStorage.getItem("token");
    if(!accessToken){
        return <Navigate to={ROUTE_NAMES.LOGIN} />
    }
    return children;
}

export default ProtectiveRoutes;