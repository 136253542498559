export const personalDetails = {
  firstNameIs: "",
  lastNameIs: "",
  phoneNumberIs: "",
  emailIs: "",
};

export const addCandidatesDetails = {
  firstNameIs: "",
  lastNameIs: "",
  phoneNumberIs: "",
  emailIs: "",
  designation: "",
  department: "",
  team: "",
  baseLocation: "",
  workLocation: "",
  dojDD: "",
  dojMM: "",
  dojYY: "",
  model: "",
  offerExpiry: "",
  yearExpMM: "",
  yearExpYY: "",
  jobLevel: "",
  ctc: "",
  fixedComp: "",
  variables: "",
  payout: "",
  takeHome: "",
};
