import { createSlice } from "@reduxjs/toolkit";
import { ApiResType } from "../../../types/apiTypes";
import { call, put, select } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";

import {
  CandidateCardsetType,
  CardsetWeekType,
  DefaultCompanyCardsetsListType
} from "../../../types/candidateTypes";
import { RootState } from "../../store";

const initialCardsetsList = {
  weekNumber: 0,
  cardSetOrder: 0,
  companyID: 0,
  cardSetID: 0,
}

export interface initalStateType {
  error: boolean;
  message: string;
  data: CardsetWeekType[];
  selectedWeek: CardsetWeekType | undefined | null;
  defaultCardsetClicked: boolean;
  defaultCompanyCardsetsList: DefaultCompanyCardsetsListType;
  addedDefaultCardset: boolean;
  remoteData: CandidateCardsetType[];
  loading: boolean;
}

const initialState: initalStateType = {
  error: false,
  message: "",
  data: [],
  selectedWeek: null,
  defaultCardsetClicked: false,
  defaultCompanyCardsetsList: {
    defaultCardSets: [initialCardsetsList],
  },
  addedDefaultCardset: false,
  remoteData: [],
  loading: false,
};

const CandidateAddCardsetSlice = createSlice({
  name: "CandidateAddCardset",
  initialState: initialState,
  reducers: {
    SelectWeek: (state: initalStateType, { payload }) => {
      state.selectedWeek = payload;
      return state;
    },
    AddCandidateCardset: (
      state: initalStateType,
      { payload }: { payload: CardsetWeekType[] }
    ) => {
      state.error = false;
      state.data = payload || [];
      state.loading = false;
      return state;
    },
    AddRemoteCandidateCardset: (
      state: initalStateType,
      { payload }: { payload: CandidateCardsetType[] }
    ) => {
      state.error = false;
      state.remoteData = payload || [];
      state.loading = false;
      return state;
    },
    UpdateCandidateCardset: (
      state: initalStateType,
      { payload }: { payload: CardsetWeekType }
    ) => {
      if (payload?.dateFrom) {
        state.data.push(payload);
      }

      return state;
    },
    AddedDefaultCandidateCardset: (
      state: initalStateType,
      { payload}: {payload: boolean}
    ) => {
      state.addedDefaultCardset = payload;
      return state;
    },
    AddDefaultCompanyCardset: (state: initalStateType,
      { payload}: { payload: DefaultCompanyCardsetsListType}) => {
      state.defaultCompanyCardsetsList = payload
    },
    DefaultCandidateCardset: (
      state: initalStateType,
      { payload }: { payload: CardsetWeekType }
    ) => {
      state.defaultCardsetClicked = true;
      if (payload?.dateFrom) {
        state.data.push(payload);
      }

      return state;
    },
    DeleteCandidateCardset: (
      state: initalStateType,
      { payload }: { payload: CardsetWeekType[] }
    ) => {
      state.data = payload;
      return state;
    },
    StartLoading: (state: initalStateType) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: initalStateType) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: initalStateType) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  SelectWeek,
  AddCandidateCardset,
  AddRemoteCandidateCardset,
  UpdateCandidateCardset,
  AddDefaultCompanyCardset,
  AddedDefaultCandidateCardset,
  DefaultCandidateCardset,
  DeleteCandidateCardset,
  StartLoading,
  StopLoading,
  ClearData,
} = CandidateAddCardsetSlice.actions;

export default CandidateAddCardsetSlice.reducer;

export function* selectCandidateCardsetWeek({ payload }: any) {
  try {
    yield put(SelectWeek(payload));
  } catch (error: any) {
    yield put(SelectWeek(0));
  }
}

export function* addCandidateCardsetList({ payload }: any) {
  try {
    yield put(StartLoading());
    yield put(AddCandidateCardset(payload));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* addRemoteCandidateCardsetList({ payload }: any) {
  try {
    yield put(StartLoading());
    yield put(AddRemoteCandidateCardset(payload));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* updateCandidateCardsetList({ payload }: any) {
  try {
    yield put(StartLoading());
    yield put(UpdateCandidateCardset(payload));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* addedDefaultCardsetList({payload}: any) {
  try {
    yield put(AddedDefaultCandidateCardset(payload));
  } catch (error) {
    yield put(AddedDefaultCandidateCardset(payload));
  }
}

export function* addDefaultCompanyCardsetsList({ payload }: any) {
  try {
    yield put(StartLoading());
    yield put(AddDefaultCompanyCardset(payload));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* defaultCandidateCardsetList({ payload }: any) {
  try {
    yield put(StartLoading());
    yield put(DefaultCandidateCardset(payload));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* deleteCandidateCardsetList({ payload }: any) {
  try {
    yield put(StartLoading());
    const state: RootState = yield select();
    const { candidateCardsetList } = state;
    const newStateData = candidateCardsetList?.data?.filter((item) => {
      return !(
        item?.weekNumber === payload?.weekNumber &&
        item?.cardSetID === payload?.cardSetID
      );
    });
    yield put(DeleteCandidateCardset(newStateData));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* clearCandidateAddCardsetSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
