import React, { memo, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import { ROUTE_NAMES } from "../constants/routeNames";
import { HomePage } from "../pages/home";
import { useEffect } from "react";

const PrivateRoute = () => {
  const authToken = localStorage.getItem("@auth");
  // console.log(authToken, "authToken PrivateRoute");

  // const [authenticated, setauthenticated] = useState(false);
  // useEffect(() => {
  //   setauthenticated(authToken);
  // }, [authToken]);

  return !authToken ? <Outlet /> : <Navigate to={ROUTE_NAMES.LOGIN} />;
};

export default PrivateRoute;
