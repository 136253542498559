import React, { useState, useEffect, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosSearch, IoMdAdd } from "react-icons/io";
import { IoClose, IoChevronDown, IoChevronUp } from "react-icons/io5";
import rightArrow from "../../../assets/icons/double_right.png";
import update from "immutability-helper";

import infoIcon from "../../../assets/svgIcons/Info-icon.svg";
import editIcon from "../../../assets/svgIcons/edit.svg";
import { EmojiCard, StackedCard } from "../../cardComponents";

import "./createCardSet.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { addCardSetCatReq } from "../../../api/cardsetReq/cardSetCategoryReq";
import { SagaActions } from "../../../redux/actionTypes";
import { toast } from "react-toastify";
import {
  addCEPCardSetReq,
  addCardSetReq,
  getCardSetByCatReq,
} from "../../../api/cardsetReq/cardSetReq";
import EmptyStackedCard from "../../cardComponents/emptyStackCard";
import { validate } from "../../../constants/validationExp";

const CreateCardSetContent = ({ onClose }: any) => {
  const { cardsetCat, cepCompanyProfile, profile } = useSelector(
    (_state: RootState) => _state
  );
  const Dispatch = useDispatch();
  const roleID = localStorage.getItem("@role");
  const _isCepAdmin = 102 == Number(roleID);
  const selectedCompany = cepCompanyProfile?.companyID;
  const _profileCompanyID = profile?.data?.companyID || null;

  const _catData = cardsetCat.data || [];
  const [cardSetName, setcardSetName] = useState("");
  const [newCatIs, setnewCatIs] = useState("");
  const [newCatError, setnewCatError] = useState("");
  const [selectedCat, setselectedCat] = useState("");
  const [cardsList, setcardsList] = useState<any>([]);
  const [newCardData, setnewCardData] = useState<any>({});
  const [feedbackCardData, setfeedbackCardData] = useState<any>({});
  const [resetCard, setresetCard] = useState<any>("false");

  const addNewCardSetFun = async () => {
    try {
      if (!cardSetName || !selectedCat)
        return toast.error(`Cardset name and category both required`);
      if (cardsList?.length < 1)
        return toast.error(`We need at-least ONE card to create a cardset`);
      if (!feedbackCardData?.title)
        return toast.error(
          `Fill-up the FEEDBACK Card before publishing the CardSet`
        );

      const feedBackCardBody = {
        cardTitle: feedbackCardData?.title,
        cardDescription: "",
        cardHeading: feedbackCardData?.cardHeading || "",
        cardLinkURL: "",
        cardOrder: 999,
      };
      const body = {
        categoryID: Number(selectedCat),
        cardSetName: cardSetName,
        isActive: 1,
        cards: [...cardsList, feedBackCardBody],
      };
      const cepBody = {
        categoryID: Number(selectedCat),
        cardSetName: cardSetName,
        isActive: 1,
        cards: [...cardsList, feedBackCardBody],
        companyId: selectedCompany,
      };
      if(_isCepAdmin) {
        const res = await addCEPCardSetReq(cepBody);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "prod",
            message: res?.message,
            fileName: "createCardSet",
            methodName: "addNewCardSetFun",
            type: "info",
          },
        });
      }else {
        const res = await addCardSetReq(body);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "prod",
            message: res?.message,
            fileName: "createCardSet",
            methodName: "addNewCardSetFun",
            type: "info",
          },
        });
      }
      Dispatch({
        type: SagaActions.GET_CARDSET,
        payload: _isCepAdmin ? selectedCompany : _profileCompanyID,
      });
      onClose();
    } catch (error: any) {
      toast.success(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "createCardSet",
          methodName: "addNewCardSetFun",
          type: "error",
        },
      });
    }
  };

  const deleteCard = (card: any) => {
    const _newCardData = cardsList.filter(
      (i: any) => i.cardOrder !== card?.cardOrder
    );
    setcardsList([..._newCardData]);
  };
  const addCardFun = () => {
    // if (!newCardData?.cardTitle) return toast.error("Enter card Title");
    const validTitle = new RegExp(validate?.cardTitle);
    if (newCardData?.cardTitle?.replace(/\s/g, "").length < 5)
      return toast.error(
        "Too small a card-name. Must be more than 4 characters."
      );
    const validUrl = new RegExp(validate?.url);
    if (!!newCardData?.cardLinkURL && !validUrl.test(newCardData?.cardLinkURL))
      return toast.error("Enter valid link. Include https://");
    const _newCardData = { ...newCardData, cardOrder: cardsList?.length + 1 };
    setcardsList((pre: any) => [...pre, _newCardData]);
    setnewCardData({});
    setresetCard(newCardData?.cardTitle);
  };

  console.log(cardsList, "Newly added cards");

  const addNewCardSetCatFun = async () => {
    try {
      if (!newCatIs) return setnewCatError("Invalid category name ");

      const res = await addCardSetCatReq({ categoryName: newCatIs });
      Dispatch({ type: SagaActions.GET_CARDSET_CAT });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "createCardSet",
          methodName: "addNewCardSetCatFun",
          type: "info",
        },
      });
      toast.success(res?.message);
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "createCardSet",
          methodName: "addNewCardSetCatFun",
          type: "error",
        },
      });
    }
    setnewCatError("");
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      newCat: () => addNewCardSetCatFun(),
      null: () => {},
    };
    fun[key](data);
  };

  const _onChange = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      cat: (data: any) => {
        setselectedCat(data);
      },
      null: () => {},
    };
    fun[key](data);
  };

  const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
    setcardsList((prevCards: any) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  useEffect(() => {
    Dispatch({ type: SagaActions.GET_CARDSET_CAT });
  }, []);

  return (
    <div className="CreateCardSet px-3">
      <Container>
        <Row className="my-2">
          <Col className="_col" sm={12} md={6} lg={4}>
            <p className="mb-1">
              Name of the CardSet <span style={{ color: "#ff4d84" }}>*</span>
            </p>
            <input
              placeholder=""
              value={cardSetName}
              onChange={(e) => setcardSetName(e.target.value)}
            />
          </Col>
          <Col className="_col" sm={12} md={6} lg={4}>
            <p className="mb-1">
              Category <span style={{ color: "#ff4d84" }}>*</span>
            </p>
            <select
              onChange={(e) => _onChange({ key: "cat", data: e.target.value })}
            >
              <option value={""}>Select</option>
              {_catData?.map((item: any, idx) => (
                <option key={idx} value={`${item?.categoryID}`}>
                  {item?.categoryName}
                </option>
              ))}
            </select>
          </Col>
          <Col className="_col" sm={12} md={6} lg={4}>
            <p className="mb-1">
              If Other, create new category
              <span style={{ color: "#ff4d84" }}>*</span>
            </p>
            <input
              disabled
              placeholder=""
              onChange={(e) => {
                setnewCatIs(e.target.value);
              }}
            />
            {!!newCatError && (
              <p className="x-sm-text text-danger">{newCatError}</p>
            )}
          </Col>
          {/* <Col className="_col mt-2" sm={12} md={6} lg={3}>
            <button
              className="btn btn-primary w-100"
              onClick={() => _onPress({ key: "newCat" })}
            >
              <label>Create category</label>
            </button>
          </Col> */}
        </Row>
      </Container>
      <p>&nbsp;</p>
      <div className=" py-4">
        <Container>
          <div className="my-2 d-flex">
            <div className="" style={{ minWidth: "280px" }}>
              {/* <EmptyStackedCard
                onChange={(cardData: any) => setnewCardData(cardData)}
              /> */}
              <EmptyStackedCard
                emptyState={true}
                deleteBtn={true}
                swapBtn={true}
                onChange={(cardData: any) => setnewCardData(cardData)}
                resetCard={resetCard}
              />
            </div>
            <div
              className={`mx-4 d-flex align-items-center justify-content-center`}
            >
              <div
                className={`add_card_box`}
                onClick={() => addCardFun()}
                style={{ maxWidth: "120px" }}
              >
                <div
                  className={`mb-2 p-2`}
                  style={{ height: "70px", width: "70px" }}
                >
                  <img
                    src={rightArrow}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <p className={`x-sm-text`} style={{ textAlign: "center" }}>
                  Push the card to CardSet
                </p>
              </div>
            </div>
            {cardsList?.map((item: any, idx: number) => (
              <div className="me-4" style={{ minWidth: "280px" }}>
                <StackedCard
                  emptyState={false}
                  localCard={true}
                  index={idx}
                  data={item}
                  deleteBtn={true}
                  swapBtn={true}
                  onDelete={() => deleteCard(item)}
                  moveCard={moveCard}
                />
              </div>
            ))}
            {cardsList?.length < 1 && <div style={{ height: "50vh" }}></div>}
            <EmojiCard
              editable
              emptyState
              onChange={(data: any) => setfeedbackCardData(data)}
            />
          </div>
        </Container>
      </div>
      <Container>
        <Row className="my-3">
          <Col className="_col" sm={12} md={6} lg={5}></Col>
          <Col className="_col " sm={12} md={6} lg={2}>
            <button
              className="sbmit_btn_outline w-100"
              onClick={() => onClose()}
            >
              <label>Cancel</label>
            </button>
          </Col>
          {/* <Col className="_col " sm={12} md={6} lg={2}>
            <button className="btn btn-outline-primary w-100">
              <label>Save draft</label>
            </button>
          </Col> */}
          <Col className="_col p-0" sm={12} md={6} lg={2}>
            <button
              className="sbmit_btn w-100"
              onClick={() => addNewCardSetFun()}
            >
              <label>Publish</label>
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const CreateCardSet = (props: any) => {
  const { onClose } = props;

  return (
    <div className="w-100  d-flex flex-column align-items-end cardSets">
      <div className="w-60 bg-white">
        <header className="header d-flex px-4 pt-2">
          <p>Add CardSets</p>
          <div style={{ flex: 1 }}></div>
          <IoClose onClick={() => onClose()} />
        </header>
        <CreateCardSetContent onClose={onClose} />
      </div>
    </div>
  );
};

export default CreateCardSet;
