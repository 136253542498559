import { createSlice } from "@reduxjs/toolkit";
import { CompanyProfileType } from "../../../types/userTypes";
import { getProfileReq } from "../../../api/user/profileReq";
import { ApiResType } from "../../../types/apiTypes";
import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { getCompanyProfileReq } from "../../../api/company/companyProfileReq";

export interface CompanyProfileSlicetypes {
  error: boolean;
  message: string;
  data: CompanyProfileType | null;
  loading: boolean;
}

const initialState: CompanyProfileSlicetypes = {
  error: false,
  message: "",
  data: null,
  loading: false,
};

const CompanyProfileSlice = createSlice({
  name: "CompanyProfile",
  initialState: initialState,
  reducers: {
    AddCompanyProfileDetails: (
      state: CompanyProfileSlicetypes,
      { payload }: { payload: CompanyProfileType | null }
    ) => {
      state.data = payload ? { ...payload } : null;
      state.loading = false;
      state.error = false;
      return state;
    },
    UpdateCompanyLogo: (
      state: CompanyProfileSlicetypes,
      { payload }: { payload: { companyWebLogoURL: string } }
    ) => {
      if (state.data?.company?.companyWebLogoURL) {
        state.data["company"]["companyWebLogoURL"] = payload?.companyWebLogoURL;
      }
      state.loading = false;

      return state;
    },
    StartLoading: (state: CompanyProfileSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: CompanyProfileSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: CompanyProfileSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const {
  AddCompanyProfileDetails,
  UpdateCompanyLogo,
  StartLoading,
  StopLoading,
  ClearData,
} = CompanyProfileSlice.actions;

export default CompanyProfileSlice.reducer;

export function* fetchComapnyProfileDetails() {
  try {
    yield put(StartLoading());
    const res: ApiResType = yield call(getCompanyProfileReq);
    yield put(AddCompanyProfileDetails(res?.data));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(AddCompanyProfileDetails(null));
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
  }
}
export function* UpdateCompanyLogoAction(data: { companyWebLogoURL: string }) {
  try {
    yield put(StartLoading());
    yield put(UpdateCompanyLogo(data));
  } catch (error: any) {
    yield put(StopLoading());
    yield put(UpdateCompanyLogo(data));
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
  }
}

export function* clearCompanyProfileSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
