import { NavLink, useResolvedPath, useLocation } from "react-router-dom";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch } from "react-redux";

const CustomNavLink = ({ opt, clickable }) => {
  const Dispatch = useDispatch();
  let resolved = useResolvedPath(opt?.route);
  let presentLocation = useLocation().pathname;
  const _active = presentLocation?.includes(resolved?.pathname?.split("/")[2]);

  const handleClick = (e) => {
    if (opt.label === "CardSets" && _active) {
      e.preventDefault();
      return;
    }

    if (!clickable) {
      e.preventDefault();
      return;
    }

    Dispatch({ type: SagaActions.CLOSE_MODAL });
  };
  return (
    <NavLink
      to={`${opt?.route}`}
      // onClick={(e) => {
      //   if (!clickable) {
      //     e.preventDefault();
      //     return;
      //   }
      //   Dispatch({ type: SagaActions.CLOSE_MODAL });
      // }}
      onClick={handleClick}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "10px",
        padding: "5px",
        textDecoration: "none",
        opacity: !_active && !clickable ? 0.5 : 1,
      }}
    >
      <div className={``} style={{ width: "30px" }}>
        <img className="icon" src={_active ? opt.activeIcon : opt.icon} />
      </div>
      <label
        className={`md-text`}
        style={{
          color: _active ? "#FF4D84" : "#615D66",
          margin: "0px 10px",
          width: "125px",
          textAlign: "left",
          fontWeight: "500",
        }}
      >
        {opt.label}
      </label>
    </NavLink>
  );
};

export default CustomNavLink;
