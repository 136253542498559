import React from "react";
import { useNavigate } from "react-router-dom";
import { AiFillInfoCircle } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";
import * as XLSX from "xlsx";

import Table from "../components/table";
import Table2 from "../components/table2";

import styles from "./tablePage.module.scss";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { LoginLog } from "../../../components/microComponents";
import { useSelector } from "react-redux";
import HrActionTable from "../components/hrActionTable";
import dayjs from "dayjs";

const TablePage = () => {
  const navigation = useNavigate();
  const { notification, hrActions } = useSelector((_state) => _state);

  const downloadXls = (data) => {
    const workbook = XLSX.utils.book_new();

    console.log("data", data);

    const modifiedData = data.map((item, index) => {
      const timestamp = new Date(item["timeStamp"]);
      const formattedTimestamp = `${timestamp.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}`;
      const createdAt = new Date(item["createdAt"]);
      const formattedCreatedAt = createdAt.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      const updatedAt = new Date(item["updatedAt"]);
      const formattedUpdatedAt = updatedAt.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
      const candidateActionDate = new Date(item["$candidateActionDate"]);
      const formattedCandidateActionDate = candidateActionDate.toLocaleString(
        "en-US",
        {
          month: "short",
          day: "numeric",
          year: "numeric",
        }
      );
      return {
        // "Sl. No": index + 1,
        // "Notification ID": item["notificationID"],
        // "Notification Msg": item["notificationMessage"],
        // "Candidate Name": item["candidateName"],
        // "Time Stamp": formattedTimestamp,
        // "Created At": formattedCreatedAt,
        // "Updated At": formattedUpdatedAt,
        // "Activity ID": item["activityID"],
        // "Employee ID": item["employeeID"],
        // "Activity Name": item["$activityName"],
        // "CandidateAction Date": formattedCandidateActionDate,
        // "Candidate Status": item["$candidateStatus"],
        // "Candidate Remark": item["$candidateRemark"],
        // "Recruiter Status": item["$recruiterStatus"],
        // "Recruiter Remark": item["$recruiterRemark"],
        // "Offer ID": item["$offerID"],
        // "Candidate CardSetID": item["$candidateCardSetID"],
        // "Candidate ID": item["$candidateID"],
        "Sl. No": index + 1,
        "Request Date": formattedTimestamp,
        "Candidate Name": item["candidateName"],
        "HR Action": item["$recruiterRemark"],
        "Activity Type": item["$activityName"],
        "Status ": item["$recruiterStatus"],
        "Remarks ": item["$candidateRemark"],
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(modifiedData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const fileName = `CareerX_HR_Actions_${dayjs(new Date()).format(
      "YYYYMMDD_hhmmss"
    )}.xlsx`;

    const buffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "buffer",
    });
    XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workbook, fileName);
  };

  const _hrActions = hrActions?.data || [];
  return (
    <div className={`${styles.tablePage} ps-2`}>
      <div>
        <LoginLog />
      </div>
      <p className="light-text primary-color">
        <span
          className={`x-sm-text primary-color`}
          onClick={() =>
            navigation(`/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.dashboard}`)
          }
        >
          Dashboard
        </span>{" "}
        <BiChevronRight className="primary-color" />{" "}
        <span className={`x-sm-text primary-color`}>HR Actions</span>
      </p>
      <div className={`bg-white ${styles.table_card}`}>
        <div
          className={`d-flex align-items-center my-1 justify-content-between px-3 my-3`}
        >
          <div className={`mt-3`}>
            <p className={`m-0 md-text`}>
              HR Actions <AiFillInfoCircle color="#dddddd" />
            </p>
          </div>
          <div className="mt-3 d-flex align-items-center">
            <div>
              <label className="me-2 x-sm-text">Filter:</label>
              <select className={`primary-color`}>
                <option value={""}>
                  <p className={`x-sm-text`}>All</p>
                </option>
              </select>
            </div>
            <div
              className={`primary-color mx-3`}
              onClick={() => downloadXls(_hrActions)}
            >
              <AiOutlineDownload />
              <span className={`sm-text`}>Export as XLS</span>
            </div>
          </div>
        </div>
        {/* <Table /> */}
        {/* <Table2 /> */}
        <div style={{ width: "100%", height: "65vh", overflowY: "scroll" }}>
          <HrActionTable dataIs={_hrActions} />
        </div>
      </div>
    </div>
  );
};
export default TablePage;
