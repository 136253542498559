import { all, fork, takeEvery, takeLatest } from "redux-saga/effects";
import { SagaActions } from "./actionTypes";
import { fetchProfileDetails } from "./slices/userSlices/profileSlice";
import {
  addCardSetModalShow,
  candidatePublishModalShow,
  candidateStatusModalShow,
  defaultCardsetModalShow,
  cardSetPickerModalShow,
  closeModal,
  notificationsModalShow,
  logoutModalShow,
} from "./slices/modalSlice/modalSlice";
import { getNotificationList } from "./slices/notificationSlice/notificationSlice";
import {
  clearCardSetCat,
  fetchCardSetCatList,
} from "./slices/cardSetSlices/cardSetCatSlice";
import { fetchCardSetList } from "./slices/cardSetSlices/cardSetSlice";
import { fetchCEPComapnyProfileDetails, selectedCEPCompany, defaultCEPCompany } from "./slices/companySclice/cepCompanyProfileSlice";
import { fetchComapnyProfileDetails } from "./slices/companySclice/companyProfileSlice";
import { updateNavBarSetting } from "./slices/userSlices/userSettingSlice";
import {
  addSelectedCandId,
  fetchCandidateList,
  getSelectedCandidateDetails,
} from "./slices/candidateSlice/candidateSlice";
import {
  addCandidateCardsetList,
  addRemoteCandidateCardsetList,
  clearCandidateAddCardsetSlice,
  addedDefaultCardsetList,
  addDefaultCompanyCardsetsList,
  defaultCandidateCardsetList,
  deleteCandidateCardsetList,
  selectCandidateCardsetWeek,
  updateCandidateCardsetList,
} from "./slices/candidateSlice/candidateAddCardsetSlice";
import {
  addCandidatePersonalDetails,
  addCandidateResourcesDetails,
  clearCandidateFormSlice,
} from "./slices/candidateSlice/candidateFormSlice";
import { AddHrActionsList } from "./slices/hrSlice/hrActionSlice";
import {
  addErrorLogsAction,
  addInfoLogsAction,
  addNewUUIDAction,
  addUserIDAction,
  addWarnLogsAction,
  clearLogs,
} from "./slices/logsSlice/logSlice";

function* modalSaga() {
  yield takeEvery(SagaActions.CLOSE_MODAL, closeModal);
  yield takeEvery(SagaActions.SHOW_ADD_CARDSET_MODAL, addCardSetModalShow);
  yield takeEvery(SagaActions.SHOW_NOTIFICATIONS_MODAL, notificationsModalShow);
  yield takeEvery(
    SagaActions.SHOW_CANDIDATE_STATUS_MODAL,
    candidateStatusModalShow
  );
  yield takeEvery(
    SagaActions.SHOW_DEFAULT_CARDSET_MODAL,
    defaultCardsetModalShow
  );
  yield takeEvery(
    SagaActions.SHOW_CANDIDATE_PUBLISH_MODAL,
    candidatePublishModalShow
  );
  yield takeEvery(
    SagaActions.SHOW_CARDSET_PICKER_MODAL,
    cardSetPickerModalShow
  );
  yield takeEvery(SagaActions.SHOW_LOGOUT_MODAL, logoutModalShow);
}

function* notificationSaga() {
  yield takeLatest(SagaActions.GET_NOTIFICATION_LISTS, getNotificationList);
}
function* logSaga() {
  yield takeLatest(SagaActions.ERROR, addErrorLogsAction);
  yield takeLatest(SagaActions.WARN, addWarnLogsAction);
  yield takeLatest(SagaActions.INFO, addInfoLogsAction);
  yield takeLatest(SagaActions.ADD_NEW_UUID, addNewUUIDAction);
  yield takeLatest(SagaActions.CLEAR_LOGS, clearLogs);
  yield takeLatest(SagaActions.ADD_USERID, addUserIDAction);
}

function* cardSet() {
  yield takeLatest(SagaActions.GET_CARDSET, fetchCardSetList);
  yield takeLatest(SagaActions.GET_CARDSET_CAT, fetchCardSetCatList);
  yield takeLatest(SagaActions.CLEAR_CARDSET_CAT, clearCardSetCat);
}

function* comapnySaga() {
  yield takeLatest(
    SagaActions.DEFAULT_CEP_COMPANY,
    defaultCEPCompany
  );
  yield takeLatest(
    SagaActions.SELECTED_CEP_COMPANY,
    selectedCEPCompany
  );
  yield takeLatest(
    SagaActions.FETCH_CEP_COMPANY_PROFILE,
    fetchCEPComapnyProfileDetails
  );
  yield takeLatest(
    SagaActions.FETCH_COMAPNY_PROFILE,
    fetchComapnyProfileDetails
  );
}
function* userSettingSaga() {
  yield takeLatest(SagaActions.UPDATE_NAVBAR_SEETINGS, updateNavBarSetting);
}
function* candidateSaga() {
  yield takeLatest(SagaActions.GET_CANDIDATES, fetchCandidateList);
  yield takeLatest(SagaActions.SELECTED_CANDIDATE_ID, addSelectedCandId);
  yield takeLatest(
    SagaActions.GET_SELECTED_CANDIDATE_DETAILS,
    getSelectedCandidateDetails
  );
  yield takeLatest(
    SagaActions.ADD_FORM_CANDIDATE_PERSONAL,
    addCandidatePersonalDetails
  );
  yield takeLatest(
    SagaActions.ADD_FORM_CANDIDATE_RESOURCES,
    addCandidateResourcesDetails
  );

  yield takeEvery(
    SagaActions.ADD_CANDIDATES_CARDSET_LIST,
    addCandidateCardsetList
  );
  yield takeEvery(
    SagaActions.ADD_REMOTE_CANDIDATES_CARDSET_LIST,
    addRemoteCandidateCardsetList
  );
  yield takeEvery(
    SagaActions.UPDATE_CANDIDATES_CARDSET_LIST,
    updateCandidateCardsetList
  );
  yield takeEvery(
    SagaActions.ADDED_DEFAULT_CANDIDATES_CARDSET_LIST,
    addedDefaultCardsetList
  );
  yield takeEvery(
    SagaActions.ADD_DEFAULT_COMPANY_CARDSET_LIST,
    addDefaultCompanyCardsetsList
  );
  yield takeEvery(
    SagaActions.DEFAULT_CANDIDATES_CARDSET_LIST,
    defaultCandidateCardsetList
  );
  yield takeEvery(
    SagaActions.DELETE_CANDIDATES_CARDSET_LIST,
    deleteCandidateCardsetList
  );
  yield takeEvery(SagaActions.SELECT_CARDSET_WEEK, selectCandidateCardsetWeek);
  yield takeEvery(
    SagaActions.CLEAR_CANDIDATE_CARDSET_FORM,
    clearCandidateAddCardsetSlice
  );
  yield takeEvery(SagaActions.CLEAR_CANDIDATE_FORM, clearCandidateFormSlice);
}

function* hrSaga() {
  yield takeLatest(SagaActions.ADD_HR_ACTIONS, AddHrActionsList);
}
function* mainSaga() {
  yield takeLatest(SagaActions.FETCH_PROFILE, fetchProfileDetails);
}

export default function* rootSaga() {
  yield all([
    fork(mainSaga),
    fork(modalSaga),
    fork(notificationSaga),
    fork(cardSet),
    fork(comapnySaga),
    fork(userSettingSaga),
    fork(candidateSaga),
    fork(hrSaga),
    fork(logSaga),
  ]);
}
