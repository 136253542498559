import React, { useState, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";

import { IoRemoveCircleOutline } from "react-icons/io5";
import { Col, Container, Row } from "react-bootstrap";

import styles from "../candidatePage.module.scss";

import phoneIcon from "../../../assets/svgIcons/telephone-icon.svg";
import mailIcon from "../../../assets/svgIcons/mail-icon.svg";
import attachIcon from "../../../assets/svgIcons/attachment-icon.svg";
import infoIcon from "../../../assets/svgIcons/Info-icon.svg";
import companyCard from "../../../assets/svgImages/company-card.svg";
import externalLinkIcon from "../../../assets/svgIcons/external-icon.svg";
import LoginLog from "../../../components/microComponents/loginLog";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import dayjs from "dayjs";
import RenderIf from "../../../components/renderIf";
import { CardsetWeekType } from "../../../types/candidateTypes";
import { ResourceCreateType } from "../../../types/otherTypes";
import { publishCandidateReq } from "../../../api/candidateReq/candidateReq";
import { toast } from "react-toastify";
import { SagaActions } from "../../../redux/actionTypes";

const ReviewForm = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  const { candidateForm, candidateCardsetList, cardset } = useSelector(
    (_state: RootState) => _state
  );
  const [cardsetList, setcardsetList] = useState<any>({});

  const _candidate = candidateForm?.data?.personalDetails;
  const _resources = candidateForm?.data?.resources || [];
  const _weeklyCardsets = candidateCardsetList?.data || [];
  const _cardset = cardset?.data || [];
  console.log("_candidate", _candidate);

  const _name = _candidate?.firstName + " " + _candidate?.lastName;
  const _mobile = _candidate?.mobile || "";
  const _email = _candidate?.email || "";
  const _yearsOfExp = _candidate?.yearOfExperience || "";
  const _jobLevel = _candidate?.jobLevel || "";
  const _DOJ = _candidate?.dateOfJoining || "";
  const _designation = _candidate?.designation || "";
  const _department = _candidate?.department || "";
  const _team = _candidate?.team || "";
  const _baseLocation = _candidate?.baseLocation || "";
  const _workLocation = _candidate?.workLocation || "";
  const _workModal = _candidate?.workModel || "";
  const _offerExpiry = _candidate?.offerExpiryDays || "";
  const _offerLetterUrl = _candidate?.offerLetterURL || "";
  const _offerLetterName = _offerLetterUrl
    .split("https://d14ntgkdxyoags.cloudfront.net/files/offerLetter/")
    .slice(1)[0];
  const _CTC = _candidate?.salaryCtcPA || "";
  const _fixedComponent = _candidate?.fixedComponentPA || "";
  const _variables = _candidate?.variableComponentPA || "";
  const _takeHome = _candidate?.approxTakeHomePM || "";

  const onPublishCandidate = async () => {
    try {
      if (!_candidate) return;
      // const body = {
      //   ..._candidate,
      //   publish: true,
      //   cardSets: _weeklyCardsets || [],
      //   resources: _resources || [],
      // };
      // console.log(body, "onPublishCandidate body");
      // const res = await publishCandidateReq(body);
      // toast.success(res?.message);
      // Dispatch({ type: SagaActions.CLEAR_CANDIDATE_FORM });
      // Dispatch({ type: SagaActions.CLEAR_CANDIDATE_CARDSET_FORM });
      Dispatch({ type: SagaActions.SHOW_CANDIDATE_PUBLISH_MODAL });
    } catch (error: any) {
      toast.error(error?.errorMsg);
    }
  };

  const groupByWeekFun = () => {
    const result = candidateCardsetList?.data?.reduce((r: any, a) => {
      const fieldName = `${a?.weekNumber}`;
      r[fieldName] = r[fieldName] || [];
      r[fieldName].push(a);
      return r;
    }, {});
    setcardsetList({ ...result });
  };

  useEffect(() => {
    if (candidateCardsetList?.data?.length < 1) return;
    groupByWeekFun();
  }, [candidateCardsetList?.data]);

  return (
    <div className={`${styles.ReviewForm} px-3`}>
      <Container>
        <LoginLog />
        <div className={`d-flex align-item-center ${styles.path_view_block}`}>
          <a className={`${styles.link} x-sm-text primary-color`}>Candidates</a>
          <BiChevronRight className={`primary-color`} />
          <a className={`${styles.link} x-sm-text primary-color`}>Add Candidates</a>
        </div>
        <Row className={`my-2`}>
          <Col className={`${styles._col}`}>
            <h5 className={`my-2 lg-text `}>Review to Publish</h5>
          </Col>
        </Row>
        <Row className={`my-2 mb-0`}>
          <Col className={`${styles._col}`}>
            <h6 className={`my-2 md-text b`}>{_name}</h6>
          </Col>
        </Row>
        <Row className={`my-0`}>
          <Col className={`${styles._col}`} sm={12} md={6} lg={3}>
            <div className={`d-flex align-items-center my-2`}>
              <div className={`${styles.icon_box}`}>
                <img src={phoneIcon} />
              </div>
              <label className={`md-text`}>+91 {_mobile}</label>
            </div>
          </Col>
          <Col className={`${styles._col}`} sm={12} md={6} lg={3}>
            <div className={`d-flex align-items-center my-2`}>
              <div className={`${styles.icon_box}`}>
                <img src={mailIcon} />
              </div>
              <label className={`md-text ms-2`}>{_email}</label>
            </div>
          </Col>
        </Row>
        <Row className={`my-2`}>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Years of Expereince</p>
            <label className={`md-text b`}>{_yearsOfExp}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Band/Job level</p>
            <label className={`md-text b`}>{_jobLevel}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Date of Joining</p>
            <label className={`md-text b`}>
              {dayjs(new Date(_DOJ)).format("MMM DD, YYYY")}
            </label>
          </Col>
        </Row>
        &nbsp;
        <div className={`border w-100 mb-1 border-light`}></div>
        <Row className={`my-2 mb-0`}>
          <Col className={`${styles._col}`}>
            <label className={`my-2`}>Offer Detail</label>
          </Col>
        </Row>
        <Row className={`my-2`}>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Designation</p>
            <label className={`md-text b`}>{_designation}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Department</p>
            <label className={`md-text b`}>{_department}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Team</p>
            <label className={`md-text b`}>{_team}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Base Location</p>
            <label className={`md-text b`}>{_baseLocation}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Work Location</p>
            <label className={`md-text b`}>{_workLocation}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Work Model</p>
            <label className={`md-text b`}>{_workModal}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>{`Offer Expiry(Days)`}</p>
            <label className={`md-text b`}>{_offerExpiry}</label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Offer Letter</p>
            <div className={`d-flex align-items-start`}>
              <RenderIf isShow={!!_offerLetterUrl}>
                <div className={`${styles.small_icon_box}`}>
                  <img src={attachIcon} />
                </div>
                {/* <label className={`mb-0`}> Revised and Uploaded</label> */}
                <label className={`mb-0`}>{_offerLetterName}</label>
              </RenderIf>
            </div>
          </Col>
        </Row>
        &nbsp;
        <div className={`border w-100 mb-1 border-light`}></div>
        <Row className={`my-2 mb-0`}>
          <Col className={`${styles._col}`}>
            <h6 className={`my-2`}>Salary Detail</h6>
          </Col>
        </Row>
        <Row className={`my-2`}>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>{`CTC (Annual)`}</p>
            <label className={`md-text b`}>
              <span>₹</span> {_CTC}
            </label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>Fixed Component</p>
            <label className={`md-text b`}>
              <span>₹</span> {_fixedComponent}
            </label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>{`Variables (Annual)`}</p>
            <label className={`md-text b`}>
              <span>₹</span> {_variables}
            </label>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`${styles.p}`}>{`Approximate Take-home(Monthly)`}</p>
            <label className={`md-text b`}>
              <span>₹</span> {_takeHome}
            </label>
          </Col>
        </Row>
        &nbsp;
        <div className={`border w-100 mb-1 border-light`}></div>
        <Row className={`my-2 mb-0`}>
          <Col className={`${styles._col}`}>
            <div className={`d-flex align-items-center`}>
              <h6 className={`my-2`}>CardSets</h6>
              <div className={`${styles.icon_box} mx-4`}>
                <img src={infoIcon} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className={`${styles.card_sets_list_block} my-1`}>
          <RenderIf isShow={Object.keys(cardsetList)?.length > 0}>
            {Object.keys(cardsetList)?.map(
              (item: number | string, idx: number) => (
                <div key={idx} className="d-flex">
                  <Col className={`my-1 py-1`} sm={12} md={3} lg={1}>
                    <label className={`sm-text x-light-text`}>
                      {Number(item) === 0
                        ? "Initial"
                        : Number(item) > 4
                        ? "Last"
                        : "week " + item}
                    </label>
                  </Col>
                  {cardsetList[`${item}`]?.map(
                    (itm: CardsetWeekType, inx: number) => {
                      const cardSetIs = _cardset.find(
                        (cs) => cs.cardSetID == itm?.cardSetID
                      );
                      return (
                        <Col
                          className={`my-1 ${styles.set_box} px-0`}
                          sm={12}
                          md={3}
                          lg={2}
                          key={inx}
                        >
                          <div className={`d-flex align-items-center w-100`}>
                            {/* <IoRemoveCircleOutline
                              size={20}
                              color={"#002C71"}
                            /> */}
                            <div className={`${styles.img_block}`}>
                              <img src={companyCard} />
                            </div>
                            <label className={`mx-2 lh-sm`}>
                              {cardSetIs?.cardSetName || "NaN"}
                            </label>
                          </div>
                        </Col>
                      );
                    }
                  )}
                </div>
              )
            )}
          </RenderIf>
        </Row>
        {/* <Row className={`${styles.card_sets_list_block} my-1`}>
          <Col className={`my-1 py-1`} sm={12} md={3} lg={1}>
            <label className={`sm-text x-light-text`}>Week 1</label>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 1</label>
            </div>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 2</label>
            </div>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 3</label>
            </div>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 4</label>
            </div>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 5</label>
            </div>
          </Col>
        </Row>
        <Row className={`${styles.card_sets_list_block} my-1`}>
          <Col className={`my-1 py-1`} sm={12} md={3} lg={1}>
            <label className={`sm-text x-light-text`}>Week 2</label>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 1</label>
            </div>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 2</label>
            </div>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <IoRemoveCircleOutline size={20} color={"#002C71"} />
              <label className={`mx-2`}>Set name 3</label>
            </div>
          </Col>
        </Row>
        <Row className={`${styles.card_sets_list_block} my-1`}>
          <Col className={`my-1 py-1`} sm={12} md={3} lg={1}>
            <label className={`sm-text x-light-text`}>Week 3</label>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <label className={`mx-2`}>Yet to be uploaded</label>
            </div>
          </Col>
        </Row>
        <Row className={`${styles.card_sets_list_block} my-1`}>
          <Col className={`my-1 py-1`} sm={12} md={3} lg={1}>
            <label className={`sm-text x-light-text`}>Last</label>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <label className={`mx-2`}>Yet to be uploaded</label>
            </div>
          </Col>
        </Row> */}
        &nbsp;
        <div className={`border w-100 mb-1 border-light`}></div>
        <Row className={`my-2 mb-0`}>
          <Col className={`${styles._col}`}>
            <div className={`d-flex align-items-center`}>
              <h6 className={`my-2`}>Resources</h6>
              <div className={`${styles.icon_box} mx-4`}>
                <img src={infoIcon} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className={`${styles.card_sets_list_block} my-1 ms-0`}>
          <RenderIf isShow={_resources?.length > 0}>
            {_resources?.map((item: ResourceCreateType, idx: number) => (
              <Col
                className={`my-1 ${styles.set_box} px-0`}
                sm={12}
                md={3}
                lg={2}
              >
                <div className={`d-flex align-items-center w-100`}>
                  <div className={`${styles.small_icon_box} `}>
                    <img src={attachIcon} />
                  </div>
                  <label className={`mx-2 `}>
                    {item?.resourceName || "NaN"}
                  </label>
                </div>
              </Col>
            ))}
          </RenderIf>

          {/* <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <div className={`${styles.small_icon_box} `}>
                <img src={attachIcon} />
              </div>
              <label className={`mx-2 `}>Spec Sheet</label>
            </div>
          </Col>
          <Col className={`my-1 ${styles.set_box} px-0`} sm={12} md={3} lg={2}>
            <div className={`d-flex align-items-center w-100`}>
              <div className={`${styles.small_icon_box} `}>
                <img src={externalLinkIcon} />
              </div>
              <label className={`mx-2 `}>Explainer</label>
            </div>
          </Col> */}
        </Row>
        {/* ----------------------- */}
        &nbsp;
        <Row className={`my-3`}>
          <Col className={`${styles._col}`} sm={12} md={6} lg={5}></Col>
          <Col className={`${styles._col} `} sm={12} md={6} lg={2}>
            <button className={`sbmit_btn_outline w-100`}>
              {/* cancel */}
              <label className={`md-text`}>cancel</label>
            </button>
          </Col>
          <Col className={`${styles._col} p-0`} sm={12} md={6} lg={4}>
            <button
              type={"button"}
              className={`sbmit_btn w-100`}
              onClick={onPublishCandidate}
            >
              {/* <label className={`sm-text`}> */}
              {/* Publish Plan and Notify Candidate */}
              Issue Offer Letter
              {/* </label> */}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReviewForm;
