const activityTableHeader = [
  {
    Header: "Activitites",
    accessor: "activityName",
  },
  {
    Header: "Date",
    accessor: "candidateActionDate",
  },
  {
    Header: "Status",
    accessor: "candidateStatus",
  },
  {
    Header: "Remarks",
    accessor: "candidateRemark",
  },
];

export default activityTableHeader;
