export const generateCardSetWeeks = (endDate: string, startDate?: string) => {
  const dateArray = [];
  let currentDate = startDate ? new Date(startDate) : new Date();
  let endDateObj = new Date(endDate);

  // Scenario 1: Generate date range up to 7 weeks or until the given date
  for (let i = 0; i < 7; i++) {
    const startDate = new Date(currentDate);
    const nextSunday = new Date(currentDate);
    nextSunday.setDate(currentDate.getDate() + (7 - currentDate.getDay()));
    if (i > 5) {
      dateArray.push({
        dateFrom: startDate.toDateString(),
        dateTo: endDateObj.toDateString(),
        weekNumber: i,
      });
      break;
    } else if (nextSunday > endDateObj) {
      dateArray.push({
        dateFrom: startDate.toDateString(),
        dateTo: endDateObj.toDateString(),
        weekNumber: i,
      });
      break;
    } else {
      dateArray.push({
        dateFrom: startDate.toDateString(),
        dateTo: nextSunday.toDateString(),
        weekNumber: i,
      });
      currentDate = new Date(nextSunday);
      currentDate.setDate(currentDate.getDate());
    }
  }
  return dateArray;
};
