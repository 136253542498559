import React, { useState } from "react";
import { useMemo, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { BsCheckLg, BsX, BsPencil, BsTrash } from "react-icons/bs";
import deleteIcon from "../../../../assets/svgIcons/delete-icon.svg";
import editIcon from "../../../../assets/svgIcons/edit.svg";
import editlight from "../../../../assets/svgIcons/editLight-icon.svg";
import { SagaActions } from "../../../../redux/actionTypes";

import fakeData from "../makeData.json";
import styles from "../table.module.scss";
import {
  deleteRecruiterByIdReq,
  updateRecruiterReq,
} from "../../../../api/recruiter/recruiterReq";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

const EditableTable = ({
  columns,
  data,
  setData,
  handleButtonClick,
  updateRecruiterFun,
}) => {
  const totalRecruiterLists = data.length;
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    setPageSize,
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    usePagination
  );

  const startIndex = pageIndex * pageSize + 1;
  const endIndex = Math.min((pageIndex + 1) * pageSize, totalRecruiterLists);

  const handleInputChange = (event, row, columnId) => {
    const newData = data.map((rowData) => {
      if (rowData.userID === row.original.userID) {
        return {
          ...rowData,
          [columnId]: event.target.value,
        };
      }
      return rowData;
    });
    setData(newData);
  };

  return (
    <div>
      <div
        className={`d-flex align-items-center my-1 justify-content-between px-3`}
      >
        <div className={``}>
          <p className={`m-0 md-text`}>Recruiter List</p>
        </div>
        <label className={`sm-text primary-color`}>
          {/* {pageIndex + 1} of {pageOptions.length} is added */}
          {/* {pageIndex + 1} of 10 is added */}
          {endIndex} of {totalRecruiterLists} recruiters displayed
        </label>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers.map((column) => (
                <th className={`sm-text`} {...column?.getHeaderProps()}>
                  {column?.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, idx) => {
                  return (
                    <td key={idx} {...cell.getCellProps}>
                      {cell.column.editEnable ? (
                        row.original.isEditing ? (
                          <div className={`d-flex align-items-center`}>
                            <input
                              type={
                                cell.column.id === "mobileNumber"
                                  ? "tel"
                                  : "text"
                              }
                              maxLength={
                                cell.column.id === "mobileNumber" ? 10 : 999
                              }
                              defaultValue={
                                cell.column.id === "firstName"
                                  ? `${row.original.firstName} ${row.original.lastName}`
                                  : cell.value
                              }
                              className="sm-text"
                              onChange={(e) =>
                                handleInputChange(e, row, cell.column.id)
                              }
                            />
                            {cell.column.id === "mobileNumber" ? (
                              <>
                                <div
                                  className="py-1"
                                  onClick={() => {
                                    updateRecruiterFun(row.original);
                                  }}
                                >
                                  <BsCheckLg
                                    className="mx-2"
                                    color={"#3A93F4"}
                                    size={20}
                                  />
                                </div>
                                <div
                                  className="py-1"
                                  onClick={() => {
                                    handleButtonClick("cancel", row.original);
                                  }}
                                >
                                  <BsX
                                    className="mx-2"
                                    color="#D92D20"
                                    size={20}
                                  />
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          cell.render("Cell")
                        )
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex align-items-center mt-3">
        <button
          style={{ width: 35 }}
          className="sbmit_btn_outline ms-2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <label>{"<"}</label>
        </button>{" "}
        <button
          style={{ width: 35 }}
          className="sbmit_btn_outline ms-2"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <label>{">"}</label>
        </button>{" "}
        <span className="ms-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        {/* <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 15, 20, 25].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> */}
      </div>
    </div>
  );
};

// ===========================================================
function RecruiterTable({ dataIs, dataLength, onDeleteRow }) {
  const Dispatch = useDispatch();
  const updateRecruiterFun = async (recrData) => {
    try {
      const [editedFirstName, editedLastName] = recrData.firstName.split(" ");
      const body = {
        email: recrData?.email,
        mobileNumber: recrData?.mobileNumber,
        firstName: editedFirstName,
        lastName: editedLastName ? editedLastName : "",
        userID: recrData?.userID,
      };
      const res = await updateRecruiterReq(body);
      toast.success(res?.message);
      handleButtonClick("save", recrData);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "recruiterTable",
          methodName: "updateRecruiterFun",
          type: "info",
        },
      });
    } catch (error) {
      console.log(error, "error in onDeleteRecruiter");
      handleButtonClick("cancel", recrData);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "recruiterTable",
          methodName: "updateRecruiterFun",
          type: "error",
        },
      });
    }
  };

  const columnsData = [
    {
      Header: "Name",
      accessor: "firstName",
      editEnable: true,
      Cell: ({ cell }) => {
        return (
          <p className="sm-text">
            {`${cell?.row?.original?.firstName} ${cell?.row?.original?.lastName}`}
          </p>
        );
      },
    },
    { Header: "Designation", accessor: "designation" },
    { Header: "Email", accessor: "email", editEnable: true },
    { Header: "Mobile Number", accessor: "mobileNumber", editEnable: true },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => {
        const status = cell?.value;
        return <p className="sm-text">{status ? "active" : "invited"}</p>;
      },
    },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row, column, cell }) => {
        const _userId = cell?.row?.original?.userID;
        const value = cell?.value;

        return (
          <div className={`d-flex align-items-center`}>
            <div
              className={`${styles.small_icon_box} d-flex me-1`}
              onClick={() =>
                row.original.isEditing
                  ? {}
                  : handleButtonClick("edit", row.original)
              }
            >
              <img src={!row.original.isEditing ? editIcon : editlight} />
            </div>
            <div
              className={`${styles.small_icon_box} d-flex ms-1`}
              onClick={() => onDeleteRecruiter(_userId)}
            >
              <img src={deleteIcon} />
            </div>
          </div>
        );
      },
    },
  ];

  const [data, setdata] = useState([]);

  const onDeleteRecruiter = async (id) => {
    try {
      const res = await deleteRecruiterByIdReq({ id });
      toast.success(res?.message);
      onDeleteRow({ id });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "recruiterTable",
          methodName: "onDeleteRecruiter",
          type: "info",
        },
      });
    } catch (error) {
      console.log(error, "error in onDeleteRecruiter");
      if (error?.errorCode + "" === "403") {
        Swal.fire({
          icon: "warning",
          text: error?.errorMsg,
        });
        return;
      }
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "recruiterTable",
          methodName: "onDeleteRecruiter",
          type: "error",
        },
      });
    }
  };

  const handleButtonClick = (action, row) => {
    const newData = data.map((rowData) => {
      if (rowData.userID === row.userID) {
        if (action === "edit") {
          return { ...rowData, isEditing: true, prevData: { ...rowData } };
        } else if (action === "cancel") {
          return { ...rowData, isEditing: false, ...rowData.prevData };
        } else if (action === "save") {
          const { prevData, ...updatedRowData } = rowData;
          const [editedFirstName, editedLastName] =
            updatedRowData.firstName.split(" ");
          return {
            ...updatedRowData,
            isEditing: false,
            firstName: editedFirstName,
            lastName: editedLastName ? editedLastName : "",
          };
        }
      }
      return rowData;
    });
    setdata(newData);
  };

  useEffect(() => {
    setdata(dataIs ? [...dataIs] : []);
  }, [dataIs]);

  return (
    <div className={`${styles.MyTable}`}>
      <EditableTable
        columns={columnsData}
        data={data}
        setData={setdata}
        handleButtonClick={handleButtonClick}
        updateRecruiterFun={updateRecruiterFun}
      />
    </div>
  );
}

export default RecruiterTable;
