import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import { publishCandidateReq } from "../../../api/candidateReq/candidateReq";
import { toast } from "react-toastify";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import logout from "../../../assets/icons/logout.png";
import { IoIosLogOut } from "react-icons/io";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import cepIcon from "../../../assets/svgIcons/cep-icon.svg";
import avatarImg from "../../../assets/images/avatar-2.png";
import { CgProfile } from "react-icons/cg";
import AES from "crypto-js/aes";

const LogoutModal = ({ onClose }: any) => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const { profile, cepCompanyProfile } = useSelector(
    (_state: RootState) => _state
  );
  const roleID = localStorage.getItem("@role");
  const _isCepAdmin = 102 == Number(roleID);

  const _profile = profile?.data || null;
  const _cepCompanyProfile = cepCompanyProfile?.data || null;
  const selectedCompany = cepCompanyProfile?.companyID;

  const selectedCompanyFun = (companyID: number) => {
    Dispatch({ type: SagaActions.SELECTED_CEP_COMPANY, payload: companyID });
  };

  const logoutHandler = () => {
    localStorage.setItem("token", "");
    navigate(`${ROUTE_NAMES.LOGIN}`);
    onClose();
  };

  const navigateToCEPView = async () => {
    try {
      const tokenExpiresOn = localStorage.getItem("tokenExpiresOn");
      const refreshToken = localStorage.getItem("refreshToken");
      const tokenExpireDuration = localStorage.getItem("tokenExpireDuration");
      const roleIs = localStorage.getItem("@role");
      const userId = localStorage.getItem("userID");
      const encriptdataIs = AES.encrypt(
        JSON.stringify({
          tokenExpiresOn,
          tokenExpireDuration,
          refreshToken,
          roleIs,
          userId,
        }),
        "vmzCareerX"
      ).toString();
      const _encriptdataIs = encriptdataIs.replaceAll("/", "$");
      console.log(encriptdataIs.replaceAll("/", "$"), "encrypted token");
      const domainUrl = process.env.REACT_APP__PADMIN_DOMAIN;
      // const domainUrl = "http://localhost:3001";

      window.location.href =
        domainUrl + "/from-eadmin/loading/" + _encriptdataIs;
    } catch (error) {}
  };

  const RadioButtons = memo(({ isChecked, onChange }: any) => {
    return (
      <input
        type="radio"
        className="radio"
        checked={isChecked}
        onChange={() => onChange()}
      />
    );
  });

  return (
    <div className="container me-1 " style={{ width: "23%" }}>
      <div className="mt-5 w-100 rounded-2 bg-white px-4 py-3">
        <div className="mb-2">
          {/* {!_isCepAdmin && ( */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <img src={avatarImg} /> */}
            <CgProfile size={18} />
            <label
              className="ms-1"
              style={{ fontSize: 16, fontWeight: 500 }}
            >{`${_profile?.firstName} ${_profile?.lastName}`}</label>
          </div>
          {/* )} */}
          {_isCepAdmin && (
            <button
              className="bg-white border border-0 p-0 mt-2"
              onClick={navigateToCEPView}
              style={{ fontSize: 15, fontWeight: 500 }}
            >
              <img src={cepIcon} width={18} className="me-2 " />
              CEP View
            </button>
          )}
        </div>
        <div className="border"></div>
        {_isCepAdmin && (
          <div className="mb-1 mt-2 d-flex flex-column">
            <div className="mb-1">
              <HiOutlineBuildingOffice size={17} />
              <label
                className="ms-2"
                style={{ marginLeft: 3, fontSize: 15, fontWeight: 500 }}
              >
                Companies
              </label>
            </div>
            {!_cepCompanyProfile?.company && (
              <div className={`d-flex position-relative align-items-center`}>
                <img src={logout} className="white-color" width={20} />
                <label style={{ fontSize: 14 }} className="ms-3 p-1">
                  No Companies
                </label>
              </div>
            )}
            {_cepCompanyProfile?.company?.map((item, idx) => (
              <div
                className={`${
                  selectedCompany === item.companyID &&
                  `bg-white secondary-color`
                } d-flex position-relative align-items-center`}
                onClick={() => selectedCompanyFun(item.companyID)}
              >
                {/* <RadioButtons
                  isChecked={selectedCompany === item.companyID}
                  onChange={() => selectedCompanyFun(item.companyID)}
                /> */}
                <img src={logout} className="white-color" width={20} />
                <label style={{ fontSize: 14 }} className="ms-3 p-1">
                  {item?.companyName}
                </label>
              </div>
            ))}
          </div>
        )}
        {_isCepAdmin && <div className="border"></div>}
        <div className="mt-2" onClick={logoutHandler}>
          {/* <img src={logout} width={20}/> */}
          <IoIosLogOut size={19} />
          <label style={{ fontSize: 15, fontWeight: 500 }} className="ms-1 p-1">
            Logout
          </label>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
