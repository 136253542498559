import React from "react";

import "./topBar.scss";
import bellIcon from "../../assets/svgIcons/bell-icon.svg";
import downArrow from "../../assets/svgIcons/chevron-down.svg";
import avatarImg from "../../assets/images/avatar-2.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { SagaActions } from "../../redux/actionTypes";

const TopBar = () => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const { profile } = useSelector((_state) => _state);

  const _roleID = profile?.data?.role || null;

  const _onPress = ({ key, data }) => {
    const fun = {
      null: (data) => {},
      logout: () => {
        if (_roleID == 301)
          return navigate(
            ROUTE_NAMES.myAccount + "/" + ROUTE_NAMES.companyProfile
          );
      },
      signout: () => {
        Dispatch({ type: SagaActions.SHOW_LOGOUT_MODAL });
      },
      bell: () => {
        Dispatch({ type: SagaActions.SHOW_NOTIFICATIONS_MODAL });
      },
    };
    fun[key](data);
  };
  return (
    <div className="topBar">
      <div className="d-flex">
        <div className="d-flex" style={{ flex: 1 }}></div>
        <div className="d-flex align-items-center">
          <button
            className="icon-box"
            onClick={() => _onPress({ key: "bell" })}
          >
            <img src={bellIcon} />
          </button>
          <button
            className="profile-icon-box"
            onClick={() => _onPress({ key: "logout" })}
          >
            <img src={avatarImg} />
          </button>
          <div className="column">
            <button
              className="icon-box"
              onClick={() => _onPress({ key: "signout" })}
            >
              <img src={downArrow} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
