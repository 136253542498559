import React, { useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import styles from "../candidatePage.module.scss";
import { validate } from "../../../constants/validationExp";

import UploadFile from "../../../components/uploadFileBlock";
import LoginLog from "../../../components/microComponents/loginLog";
import { personalDetails } from "../constant/initialValues";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { addCandidatesDetails } from "../constant/initialValues";
import { fileUploadPaths } from "../../../constants/fileUploadPaths";
import { toast } from "react-toastify";
import { addCandidateReq } from "../../../api/candidateReq/candidateReq";
import { SagaActions } from "../../../redux/actionTypes";
import { RootState } from "../../../redux/store";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import dayjs from "dayjs";
import { COUNTRY_CODES } from "../../../constants/countryCodes";
import indianFlag from "../../../assets/icons/indian_flag.png"
import MobileNoLayout from "../../../components/compLayouts/mobileNoLayout";

const PersonalInfoForm = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  const { candidateForm } = useSelector((_state: RootState) => _state);
  const _formValues = candidateForm?.data?.personalDetails;

  const [offerLetterFile, setofferLetterFile] = useState();
  const [formValues, setformValues] = useState(addCandidatesDetails);
  const [offerLetterPath, setOfferLetterPath] = useState(
    _formValues?.offerLetterURL || ""
  );
  const [offerLetterUploadError, setofferLetterUploadError] = useState("");
  const [updateLoder, setupdateLoder] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [selectedTimeZone, setSelectedTimeZone] = useState("India (GMT+5:30)");

  const handleCountryCodeChange = (e: any) => {
    const selectedCountryCode = e.countryCode;
    const selectedCountry = COUNTRY_CODES.find(
      (country) => country.countryCode === selectedCountryCode
    );
    if (selectedCountry) {
      setSelectedCountryCode(selectedCountry.countryCode);
      setSelectedTimeZone(selectedCountry.countryTimeZone[0].timeZone);
    }
  };

  const selectedCountry = COUNTRY_CODES?.find(
    (country) => country.countryCode === selectedCountryCode
  );

  const handleTimeZoneChange = (e: any) => {
    setSelectedTimeZone(e.target.value);
  };


  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    values: {
      firstNameIs: _formValues?.firstName || "",
      lastNameIs: _formValues?.lastName || "",
      phoneNumberIs: _formValues?.mobile || "",
      emailIs: _formValues?.email || "",
      designation: _formValues?.designation || "",
      department: _formValues?.department || "",
      team: _formValues?.team || "",
      baseLocation: _formValues?.baseLocation || "",
      workLocation: _formValues?.workLocation || "",
      dojDD: _formValues?.dateOfJoining?.toString().split(" ")[2],
      dojMM: _formValues?.dateOfJoining?.toString().split(" ")[1],
      dateOfJoining: dayjs(_formValues?.dateOfJoining).format('YYYY-MM-DD'),
      // dojYY: _formValues?.dateOfJoining?.toString().split(" ")[3],
      model: _formValues?.workModel || "",
      offerExpiry: _formValues?.offerExpiryDays || "",
      yearExpMM: _formValues?.yearOfExperience?.toString().split(".")[1],
      yearExpYY: _formValues?.yearOfExperience?.toString().split(".")[0],
      jobLevel: _formValues?.jobLevel || "",
      ctc: _formValues?.salaryCtcPA || "",
      fixedComp: _formValues?.fixedComponentPA || "",
      variables: _formValues?.variableComponentPA || "",
      payout: _formValues?.variablePayoutFreq || "",
      takeHome: _formValues?.approxTakeHomePM || "",
    },
  });
  const phNo = watch("phoneNumberIs");
  const _numberError = errors.phoneNumberIs?.message;
  const fileName = _formValues?.offerLetterURL
  .split("https://d14ntgkdxyoags.cloudfront.net/files/offerLetter/")
  .slice(1)[0];
  console.log("fileName",fileName)

  const days = Array.from({ length: 31 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  );
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const years = Array.from({ length: 2 }, (_, i) => 2024 - i);

  const onSubmitFun = async (formData: any) => {
    // console.log("formData", formData);
    setupdateLoder(true);
    try {
      if (!offerLetterPath) {
        setofferLetterUploadError(`Offer letter required`);
        toast.error("Please upload job-offer letter");
        setupdateLoder(false);
        return;
      }
      setofferLetterUploadError("");
      const personalDetailsBody = {
        email: formData?.emailIs || "",
        firstName: formData?.firstNameIs || "",
        lastName: formData?.lastNameIs || "",
        mobile: formData?.phoneNumberIs || "",
        designation: formData?.designation || "",
        yearOfExperience: `${formData?.yearExpYY}.${formData?.yearExpMM}`,
        jobLevel: formData?.jobLevel || "",
        // dateOfJoining: new Date(
        //   `${formData?.dojYY}/${formData?.dojMM}/${formData?.dojDD}`
        // ),
        dateOfJoining: new Date(formData?.dateOfJoining),
        department: formData?.department || "",
        team: formData?.team || "",
        baseLocation: formData?.baseLocation || "",
        workLocation: formData?.workLocation || "",
        workModel: formData?.model || "",
        offerExpiryDays: formData?.offerExpiry || "",
        fixedComponentPA: formData?.fixedComp || "",
        variableComponentPA: formData?.variables || "",
        variablePayoutFreq: formData?.payout || "",
        salaryCtcPA: formData?.ctc || "",
        approxTakeHomePM: formData?.takeHome || "",
        // no fields from frontend------------------
        offerLetterURL: offerLetterPath,
        countryCode: selectedCountryCode,
        TimeZone: selectedTimeZone
      };

      Dispatch({
        type: SagaActions.ADD_FORM_CANDIDATE_PERSONAL,
        payload: personalDetailsBody,
      });
      const parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
      navigate(`${parentPath}/${ROUTE_NAMES.cardSetForm}`);

      // const res = await addCandidateReq(personalDetailsBody);
      // toast.success(res?.message);
      setupdateLoder(false);
    } catch (error) {
      // toast.error(error?.errorMsg);
      setupdateLoder(false);
      setofferLetterUploadError("");
    }
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = { submit: () => {} };
    fun[key](data);
  };

  return (
    <div className={`${styles.personalInfoForm} px-3`}>
      <Container>
        <LoginLog />
        <form
          onSubmit={handleSubmit((data) => {
            onSubmitFun(data);
          })}
        >
          <div className={`d-flex align-item-center ${styles.path_view_block}`}>
            <a className={`${styles.link} x-sm-text primary-color`}>Candidates</a>
            <BiChevronRight className={`primary-color`} />
            <a className={`${styles.link} x-sm-text primary-color`}>Add Candidates</a>
          </div>
          <Row className={`my-2`}>
            <Col className={`${styles._col} mt-2`}>
              <p className={`mb-1 lg-text`}>Add a New Candidate</p>
            </Col>
          </Row>
          <Row className={`my-0`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>First Name<span className="text-danger">*</span></p>
              <input
                {...register("firstNameIs", {
                  required: "First Name required",
                })}
                id="firstNameIs"
                className={`md-text ${
                  errors?.firstNameIs?.message && "border-danger"
                }`}
                placeholder=""
              />
              {errors?.firstNameIs?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.firstNameIs?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>Last Name<span className="text-danger">*</span></p>
              <input
                {...register("lastNameIs", { required: "Last Name required" })}
                className={`md-text ${
                  errors?.lastNameIs?.message && "border-danger"
                }`}
                id="lastNameIs"
                placeholder=""
              />
              {errors?.lastNameIs?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.lastNameIs?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row className={`my-0`}>
            {/* <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>Mobile Number</p>
              <div className={`d-flex`}>
                <div className={`ps-2 me-2 country_code_field`}>
                  <div className="flag_icon_box"></div>
                  <input
                    className={`md-text border-0 `}
                    placeholder="+91"
                    value={`+91`}
                    id="code"
                  />
                </div>
                <input
                  className={`md-text ${
                    errors?.phoneNumberIs?.message && "border-danger"
                  }`}
                  id="phoneNumberIs"
                  placeholder=""
                  type="tel"
                  maxLength={10}
                  {...register("phoneNumberIs", {
                    required: "Mobile number required",
                    maxLength: {
                      value: 10,
                      message: "enter 10 digit mobile number",
                    },
                    minLength: {
                      value: 10,
                      message: "enter 10 digit mobile number",
                    },
                    pattern: {
                      value: validate.phone,
                      message: "Enter correct mobile number",
                    },
                  })}
                />
              </div>
              {errors?.phoneNumberIs?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.phoneNumberIs?.message}
                </p>
              )}
            </Col> */}
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={5}>
              {/* <p className={`mb-1 md-text`}>
                Mobile Number<span className="text-danger">*</span>
              </p>
              <div className={`d-flex align-items-start`}>
                <div className={`ps-2 me-2 country_code_field`}>
                  <div className="flag_icon_box">
                    <img
                      src={
                        selectedCountry
                          ? selectedCountry?.countryImg
                          : indianFlag
                      }
                    />
                  </div>
                  <input
                    className={`md-text border-0 `}
                    placeholder="+91"
                    value={`+91`}
                    maxLength={10}
                    disabled
                    id="code"
                  />
                  <select
                    onChange={handleCountryCodeChange}
                    className={`sm-text border-0`}
                    style={{ outline: "none" }}
                    // placeholder="+91"
                    value={selectedCountryCode}
                    id="code"
                  >
                    {COUNTRY_CODES.map((codeOption) => (
                      <option
                        key={codeOption.countryCode}
                        value={codeOption.countryCode}
                      >
                        <img
                          src={codeOption.countryImg}
                          className="image_icon"
                        />
                        {codeOption.countryCode}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <input
                    type="tel"
                    maxLength={10}
                    className={`md-text ${
                      errors?.phoneNumberIs?.message && "border-danger"
                    }`}
                    id="mobile"
                    placeholder=""
                    {...register("phoneNumberIs", {
                      required: "Mobile number required",
                      maxLength: {
                        value: 10,
                        message: "enter 10 digit mobile number",
                      },
                      minLength: {
                        value: 10,
                        message: "enter 10 digit mobile number",
                      },
                    })}
                  />
                  {errors?.phoneNumberIs?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.phoneNumberIs?.message}
                    </p>
                  )}
                </div>
              </div> */}
              <MobileNoLayout
                isError={_numberError}
                mobileNumber={phNo}
                required
                onCountryCodeChange={handleCountryCodeChange}
                // countryCodeOptions={COUNTRY_CODES}
                selectedCountryCode={selectedCountryCode}
              >
                <input
                  className={`flex-grow-1 `}
                  placeholder=""
                  type="tel"
                  maxLength={10}
                  {...register("phoneNumberIs", {
                    required: "Enter 10 digit mobile number",
                    maxLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                    minLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                    pattern: {
                      value: validate.phone,
                      message: "Incorrect mobile number",
                    },
                  })}
                />
              </MobileNoLayout>
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Time Zone</p>
              <select
                className="w-100 px-2"
                value={selectedTimeZone}
                onChange={handleTimeZoneChange}
              >
                {selectedCountryCode &&
                  COUNTRY_CODES.find(
                    (country) => country.countryCode === selectedCountryCode
                  )?.countryTimeZone.map((timezone) => (
                    <option key={timezone.zone} value={timezone.timeZone}>
                      {timezone.zone}
                    </option>
                  ))}
              </select>
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text d-flex flex-row`}>Email ID <span className="text-danger">*</span></p>
              <input
                className={`md-text ${
                  errors?.emailIs?.message && "border-danger"
                }`}
                id="emailIs"
                placeholder=""
                {...register("emailIs", {
                  required: "Email required",
                  pattern: {
                    value: validate.email,
                    message: "Entered incorrect email format",
                  },
                })}
              />
              {errors?.emailIs?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.emailIs?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row className={`my-2 mb-0`}>
            <Col className={`${styles._col} mt-3`}>
              <p className={`mb-1 md-text b`}>Offer Detail</p>
            </Col>
          </Row>
          <Row className={`my-0`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Designation<span className="text-danger">*</span></p>
              <input
                className={`md-text ${
                  errors?.designation?.message && "border-danger"
                }`}
                id="designation"
                placeholder=""
                {...register("designation", {
                  required: "Designation required",
                })}
              />
              {errors?.designation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.designation?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Department<span className="text-danger">*</span></p>
              <input
                className={`md-text ${
                  errors?.department?.message && "border-danger"
                }`}
                id="department"
                placeholder=""
                {...register("department", {
                  required: "department required",
                })}
              />
              {errors?.department?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.department?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Team<span className="text-danger">*</span></p>
              <input
                className={`md-text ${
                  errors?.team?.message && "border-danger"
                }`}
                id="team"
                placeholder=""
                {...register("team", {
                  required: "team required",
                })}
              />
              {errors?.team?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.team?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Base Location<span className="text-danger">*</span></p>
              <input
                className={`md-text ${
                  errors?.baseLocation?.message && "border-danger"
                }`}
                id="baseLocation"
                placeholder=""
                {...register("baseLocation", {
                  required: "baseLocation required",
                })}
              />
              {errors?.baseLocation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.baseLocation?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Work Location<span className="text-danger">*</span></p>
              <input
                className={`md-text ${
                  errors?.workLocation?.message && "border-danger"
                }`}
                id="workLocation"
                placeholder=""
                {...register("workLocation", {
                  required: "workLocation required",
                })}
              />
              {errors?.workLocation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.workLocation?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Date of Joining<span className="text-danger">*</span></p>
              {/* <Row>
                <Col className={`${styles._col} pe-0`} xs={4}>
                  <select
                    className={`md-text ${
                      errors?.dojDD?.message && "border-danger"
                    }`}
                    id="dojDD"
                    {...register("dojDD", {
                      required: "dojDD required",
                    })}
                  >
                    <option value={""}>DD</option>
                    {days.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                  {errors?.dojDD?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.dojDD?.message}
                    </p>
                  )}
                </Col>
                <Col className={`${styles._col} px-1`} xs={4}>
                  <select
                    className={`md-text ${
                      errors?.dojMM?.message && "border-danger"
                    }`}
                    id="dojMM"
                    {...register("dojMM", {
                      required: "dojMM required",
                    })}
                  >
                    <option value={""}>MM</option>
                    {months.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                  {errors?.dojMM?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.dojMM?.message}
                    </p>
                  )}
                </Col>
                <Col className={`${styles._col} ps-0`} xs={4}>
                  <select
                    className={`md-text ${
                      errors?.dojYY?.message && "border-danger"
                    }`}
                    id="dojYY"
                    {...register("dojYY", {
                      required: "dojYY required",
                    })}
                  >
                    <option value={""}>YY</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {errors?.dojYY?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.dojYY?.message}
                    </p>
                  )}
                </Col> */}
              <input
                className={`md-text ${
                  errors?.dateOfJoining?.message && "border-danger"
                }`}
                type="date"
                placeholder="Select a date"
                min={dayjs().add(1, "day").format("YYYY-MM-DD")}
                {...register("dateOfJoining", {
                  required: "DOJ required",
                })}
              />
              {/* </Row> */}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Work Model<span className="text-danger">*</span></p>
              <select
                className={`md-text ${
                  errors?.model?.message && "border-danger"
                }`}
                id="model"
                {...register("model", {
                  required: "model required",
                })}
              >
                <option value={""}>Select</option>
                <option value={"Office"}>Office</option>
                <option value={"Hybrid"}>Hybrid</option>
                <option value={"Fully Virtual"}>Fully Virtual</option>
              </select>
              {errors?.model?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.model?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3 `} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>{`Offer Expiry(Days)`}<span className="text-danger">*</span></p>

              <input
                className={`md-text ${
                  errors?.offerExpiry?.message && "border-danger"
                }`}
                id="offerExpiry"
                placeholder="0-10 days"
                {...register("offerExpiry", {
                  required: "offerExpiry required",
                  pattern: {
                    value: /^(?:[0-9]|[0][0-9]|[1][0])$/,
                    message: "offerExpiry can be 0 to 10",
                  },
                })}
              />
              {errors?.offerExpiry?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.offerExpiry?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Years of Exp<span className="text-danger">*</span></p>
              <Row>
                <Col className={`${styles._col} pe-0`} xs={6}>
                  <input
                    className={`md-text ${
                      errors?.yearExpYY?.message && "border-danger"
                    }`}
                    id="yearExpYY"
                    placeholder="YY"
                    {...register("yearExpYY", {
                      required: "Year is required",
                      pattern: {
                        value: /^(?:[0-9]|[0][0-9]|[1-9][0-9])$/,
                        message: "Year can be 0 to 99",
                      },
                    })}
                  />
                  {errors?.yearExpYY?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.yearExpYY?.message}
                    </p>
                  )}
                </Col>
                <Col className={`${styles._col} px-1`} xs={6}>
                  <input
                    className={`md-text ${
                      errors?.yearExpMM?.message && "border-danger"
                    }`}
                    id="yearExpMM"
                    placeholder="MM"
                    {...register("yearExpMM", {
                      required: "Month is required",
                      pattern: {
                        value: /^(?:[0-9]|[0][0-9]|[1][0-1])$/,
                        message: "Month can be 0 to 11",
                      },
                    })}
                  />
                  {errors?.yearExpMM?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.yearExpMM?.message}
                    </p>
                  )}
                </Col>
              </Row>
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Band/Job Level<span className="text-danger">*</span></p>
              <select
                className={`md-text ${
                  errors?.jobLevel?.message && "border-danger"
                }`}
                {...register("jobLevel", {
                  required: "jobLevel required",
                })}
              >
                <option value={""}>Select</option>
                <option value={"JL 1"}>JL 1</option>
                <option value={"JL 2"}>JL 2</option>
                <option value={"JL 3"}>JL 3</option>
                <option value={"JL 4"}>JL 4</option>
                <option value={"JL 5"}>JL 5</option>
                <option value={"JL 6"}>JL 6</option>
                <option value={"JL 7"}>JL 7</option>
                <option value={"JL 8"}>JL 8</option>
                <option value={"JL 9"}>JL 9</option>
                <option value={"JL 10"}>JL 10</option>
                <option value={"JL 11"}>JL 11</option>
                <option value={"JL 12"}>JL 12</option>
              </select>
              {errors?.jobLevel?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.jobLevel?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row className={`my-3`}>
            <Col className={`${styles._col} mt-3`}>
              <p className={`mb-1 my-1 md-text b`}>Salary Detail</p>
            </Col>
          </Row>
          <Row className={`my-3`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>{`CTC (Annual)`}<span className="text-danger">*</span></p>
              <input
                className={`md-text ${errors?.ctc?.message && "border-danger"}`}
                id="ctc"
                placeholder="10000"
                {...register("ctc", {
                  required: "ctc required",
                })}
              />
              {errors?.ctc?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.ctc?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Fixed Component</p>
              <input
                className={`md-text ${
                  errors?.fixedComp?.message && "border-danger"
                }`}
                id="fixedComp"
                placeholder="8000"
                {...register("fixedComp", {
                  // required: "fixedComp required",
                })}
              />
              {errors?.fixedComp?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.fixedComp?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Variables</p>
              <input
                className={`md-text ${
                  errors?.variables?.message && "border-danger"
                }`}
                id="variables"
                placeholder="2000"
                {...register("variables", {
                  // required: "variables required",
                })}
              />
              {errors?.variables?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.variables?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Variable Payout<span className="text-danger">*</span></p>
              <select
                className={`md-text ${
                  errors?.payout?.message && "border-danger"
                }`}
                {...register("payout", {
                  required: "payout required",
                })}
              >
                <option value={""}>Select</option>
                <option value={"monthly"}>Monthly</option>
                <option value={"quarterly"}>Quarterly</option>
                <option value={"halfYearly"}>Half Yearly</option>
                <option value={"yearly"}>Yearly</option>
              </select>
              {errors?.payout?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.payout?.message}
                </p>
              )}
            </Col>

            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p
                className={`mb-1 md-text`}
              >{`Approximate Take Home (Monthly)`}</p>
              <input
                className={`md-text ${
                  errors?.takeHome?.message && "border-danger"
                }`}
                id="takeHome"
                placeholder="8300"
                {...register("takeHome", {
                  // required: "takeHome required",
                })}
              />
              {errors?.takeHome?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.takeHome?.message}
                </p>
              )}
            </Col>
            {/* <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Placeholder names</p>
              <input
                
                className={`md-text ${
                  errors?.placeholder1?.message && "border-danger"
                }`}
                placeholder=""
                {...register("placeholder1", {
                  required: "placeholder1 required",
                })}
              />
              {errors?.placeholder1?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.placeholder1?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Placeholder names</p>
              <input
                
                className={`md-text ${
                  errors?.placeholder2?.message && "border-danger"
                }`}
                placeholder=""
                {...register("placeholder2", {
                  required: "placeholder2 required",
                })}
              />
              {errors?.placeholder2?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.placeholder2?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>Placeholder names</p>
              <input
                
                className={`md-text ${
                  errors?.placeholder3?.message && "border-danger"
                }`}
                placeholder=""
                {...register("placeholder3", {
                  required: "placeholder3 required",
                })}
              />
              {errors?.placeholder3?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.placeholder3?.message}
                </p>
              )}
            </Col> */}
          </Row>
          &nbsp;
          <Row className={`my-3`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 my-1 md-text b`}>Offer Letter</p>
              <p className={`m-1 x-sm-text light-text`}>
                Browse or Drag and Drop <br />
                to Upload offer Letter (PDF)
              </p>
              {/* <p className={`mb-1 my-1 x-sm-text light-text`}>
                Jpg, Png Max 200Kb of 5:3 <br />
                ratio
              </p> */}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <UploadFile
                accept={".pdf"}
                imagePath={offerLetterPath}
                discription="Upload offer letter PDF"
                path={fileUploadPaths.candidateOfferLetters}
                fileName={fileName}
                onUpload={(data: any) => {
                  setOfferLetterPath(data?.path || "");
                }}
              />
              {!!offerLetterUploadError && (
                <p className={`xx-sm-text text-danger`}>
                  {offerLetterUploadError}
                </p>
              )}
            </Col>
            {/* <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
            <UploadFile discription="Logo in color" />
          </Col> */}
          </Row>
          <Row className={`my-3`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={7}></Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={2}>
              <button className={`sbmit_btn_outline w-100`}>
                <label className={`sm-text`}>Cancel</label>
                {/* cancel */}
              </button>
            </Col>
            <Col className={`${styles._col} mt-3 p-0`} sm={12} md={6} lg={2}>
              <button
                // className={`btn btn-primary w-100`}
                className={`sbmit_btn w-100 py-2 sm-text d-flex align-items-center justify-content-center`}
                // type="button"
                type="submit"
                // onClick={() => _onPress({ key: "submit" })}
              >
                Save & Continue
                {!!updateLoder && (
                  <div className="ms-2">
                    <Spinner animation="border" variant="white" size="sm" />
                  </div>
                )}
              </button>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default PersonalInfoForm;
