import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTE_NAMES } from "../constants/routeNames";
import { LoginPage } from "../pages/auth";
import SignupPage from "../pages/auth/signupPage";
import CandidateMainLayout, {
  AddCandidatePage,
  AddCardSetForm,
  PersonalInfoForm,
  ResourcesForm,
  ReviewForm,
} from "../pages/candidate";
import CardSetsPage, { CompanyIntro } from "../pages/cardSets";
import DashboardPage, { TablePage } from "../pages/dashboard";
import { HomePage } from "../pages/home";
import { MainLayoutPage } from "../pages/mainLayout";
import PrivateRoute from "./privateRoutes";
import PublicRoute from "./publicRoutes";
import { ReportPage } from "../pages/report";
import HelpPageMainLayout, {
  ContactUsPage,
  FAQPage,
  HowItWorksPage,
} from "../pages/help";
import HowItWorksMainLayout, { WelcomePage } from "../pages/howItWorks";
import AllCandidatePage from "../pages/allCadidate";
import MyAccountMainLayout, {
  WelcomePage as AccountWelcomePage,
  AddRecruiter,
  CompanyProfile,
  PlanPage,
} from "../pages/myAccount";
import { GlobalHistory } from "./rootNavigation";
import { useDispatch } from "react-redux";
import { SagaActions } from "../redux/actionTypes";
import RedirectLoadingPage from "../pages/redirect/redirectLoadingPage";
import ProtectiveRoutes from "./protectiveRoute";

const PageRoutes = () => {
  const Dispatch = useDispatch();

  useEffect(() => {
    Dispatch({ type: SagaActions.ADD_NEW_UUID });
  }, []);

  return (
    <BrowserRouter>
      <GlobalHistory />
      <Routes>
        <Route exact path={ROUTE_NAMES.LOGIN} element={<PublicRoute />}>
          <Route exact path={ROUTE_NAMES.LOGIN} element={<LoginPage />} />
        </Route>
        <Route exact path={ROUTE_NAMES.INITIAL} element={<PrivateRoute />}>
          <Route exact path={ROUTE_NAMES.INITIAL} element={<LoginPage />} />
          <Route exact path={ROUTE_NAMES.SIGNUP} element={<SignupPage />} />
          <Route
            exact
            path={ROUTE_NAMES.FROM_PADMIN}
            element={<RedirectLoadingPage />}
          />
            <Route
              exact
              path={ROUTE_NAMES.mainLayout}
              element={
                <ProtectiveRoutes>
                  <MainLayoutPage />
                </ProtectiveRoutes>
              }
            >
              <Route path={ROUTE_NAMES.dashboard} element={<DashboardPage />} />
              <Route exact path={ROUTE_NAMES.table} element={<TablePage />} />

              <Route
                exact
                path={ROUTE_NAMES.CANDIDATE}
                element={<CandidateMainLayout />}
              >
                <Route
                  path={ROUTE_NAMES.allCandidate}
                  element={<AllCandidatePage />}
                />
                <Route
                  path={ROUTE_NAMES.addCandidate}
                  element={<AddCandidatePage />}
                >
                  <Route
                    path={ROUTE_NAMES.basic_info_form}
                    element={<PersonalInfoForm />}
                  />
                  <Route
                    path={ROUTE_NAMES.cardSetForm}
                    element={<AddCardSetForm />}
                  />
                  <Route
                    path={ROUTE_NAMES.resourcesForm}
                    element={<ResourcesForm />}
                  />
                  <Route
                    path={ROUTE_NAMES.reviewForm}
                    element={<ReviewForm />}
                  />
                </Route>
              </Route>
              <Route
                exact
                path={ROUTE_NAMES.cardSetsPage}
                element={<CardSetsPage />}
              >
                <Route
                  exact
                  path={ROUTE_NAMES.companyIntro}
                  element={<CompanyIntro />}
                />
              </Route>
              <Route exact path={ROUTE_NAMES.report} element={<ReportPage />} />
              <Route
                exact
                path={ROUTE_NAMES.help}
                element={<HelpPageMainLayout />}
              >
                <Route
                  exact
                  path={ROUTE_NAMES.HowItWorksPage}
                  element={<HowItWorksPage />}
                />
                <Route
                  exact
                  path={ROUTE_NAMES.contact_us}
                  element={<ContactUsPage />}
                />
                <Route exact path={ROUTE_NAMES.faq} element={<FAQPage />} />
              </Route>
              <Route
                path={ROUTE_NAMES.howItWorks}
                element={<HowItWorksMainLayout />}
              >
                <Route
                  exact
                  path={ROUTE_NAMES.welcome}
                  element={<WelcomePage />}
                />
              </Route>
              <Route
                path={ROUTE_NAMES.myAccount}
                element={<MyAccountMainLayout />}
              >
                <Route
                  exact
                  path={ROUTE_NAMES.welcome}
                  element={<AccountWelcomePage />}
                />
                <Route
                  exact
                  path={ROUTE_NAMES.companyProfile}
                  element={<CompanyProfile />}
                />
                <Route exact path={ROUTE_NAMES.plan} element={<PlanPage />} />
                <Route
                  exact
                  path={ROUTE_NAMES.addRecruiter}
                  element={<AddRecruiter />}
                />
              </Route>
            </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PageRoutes;
