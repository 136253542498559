import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsLink45Deg, BsFileEarmarkText, BsCheckCircle } from "react-icons/bs";
import { MdPersonAddAlt } from "react-icons/md";

import styles from "./dashboard.module.scss";
import ScatterChart from "../../components/charts/scatterChart";
import DoughnutChart from "../../components/charts/DoughnutChart";
import RadarChart from "../../components/charts/radarChart";
import CardSetIcon from "../../assets/svgIcons/cardset-icon.svg";
import BellIcon from "../../assets/svgIcons/bell-icon.svg";
import NotificationCard from "../../components/notificationCard";
import engagementCardSetSvg from "../../assets/svgImages/engagement_card_set.svg";
import { getNotificationReq } from "../../api/notifications/notificationReq";
import Table from "./components/table";
import Table2 from "./components/table2";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../redux/actionTypes";
import HrActionTable from "./components/hrActionTable";
import StatsBoxOne from "../../components/statsBox/statsBoxOne";
import StatsBoxTwo from "../../components/statsBox/statsBoxTwo";

const Dashboard = () => {
  const navigation = useNavigate();
  const Dispatch = useDispatch();
  const currentPath = useLocation().pathname;
  const { companyProfile, notification, hrActions } = useSelector(
    (_state) => _state
  );
  const _roleId = 302 == localStorage.getItem("@role");

  const _hrActions = hrActions?.data || [];
  const eadminStats = companyProfile?.data;
  const loggedinDate = localStorage.getItem("@loggedDate");
  const [notifLists, setNotifLists] = useState();

  const data = {
    labels: ["Thing 1", "Thing 2", "Thing 3", "Thing 4", "Thing 5", "Thing 6"],
    datasets: [
      {
        label: "# of Votes",
        data: [2, 9, 3, 5, 2, 3],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    Dispatch({ type: SagaActions.GET_NOTIFICATION_LISTS });
    Dispatch({ type: SagaActions.ADD_HR_ACTIONS });
  }, []);

  const notificationLists = notification.data.slice(0, 4);

  const _onPress = ({ key, data }) => {
    const fun = {
      addCandidate: () => {
        navigation(
          `/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.CANDIDATE}/${ROUTE_NAMES.addCandidate}/${ROUTE_NAMES.basic_info_form}`
        );
      },
      addCardset: () => {
        navigation(
          `/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.cardSetsPage}/${ROUTE_NAMES.companyIntro}`
        );
      },
      addRecruiter: () => {
        navigation(
          `/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.myAccount}/${ROUTE_NAMES.addRecruiter}`
        );
      },
      report: () => {
        // navigation(`/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.report}`);
      },
      bell: () => {
        Dispatch({ type: SagaActions.SHOW_NOTIFICATIONS_MODAL });
      },
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.Dashboard} px-1`}>
      <ToastContainer />
      <div className={`d-flex justify-content-between`}>
        <div className={`d-flex justify-content-end`}>
          {/* <p className="sm-text x-light-text">Logged In</p>
          <p className="sm-text">:</p> */}
        </div>
        <div className={`d-flex justify-content-end pe-2`}>
          <p className="sm-text x-light-text">Logged In</p>
          <p className="sm-text">: {loggedinDate}</p>
        </div>
      </div>
      <div className={`${styles.container}`}>
        <Container>
          <Row>
            <Col md={12} lg={12} className="p-0 m-0">
              <div className="d-flex py-3">
                <StatsBoxTwo
                  label={"Job Offer Acceptance"}
                  status={"Accepted"}
                  numbers={eadminStats?.acceptanceRate || 0}
                  presentValue={eadminStats?.activeOfferedCandidatesCount || 0}
                  totalValue={eadminStats?.candidatesCount || 0}
                  desc={"(all time)"}
                  inPercentage={true}
                  inRupees={false}
                  // className="mx-2"
                  color={"#ff4d84"}
                />
                <StatsBoxTwo
                  label={"Candidates Joined"}
                  status={"Joined"}
                  numbers={eadminStats?.joinedPercentage || 0}
                  presentValue={eadminStats?.joinedCandidatesCount || 0}
                  totalValue={eadminStats?.candidatesCount || 0}
                  desc={"(all time)"}
                  inPercentage={true}
                  inRupees={false}
                  className="ms-2"
                  color={"#002C71"}
                />
                <div className={`${styles.card_box}`}>
                  <p className="fw-bold md-text">CardSets</p>
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={engagementCardSetSvg}
                      style={{ position: "absolute", width: 90 }}
                    />
                    <p
                      style={{
                        color: "#FF4D84",
                        fontSize: 25,
                        marginTop: 20,
                        marginRight: 3,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        justifyContent: "center",
                      }}
                    >
                      {eadminStats?.totalCardSets}
                    </p>
                  </div>
                  {/* <p className="x-sm-text mt-5">
                    Out of{" "}
                    <span className="secondary-color">{`${_cardSetTotalCount} `}</span>
                    Sets
                  </p> */}
                </div>
                {/* <StatsBoxOne
                  label={"Job Offers"}
                  numbers={eadminStats?.totalOffers || 0}
                  desc={""}
                  inPercentage={false}
                  inRupees={false}
                  className="mx-2"
                />
                <StatsBoxOne
                  label={"Candidates"}
                  numbers={eadminStats?.candidatesCount ||0}
                  desc={""}
                  inPercentage={false}
                  inRupees={false}
                  className="mx-2"
                />
                <StatsBoxOne
                  label={"Companies"}
                  numbers={eadminStats?.companyAssigned ||0}
                  desc={""}
                  inPercentage={false}
                  inRupees={false}
                  className="mx-2"
                />
                <StatsBoxOne
                  label={"Engagement Score"}
                  numbers={0}
                  desc={""}
                  inPercentage={false}
                  inRupees={false}
                  className="mx-2"
                />
                <StatsBoxOne
                  label={"CardSets"}
                  numbers={eadminStats?.totalCardSets ||0}
                  desc={""}
                  inPercentage={false}
                  inRupees={false}
                  className="mx-2"
                /> */}
              </div>
              {/* <div className="d-flex py-3">
                <StatsBoxOne
                  label={"Joiners"}
                  numbers={eadminStats?.acceptanceRate || 0}
                  desc={""}
                  presentValue={eadminStats?.acceptedCandidatesCount ||0}
                  totalValue={eadminStats?.candidatesCount ||0}
                  presentValueLabel="Joiners"
                  totalValueLabel="Out of"
                  inPercentage={true}
                  inRupees={false}
                  className="mx-2"
                />
                <StatsBoxOne
                  label={"Drop-outs"}
                  numbers={eadminStats?.declineRate ||0}
                  desc={``}
                  presentValue={eadminStats?.declinedCandidatesCount ||0}
                  totalValue={eadminStats?.candidatesCount ||0}
                  presentValueLabel="Drop-outs"
                  totalValueLabel="Out of"
                  inPercentage={true}
                  inRupees={false}
                  className="mx-2"
                />
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={7} className="p-0">
              {/* <div className={`${styles.col_card} bg-white p-3 my-1`}>
                <Row>
                  <Col md={7}>
                    <div className={`${styles.flex_1}`}>
                      <p className={`m-0 md-text b`}>Candidate Insights</p>
                      <p className={`xx-sm-text m-0 x-light-text `}>
                        *Data As Per Logged In Date
                      </p>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div
                      className={`${styles.flex_1} flex-row align-items-center mt-3`}
                    >
                      <label className={`x-sm-text me-3`}>Filter:</label>
                      <select
                        className={`mx-1 w-50 primary-color`}
                        id="option2"
                      >
                        <option value={""}>
                          <p className={`x-sm-text`}>All</p>
                        </option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <div className={`d-flex align-items-center my-1 mt-3`}>
                  <div className={`${styles.flex_1}`}>
                    <p className={`m-0 sm-text`}>
                      Sentiment-Journey Map <AiFillInfoCircle color="#dddddd" />
                    </p>
                    <p className={`${styles.x_small_text} m-0`}>
                      20 Total Candidates
                    </p>
                  </div>
                </div>
                <Row>
                  <Col xs={8}>
                    <div className={`${styles.experience_chart_block} my-2`}>
                      <ScatterChart />
                    </div>

                    <div
                      className={`d-flex align-items-center justify-content-center `}
                    >
                      <div
                        className={`d-flex align-items-center bullet-point-box`}
                      >
                        <h2
                          className={`bullet-point`}
                          style={{ color: "#20E19B" }}
                        >
                          &#8226;
                        </h2>
                        <p className={`x-sm-text b`}> Well Engagaged</p>
                      </div>
                      <div
                        className={`d-flex align-items-center bullet-point-box`}
                      >
                        <h2
                          className={`bullet-point`}
                          style={{ color: "#3A93F4" }}
                        >
                          &#8226;
                        </h2>
                        <p className={`x-sm-text b`}>Need Help</p>
                      </div>
                      <div
                        className={`d-flex align-items-center bullet-point-box`}
                      >
                        <h2
                          className={`bullet-point`}
                          style={{ color: "#FFB964" }}
                        >
                          &#8226;
                        </h2>
                        <p className={`x-sm-text b`}>Yet to Accept Offer</p>
                      </div>
                      <div
                        className={`d-flex align-items-center bullet-point-box`}
                      >
                        <h2
                          className={`bullet-point`}
                          style={{ color: "#FF8982" }}
                        >
                          &#8226;
                        </h2>
                        <p className={`x-sm-text b`}>At Risk</p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <p className={`md-text`}>Engagement Score</p>
                    <div className={`${styles.engagement_score_card}`}>
                      <p className={`sm-text`}>Your candidates</p>
                      <h3 className={`x-lg-text`}>84%</h3>
                    </div>
                    <div className={`${styles.engagement_score_card}`}>
                      <p className={`sm-text`}>Across Company</p>
                      <h3 className={`x-lg-text`}>79%</h3>
                    </div>
                  </Col>
                </Row>
              </div> */}
              <div className={`${styles.col_card} bg-white py-3 my-2`}>
                <div
                  className={`d-flex align-items-center my-1 justify-content-between px-3`}
                >
                  <div className={``}>
                    <p className={`m-0 md-text`}>
                      HR Actions <AiFillInfoCircle color="#dddddd" />
                    </p>
                  </div>
                  <label
                    className={`primary-color`}
                    onClick={() =>
                      navigation(
                        `/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.table}`
                      )
                    }
                  >
                    Show all
                  </label>
                </div>
                <div className={`${styles.table_card}`}>
                  {/* <Table dataLength={3} /> */}
                  {/* <Table2 dataLength={3} /> */}
                  <HrActionTable dataIs={_hrActions} />
                </div>
              </div>
            </Col>
            <Col md={12} lg={5} className="p-0 ps-1">
              {/* <div className={`${styles.col_card} bg-white p-2 px-3 my-1`}>
                <div className={`my-2`}>
                  <div className={`${styles.flex_1}`}>
                    <p className={`m-0 md-text`}>
                      Candidate Behavioral Insights
                    </p>
                    <p className={`xx-sm-text x-light-text m-0`}>
                      *Data As Per Logged In Date
                    </p>
                  </div>
                </div>
                <Row>
                  <Col lg={6} className={`py-2`}>
                    <div className={`${styles.doughnut_chart_container} mt-2`}>
                      <DoughnutChart />
                    </div>
                  </Col>
                  <Col>
                    <div
                      className={`${styles.flex_1} flex-row align-items-center`}
                    >
                      <label className={`x-sm-text`}>Filter:</label>
                      <select
                        className={`mx-1 w-50 primary-color`}
                        id="option3"
                      >
                        <option value={""}>
                          <p className={`x-sm-text`}>All</p>
                        </option>
                      </select>
                    </div>
                    <p>&nbsp;</p>
                    <div
                      className={`d-flex align-items-center bullet-point-box`}
                    >
                      <h2
                        className={`bullet-point`}
                        style={{ color: "#20E19B" }}
                      >
                        &#8226;
                      </h2>
                      <p className={`x-sm-text`}> Well Engagaged</p>
                    </div>
                    <div
                      className={`d-flex align-items-center bullet-point-box`}
                    >
                      <h2
                        className={`bullet-point`}
                        style={{ color: "#3A93F4" }}
                      >
                        &#8226;
                      </h2>
                      <p className={`x-sm-text`}>Need Help</p>
                    </div>
                    <div
                      className={`d-flex align-items-center bullet-point-box`}
                    >
                      <h2
                        className={`bullet-point`}
                        style={{ color: "#FFB964" }}
                      >
                        &#8226;
                      </h2>
                      <p className={`x-sm-text`}>Yet to Accept Offer</p>
                    </div>
                    <div
                      className={`d-flex align-items-center bullet-point-box`}
                    >
                      <h2
                        className={`bullet-point`}
                        style={{ color: "#FF8982" }}
                      >
                        &#8226;
                      </h2>
                      <p className={`x-sm-text`}>At Risk</p>
                    </div>
                  </Col>
                </Row>
                <Row className={`m-0`}>
                  <Col lg={6} className={`my-3`}>
                    <div className={`d-flex align-items-center my-1`}>
                      <div className={`${styles.flex_1}`}>
                        <p className={`m-0 sm-text `}>
                          Personality Map{" "}
                          <AiFillInfoCircle color="#dddddd" size={"18px"} />
                        </p>
                        <p className={`x-sm-text my-1 x-light-text`}>
                          20 Total Candidates
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <RadarChart data={data} />
                  </Col>
                </Row>
              </div> */}
              <div className={`${styles.col_card} bg-white my-2 pb-2`}>
                <div className={`row-center p-2`}>
                  <BsLink45Deg className={`me-2`} />
                  <p className={`md-text`}>Quick Links</p>
                </div>
                <div className={`${styles.v_divider}`}></div>
                <div className={`row-center mt-2 px-2`}>
                  <button
                    className={`row-center ${styles.quick_link_btn}`}
                    onClick={() => _onPress({ key: "addCandidate" })}
                  >
                    <MdPersonAddAlt color="#002c71" className={`mx-2`} />
                    <label className={`x-sm-text primary-color`}>
                      Add Candidates
                    </label>
                  </button>
                  <button
                    className={`row-center ${styles.quick_link_btn}`}
                    onClick={() => _onPress({ key: "addCardset" })}
                  >
                    <div className={`mx-2 ${styles.img_icon}`}>
                      <img src={CardSetIcon} />
                    </div>
                    <label className={`x-sm-text primary-color`}>
                      Add CardSet
                    </label>
                  </button>
                </div>
                <div className={`row-center px-2`}>
                  {!_roleId && 
                  <button
                    className={`row-center ${styles.quick_link_btn}`}
                    onClick={() => _onPress({ key: "addRecruiter" })}
                  >
                    <div className={`mx-2 ${styles.img_icon}`}>
                      <img src={CardSetIcon} />
                    </div>
                    <label className={`x-sm-text primary-color`}>
                      Add Recruiters
                    </label>
                  </button>}
                  {!_roleId && 
                  <button
                    className={`row-center ${styles.quick_link_btn}`}
                    onClick={() => _onPress({ key: "report" })}
                  >
                    <BsFileEarmarkText color="#002c71" className={`mx-2`} />
                    <label className={`x-sm-text primary-color`}>Reports</label>
                  </button>}
                </div>
              </div>
              <div className={`${styles.col_card} bg-white  my-2`}>
                {/* <div className={`row-center p-2`}>
                  <div className={`mx-2`}>
                    <img src={BellIcon} />
                  </div>
                  <p className={`md-text`}>Notification</p>
                </div> */}
                <div
                  className={`d-flex align-items-center my-1 justify-content-between px-3`}
                >
                  <div className={`d-flex flex-row align-items-center`}>
                    <div className={`mx-2`}>
                      <img src={BellIcon} />
                    </div>
                    <p className={`md-text`}>Notification</p>
                  </div>
                  <label
                    className={`primary-color`}
                    onClick={() => _onPress({ key: "bell" })}
                  >
                    Show all
                  </label>
                </div>
                <div className={`${styles.v_divider} x-light-text`}></div>
                <div className={`p-1`}>
                  {/* {[1, 2, 3, 4].map((item, idx) => (
                    <NotificationCard key={idx} />
                  ))} */}
                  <NotificationCard notifLists={notificationLists} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Dashboard;
