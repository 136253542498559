import React from "react";

import styles from "./activityIndicator.module.scss";

const ActivityIndicator = ({ size }) => {
  return (
    <div
      style={{
        height: size ? size + "px" : "25px",
        width: size ? size + "px" : "25px",
      }}
    >
      <div className={`${styles.lds_dual_ring}`}></div>
    </div>
  );
};

export default ActivityIndicator;
