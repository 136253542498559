import React, { useState } from "react";

import styles from "./contentStyles.module.scss";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import Container from "react-bootstrap/container";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useForm, SubmitHandler } from "react-hook-form";

import attachIcon from "../../../assets/svgIcons/attachment-icon.svg";
import { CandidateInfoType } from "../../../types/candidateTypes";
import dayjs from "dayjs";
import RenderIf from "../../../components/renderIf";
import UploadFile from "../../../components/uploadFileBlock";
import { fileUploadPaths } from "../../../constants/fileUploadPaths";
import { updateCandidateOfferDetailsReq } from "../../../api/candidateReq/candidateDetailsReq";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../../redux/actionTypes";
import "../../../prototypes";
import { RootState } from "../../../redux/store";

const PersonalContent = (props: {
  data?: CandidateInfoType;
  onUpdate: any;
}) => {
  const { data, onUpdate } = props;
  const Dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [offerLetterPath, setOfferLetterPath] = useState("");
  const { candidateCardsetList, cardset, candidates } = useSelector(
    (_state: RootState) => _state
  );

  const FileLinkComponent = (fileLink: any) => {
    const linkParts = fileLink.split("/");

    const lastName = linkParts[linkParts.length - 1];

    return lastName;
  };

  const _yearOfExp = data?.CandidateOffer?.yearOfExp || "";
  const _jobLevel = data?.CandidateOffer?.jobLevel || "";
  const _dateOfJoining = data?.CandidateOffer?.dateOfJoining || "";
  const _designation = data?.CandidateOffer?.designation || "";
  const _department = data?.CandidateOffer?.department || "";
  const _team = data?.CandidateOffer?.team || "";
  const _baseLocation = data?.CandidateOffer?.baseLocation || "";
  const _workLocation = data?.CandidateOffer?.workLocation || "";
  const _workModel = data?.CandidateOffer?.workModel || "";
  const _offerExpiryDate = data?.CandidateOffer?.offerExpiryDate || "";
  const _offerLetterURL = data?.CandidateOffer?.offerLetterURL || "";
  // const _offerLetterName =
  //   data?.CandidateOffer?.offerLetterURL
  //     .split("https://d14ntgkdxyoags.cloudfront.net/files/resources/")
  //     .slice(1)[0] || "";
  const _offerLetterName = FileLinkComponent(
    data?.CandidateOffer?.offerLetterURL
  );
  const _salaryCtcPA = data?.CandidateOffer?.salaryCtcPA || "";
  const _fixedComponentPA = data?.CandidateOffer?.fixedComponentPA || "";
  const _variableComponentPA = data?.CandidateOffer?.variableComponentPA || "";
  const _approxTakeHomePM = data?.CandidateOffer?.approxTakeHomePM || "";
  const _candidateStatus =
    candidates.selectedCandidatesDetails.CandidateOffer.offerStatus;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    values: {
      yearOfExp: data?.CandidateOffer?.yearOfExp.toString().split(".")[0] || "",
      monthOfExp:
        data?.CandidateOffer?.yearOfExp.toString().split(".")[1] || "",
      jobLevel: data?.CandidateOffer?.jobLevel || "",
      dateOfJoining:
        dayjs(data?.CandidateOffer?.dateOfJoining).format("YYYY-MM-DD") || "",
      designation: data?.CandidateOffer?.designation || "",
      department: data?.CandidateOffer?.department || "",
      team: data?.CandidateOffer?.team || "",
      baseLocation: data?.CandidateOffer?.baseLocation || "",
      workLocation: data?.CandidateOffer?.workLocation || "",
      workModel: data?.CandidateOffer?.workModel || "",
      offerExpiryDate:
        dayjs(data?.CandidateOffer?.offerExpiryDate).format("YYYY-MM-DD") || "",
      offerLetterURL: data?.CandidateOffer?.offerLetterURL || "",
      salaryCtcPA: data?.CandidateOffer?.salaryCtcPA || "",
      fixedComponentPA: data?.CandidateOffer?.fixedComponentPA || "",
      variableComponentPA: data?.CandidateOffer?.variableComponentPA || "",
      approxTakeHomePM: data?.CandidateOffer?.approxTakeHomePM || "",
    },
  });

  const updatePersonalDetails = async (formdata: any) => {
    try {
      if (!data?.Candidate?.candidateID) {
        setEditing(false);
        return;
      }
      const yearsOfExp = `${formdata?.yearOfExp}.${formdata?.monthOfExp}`;
      const personalDetailsBody = {
        yearOfExp: Number(yearsOfExp) || "",
        jobLevel: formdata?.jobLevel || "",
        dateOfJoining: formdata?.dateOfJoining || "",
        designation: formdata?.designation || "",
        department: formdata?.department || "",
        team: formdata?.team || "",
        baseLocation: formdata?.baseLocation || "",
        workLocation: formdata?.workLocation || "",
        workModel: formdata?.workModel || "",
        offerExpiryDate: formdata?.offerExpiryDate || "",
        offerLetterURL: offerLetterPath ? offerLetterPath : _offerLetterURL,
        salaryCtcPA: formdata?.salaryCtcPA || "",
        fixedComponentPA: formdata?.fixedComponentPA || "",
        variableComponentPA: formdata?.variableComponentPA || "",
        approxTakeHomePM: formdata?.approxTakeHomePM || "",
      };
      const res = await updateCandidateOfferDetailsReq(
        {
          candidateID: data?.Candidate?.candidateID,
        },

        personalDetailsBody
      );
      toast.success(res?.message);

      setEditing(false);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "personalContent",
          methodName: "updatePersonalDetails",
          type: "info",
        },
      });
      onUpdate();
    } catch (error: any) {
      setEditing(false);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "personalContent",
          methodName: "updatePersonalDetails",
          type: "error",
        },
      });
    }
  };

  return (
    <div className={`${styles.personalInfoForm}`}>
      <form
        onSubmit={handleSubmit((data) => {
          updatePersonalDetails(data);
        })}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 60,
          }}
        >
          {editing ? (
            <div>
              <button
                className={`sbmit_btn sm-text px-2`}
                type="submit"
                // onClick={updatePersonalDetails}
              >
                Update
              </button>
            </div>
          ) : (
            // <div
            //   className={`p-1`}
            //   style={{ cursor: "pointer" }}
            //   onClick={() => setEditing(true)}
            // >
            //   <MdOutlineModeEditOutline className={``} color="#3a93f4" />
            // </div>
            <button
              className={`${styles.edit_btn} md-text mt-2`}
              // style={{ cursor: "pointer" }}
              onClick={() =>
                setEditing(
                  _candidateStatus == "Joined"
                    ? false
                    : _candidateStatus == "Dropout"
                    ? false
                    : true
                )
              }
            >
              <MdOutlineModeEditOutline className={`me-2`} />
              Edit
            </button>
          )}
        </div>
        <Container>
          <Row className={`my-3`}>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Years of Expereince</p>
              {/* <label className={`md-text`}>{_yearOfExperience} Years</label> */}
              {/* {renderField("yearOfExp", "Years", editedData?.yearOfExp)} */}
              {editing ? (
                <div className={styles.yearInput}>
                  <div className={styles.yearExprs}>
                    <input
                      className={`md-text ${
                        errors?.yearOfExp?.message && "border-danger"
                      }`}
                      id="yearOfExp"
                      placeholder="YY"
                      {...register("yearOfExp", {
                        required: "yearOfExp required",
                        pattern: {
                          value: /^(?:[0-9]|[0][0-9]|[1-9][0-9])$/,
                          message: "Year can be 0 to 99",
                        },
                      })}
                    />
                    {errors?.yearOfExp?.message && (
                      <p className={`xx-sm-text text-danger`}>
                        {errors?.yearOfExp?.message}
                      </p>
                    )}
                  </div>
                  <div className={styles.yearExprs}>
                    <input
                      className={`md-text ${
                        errors?.monthOfExp?.message && "border-danger"
                      }`}
                      id="monthOfExp"
                      placeholder="MM"
                      {...register("monthOfExp", {
                        required: "monthOfExp required",
                        pattern: {
                          value: /^(?:[0-9]|[0][0-9]|[1][0-1])$/,
                          message: "Month can be 0 to 11",
                        },
                      })}
                    />
                    {errors?.monthOfExp?.message && (
                      <p className={`xx-sm-text text-danger`}>
                        {errors?.monthOfExp?.message}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <label className={`md-text`}>{_yearOfExp} Years</label>
              )}

              {/* {errors?.yearOfExp?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.yearOfExp?.message}
                </p>
              )} */}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Band/Job level</p>
              {/* <label className={`md-text`}>{_jobLevel}</label> */}
              {/* {renderField("jobLevel", "", editedData?.jobLevel)} */}
              {editing ? (
                // <input
                //   className={`md-text ${
                //     errors?.jobLevel?.message && "border-danger"
                //   }`}
                //   id="jobLevel"
                //   placeholder=""
                //   {...register("jobLevel", {
                //     required: "job level required",
                //   })}
                // />
                <select
                  className={`md-text ${
                    errors?.jobLevel?.message && "border-danger"
                  }`}
                  {...register("jobLevel", {
                    required: "jobLevel required",
                  })}
                >
                  <option value={""}>Select</option>
                  <option value={"JL 1"}>JL 1</option>
                  <option value={"JL 2"}>JL 2</option>
                  <option value={"JL 3"}>JL 3</option>
                  <option value={"JL 4"}>JL 4</option>
                  <option value={"JL 5"}>JL 5</option>
                  <option value={"JL 6"}>JL 6</option>
                  <option value={"JL 7"}>JL 7</option>
                  <option value={"JL 8"}>JL 8</option>
                  <option value={"JL 9"}>JL 9</option>
                  <option value={"JL 10"}>JL 10</option>
                  <option value={"JL 11"}>JL 11</option>
                  <option value={"JL 12"}>JL 12</option>
                </select>
              ) : (
                <label className={`md-text`}>{_jobLevel}</label>
              )}

              {errors?.jobLevel?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.jobLevel?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Date of Joining</p>
              {/* <label className={`md-text`}>
              {dayjs(_dateOfJoining).format("MMM DD,YYYY")}
            </label> */}
              {/* {renderField(
              "dateOfJoining",
              "",
              dayjs(editedData?.dateOfJoining).format("MMM DD,YYYY")
            )} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.dateOfJoining?.message && "border-danger"
                  }`}
                  id="dateOfJoining"
                  type="date"
                  // value={dayjs(_dateOfJoining).format("YYYY-MM-DD")}
                  // min={dayjs().add(1, "day").format("YYYY-MM-DD")}
                  {...register("dateOfJoining", {
                    required: "Date of joining required",
                  })}
                />
              ) : (
                <label className={`md-text`}>
                  {dayjs(_dateOfJoining).format("MMM DD,YYYY")}
                </label>
              )}

              {errors?.dateOfJoining?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.dateOfJoining?.message}
                </p>
              )}
            </Col>
            {/* <Col
            className={`${styles._col} ${styles.fields}`}
            sm={6}
            md={4}
            lg={2}
          >
            <p>&nbsp;</p>
            <button
              className={`${styles.button} btn btn-primary md-text`}
              onClick={handleEditUpdate}
            >
              {editing ? "Update" : "Edit"}
            </button>
          </Col> */}
          </Row>
          <div className={`border mb-1 border-light`}></div>
          <Row className={` mb-0`}>
            <Col className={`${styles._col}`}>
              <label className={`my-2 md-text`}>Offer Detail</label>
            </Col>
          </Row>
          <Row className={``}>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Designation</p>
              {/* <label className={`md-text`}>{_designation}</label> */}
              {/* {renderField("designation", "", editedData?.designation)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.designation?.message && "border-danger"
                  }`}
                  id="designation"
                  placeholder=""
                  {...register("designation", {
                    required: "Designation required",
                  })}
                />
              ) : (
                <label className={`md-text`}>{_designation}</label>
              )}

              {errors?.designation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.designation?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Department</p>
              {/* <label className={`md-text`}>{_department}</label> */}
              {/* {renderField("department", "", editedData?.department)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.department?.message && "border-danger"
                  }`}
                  id="department"
                  placeholder=""
                  {...register("department", {
                    required: "Department required",
                  })}
                />
              ) : (
                <label className={`md-text`}>{_department}</label>
              )}

              {errors?.department?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.department?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Team</p>
              {/* <label className={`md-text`}>{_team}</label> */}
              {/* {renderField("team", "", editedData?.team)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.team?.message && "border-danger"
                  }`}
                  id="team"
                  placeholder=""
                  {...register("team", {
                    required: "Team required",
                  })}
                />
              ) : (
                <label className={`md-text`}>{_team}</label>
              )}

              {errors?.team?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.team?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Base Location</p>
              {/* <label className={`md-text`}>{_baseLocation}</label> */}
              {/* {renderField("baseLocation", "", editedData?.baseLocation)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.baseLocation?.message && "border-danger"
                  }`}
                  id="baseLocation"
                  placeholder=""
                  {...register("baseLocation", {
                    required: "Base Location required",
                  })}
                />
              ) : (
                <label className={`md-text`}>{_baseLocation}</label>
              )}

              {errors?.baseLocation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.baseLocation?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Work Location</p>
              {/* <label className={`md-text`}>{_workLocation}</label> */}
              {/* {renderField("workLocation", "", editedData?.workLocation)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.workLocation?.message && "border-danger"
                  }`}
                  id="workLocation"
                  placeholder=""
                  {...register("workLocation", {
                    required: "Work Location required",
                  })}
                />
              ) : (
                <label className={`md-text`}>{_workLocation}</label>
              )}

              {errors?.workLocation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.workLocation?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Work Model</p>
              {/* <label className={`md-text`}>{_workModel}</label> */}
              {/* {renderField("workModel", "", editedData?.workModel)} */}
              {editing ? (
                // <input
                //   className={`md-text ${
                //     errors?.workModel?.message && "border-danger"
                //   }`}
                //   id="workModel"
                //   placeholder=""
                //   {...register("workModel", {
                //     required: "Work model required",
                //   })}
                // />
                <select
                  className={`md-text ${
                    errors?.workModel?.message && "border-danger"
                  }`}
                  id="workModel"
                  {...register("workModel", {
                    required: "Work model required",
                  })}
                >
                  <option value={""}>Select</option>
                  <option value={"Office"}>Office</option>
                  <option value={"Hybrid"}>Hybrid</option>
                  <option value={"Fully Virtual"}>Fully Virtual</option>
                </select>
              ) : (
                <label className={`md-text`}>{_workModel}</label>
              )}

              {errors?.workModel?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.workModel?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>{`Offer Expiry(Days)`}</p>
              {/* <label className={`md-text`}>{_offerExpiryDays}</label> */}
              {/* {renderField(
              "offerExpiryDate",
              "",
              // editedData?.offerExpiryDate
              dayjs(editedData?.offerExpiryDate).format("MMM DD,YYYY")
            )} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.offerExpiryDate?.message && "border-danger"
                  }`}
                  id="offerExpiryDate"
                  placeholder=""
                  type="date"
                  // min={dayjs().add(1, "day").format("YYYY-MM-DD")}
                  {...register("offerExpiryDate", {
                    required: "Offer expiry date required",
                  })}
                />
              ) : (
                <label className={`md-text`}>
                  {dayjs(_offerExpiryDate).format("MMM DD,YYYY")}
                </label>
              )}

              {errors?.offerExpiryDate?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.offerExpiryDate?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Offer Letter</p>
              {editing ? (
                <UploadFile
                  imagePath={offerLetterPath}
                  discription="Browse or Drag and Drop to file, Max 5mb"
                  path={fileUploadPaths.resources}
                  onUpload={(data: any) => {
                    setOfferLetterPath(data?.path || "");
                    // setEditedData({
                    //   ...editedData,
                    //   offerLetterURL: data?.path || "",
                    // });
                  }}
                />
              ) : (
                <RenderIf isShow={_offerLetterURL}>
                  <div className={`d-flex align-items-start`}>
                    <div className={`${styles.small_icon_box}`}>
                      <img src={attachIcon} />
                    </div>
                    <label className={`mb-0`}>{_offerLetterName}</label>
                  </div>
                </RenderIf>
              )}
              {/* <RenderIf isShow={_offerLetterURL}>
              <div className={`d-flex align-items-start`}>
                <div className={`${styles.small_icon_box}`}>
                  <img src={attachIcon} />
                </div>
                <label className={`mb-0`}> Revised and Uploaded</label>
              </div>
            </RenderIf> */}
            </Col>
          </Row>
          &nbsp;
          <div className={`border  mb-1 border-light`}></div>
          <Row className={`mb-0`}>
            <Col className={`${styles._col}`}>
              <label className={`my-2 md-text`}>Salary Detail</label>
            </Col>
          </Row>
          <Row className={``}>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>{`CTC (Annual)`}</p>
              {/* <label className={`md-text`}>
              <span>₹</span> {_salaryCtcPA}
            </label> */}
              {/* <span>₹</span> */}
              {/* {editing ? "" : <span>₹</span>} */}
              {/* {renderField("salaryCtcPA", "", editedData?.salaryCtcPA)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.salaryCtcPA?.message && "border-danger"
                  }`}
                  id="salaryCtcPA"
                  placeholder=""
                  {...register("salaryCtcPA", {
                    required: "Salary ctc PA required",
                  })}
                />
              ) : (
                <label className={`md-text`}>
                  {/* <span>₹</span> {_salaryCtcPA} */}
                  <span>₹</span>
                  {Number(_salaryCtcPA).formatRupee()}
                </label>
              )}

              {errors?.salaryCtcPA?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.salaryCtcPA?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>Fixed Component</p>
              {/* <label className={`md-text`}>
              <span>₹</span> {_fixedComponentPA}
            </label> */}
              {/* <span>₹</span> */}
              {/* {editing ? "" : <span>₹</span>} */}
              {/* {renderField("fixedComponentPA", "", editedData?.fixedComponentPA)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.fixedComponentPA?.message && "border-danger"
                  }`}
                  id="fixedComponentPA"
                  placeholder=""
                  {...register("fixedComponentPA", {
                    required: "fixed componentPA required",
                  })}
                />
              ) : (
                <label className={`md-text`}>
                  {/* <span>₹</span> {_fixedComponentPA} */}
                  <span>₹</span>
                  {Number(_fixedComponentPA).formatRupee()}
                </label>
              )}

              {errors?.fixedComponentPA?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.fixedComponentPA?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>{`Variables (Annual)`}</p>
              {/* <label className={`md-text`}>
              <span>₹</span> {_variableComponentPA}
            </label> */}
              {/* <span>₹</span> */}
              {/* {editing ? "" : <span>₹</span>} */}
              {/* {renderField(
              "variableComponentPA",
              "",
              editedData?.variableComponentPA
            )} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.variableComponentPA?.message && "border-danger"
                  }`}
                  id="variableComponentPA"
                  placeholder=""
                  {...register("variableComponentPA", {
                    required: "variable componentPA required",
                  })}
                />
              ) : (
                <label className={`md-text`}>
                  {/* <span>₹</span> {_variableComponentPA} */}
                  <span>₹</span>
                  {Number(_variableComponentPA).formatRupee()}
                </label>
              )}

              {errors?.variableComponentPA?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.variableComponentPA?.message}
                </p>
              )}
            </Col>
            <Col
              className={`${styles._col} ${styles.fields}`}
              sm={12}
              md={6}
              lg={3}
            >
              <p className={`sm-text x-light-text`}>{`~ Take-home(Monthly)`}</p>
              {/* <label className={`md-text`}>
              <span>₹</span> {_approxTakeHomePM}
            </label> */}
              {/* <span>₹</span> */}
              {/* {editing ? "" : <span>₹</span>} */}
              {/* {renderField("approxTakeHomePM", "", editedData?.approxTakeHomePM)} */}
              {editing ? (
                <input
                  className={`md-text ${
                    errors?.approxTakeHomePM?.message && "border-danger"
                  }`}
                  id="approxTakeHomePM"
                  placeholder=""
                  {...register("approxTakeHomePM", {
                    required: "Company Address required",
                  })}
                />
              ) : (
                <label className={`md-text`}>
                  {/* <span>₹</span> {_approxTakeHomePM} */}
                  <span>₹</span>
                  {Number(_approxTakeHomePM).formatRupee()}
                </label>
              )}

              {errors?.approxTakeHomePM?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.approxTakeHomePM?.message}
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </form>
    </div>
  );
};

export default PersonalContent;
