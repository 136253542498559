import { createSlice } from "@reduxjs/toolkit";
import { CardSetType } from "../../../types/cardsTpes";
import { ApiResType } from "../../../types/apiTypes";
import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { getCardSetCatReq } from "../../../api/cardsetReq/cardSetCategoryReq";
import { getAllCardSetReq } from "../../../api/cardsetReq/cardSetReq";

type payloadType = {
  data: CardSetType[];
  // recentData: CardSetType[];
};

export interface CardSetSlicetypes {
  error: boolean;
  message: string;
  data: CardSetType[];
  // recentData: CardSetType[];
  loading: boolean;
}

const initialState: CardSetSlicetypes = {
  error: false,
  message: "",
  data: [],
  // recentData: [],
  loading: false,
};

const CardSetSlice = createSlice({
  name: "CardSet",
  initialState: initialState,
  reducers: {
    AddCardSet: (
      state: CardSetSlicetypes,
      { payload }: { payload: payloadType }
    ) => {
      state.error = false;
      state.data = payload ? [...payload.data] : [];
      // state.recentData = payload ? [...payload.recentData] : [];
      state.loading = false;
      return state;
    },
    StartLoading: (state: CardSetSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: CardSetSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: CardSetSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const { AddCardSet, StartLoading, StopLoading, ClearData } =
  CardSetSlice.actions;

export default CardSetSlice.reducer;

export function* fetchCardSetList({ payload }: any) {
  interface ApiResType2 extends ApiResType {
    recent: any;
  }
  try {
    yield put(StartLoading());
    const res: ApiResType2 = yield call(getAllCardSetReq, payload);
    yield put(AddCardSet({ data: res?.data }));
  } catch (error: any) {
    yield put(AddCardSet({ data: [] }));
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}

export function* clearCardSetSlice() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
