import { createSlice } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { ApiResType } from "../../../types/apiTypes";
import { CardSetCatType } from "../../../types/cardsTpes";
import { getCardSetCatReq } from "../../../api/cardsetReq/cardSetCategoryReq";

type stateType = {
  loading: boolean;
  error: boolean;
  message: string;
  data: CardSetCatType[] | null;
};

const initialState: stateType = {
  loading: false,
  error: false,
  message: "",
  data: null,
};
const CardSetCategoriesSlice = createSlice({
  name: "CardSetCategories",
  initialState: initialState,
  reducers: {
    AddCardsetCatList: (
      state: stateType,
      { payload }: { payload: CardSetCatType[] | null }
    ) => {
      state.data = payload ? [...payload] : null;
      state.loading = false;
      return state;
    },
    StartLoading: (state: stateType) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: stateType) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: stateType) => {
      state = initialState;
      return state;
    },
  },
});

export const { AddCardsetCatList, StartLoading, StopLoading, ClearData } =
  CardSetCategoriesSlice.actions;

export default CardSetCategoriesSlice.reducer;

export function* fetchCardSetCatList({ payload }: any) {
  try {
    yield put(StartLoading());
    const res: ApiResType = yield call(getCardSetCatReq);
    yield put(AddCardsetCatList(res?.data));
  } catch (error: any) {
    yield put(AddCardsetCatList(null));
    yield put(StopLoading());
    // yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
  }
}

export function* clearCardSetCat() {
  try {
    yield put(ClearData());
  } catch (error) {
    yield put(ClearData());
  }
}
