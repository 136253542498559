import React from "react";

import styles from "./contentStyles.module.scss";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import scoreCircleSvg from "../../../assets/svgImages/score_progress_circle.svg";
import radarChartSvg from "../../../assets/svgImages/radar_chart.svg";
import engagementCardSetSvg from "../../../assets/svgImages/engagement_card_set.svg";
import progressCircleSvg from "../../../assets/svgImages/Progress_circle.svg";
import giftSvg from "../../../assets/svgImages/gift.svg";
import { CandidateEngagementType } from "../../../types/candidateTypes";
import RadarChart from "../../../components/charts/radarChart";
import { Col, Container, Row } from "react-bootstrap";

const EngagementContent = ({
  data,
}: {
  data?: CandidateEngagementType | null;
}) => {
  const _cardSetTotalCount = data?.cardSetTotalCount || 0;
  const _cardSetCompletedCount = data?.cardSetCompletedCount || 0;
  const _completedPercentage = data?.completedPercentage || 0;
  const _claimedRewards = data?.claimedRewards || 0;
  const _unClaimedRewards = data?.unClaimedRewards || 0;
  const _engagementScore = data?.engagementScore || 0;

  const categoryNames = data?.personalityMap?.map((item) => item.categoryName);

  const averageFeedback = data?.personalityMap?.map(
    (item) => item.averageFeedback
  );

  const datas = {
    // labels: [
    //   "Purpose and clarity",
    //   "Purpose and clarity",
    //   "Purpose and clarity",
    //   "Purpose and clarity",
    //   "Purpose and clarity",
    //   "Purpose and clarity",
    //   "Purpose and clarity",
    //   "Purpose and clarity",
    // ],
    labels: categoryNames,
    datasets: [
      {
        label: "# of Votes",
        // data: [2, 9, 3, 5, 2, 3, 7, 4],
        data: averageFeedback,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className={`${styles.EngagementContent} d-flex py-3`}>
      {/* <div className={`${styles.card_box}`}>
        <p className="sm-text text-center">Engagement Score</p>
        <div>
          <img src={scoreCircleSvg} />
        </div>
        <div style={{ height: "60%", width: "80%" }}>
          <CircularProgressbarWithChildren
            value={Number(_engagementScore)}
            styles={{
              path: {
                stroke: "#FF4D84",
              },
            }}
          >
            <div style={{ fontSize: 20 }}>
              <strong>{`${Math.floor(Number(_engagementScore))}%`}</strong>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div> */}
      <div className={`${styles.personality_map_card_box}`}>
        <p className="sm-text text-center">Personality Map</p>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            marginBottom: 15,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <img src={radarChartSvg} /> */}
          <RadarChart data={datas} />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={`${styles.card_box}`}>
          <p className="sm-text">CardSet</p>
          <div
            style={{
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={engagementCardSetSvg} style={{ position: "absolute" }} />
            <p
              style={{
                color: "#FF4D84",
                fontSize: 25,
                marginTop: 10,
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            >
              {_cardSetCompletedCount}
            </p>
          </div>
          <p className="x-sm-text mt-5">
            Out of{" "}
            <span className="secondary-color">{`${_cardSetTotalCount} `}</span>
            Sets
          </p>
        </div>
        {/* <div className={`${styles.card_box}`}>
        <p className="sm-text">Progress</p>
        <div style={{ height: "60%", width: "80%" }}>
          <CircularProgressbarWithChildren
            value={Number(_completedPercentage)}
            styles={{
              path: {
                stroke: "#3A93F4",
              },
            }}
          >
            <p className="x-sm-text">Completed</p>
            <div style={{ fontSize: 20, marginTop: -5 }}>
              <strong>{`${Math.floor(Number(_completedPercentage))}%`}</strong>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <p className="x-sm-text mt-3">
          <span className="secondary-color">6</span> more steps left
        </p>
      </div> */}
        <div className={`${styles.card_box}`}>
          <p className="sm-text">Rewards</p>
          <div>
            <img src={giftSvg} />
          </div>
          <p className="x-sm-text">
            Claimed:{" "}
            <span className="secondary-color lg-text">{_claimedRewards}</span>
          </p>
          <p className="x-sm-text">
            Unclaimed:{" "}
            <span className="secondary-color lg-text">{_unClaimedRewards}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EngagementContent;
