import { AxiosError } from "axios";
import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { checkTokenValidation } from "../auth/tokenReq";

export const addRecruiterReq = async (data?: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": recruiterReq->addRecruiterReq " + t
    );
  try {
    await checkTokenValidation();

    const body = {
      countryCode: data?.countryCode,
      TimeZone: data?.TimeZone,
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      designation: data?.designation,
      companyID: data?.companyID,
      mobile: data?.mobile,
      password: data?.password,
      role: data?.role,
    };
    const res = await authAxios.post(`${ApiKey.addRecruiterKey}`, body);
    //  logfun(res, "res");
    const resData = res.data;
    const _msg = resData?.message || "Recruiter added*";
    const _data = {};
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateRecruiterReq = async (data?: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": recruiterReq->updateRecruiterReq " + t
    );
  try {
    await checkTokenValidation();

    const body = {
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      userID: data?.userID,
      mobileNumber: data?.mobileNumber,
    };
    const res = await authAxios.patch(`${ApiKey.updateRecruiterKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData?.message || "Recruiter updated*";
    const _data = {};
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const getRecruiterReq = async (data?: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": recruiterReq->getRecruiterReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.getRecruiterKey}`);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _data = resData?.data?.map((item: any) => ({
      employeeID: item?.employeeID || "",
      firstName: item?.firstName || "",
      lastName: item?.lastName || "",
      designation: item?.designation || "",
      email: item?.email || "",
      mobileNumber: item?.mobileNumber || "",
      workLocation: item?.workLocation || "",
      companyID: item?.companyID || "",
      userID: item?.userID || "",
      status: item?.status || "",
      // ------These fields added to get editable table row
      isEditing: false,
    }));
    return { error: false, data: _data, message: "", errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const getRecruiterByIdReq = async (data?: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": recruiterReq->getRecruiterByIdReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(
      `${ApiKey.getRecruiterByIdKey}/${data?.id}`
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _data = {};
    return { error: false, data: _data, message: "", errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const deleteRecruiterByIdReq = async (data?: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": recruiterReq->deleteRecruiterByIdReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.delete(
      `${ApiKey.deleteRecruiterKey}/${data?.id}`
    );
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData?.message || "Deleted successfully*";
    const _data = {};
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    const aError = err as AxiosError;
    let error;
    let errorCode;
    if (aError?.response?.status) errorCode = aError?.response?.status;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw {
      error: true,
      data: "",
      message: "",
      errorMsg: error,
      errorCode: errorCode,
    };
  }
};
