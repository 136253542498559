import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { checkTokenValidation } from "../auth/tokenReq";

type NotificationsType = {
  notificationID: number;
  title: string;
  notificationMessage: string;
};

type notifyType = {
  userNotificationID: number;
  status: string;
  userID: number;
  notificationID: number;
  createdAt: string;
  title: string;
  notificationMessage: string;
};

export const getNotificationReq = async () => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": contactUsReq->getContactUsReq " + t
    );
  try {
    await checkTokenValidation();
    const res = await authAxios.get(`${ApiKey.notificationList}`);
    //  logfun(res.data, "res");
    const resData = res?.data?.data || [];
    const msg = res?.data?.message;

    const _data = resData?.map((item: any) => ({
      userNotificationID: item?.userNotificationID || 0,
      status: item?.status || "",
      userID: item?.userID || 0,
      notificationID: item?.notificationID || 0,
      createdAt: item?.createdAt || new Date(),
      title: item?.Notification?.title || "",
      notificationMessage: item?.Notification?.notificationMessage || "",
    }));
    return { error: false, data: _data, message: msg || "", errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
