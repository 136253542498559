import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import axios from "axios";
import { CardSetCatType } from "../../types/cardsTpes";
import { checkTokenValidation } from "../auth/tokenReq";
import defaultCardets from "../../pages/candidate/constant/defaultCardsets1.json";

type cardsType = {
  cardTitle: string;
  cardOrder: number;
  cardImage: string;
  cardDescription: string;
  cardLinkURL: string;
};

export const addCardSetReq = async (data: {
  categoryID: number;
  cardSetName: string;
  isActive: number;
  cards: cardsType[];
}) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->addCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      categoryID: data?.categoryID,
      cardSetName: data?.cardSetName,
      isActive: data?.isActive,
      cards: data?.cards,
    };
    const res = await authAxios.post(`${ApiKey.addCardSetKey}`, body);
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {
      categoryID: resData?.categoryID || "",
      categoryName: resData?.categoryName || "",
      cardSetURL: resData?.cardSetURL || "",
      categoryDesc: resData?.categoryDesc || "",
      companyID: resData?.companyID || "",
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const addCEPCardSetReq = async (data: {
  categoryID: number;
  cardSetName: string;
  isActive: number;
  cards: cardsType[];
  companyId: number;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->addCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      categoryID: data?.categoryID,
      cardSetName: data?.cardSetName,
      isActive: data?.isActive,
      cards: data?.cards,
      companyID: data?.companyId,
    };
    const res = await authAxios.post(`${ApiKey.addCardSetKey}`, body);
    logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {
      categoryID: resData?.categoryID || "",
      categoryName: resData?.categoryName || "",
      cardSetURL: resData?.cardSetURL || "",
      categoryDesc: resData?.categoryDesc || "",
      companyID: resData?.companyID || "",
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};


export const addNewCardToCardSetReq = async (
  params: {
    cardSetID: number;
  },
  data: {
    cardTitle: string;
    cardOrder: number;
    cardImage: string;
    cardDescription: string;
    cardLinkURL: string;
    categoryID: number;
    cardHeading: string;
  }
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->addNewCardToCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      cardTitle: data?.cardTitle || "",
      cardOrder: data?.cardOrder || "",
      cardImage: data?.cardImage || "",
      cardHeading: data?.cardHeading || "",
      cardDescription: data?.cardDescription || "",
      cardLinkURL: data?.cardLinkURL || "",
      categoryID: data?.categoryID || "",
    };
    const res = await authAxios.post(
      `${ApiKey.addNewCardToCardSetKey}/${params?.cardSetID}/card`,
      body
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.card || [];
    const _data = {
      cardID: resData?.cardID || "",
      cardOrder: resData?.cardOrder || "",
      cardTitle: resData?.cardTitle || "",
      cardImage: resData?.cardImage || "",
      cardHeading: resData?.cardHeading || "",
      cardDescription: resData?.cardDescription || "",
      cardLinkURL: resData?.cardLinkURL || "",
      cardSetID: resData?.cardSetID || "",
      categoryID: resData?.categoryID || "",
      companyID: resData?.companyID || "",
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(err, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const addNewCardToCEPCardSetReq = async (
  params: {
    cardSetID: number;
  },
  data: {
    cardTitle: string;
    cardOrder: number;
    cardImage: string;
    cardDescription: string;
    cardLinkURL: string;
    categoryID: number;
    cardHeading: string;
    companyID: number;
  }
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->addNewCardToCEPCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      cardTitle: data?.cardTitle || "",
      cardOrder: data?.cardOrder || "",
      cardImage: data?.cardImage || "",
      cardHeading: data?.cardHeading || "",
      cardDescription: data?.cardDescription || "",
      cardLinkURL: data?.cardLinkURL || "",
      categoryID: data?.categoryID || "",
      companyID: data?.companyID || "",
    };
    const res = await authAxios.post(
      `${ApiKey.addNewCardToCardSetKey}/${params?.cardSetID}/card`,
      body
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.card || [];
    const _data = {
      cardID: resData?.cardID || "",
      cardOrder: resData?.cardOrder || "",
      cardTitle: resData?.cardTitle || "",
      cardImage: resData?.cardImage || "",
      cardHeading: resData?.cardHeading || "",
      cardDescription: resData?.cardDescription || "",
      cardLinkURL: resData?.cardLinkURL || "",
      cardSetID: resData?.cardSetID || "",
      categoryID: resData?.categoryID || "",
      companyID: resData?.companyID || "",
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(err, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

type getCardSetReqPropType = {
  categoryID: number;
};

type cardSetType = {
  cardSetID: number;
  cardSetName: string;
  cardSetURL: string;
  isActive: boolean;
  categoryID: number;
  companyID: number;
};

export const getCardSetByCatReq = async (data?: getCardSetReqPropType) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetReq->getCardSetByCatReq " + t
    );

  //  logfun(data, "data--------");
  try {
    await checkTokenValidation();

    const res = await authAxios.get(
      `${ApiKey.getCardSetByCatKey}/${data?.categoryID}`
    );

    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = resData?.map((itm: cardSetType) => ({
      cardSetID: itm?.cardSetID || "",
      cardSetName: itm?.cardSetName || "",
      cardSetURL: itm?.cardSetURL || "",
      isActive: itm?.isActive || "",
      categoryID: itm?.categoryID || "",
      companyID: itm?.companyID || "",
    }));

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

type getCardSetByIdReqPropType = {
  cardSetID: number | string;
};

type CardType = {
  cardID: number;
  cardOrder: string;
  cardTitle: string;
  cardImage: string;
  cardHeading: string;
  cardDescription: string;
  cardLinkURL: string;
  cardSetID: number;
  categoryID: number;
  companyID: number;
};

type CardSetType = {
  cardSetID: number;
  cardSetName: string;
  cardSetURL: string;
  isActive: boolean;
  categoryID: number;
  companyID: number;
  CardSetCategory: {
    categoryID: number;
    categoryName: string;
    categoryDesc: string;
    cardSetURL: string;
  };
  Cards: CardType[];
};

export const getCardSetByIdReq = async (data?: getCardSetByIdReqPropType) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetReq->getCardSetByIdReq " + t
    );

  //  logfun(data, "data--------");
  try {
    await checkTokenValidation();

    const res = await authAxios.get(
      `${ApiKey.getCardSetByIdKey}/${data?.cardSetID}`
    );

    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {
      cardSetID: resData?.cardSetID || "",
      cardSetName: resData?.cardSetName || "",
      cardSetURL: resData?.cardSetURL || "",
      isActive: resData?.isActive || "",
      categoryID: resData?.categoryID || "",
      companyID: resData?.companyID || "",
      cardSetCategory: {
        categoryID: resData?.CardSetCategory?.categoryID || "",
        categoryName: resData?.CardSetCategory?.categoryName || "",
        categoryDesc: resData?.CardSetCategory?.categoryDesc || "",
      },
      cards: resData?.Cards?.map((item: CardType) => ({
        cardID: item?.cardID || "",
        cardOrder: item?.cardOrder || "",
        cardTitle: item?.cardTitle || "",
        cardImage: item?.cardImage || "",
        cardHeading: item?.cardHeading || "",
        cardDescription: item?.cardDescription || "",
        cardLinkURL: item?.cardLinkURL || "",
        cardSetID: item?.cardSetID || "",
        categoryID: item?.categoryID || "",
        companyID: item?.companyID || "",
      })),
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const getAllCardSetReq = async (params: { companyID: number }) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetReq->getAllCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const res = await authAxios.get(
      `${ApiKey.getAllCardSetKey}?companyID=${params}`
    );
    //  logfun(res?.data, "res");

    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = resData?.map((itm: CardSetType) => ({
      cardSetID: itm?.cardSetID || "",
      cardSetName: itm?.cardSetName || "",
      cardSetURL: itm?.cardSetURL || "",
      isActive: itm?.isActive || "",
      categoryID: itm?.categoryID || "",
      companyID: itm?.companyID || "",
      cardSetCategory: {
        categoryID: itm?.CardSetCategory?.categoryID || "",
        categoryName: itm?.CardSetCategory?.categoryName || "",
        categoryDesc: itm?.CardSetCategory?.categoryDesc || "",
        cardSetURL: itm?.CardSetCategory?.cardSetURL || "",
      },
      cards: itm?.Cards?.map((item: CardType) => ({
        cardID: item?.cardID || "",
        cardOrder: item?.cardOrder || "",
        cardTitle: item?.cardTitle || "",
        cardImage: item?.cardImage || "",
        cardHeading: item?.cardHeading || "",
        cardDescription: item?.cardDescription || "",
        cardLinkURL: item?.cardLinkURL || "",
        cardSetID: item?.cardSetID || "",
        categoryID: item?.categoryID || "",
        companyID: item?.companyID || "",
      })),
    }));

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

type defaultCardsType = {
  weekNumber: number;
  cardSetOrder: number;
  companyID: number;
  cardSetID: number;
};

export const addDefaultCardSetReq = async (defaultCardSets: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetReq->addDefaultCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const body = defaultCardSets;
    const res = await authAxios.post(`${ApiKey.addDefaultCardSetKey}`, body);
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.DefaultCardSets || [];
    const _data = {
      // cardID: resData?.cardID || "",
      // cardOrder: resData?.cardOrder || "",
      // cardTitle: resData?.cardTitle || "",
      // cardImage: resData?.cardImage || "",
      // cardHeading: resData?.cardHeading || "",
      // cardDescription: resData?.cardDescription || "",
      // cardLinkURL: resData?.cardLinkURL || "",
      // cardSetID: resData?.cardSetID || "",
      // categoryID: resData?.categoryID || "",
      // companyID: resData?.companyID || "",
    };

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(err, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const getDefaultCardSetReq = async (data: { companyID: number }) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetReq->getDefaultCardSetReq " + t
    );

  try {
    await checkTokenValidation();
    const companyID = data?.companyID;

    const res = await authAxios.get(
      `${ApiKey.getDefaultCardSetKey}/${companyID}`
    );
    logfun(res?.data, "res");

    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res?.data?.DefaultCardSets || [];
    // const _data = resData?.map((itm: any) => ({
    //   cardSetID: itm?.cardSetID || "",
    //   cardSetName: itm?.cardSetName || "",
    //   cardSetURL: itm?.cardSetURL || "",
    //   isActive: itm?.isActive || "",
    //   categoryID: itm?.categoryID || "",
    //   companyID: itm?.companyID || "",
    //   cardSetCategory: {
    //     categoryID: itm?.CardSetCategory?.categoryID || "",
    //     categoryName: itm?.CardSetCategory?.categoryName || "",
    //     categoryDesc: itm?.CardSetCategory?.categoryDesc || "",
    //     cardSetURL: itm?.CardSetCategory?.cardSetURL || "",
    //   },
    //   cards: itm?.Cards?.map((item: any) => ({
    //     cardID: item?.cardID || "",
    //     cardOrder: item?.cardOrder || "",
    //     cardTitle: item?.cardTitle || "",
    //     cardImage: item?.cardImage || "",
    //     cardHeading: item?.cardHeading || "",
    //     cardDescription: item?.cardDescription || "",
    //     cardLinkURL: item?.cardLinkURL || "",
    //     cardSetID: item?.cardSetID || "",
    //     categoryID: item?.categoryID || "",
    //     companyID: item?.companyID || "",
    //   })),
    // }));
    const _data = resData.map((itm: any) => ({
      weekNumber: itm?.weekNumber || 0,
      cardSetOrder: Number(itm?.cardSetOrder) || "",
      cardSetID: itm?.cardSetID || "",
    }));

    //  logfun(_data, "_data");
    // console.log("_data",_data)

    return { error: false, data: _data, message: "", errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCardSetReq = async (
  params: {
    cardSetID: string | number;
  },
  data: {
    categoryID: number;
    cardSetName: string;
    cards: any;
  }
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->updateCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      categoryID: data?.categoryID,
      cardSetName: data?.cardSetName,
      cards: data?.cards,
    };
    const res = await authAxios.put(
      `${ApiKey.updateCardSetKey}/${params?.cardSetID}`,
      body
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {};

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCEPCardSetReq = async (
  params: {
    cardSetID: string | number;
  },
  data: {
    categoryID: number;
    cardSetName: string;
    cards: any;
    companyID: number;
  }
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->updateCEPCardSetReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      categoryID: data?.categoryID,
      cardSetName: data?.cardSetName,
      cards: data?.cards,
      companyID: data?.companyID,
    };
    const res = await authAxios.put(
      `${ApiKey.updateCardSetKey}/${params?.cardSetID}`,
      body
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {};

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCardSetCardReq = async (
  params: {
    cardSetID: string | number;
    cardID: string | number;
  },
  data: {
    cardTitle: string;
    cardImage: string;
    cardDescription: string;
    cardHeading: string;
    cardLinkURL: string;
  }
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->updateCardSetCardReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      cardTitle: data?.cardTitle,
      cardImage: data?.cardImage,
      cardDescription: data?.cardDescription,
      cardHeading: data?.cardHeading,
      cardLinkURL: data?.cardLinkURL,
    };
    const res = await authAxios.patch(
      `${ApiKey.updateCardSetCardKey}/${params?.cardSetID}/card/${params?.cardID}`,
      body
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {};

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
export const updateCEPCardSetCardReq = async (
  params: {
    cardSetID: string | number;
    cardID: string | number;
  },
  data: {
    cardTitle: string;
    cardImage: string;
    cardDescription: string;
    cardHeading: string;
    cardLinkURL: string;
    companyID: number;
  }
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->updateCardSetCardReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      cardTitle: data?.cardTitle,
      cardImage: data?.cardImage,
      cardDescription: data?.cardDescription,
      cardHeading: data?.cardHeading,
      cardLinkURL: data?.cardLinkURL,
      companyID: data?.companyID,
    };
    const res = await authAxios.patch(
      `${ApiKey.updateCardSetCardKey}/${params?.cardSetID}/card/${params?.cardID}`,
      body
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {};

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const deleteCardSetCardReq = async (params: {
  cardSetID: string | number;
  cardID: string | number;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->deleteCardSetCardReq " + t
    );

  try {
    await checkTokenValidation();

    const res = await authAxios.delete(
      `${ApiKey.deleteCardSetCardKey}/${params?.cardSetID}/card/${params?.cardID}`
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {};

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const deleteCEPCardSetCardReq = async (
  params: {
    cardSetID: string | number;
    cardID: string | number;
  },
  data: {
    companyID: string;
  }
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": cardSetCatReq->deleteCardSetCardReq " + t
    );

  try {
    await checkTokenValidation();

    const body = {
      companyID: data?.companyID,
    };

    const res = await authAxios.delete(
      `${ApiKey.deleteCardSetCardKey}/${params?.cardSetID}/card/${params?.cardID}`,
      {data: body}
    );
    //  logfun(res?.data, "res");
    const _resMsg = res?.data?.message || "";
    const resData = res.data?.data || [];
    const _data = {};

    //  logfun(_data, "_data");

    return { error: false, data: _data, message: _resMsg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (axios.isCancel(err)) console.log("Request canceled", err.message);
    else if (err.response)
      error = err?.response?.data?.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
