import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";

const NOTIFICATION_API_KEY =
  "QUl6YVN5Q1ZJNjZOZFFKTDVQUkF1Vk1mbFBKanpFNjVQclNJR0pB" ||
  process.env.REACT_APP__NOTIFICATION_API_KEY;
const NOTIFICATION_VAPID_KEY =
  "QlBDWXl6UHpDdlpidFVUeUk4NHNPVXp3VHlJcWhkUVBneU9LWWQ1ZldIVmVRZnctcDFfYUNaQ25ZZmkxM1F5X3VSeVpBUWVuSkd6eVJnVk5xc3NNUTc0" ||
  process.env.REACT_APP__NOTIFICATION_VAPID_KEY;

const firebaseConfig = {
  apiKey: atob(NOTIFICATION_API_KEY),
  authDomain: "peoplex-android.firebaseapp.com",
  projectId: "peoplex-android",
  storageBucket: "peoplex-android.appspot.com",
  messagingSenderId: "1072042456423",
  appId: "1:1072042456423:web:16cdee84dbe6e65fa7abc2",
  measurementId: "G-X02SQKHZED",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const requestPermission = async () => {
  try {
    console.log("Requesting permission...");
    const supported = await isSupported();

    if (!supported) return;
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        getCurrentToken();
      } else {
        console.log("dont have permissions");
      }
    });
    navigator?.serviceWorker?.addEventListener("message", (message) =>
      console.log(message, "serviceWorker - addEventListener")
    );
  } catch (error) {
    console.log(error, "error in requestPermission for notification");
  }
};

export const getCurrentToken = async () => {
  try {
    const tokenIs = await getToken(messaging, {
      vapidKey: atob(NOTIFICATION_VAPID_KEY),
    });
    console.log(tokenIs, "currentToken");

    localStorage.setItem("@deviceToken", tokenIs || "");
    return tokenIs;
  } catch (error) {
    console.log("can't get token");
  }
};

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    (async () => {
      const supported = await isSupported();
      if (!supported) return reject("");
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    })();
  });
