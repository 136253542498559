import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

import styles from "./howItWorks.module.scss";
import { ROUTE_NAMES } from "../../constants/routeNames";

const menuListGroupOne = [
  {
    label: "Welcome",
    icon: "",
    route: ROUTE_NAMES.welcome,
  },
  {
    label: "Issue offer letters",
    icon: "",
    route: "#",
  },
  {
    label: "Candidates",
    icon: "",
    route: "#",
  },
  {
    label: "Card sets",
    icon: "",
    route: "#",
  },
];

const HowItWorksPage = () => {
  const navigation = useNavigate();
  const [activeMenu, setactiveMenu] = useState("Welcome");

  const _onPress = ({ key, data }) => {
    const fun = {
      null: (data) => {},
      menuBtn: (data) => {
        setactiveMenu(data?.label);
        navigation(data?.route);
      },
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.HowItWorks} h-100`}>
      <div className="d-flex h-100">
        <div className={`${styles.nav_bar_block} h-100`}>
          &nbsp;
          <p></p>
          &nbsp;
          {menuListGroupOne?.map((opt, idx) => {
            const active = activeMenu === opt.label;
            return (
              <button
                className={`${styles.opt_box} ${active && styles.active}`}
                key={idx}
                onClick={() => _onPress({ key: "menuBtn", data: opt })}
              >
                <div className="d-flex align-items-center">
                  {/* replace this line to show check mark <div className={`icon-box ${active && "checked"}`}>  */}
                  <div className={`${styles.icon_box}`}>
                    {/* {!active ? <FaCheck size={9} color={"white"} /> : null} */}
                  </div>
                  <label className={`md-text ${!active && "light-text"}`}>
                    {opt.label}
                  </label>
                </div>
              </button>
            );
          })}
        </div>
        <div className={`${styles.content_block} h-100`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default HowItWorksPage;
