import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { IoClose, IoChevronDown, IoChevronUp } from "react-icons/io5";

import styles from "./cardSetsPage.module.scss";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { DropdownMenu } from "../../components/dropdownMenu";
import companyIcon from "../../assets/svgIcons/company.svg";
import { leftMenuData } from "./constants/leftMenuData";
import { useDispatch, useSelector } from "react-redux";
import EmptyCardSet from "./components/emptyCardSet";
import { SagaActions } from "../../redux/actionTypes";

const CardSetsPage = () => {
  const navigation = useNavigate();
  const Dispatch = useDispatch();
  const { cardset, cardsetCat, cepCompanyProfile, profile } = useSelector(
    (_state) => _state
  );
  const selectedCompany = cepCompanyProfile?.companyID;
  const roleID = localStorage.getItem("@role");
  const _isCepAdmin = 102 == Number(roleID);

  const _profileCompanyID = profile?.data?.companyID || null;

  const [activeMenu, setactiveMenu] = useState("");
  const [runInitialRoute, setrunInitialRoute] = useState(true);
  const [cardSetList, setcardSetList] = useState({});

  const _onPress = ({ key, data }) => {
    const fun = {
      null: (data) => {},
      menuBtn: (data) => {
        setactiveMenu(data?.label);
        navigation(data?.route);
      },
    };
    fun[key](data);
  };

  const groupCardsetFun = () => {
    const result = cardset?.data?.reduce((r, a) => {
      r[a?.cardSetCategory?.categoryName] =
        r[a?.cardSetCategory?.categoryName] || [];
      r[a?.cardSetCategory?.categoryName].push(a);
      return r;
    }, {});
    console.log(result, "cardset groups");
    setcardSetList({ ...result });
    if (!runInitialRoute) return;
    const initialCardSet = cardset?.data[0];
    if (!initialCardSet) return;
    setactiveMenu(initialCardSet?.cardSetCategory?.categoryName);
    navigation(ROUTE_NAMES.companyIntro, { state: initialCardSet });
    setrunInitialRoute(false);
  };

  useEffect(() => {
    if (cardset?.data?.length < 1) {
      setcardSetList({});
      return
    }
    groupCardsetFun();
  }, [cardset?.data]);

  useEffect(() => {
    Dispatch({
      type: SagaActions.GET_CARDSET,
      payload: _isCepAdmin ? selectedCompany : _profileCompanyID
    });
    Dispatch({ type: SagaActions.GET_CARDSET_CAT });
  }, [selectedCompany]);

  const ListItemBlock = ({ item }) => {
    return (
      <button
        className={`${styles.list_item_block}`}
        onClick={() => navigation(ROUTE_NAMES.companyIntro, { state: item })}
      >
        <p className={`x-sm-text`}>{item?.cardSetName}</p>
      </button>
    );
  };

  return (
    <div className={`${styles.CardSetsPage} h-100`}>
      <div className={`d-flex h-100`}>
        <div className={`${styles.nav_bar_block} h-100`}>
          &nbsp;
          <p></p>
          &nbsp;
          {Object.keys(cardSetList)?.map((catName, idx) => (
            <DropdownMenu
              key={idx}
              headerClass={({ showList }) =>
                `${styles.dropdown_header_block} ${
                  showList && styles.active_header
                }`
              }
              expand={activeMenu === catName}
              onActiveClass={""}
              headerComponent={({ showList }) => (
                <div className={`d-flex align-items-center `}>
                  <div className={`${styles.small_icon_box} me-2`}>
                    {cardSetList[catName][0].cardSetCategory?.cardSetURL && (
                      <img
                        src={
                          cardSetList[catName][0].cardSetCategory?.cardSetURL
                        }
                      />
                    )}
                  </div>
                  <p>{catName}</p>
                </div>
              )}
              listData={cardSetList[catName] || []}
              renderListItem={({ item }) => <ListItemBlock item={item} />}
              onClick={() => setactiveMenu(catName)}
            />
          ))}
          {/* {leftMenuData?.map((menu, idx) => (
            <DropdownMenu
              key={idx}
              headerClass={({ showList }) =>
                `${styles.dropdown_header_block} ${
                  showList && styles.active_header
                }`
              }
              expand={idx === 0}
              onActiveClass={""}
              headerComponent={({ showList }) => (
                <div className={`d-flex align-items-center `}>
                  <div className={`${styles.small_icon_box} me-2`}>
                    <img src={menu.icon} />
                  </div>
                  <p>{menu.label}</p>
                </div>
              )}
              listData={menu?.subMenu || []}
              renderListItem={({ item }) => <ListItemBlock item={item} />}
            />
          ))} */}
        </div>
        {Object.keys(cardSetList)?.length > 0 ? (
          <div className={`${styles.content_block} h-100`}>
            <Outlet />
          </div>
        ) : (
          <div className={`${styles.content_block} h-100`}>
            <EmptyCardSet />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardSetsPage;
