import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/container";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";

import styles from "./allCandidatePage.module.scss";
import LeftSection from "./sections/leftSection";
import RightSection from "./sections/rightSection";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../redux/actionTypes";
import RenderIf from "../../components/renderIf";
import { Spinner } from "react-bootstrap";
import { RootState } from "../../redux/store";
import { CandidateType } from "../../types/candidateTypes";

const AllCandidatePage = () => {
  const Dispatch = useDispatch();
  const { candidates } = useSelector((_state: RootState) => _state);

  const _candidatesLoader = candidates?.loading;

  const onCandidateSelect = (cand: CandidateType) => {
    Dispatch({
      type: SagaActions.SELECTED_CANDIDATE_ID,
      payload: {
        selectedCandidateId: cand?.candidateID,
      },
    });
  };

  useEffect(() => {
    Dispatch({ type: SagaActions.GET_CANDIDATES });
    Dispatch({ type: SagaActions.CLEAR_CANDIDATE_FORM });
    Dispatch({ type: SagaActions.CLEAR_CANDIDATE_CARDSET_FORM });
  }, []);

  return (
    <div className={`${styles.AllCandidatePage}`}>
      <Container>
        <RenderIf isShow={_candidatesLoader}>
          <div className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" variant="primary" size="sm" />
          </div>
        </RenderIf>
        <RenderIf isShow={!_candidatesLoader}>
          <Row>
            <Col md={12} lg={4} className={``}>
              <div className={`${styles.left_col}`}>
                <LeftSection
                  onCandidateSelect={(_data: any) => onCandidateSelect(_data)}
                />
              </div>
            </Col>
            <Col md={12} lg={8} className={`bg-white p-0 m-0`}>
              <div className={`${styles.right_col}`}>
                <RightSection />
              </div>
            </Col>
          </Row>
        </RenderIf>
      </Container>
    </div>
  );
};

export default AllCandidatePage;
