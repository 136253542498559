import { createSlice } from "@reduxjs/toolkit";
import { getNotificationReq } from "../../../api/notifications/notificationReq";
import { NotificationType } from "../../../types/notificationTypes";
import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { ApiResType } from "../../../types/apiTypes";

type payloadType = {
  data: NotificationType[];
};

export interface NotificationSlicetypes {
  error: boolean;
  message: string;
  data: NotificationType[];
  loading: boolean;
}

const initialState: NotificationSlicetypes = {
  error: false,
  message: "",
  data: [],
  loading: false,
};

const NotificationSlice = createSlice({
  name: "Notification",
  initialState: initialState,
  reducers: {
    GetNotification: (
      state: NotificationSlicetypes,
      { payload }: { payload: payloadType }
    ) => {
      state.error = false;
      state.data = payload ? [...payload.data] : [];
      state.loading = false;
      return state;
    },
    StartLoading: (state: NotificationSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: NotificationSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: NotificationSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const { GetNotification, StartLoading, StopLoading, ClearData } =
  NotificationSlice.actions;

export default NotificationSlice.reducer;

export function* getNotificationList({ payload }: any) {
  interface ApiResType2 extends ApiResType {
    recent: any;
  }
  try {
    yield put(StartLoading());
    const res: ApiResType2 = yield call(getNotificationReq);
    yield put(GetNotification({ data: res?.data }));
  } catch (error: any) {
    yield put(GetNotification({ data: [] }));
    yield put(StopLoading());
    yield put(ShowAlert({ typeIs: "failure", alertMsg: error?.errorMsg }));
    console.log(error, "error from redux-saga");
  }
}
