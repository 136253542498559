import {createSlice} from '@reduxjs/toolkit';

type AlertWindowSliceType = {
  typeIs: string;
  alertMsg: string;
  show: boolean;
  autoHide: boolean;
  timeOutID: number | null;
};

const initialState: AlertWindowSliceType = {
  typeIs: '',
  alertMsg: '',
  show: false,
  autoHide: true,
  timeOutID: null,
};

export const AlertWindowSlice = createSlice({
  name: 'alertWindow',
  initialState: initialState,
  reducers: {
    showAlert: (state, {payload}) => {
      state.typeIs = payload.typeIs;
      state.alertMsg = payload.alertMsg;
      state.show = true;
      return state;
    },
    hideAlert: state => {
      state.typeIs = '';
      state.alertMsg = '';
      state.show = false;
      return state;
    },
    ShowAlert: (state, {payload}) => {
      // let timeOutID = setTimeout(() => {
      //   state.typeIs = '';
      //   state.alertMsg = '';
      //   state.show = false;
      //   state.timeOutID = null;
      // }, 5000);
      state.typeIs = payload.typeIs;
      state.alertMsg = payload.alertMsg;
      state.show = true;
      // state.timeOutID = timeOutID;
      return state;
    },
    HideAlert: state => {
      // if (state?.timeOutID) clearTimeout(state.timeOutID);
      state.typeIs = '';
      state.alertMsg = '';
      state.show = false;
      return state;
    },
  },
});

export const {showAlert, hideAlert, ShowAlert, HideAlert} =
  AlertWindowSlice.actions;

export default AlertWindowSlice.reducer;

let timeoutId: number | undefined;

export const showAlertAction =
  (type: string, msg: string) => (dispatch: any) => {
    const body = {
      typeIs: type,
      alertMsg: msg,
    };
    // timeoutId = setTimeout(() => {
    //   dispatch(hideAlertAction());
    //   console.log('timeoutId alert');
    // }, 5000);
    dispatch(showAlert(body));
  };

export const hideAlertAction = () => (dispatch: any) => {
  // if (timeoutId) clearTimeout(timeoutId);
  dispatch(hideAlert());
};
