import React, { useState, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

import styles from "../candidatePage.module.scss";

import fileUploadImg from "../../../assets/svgImages/upload_file.svg";
import attachmentIcon from "../../../assets/svgIcons/attachment-icon.svg";
import external_link_Icon from "../../../assets/svgIcons/external-icon.svg";
import link_Icon from "../../../assets/svgIcons/link-icon.svg";
import deleteIcon from "../../../assets/svgIcons/delete-icon.svg";
import LoginLog from "../../../components/microComponents/loginLog";
import UploadFileBlock from "../../../components/uploadFileBlock/uploadFileBlock";
import RenderIf from "../../../components/renderIf";
import UploadFile from "../../../components/uploadFileBlock";
import { fileUploadPaths } from "../../../constants/fileUploadPaths";
import { ResourceCreateType } from "../../../types/otherTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SagaActions } from "../../../redux/actionTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { validate } from "../../../constants/validationExp";

const ResourcesForm = () => {
  const navigate = useNavigate();
  const Dispatch = useDispatch();
  const currentPath = useLocation().pathname;

  const { candidateForm } = useSelector((_state: RootState) => _state);
  const _formValues = candidateForm?.data?.resources;

  const [attachOrLink, setattachOrLink] = useState("attach");
  const [offerLetterPath, setOfferLetterPath] = useState("");
  const [updateLoder, setupdateLoder] = useState(false);
  const [offerLetterUploadError, setofferLetterUploadError] = useState("");
  const [resourcesList, setresourcesList] = useState<ResourceCreateType[]>([]);
  const [resourceName, setResourceName] = useState("");
  const [resourceLink, setResourceLink] = useState("");
  const [resourceNameUploadError, setresourceNameUploadError] = useState("");
  const [resourceLinkUploadError, setResourceLinkUploadError] = useState("");
  const [resetResourceForm, setresetResourceForm] = useState(false);

  const onAddFun = () => {
    setupdateLoder(true);
    if (!resourceName) {
      setresourceNameUploadError(`Resource name required`);
      toast.error("What is the name/title of this resource? ");
      setupdateLoder(false);
      return;
    }

    if (!offerLetterPath && attachOrLink === "attach") {
      setofferLetterUploadError(`Document required`);
      toast.error("Upload a document");
      setupdateLoder(false);
      return;
    }
    if (!resourceLink && attachOrLink === "link") {
      setResourceLinkUploadError(`Url link required`);
      toast.error("Insert the URL (web-link)");
      setupdateLoder(false);
      return;
    }

    const validUrl = new RegExp(validate.url);

    if (!validUrl.test(resourceLink) && attachOrLink === "link") {
      setResourceLinkUploadError(`Enter valid link`);
      toast.error("Enter valid link. Include https:// ");
      setupdateLoder(false);
      return;
    }

    const newResource = {
      resourceName: resourceName,
      resourceURL: offerLetterPath || resourceLink || "",
      resourceType: attachOrLink,
    };

    setresourcesList((pre: any) =>
      pre?.length > 0 ? [...pre, newResource] : [newResource]
    );
    setresetResourceForm(true);
    setupdateLoder(false);
    setResourceName("");
    setOfferLetterPath("");
    setResourceLink("");
    setofferLetterUploadError("");
    setResourceLinkUploadError("");
    setresourceNameUploadError("");
  };

  const onDelete = (data: ResourceCreateType) => {
    const newList = resourcesList?.filter(
      (i) => i.resourceName !== data?.resourceName
    );
    setresourcesList([...newList]);
  };

  const onSaveFun = () => {
    if (resourcesList?.length < 1) return toast.error("Add resources");
    Dispatch({
      type: SagaActions.ADD_FORM_CANDIDATE_RESOURCES,
      payload: resourcesList,
    });
    const parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
    navigate(`${parentPath}/${ROUTE_NAMES.reviewForm}`);
  };

  useEffect(() => {
    if (candidateForm?.data?.resources?.length < 0) return;
    setresourcesList(candidateForm?.data?.resources);
  }, []);

  useEffect(() => {
    if (resetResourceForm) {
      setresetResourceForm(false);
    }
  }, [resetResourceForm]);

  return (
    <div className={`${styles.ResourcesForm} px-3 flex-1 flex-column`}>
      <Container className="">
        <LoginLog />
        <div className={`d-flex align-item-center ${styles.path_view_block}`}>
          <a className={`${styles.link} x-sm-text primary-color`}>Candidates</a>
          <BiChevronRight className={`primary-color`} />
          <a className={`${styles.link} x-sm-text primary-color`}>Add Candidates</a>
        </div>
        <Row className={`my-2`}>
          <Col className={`${styles._col} mt-2`}>
            <h5 className={`my-1 lg-text`}>Add Resources</h5>
          </Col>
        </Row>

        <Row className={`my-3`}>
          <Col className={`${styles._col}`} sm={12} md={6} lg={2}>
            <p className={`my-1`}>Resource name</p>
          </Col>
          <Col className={`${styles._col}`} sm={12} md={6} lg={3}>
            <input
              type="text"
              placeholder=""
              id="text"
              value={resourceName}
              onChange={(e) => setResourceName(e.target.value)}
            />
            {!!resourceNameUploadError && (
              <p className={`xx-sm-text text-danger`}>
                {resourceNameUploadError}
              </p>
            )}
          </Col>
        </Row>

        <div className={`my-3 d-flex align-items-center`}>
          <div className={`d-flex align-items-center w-25`}>
            <input
              type="radio"
              className={`me-2`}
              id="radio1"
              checked={attachOrLink === "attach"}
              onChange={() => setattachOrLink("attach")}
            />
            <p className={`md-text`}>Attach resource</p>
          </div>
          <div className={`d-flex align-items-center w-25`}>
            <input
              type="radio"
              id="radio2"
              className={`me-2`}
              checked={attachOrLink === "link"}
              onChange={() => setattachOrLink("link")}
            />
            <p className={`md-text`}>Link resource</p>
          </div>
        </div>

        <Row className={`my-3`}>
          <Col className={`${styles._col} p-0`} sm={6} md={6} lg={5}>
            <RenderIf isShow={attachOrLink === "link"}>
              <div>
                <Col lg={8}>
                  <p className={`md-text mt-2`}>Add Urls/Link:</p>
                  <div className={`my-2 d-flex align-items-center`}>
                    <input
                      type="text"
                      placeholder="Copy and paste the external Url"
                      value={resourceLink}
                      onChange={(e) => setResourceLink(e.target.value)}
                    />
                    <div className={`${styles.link_icon_box}`}>
                      <img src={link_Icon} />
                    </div>
                  </div>
                  {!!resourceLinkUploadError && (
                    <p className={`xx-sm-text text-danger`}>
                      {resourceLinkUploadError}
                    </p>
                  )}
                </Col>
              </div>
            </RenderIf>
            <RenderIf isShow={attachOrLink === "attach"}>
              <Row>
                <Col md={8} lg={8}>
                  <label className={`md-text my-3`}>Upload document</label>
                  {/* <UploadFileBlock /> */}
                  <UploadFile
                    imagePath={offerLetterPath}
                    discription="Browse or Drag and Drop to file, Max 5mb"
                    path={fileUploadPaths.resources}
                    onUpload={(data: any) => {
                      setOfferLetterPath(data?.path || "");
                    }}
                    resetForm={resetResourceForm}
                  />
                  {!!offerLetterUploadError && (
                    <p className={`xx-sm-text text-danger`}>
                      {offerLetterUploadError}
                    </p>
                  )}
                </Col>
              </Row>
            </RenderIf>
          </Col>
          <Col className={`${styles._col} p-0`} sm={12} md={6} lg={2}>
            <button
              className={`sbmit_btn w-100`}
              type="submit"
              onClick={onAddFun}
            >
              Add
            </button>
          </Col>
        </Row>

        <RenderIf isShow={resourcesList?.length > 0}>
          <div className={`border w-100 mb-1 border-light-subtle`}></div>
        </RenderIf>

        <RenderIf isShow={resourcesList?.length > 0}>
          <Row className={`my-2 mb-0 mt-4`}>
            <Col className={`${styles._col}`}>
              <p className={`md-text b`}>Resources Added</p>
            </Col>
          </Row>
        </RenderIf>
        <RenderIf isShow={resourcesList?.length > 0}>
          <Row className={`my-0 mx-2`}>
            <div className={`${styles.card_block}`}>
              {resourcesList.map((resource, index) => (
                <div className={`${styles.card_box}`} key={index}>
                  <label>{resource?.resourceName}</label>
                  <div
                    className={`${styles.delete_icon}`}
                    onClick={() => {
                      // Remove the resource when the delete icon is clicked
                      const updatedResources = [...resourcesList];
                      updatedResources.splice(index, 1);
                      setresourcesList(updatedResources);
                    }}
                  >
                    <img src={deleteIcon} alt="Delete" />
                  </div>
                  <div className={`${styles.img_block}`}>
                    {/* <img src={attachmentIcon} alt="Attachment" /> */}
                    {resource?.resourceType === "attach" ? (
                      <img src={attachmentIcon} alt="Attachment" />
                    ) : (
                      <img src={external_link_Icon} alt="Link" />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Row>
        </RenderIf>
      </Container>
      <div className={`flex-1 flex-column`}></div>

      <Row className={`my-3`}>
        <Col className={`${styles._col}`} sm={12} md={6} lg={6}></Col>
        <Col className={`${styles._col}`} sm={12} md={6} lg={2}>
          <button className={`sbmit_btn_outline w-100`}>
            <label className={`md-text`}>cancel</label>
          </button>
        </Col>
        <Col className={`${styles._col} p-0`} sm={12} md={6} lg={3}>
          <button className={`sbmit_btn w-100`} onClick={onSaveFun}>
            {/* <label className="sm-text">Save & Continue</label> */}
            Save & Continue
            {!!updateLoder && (
              <div className="ms-2">
                <Spinner animation="border" variant="white" size="sm" />
              </div>
            )}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default ResourcesForm;
