import React, { useCallback, useState, useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { IoIosSearch, IoMdAdd } from "react-icons/io";

import styles from "../cardSetsPage.module.scss";
import LoginLog from "../../../components/microComponents/loginLog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SagaActions } from "../../../redux/actionTypes";
import CardsetDetails from "./cardsetDetails";
import RenderIf from "../../../components/renderIf";
import {
  getAllCardSetReq,
  getCardSetByIdReq,
} from "../../../api/cardsetReq/cardSetReq";
import { toast } from "react-toastify";
import { useRoutes, useParams, useLocation } from "react-router-dom";

const CompanyIntro = () => {
  const { modals, companyProfile } = useSelector((_state: RootState) => _state);
  const Dispatch = useDispatch();
  const { state } = useLocation();
  const _cardSetData = state;
  const [cardsetDetails, setcardsetDetails] = useState<any>();
  const [cardsLoading, setcardsLoading] = useState(false);
  const [cardSetList, setcardSetList] = useState<any[]>([]);

  const _eadminStatsStatus = companyProfile?.data?.company?.Status;

  const getcardsetDetailsFun = async (id: string | number) => {
    try {
      setcardsLoading(true);
      const res = await getCardSetByIdReq({ cardSetID: id });
      setcardsetDetails(res?.data);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "companyIntro",
          methodName: "getcardsetDetailsFun",
          type: "info",
        },
      });
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "companyIntro",
          methodName: "getcardsetDetailsFun",
          type: "error",
        },
      });
    }
    setcardsLoading(false);
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      addNewCardset: () => {
        Dispatch({ type: SagaActions.SHOW_ADD_CARDSET_MODAL });
      },
      cardClick: (id: string | number) => getcardsetDetailsFun(id),
    };
    fun[key](data);
  };

  useEffect(() => {
    setcardsetDetails(_cardSetData);
  }, [state]);

  return (
    <div className={`${styles.CompanyIntro} px-3`}>
      <LoginLog />
      <Container>
        <Row className={`my-2`}>
          <Col sm={12} md={12} lg={5}>
            <h5 className={`lg-text`}>Edit CardSets</h5>
          </Col>
          <Col sm={12} md={12} lg={5}>
            <div className={`border d-flex ${styles.search_block}`}>
              <input
                placeholder="Search by card set name"
                className={`x-sm-text `}
                id="card set name"
              />
              <button className={`${styles.search_icon} p-1`}>
                <IoIosSearch size={20} color="#002c71" />
              </button>
            </div>
          </Col>
          <Col sm={12} md={12} lg={2} className={`px-0`}>
            <button
              disabled={
                _eadminStatsStatus === "Paused" ||
                _eadminStatsStatus === "Inactive"
                  ? true
                  : false
              }
              type="button"
              className={`${
                _eadminStatsStatus === "Paused" ||
                _eadminStatsStatus === "Inactive"
                  ? `sbmit_btn_disable`
                  : `sbmit_btn`
              } py-2`}
              onClick={() => _onPress({ key: "addNewCardset" })}
            >
              <IoMdAdd />
              <p className={`mx-2 sm-text`}>New CardSet</p>
            </button>
          </Col>
        </Row>
      </Container>
      <p>&nbsp;</p>

      {/* <RenderIf isShow={cardsLoading}>
        <div
          className={`d-flex flex-grow-1 align-items-center justify-content-center`}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      </RenderIf> */}
      {/* <RenderIf isShow={!cardsetDetails?.cardSetID && !cardsLoading}>
        <Container>
          <Row>
            {cardSetList?.map((item, idx) => (
              <Col key={idx} sm={6} md={4} lg={3}>
                <div
                  className={`${styles.card_box}`}
                  onClick={() =>
                    _onPress({ key: "cardClick", data: `${item?.cardSetID}` })
                  }
                >
                  <div className={`${styles.img_block}`}>
                    <img src={item?.cardSetURL} />
                  </div>
                  <label className={`x-sm-text`}>{item?.cardSetName}</label>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </RenderIf> */}
      <RenderIf isShow={!!cardsetDetails?.cardSetID && !cardsLoading}>
        <CardsetDetails data={cardsetDetails} />
      </RenderIf>
    </div>
  );
};

export default CompanyIntro;
