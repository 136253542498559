import React, { useState, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./contentStyles.module.scss";
import { toast } from "react-toastify";
import { MdOutlineModeEditOutline } from "react-icons/md";

import attachmentSvg from "../../../assets/svgIcons/attachment-icon.svg";
import externalSvg from "../../../assets/svgIcons/external-icon.svg";
import deleteIcon from "../../../assets/svgIcons/delete-icon.svg";
import { ResourceGetType, ResourceCreateType } from "../../../types/otherTypes";
import RenderIf from "../../../components/renderIf";
import link_Icon from "../../../assets/svgIcons/link-icon.svg";
import UploadFile from "../../../components/uploadFileBlock";
import { fileUploadPaths } from "../../../constants/fileUploadPaths";
import { validate } from "../../../constants/validationExp";
import {
  deleteCandidateResourceReq,
  updateCandidateResourceReq,
} from "../../../api/candidateReq/candidateDetailsReq";
import { CandidateInfoType } from "../../../types/candidateTypes";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const ResourceContent = (props: {
  data: ResourceGetType[];
  candidateDetails?: CandidateInfoType;
  onUpdate: any;
}) => {
  const { data, candidateDetails, onUpdate } = props;
  console.log("props data", data);
  const Dispatch = useDispatch();
  const { candidateCardsetList, cardset, candidates } = useSelector(
    (_state: RootState) => _state
  );
  const [editing, setEditing] = useState(false);
  const [resourceName, setResourceName] = useState("");
  const [attachOrLink, setattachOrLink] = useState("attach");
  const [updateLoder, setupdateLoder] = useState(false);
  const [resetResourceForm, setresetResourceForm] = useState(false);
  // const [isFormVisible, setIsFormVisible] = useState(false);
  const [resourceNameUploadError, setresourceNameUploadError] = useState("");
  const [resourcesList, setresourcesList] = useState<ResourceCreateType[]>([]);
  const [remoteResourcesList, setremoteResourcesList] = useState<
    ResourceGetType[]
  >([]);
  const [resourceLinkUploadError, setResourceLinkUploadError] = useState("");
  const [resourceLink, setResourceLink] = useState("");
  const [offerLetterPath, setOfferLetterPath] = useState("");
  const [offerLetterUploadError, setofferLetterUploadError] = useState("");

  const _candidateID = candidateDetails?.Candidate?.candidateID || 0;
  const _candidateStatus =
    candidates.selectedCandidatesDetails.CandidateOffer.offerStatus;

  const onAddFun = () => {
    setupdateLoder(true);
    if (!resourceName) {
      setresourceNameUploadError(`Resource name required`);
      toast.error("What is the name/title of this resource?");
      setupdateLoder(false);
      return;
    }

    if (!offerLetterPath && attachOrLink === "attach") {
      setofferLetterUploadError(`Document required`);
      toast.error("Upload a document");
      setupdateLoder(false);
      return;
    }
    if (!resourceLink && attachOrLink === "link") {
      setResourceLinkUploadError(`Url link required`);
      toast.error("Insert the URL (web-link)");
      setupdateLoder(false);
      return;
    }

    const validUrl = new RegExp(validate.url);

    if (!validUrl.test(resourceLink) && attachOrLink === "link") {
      setResourceLinkUploadError(`Enter valid link`);
      toast.error("Enter valid link. Include https://");
      setupdateLoder(false);
      return;
    }

    const newResource = {
      resourceName: resourceName,
      resourceURL: offerLetterPath || resourceLink || "",
      resourceType: attachOrLink,
      candidateID: _candidateID,
    };

    setresourcesList((prevList: any) => [...prevList, newResource]);

    setresetResourceForm(true);
    setupdateLoder(false);
    setResourceName("");
    setOfferLetterPath("");
    setResourceLink("");
    setofferLetterUploadError("");
    setResourceLinkUploadError("");
    setresourceNameUploadError("");
  };

  const updateResource = async () => {
    if (resourcesList?.length < 1) return setEditing(false);
    try {
      setEditing(false);
      console.log(resourcesList, "resourcesList");
      const res = await updateCandidateResourceReq(resourcesList);
      toast.success(res?.message);
      setEditing(false);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "resourceContent",
          methodName: "updateResource",
          type: "info",
        },
      });
      onUpdate();
    } catch (error: any) {
      setEditing(false);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "resourceContent",
          methodName: "updateResource",
          type: "error",
        },
      });
    }
  };

  const onDeleteResource = async (resource: ResourceGetType, idx: number) => {
    try {
      const res = await deleteCandidateResourceReq({
        candidateID: _candidateID,
        resourceID: resource?.resourceID,
      });
      toast.success(res?.message);
      const updatedResources = remoteResourcesList.filter(
        (item) => item?.resourceID !== resource?.resourceID
      );
      setremoteResourcesList([...updatedResources]);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "resourceContent",
          methodName: "onDeleteResource",
          type: "info",
        },
      });
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "resourceContent",
          methodName: "onDeleteResource",
          type: "error",
        },
      });
    }
  };

  useEffect(() => {
    if (data?.length < 1) return;
    setremoteResourcesList(data);
  }, [data]);

  useEffect(() => {
    if (resetResourceForm) {
      setresetResourceForm(false);
    }
  }, [resetResourceForm]);
  return (
    <div
      className={`${styles.ResourceContent} d-flex flex-column border py-2 px-3`}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          // flex: 1,
          alignItems: "flex-start",
          justifyContent: "flex-end",
        }}
      >
        {editing ? (
          <div>
            <button
              className={`sbmit_btn sm-text px-2 `}
              onClick={updateResource}
            >
              Update
            </button>
          </div>
        ) : (
          <button
            className={`${styles.edit_btn} md-text`}
            style={{ cursor: "pointer" }}
            onClick={() =>
              setEditing(
                _candidateStatus == "Joined"
                  ? false
                  : _candidateStatus == "Dropout"
                  ? false
                  : true
              )
            }
          >
            <MdOutlineModeEditOutline className={`me-2`} />
            Edit
          </button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <div className={`${styles.card_block}`}>
          {remoteResourcesList?.map((item: ResourceGetType, idx: number) => (
            <div key={idx} className={`${styles.card_box} md-text d-flex`}>
              <div className={`${styles.x_small_icon}`}>
                <img
                  src={
                    item?.resourceType.toLowerCase() !== "link"
                      ? attachmentSvg
                      : externalSvg
                  }
                />
              </div>
              <label className="sm-text">{item?.resourceName || ""}</label>
              {editing && (
                <button
                  className={`${styles.delete_icon_box}`}
                  onClick={() => onDeleteResource(item, idx)}
                >
                  <img className={`${styles.delete_icon}`} src={deleteIcon} />
                </button>
              )}
            </div>
          ))}
          {resourcesList?.map((resource, index) => (
            <div key={index} className={`${styles.card_box} md-text d-flex`}>
              <div className={`${styles.x_small_icon}`}>
                <img
                  src={
                    resource?.resourceType.toLowerCase() !== "link"
                      ? attachmentSvg
                      : externalSvg
                  }
                />
              </div>
              <label>{resource?.resourceName || ""}</label>
              {editing && (
                <div
                  className={`${styles.delete_icon_box}`}
                  onClick={() => {
                    const updatedResources = [...resourcesList];
                    updatedResources.splice(index, 1);
                    setresourcesList(updatedResources);
                  }}
                >
                  <img
                    className={`${styles.delete_icon}`}
                    src={deleteIcon}
                    alt="Delete"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {resourcesList.length > 0 && (
          <p className="md-text ms-2 mt-3">Newly added resources: </p>
        )}
        <div className={`${styles.card_block}`}>
          {resourcesList?.map((resource, index) => (
            <div key={index} className={`${styles.card_box} md-text d-flex`}>
              <div className={`${styles.x_small_icon}`}>
                <img
                  src={
                    resource?.resourceType.toLowerCase() !== "link"
                      ? attachmentSvg
                      : externalSvg
                  }
                />
              </div>
              <label>{resource?.resourceName || ""}</label>
              {editing && (
                <div
                  className={`${styles.delete_icon_box}`}
                  onClick={() => {
                    const updatedResources = [...resourcesList];
                    updatedResources.splice(index, 1);
                    setresourcesList(updatedResources);
                  }}
                >
                  <img
                    className={`${styles.delete_icon}`}
                    src={deleteIcon}
                    alt="Delete"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
        }}
      >
        <Container>
          {editing && (
            <Row className={`my-3`}>
              <Col className={`${styles._col} w-15`} sm={12} md={2} lg={3}>
                <p className={`my-1 fs-6`}>Resource name</p>
              </Col>
              <Col className={`${styles._col}`} sm={12} md={4} lg={3}>
                <input
                  className="w-100 border border-dark-subtle p-1"
                  style={{ borderRadius: "5px" }}
                  type="text"
                  id="text"
                  placeholder=""
                  value={resourceName}
                  onChange={(e) => setResourceName(e.target.value)}
                />
                {!!resourceNameUploadError && (
                  <p className={`xx-sm-text text-danger`}>
                    {resourceNameUploadError}
                  </p>
                )}
              </Col>
              <Col className={`${styles._col} p-0`} sm={12} md={6} lg={2}>
                <button
                  className={`sbmit_btn w-100`}
                  style={{ padding: "4px" }}
                  type="submit"
                  onClick={onAddFun}
                >
                  Add
                </button>
              </Col>
            </Row>
          )}
          {editing && (
            <div className={`my-3 d-flex align-items-center`}>
              <div className={`d-flex align-items-center w-25`}>
                <input
                  type="radio"
                  className={`me-2`}
                  id="radio1"
                  checked={attachOrLink === "attach"}
                  onChange={() => setattachOrLink("attach")}
                />
                <p className={`md-text`}>Attach resource</p>
              </div>
              <div className={`d-flex align-items-center w-25`}>
                <input
                  type="radio"
                  id="radio2"
                  className={`me-2`}
                  checked={attachOrLink === "link"}
                  onChange={() => setattachOrLink("link")}
                />
                <p className={`md-text`}>Link resource</p>
              </div>
            </div>
          )}

          {editing && (
            <RenderIf isShow={attachOrLink === "link"}>
              <div>
                <Col lg={3}>
                  <p className={`md-text mt-2`}>Add Urls/Link:</p>
                  <div className={`my-2 d-flex align-items-center`}>
                    <input
                      type="text"
                      className="w-100 border border-dark-subtle rounded p-1"
                      placeholder="Copy and paste the external Url"
                      value={resourceLink}
                      onChange={(e) => setResourceLink(e.target.value)}
                    />
                    <div className={`${styles.link_icon_box} ms-2`}>
                      <img
                        src={link_Icon}
                        style={{ width: "32px", height: "32px" }}
                      />
                    </div>
                  </div>
                  {!!resourceLinkUploadError && (
                    <p className={`xx-sm-text text-danger`}>
                      {resourceLinkUploadError}
                    </p>
                  )}
                </Col>
              </div>
            </RenderIf>
          )}

          {editing && (
            <RenderIf isShow={attachOrLink === "attach"}>
              <Row>
                <Col md={8} lg={4}>
                  <label className={`md-text my-3`}>Upload document</label>
                  {/* <UploadFileBlock /> */}
                  <UploadFile
                    imagePath={offerLetterPath}
                    discription="Browse or Drag and Drop to file, Max 5mb"
                    path={fileUploadPaths.resources}
                    onUpload={(data: any) => {
                      setOfferLetterPath(data?.path || "");
                    }}
                    resetForm={resetResourceForm}
                  />
                  {!!offerLetterUploadError && (
                    <p className={`xx-sm-text text-danger`}>
                      {offerLetterUploadError}
                    </p>
                  )}
                </Col>
              </Row>
            </RenderIf>
          )}
        </Container>
      </div>
    </div>
  );
};

export default ResourceContent;
