export const ApiKey = {
  // =========WEB=========================================
  //------------- auth keys -------------
  getOtpByMailKey: "/user/sendloginotp",
  loginWithMailKey: "/user/authenticate",
  setPasswordKey: "/user/setPassword",
  registerUserKey: "/user/register",
  getSignUpOtpkey: "/user/sendSignUpOTP",
  resendSignUpOtpkey: "/user/resendSingUpOTP",
  verifyOtpKey: "/user/verifyOTP",

  //------------- user keys -------------
  createCompanyProfileKey: "/company/profile",
  updateCompanyProfileKey: "/company/updateProfile",
  getCompanyProfileKey: "/company/details",
  profileKey: "/user/getProfile",
  cepProfileKey: "/user/CEP/Profile",

  //------------- recruiter keys -------------
  addRecruiterKey: "/company/recruiters/add",
  updateRecruiterKey: "/company/recruiters/update",
  getRecruiterKey: "/company/recruiters",
  getRecruiterByIdKey: "/company/recruiters",
  deleteRecruiterKey: "/company/recruiters",
  getHrActionsKey: "/hrnotifications",
  updateHrActionByIDKey: "/candidates", //  /:candidateID/hrAction/:activityID,
  changeDojKey: "/company/changedoj",
  // -------------CardSet category--------------
  getCardSetCatKey: "/cardsets/categories",
  addCardSetCatKey: "/cardSets/categories/add",
  updateCardSetCatKey: "/cardSets/categories/update",

  // -------------CardSet --------------
  getCardSetByCatKey: "/cardSets/categories", // /2
  getCardSetByIdKey: "/cardSets", // /2
  getAllCardSetKey: "/cardSets/getAll", // /2
  addDefaultCardSetKey: "/default/cardSets/add",
  getDefaultCardSetKey: "/default/cardSets", // {companyID}
  addCardSetKey: "/cardSets/add",
  addNewCardToCardSetKey: "/cardSets", // /{cardsetID}/card/
  updateCardSetKey: "/cardSets", // /19
  updateCardSetCardKey: "/cardSets", // /{cardsetID}/card/{cardID}
  deleteCardSetCardKey: "/cardSets", // /{cardsetID}/card/{cardID}

  //------------- Candidate keys -------------

  addCandidateKey: "/candidates/add",
  publishCandidateKey: "/candidates/candidatePublish",
  getCandidatesKey: "/candidates",
  getCandidateDetailsKey: "/candidates",
  getCandidateAllDetailsKey: "/candidates/info",
  addCandidateCardSetKey: "/candidates/addCardSet",
  updateCandidateCardSetKey: "/candidates/cardSet/add",
  deleteCandidateCardSetKey: "/candidates/cardset", // /{cardsetID}
  updateCandidateDetailsKey: "/candidates", // /400100046/updateCandidateDetail
  updateCandidateOfferDetailsKey: "/candidates", // /400100046/updateCandidateOffer
  updateCandidateResourceKey: "/candidates/resources/add",
  deleteCandidateResourceKey: "/candidates", // /candidateID/resources/resourceID
  updateCandidateStatusKey: "/candidates", // /candidateID/updateStatus

  // ---------------------------
  plansListKey: "/subscriptions/plans",
  refreshTokenKey: "/user/refreshToken",
  contactUskey: "/company/CEP", //companyID

  // ==========================================================
  //------------- auth keys -------------
  getOtpKey: "/user/sendloginotp",
  loginWithOtpKey: "/user/loginWithOTP",
  resendOtpKey: "/user/resendloginotp",
  updateProfileKey: "/user/updateProfile",

  //------------- Documents keys -------------
  cmpnyDocumentsKey: "/company", // add companyID -> /company/companyId/documents
  cmpnyDocumentByIdKey: "/company", // add documentID & companyId -> /company/documents/1

  //------------- Candidate/card set keys -------------
  cardSetKey: "/candidate/cardset", // add companyID -> /candidate/cardset/202300001
  cardSetDashboardKey: "/candidate/dashboard", // add companyID -> /candidate/dashboard/202300001
  cardSetByCatKey: "/candidate/cardset", // add companyID and cardSetId -> /cardset/202300001/1
  candidateCardSetKey: "/candidate/cardset", // add cardSetId -> /candidate/cardset/1
  cardSetCategoriesKey: "/candidate/cardset", // add cardSetId -> /candidate/cardset/companyID/categories
  candidateCardSetReqKey: "/cardset/request",
  candidateCardFeedBackKey: "/cardset/feedback",
  candidateJourneyKey: "/candidate/journey",
  cardSetCardsKey: "/card", //add cardSetID -> /card/1

  //------------- Offer keys -------------
  offersKey: "/offers",
  offersByIdKey: "/offers", // add offerId -> offers/1
  offerLetterUrlKey: "/offers", // add offerId -> offers/1/download
  offerAcceptsKey: "/offers/accept",
  offerDeclineKey: "/offers/decline",
  updateDOJKey: "/offers/updateDOJ",

  //------------- Other keys -------------
  videoListKey: "/",
  contactsKey: "/company/CEP", // add employID -> /company/CEP/employID
  updateConsentKey: "/user/updateConsent",

  //------------- Notification keys -------------
  updateFCMToken: "/user/updateDevice",
  deleteFCMToken: "/user/deleteDevice",
  notificationList: "/notifications",
};
