import React, { useState } from "react";
import CustomModal from "../modal/customModal";
import CardSets from "./components/cardSets";
import CreateCardSet from "./components/createCardSet";
import Notifications from "./components/notifications";
import { useDispatch, useSelector } from "react-redux";
import RenderIf from "../renderIf";
import { RootState } from "../../redux/store";
import { SagaActions } from "../../redux/actionTypes";
import CandidateStatusModal from "./components/candidateStatus";
import PublishContentModal from "./components/publishContent";
import LogoutModal from "./components/logout";
import DefaultCardsetModal from "./components/defaultCardsets";

const ModalComponents = (props: any) => {
  const [showModal, setshowModal] = useState(false);
  const { modals } = useSelector((_state: RootState) => _state);
  const Dispatch = useDispatch();
  return (
    <CustomModal
      isShow={modals.modal}
      onCloseModal={() => Dispatch({ type: SagaActions.CLOSE_MODAL })}
    >
      <RenderIf isShow={modals.showAddCardSet}>
        <CreateCardSet
          onClose={() => {
            Dispatch({ type: SagaActions.CLOSE_MODAL });
          }}
        />
      </RenderIf>
      <RenderIf isShow={modals.showCardSetPicker}>
        <CardSets
          onClose={() => {
            Dispatch({ type: SagaActions.CLOSE_MODAL });
          }}
        />
      </RenderIf>
      <RenderIf isShow={modals.showCandidateStatus}>
        <CandidateStatusModal
          onClose={() => {
            Dispatch({ type: SagaActions.CLOSE_MODAL });
          }}
        />
      </RenderIf>
      <RenderIf isShow={modals.showCandidatePublish}>
        <PublishContentModal
          onClose={() => {
            Dispatch({ type: SagaActions.CLOSE_MODAL });
          }}
        />
      </RenderIf>
      <RenderIf isShow={modals.showDefaultCardset}>
        <DefaultCardsetModal
          onClose={() => {
            Dispatch({ type: SagaActions.CLOSE_MODAL });
          }}
        />
      </RenderIf>
      <RenderIf isShow={modals.showNotifications}>
        <Notifications
          onClose={() => {
            Dispatch({ type: SagaActions.CLOSE_MODAL });
          }}
        />
      </RenderIf>
      <RenderIf isShow={modals.showLogoutModal}>
        <LogoutModal
          onClose={() => {
            Dispatch({ type: SagaActions.CLOSE_MODAL });
          }}
        />
      </RenderIf>
      {/* <CardSets onClose={() => setshowModal(false)} /> */}
    </CustomModal>
  );
};

export default ModalComponents;
