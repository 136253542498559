import CandidateMainLayout from "./candidatePage";
import PersonalInfoForm from "./components/personalInfoForm";
import AddCardSetForm from "./components/addCardSetForm";
import ResourcesForm from "./components/resourcesForm";
import ReviewForm from "./components/reviewForm";
import AddCandidatePage from "./addCandidatePage";

export default CandidateMainLayout;

export {
  PersonalInfoForm,
  AddCardSetForm,
  ResourcesForm,
  ReviewForm,
  AddCandidatePage,
};
