import React, { useEffect, useState } from "react";
import {
  IoAddOutline,
  IoSearchOutline,
  IoFilterOutline,
  IoCallOutline,
} from "react-icons/io5";

import styles from "./leftSection.module.scss";
import VeryMuchEmoji from "../../../assets/emoji/verymuch-emoji.svg";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import RenderIf from "../../../components/renderIf";
import { CandidateType } from "../../../types/candidateTypes";
import dayjs from "dayjs";
import { SagaActions } from "../../../redux/actionTypes";
import { Spinner } from "react-bootstrap";

const CandidateCard = (props: {
  item: CandidateType;
  onClick: any;
  isSelected: boolean;
  index: number;
}) => {
  const { item, onClick, isSelected, index } = props;

  const cardClassName = `${styles.candidate_card} m-1 my-2 py-2 px-4 ${
    isSelected ? styles.selectedCard : ""
  }`;

  const _candidateName = item?.firstName + " " + item?.lastName;

  const _designation =
    item?.CandidateOffers?.length > 0
      ? item?.CandidateOffers[0].designation
      : "";

  const _mobileNumber = item?.User?.mobileNumber;

  const _DOJ =
    item?.CandidateOffers?.length > 0
      ? item?.CandidateOffers[0].dateOfJoining
      : "";

  const _offerStatus =
    item?.CandidateOffers?.length > 0
      ? item?.CandidateOffers[0].offerStatus
      : "";

  return (
    <div className={cardClassName} onClick={() => onClick(index)}>
      <div className={`d-flex align-items-start`}>
        <div className={`${styles.flex_1} flex-column`}>
          <p className="md-text b">{_candidateName}</p>
          <p className="x-sm-text my-1">{_designation}</p>
          {_offerStatus && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <p
                // style={{ color: _offerStatus === "Offered" ? "orangered" : "" }}
                className="x-sm-text"
              >
                Status:
              </p>
              <p
                // style={{ color: _offerStatus === "Dropout" ? "red" : "" }}
                style={{
                  color:
                    _offerStatus === "Dropout"
                      ? "red"
                      : _offerStatus === "Joined"
                      ? "green"
                      : "black",
                }}
                className="x-sm-text ms-1"
              >
                {_offerStatus}
              </p>
            </div>
          )}
          {/* <p className="x-sm-text">Product Management</p> */}
        </div>
        {/* <div className={`${styles.img_icon}`}>
          <img
            src={VeryMuchEmoji}
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div> */}
      </div>

      <div className={`d-flex my-1 mt-2`}>
        <div className={`${styles.flex_1} align-items-center`}>
          <IoCallOutline size={10} color="#FF4D84" />
          <span className={`x-sm-text ms-1 light-text`}>
            +91 {_mobileNumber}
          </span>
        </div>
        <div className={``}>
          <p className="x-sm-text">
            DOJ:
            <span className={`secondary-color ms-1 md-text`}>{`${dayjs(
              _DOJ
            ).format("MMM DD, YYYY")}`}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const LeftSection = (props: any) => {
  const { onCandidateSelect } = props;
  const navigation = useNavigate();
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const Dispatch = useDispatch();
  const { candidates, companyProfile } = useSelector(
    (_state: RootState) => _state
  );

  const [finalCandidateList, setfinalCandidateList] = useState<CandidateType[]>(
    []
  );
  const [searchText, setsearchText] = useState("");
  const [filterKey, setfilterKey] = useState<undefined | string>(undefined);
  const [pageLoader, setpageLoader] = useState(false);

  const _candidateList = candidates?.data || [];
  const _eadminStats = companyProfile?.data;
  const _eadminStatsStatus = companyProfile?.data?.company?.Status;
  const _selectedCandidateId = candidates?.selectedCandidateId;
  console.log("_eadminStats", _eadminStats);

  const searchCandidates = (searchKey?: string, filterValue?: string) => {
    let filtredObj = _candidateList || [];
    if (filterValue) {
      filtredObj = _candidateList.filter(
        (item: CandidateType) =>
          item?.CandidateOffers[0]?.offerStatus === filterValue
      );
    }
    console.log("filtredObj", filtredObj);
    if (!searchKey) {
      const OfferedStatusData = filtredObj.filter(
        (item: any) => item?.CandidateOffers?.[0]?.offerStatus === "Offered"
      );
      const AcceptStatusData = filtredObj.filter(
        (item: any) => item?.CandidateOffers?.[0]?.offerStatus === "Accepted"
      );
      const JoinedStatusData = filtredObj.filter(
        (item: any) => item?.CandidateOffers?.[0]?.offerStatus === "Joined"
      );
      const DropoutStatusData = filtredObj.filter(
        (item: any) => item?.CandidateOffers?.[0]?.offerStatus === "Dropout"
      );
      const compareByFirstName = (a: any, b: any) => {
        const nameA = a.firstName.toUpperCase();
        const nameB = b.firstName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      };

      // Sort the array using the compare function
      const sortedOfferedStatusData =
        OfferedStatusData.slice().sort(compareByFirstName);
      const sortedAcceptStatusData =
        AcceptStatusData.slice().sort(compareByFirstName);
      const sortedJoinedStatusData =
        JoinedStatusData.slice().sort(compareByFirstName);
      const sortedDropoutStatusData =
        DropoutStatusData.slice().sort(compareByFirstName);
      setfinalCandidateList([
        ...sortedOfferedStatusData,
        ...sortedAcceptStatusData,
        ...sortedJoinedStatusData,
        ...sortedDropoutStatusData,
      ]);
      // onCandidateSelect(filtredObj[0]);
      return;
    }
    const _searchKey = searchKey.toLowerCase(); // Convert the searchKey to lowercase for case-insensitive search

    const newList = filtredObj.filter((item: CandidateType) => {
      const firstNameMatch = item?.firstName.toLowerCase().includes(_searchKey);
      const lastNameMatch = item?.lastName.toLowerCase().includes(_searchKey);
      // const phoneMatch = item?.mobile.phone.includes(searchKey);

      // Return true if either the name or phone number matches the searchKey
      return firstNameMatch || lastNameMatch;
    });
    setfinalCandidateList([...newList]);

    // onCandidateSelect(newList[0]);
  };

  let timerId: any;

  const onSearchFun = (serachInput: string, filterValue: string = "") => {
    clearTimeout(timerId);
    setpageLoader(true);
    timerId = setTimeout(() => {
      searchCandidates(serachInput, filterValue);
      setpageLoader(false);
    }, 1500);
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      null: () => {},
      addCandidate: () => {
        Dispatch({ type: SagaActions.CLEAR_CANDIDATE_CARDSET_FORM });
        navigation(
          `/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.CANDIDATE}/${ROUTE_NAMES.addCandidate}`
        );
      },
      candidateDetails: (candidateData: any) =>
        onCandidateSelect(candidateData),
    };
    fun[key](data);
  };
  useEffect(() => {
    if (_candidateList?.length > 0) {
      searchCandidates();
    }
  }, []);

  useEffect(() => {
    if (filterKey || searchText || filterKey === "") {
      onSearchFun(searchText, filterKey);
    }
  }, [searchText, filterKey]);

  return (
    <div className={`${styles.left_section} mt-1 p-2`}>
      <div
        className={`${styles.header} d-flex align-items-center justify-content-between`}
      >
        <p className={`lg-text`}>All Candidates</p>
        <button
          disabled={_eadminStatsStatus === "Paused" || _eadminStatsStatus === "Inactive" ? true : false}
          className={`${
            _eadminStatsStatus !== "Active" && styles.disable_button
          } ${_eadminStatsStatus === "Active" && styles.button} p-1 px-2 sm-text`}
          onClick={() => _onPress({ key: "addCandidate" })}
        >
          <IoAddOutline size={16} className={`me-2`} />
          Add Candidate
        </button>
      </div>
      <br />
      <div className={`${styles.filter_block} d-flex align-items-center `}>
        <div className={`${styles.search_box}  d-flex align-items-center`}>
          <input
            className={`x-sm-text text-dark`}
            id="search"
            placeholder="Search by name"
            value={searchText}
            onChange={(e) => {
              setsearchText(e.target.value);
              onSearchFun(e.target.value);
            }}
          />
          <IoSearchOutline color="#002c71" className="mx-2" />
        </div>
        <div className={``}>
          <IoFilterOutline color="#002c71" className="mx-2" />
        </div>
        <div className={`${styles.select_box}`}>
          <select
            id="option"
            className={`${styles.filter_input} primary-color`}
            value={filterKey}
            onChange={(e) => setfilterKey(e.target.value)}
          >
            <option value={""}>All</option>
            <option value={"Offered"}>Offered</option>
            <option value={"Accepted"}>Accepted</option>
            <option value={"Decline"}>Decline</option>
            <option value={"Joined"}>Joined</option>
          </select>
        </div>
      </div>
      <br />
      <RenderIf isShow={finalCandidateList?.length < 1 && !pageLoader}>
        <div className="d-flex align-items-center justify-content-center">
          <p className="xx-sm-text">-: No candidate :-</p>
        </div>
      </RenderIf>
      <RenderIf isShow={pageLoader}>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" size="sm" />
        </div>
      </RenderIf>
      <RenderIf isShow={finalCandidateList?.length > 0 && !pageLoader}>
        <div className={`${styles.card_list_container} `}>
          {finalCandidateList.map((item: CandidateType, idx) => (
            <CandidateCard
              key={idx}
              item={item}
              // onClick={() => _onPress({ key: "candidateDetails", data: item })}
              isSelected={_selectedCandidateId == item?.candidateID}
              index={idx}
              onClick={(clickedIndex: number) => {
                setSelectedCardIndex(clickedIndex);
                _onPress({ key: "candidateDetails", data: item });
              }}
            />
          ))}
        </div>
      </RenderIf>
    </div>
  );
};

export default LeftSection;
