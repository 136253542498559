import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
// import { CompanyDetailsType } from "../../types/paymentTypes";
import { checkTokenValidation } from "../auth/tokenReq";


export const getCepAdminProfileReq = async () => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": dashboardStatsReq->getDashboardStatsReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.cepProfileKey}`);
    logfun(res.data, "res");
    // const resData = jsonData;
    const resData = res.data;
    // const _msg = res?.data?.message || "";
    const _data = {
      firstName: resData?.user?.firstName || "",
      lastName: resData?.user?.lastName || "",
      employeeID: resData?.user?.employeeID || "",
      designation: resData?.user?.designation || "",
      workLocation: resData?.user?.workLocation || "",
      companyID: resData?.user?.companyID || "",
      userID: resData?.user?.userID || "",
      email: resData?.user?.email || "",
      mobile: resData?.user?.mobile || "",
      role: resData?.user?.role || "",
      addressLine1: resData?.user?.address?.addressLine1 || "",
      addressLine2: resData?.user?.address?.addressLine2 || "",
      city: resData?.user?.address?.city || "",
      state: resData?.user?.address?.state || "",
      zipcode: resData?.user?.address?.zipcode || "",
      country: resData?.user?.address?.country || "",
      company: resData?.company?.map((item: any) => ({
        firstName: item?.EAdminDetails?.firstName || "",
        lastName: item?.EAdminDetails?.lastName || "",
        mobileNumber: item?.EAdminUserData?.mobileNumber || "",
        email: item?.EAdminUserData?.email || "",
        designation: item?.EAdminDetails?.designation || "",
        workLocation: item?.EAdminDetails?.workLocation || "",
        GSTNo: item?.GSTNo || "",
        companyName: item?.CompanyName || "",
        companyID: item?.companyID || "",
        companyWebLogoURL: item?.companyWebLogoURL || "",
        companyLogoURL: item?.companyLogoURL || "",
        Status: item?.company?.Status || "",
        roleID: item?.EAdminUserData?.roleID || "",
        addressLine1: item?.EAdminaddressData?.addressLine1 || "",
        addressLine2: item?.EAdminaddressData?.addressLine2 || "",
        city: item?.EAdminaddressData?.city || "",
        state: item?.EAdminaddressData?.state || "",
        country: item?.EAdminaddressData?.country || "",
        zipcode: item?.EAdminaddressData?.zipcode || "",
      })),
    };
    return { error: false, data: _data, message: "", errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    // logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
