import { authAxios } from "../config";

export const pushLogsToCloud = async (data: any) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": loginRequests->pushLogsToCloud " + t);
  try {
    const body = {
      data,
    };
    const res = await authAxios.post(
      `https://twmhcweytf.execute-api.ap-south-1.amazonaws.com/dev/postLog`,
      body
    );
    //  logfun(res.data, 'res');
    // const resData = res.data;
    const _data = {};
    return { error: false, data: _data, message: "", errorMsg: "" };
  } catch (err: any) {
    //  logfun(err, 'ERROR');
  }
};
