import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import styles from "./myAccount.module.scss";
import { ROUTE_NAMES } from "../../constants/routeNames";
import MenuBox from "./components/menuBox";
import { getCompanyProfileReq } from "../../api/company/companyProfileReq";
import { toast } from "react-toastify";

const menuListGroupOne = [
  {
    label: "Welcome",
    icon: "",
    route: ROUTE_NAMES.welcome,
  },
  {
    label: "Company Profile",
    icon: "",
    route: ROUTE_NAMES.companyProfile,
  },
  {
    label: "Subscription",
    icon: "",
    route: ROUTE_NAMES.plan,
  },
  {
    label: "Manage Recruiters",
    icon: "",
    route: ROUTE_NAMES.addRecruiter,
  },
];

const MyAccountPage = () => {
  const navigation = useNavigate();
  const currentPath = useLocation().pathname;
  const [activeMenu, setactiveMenu] = useState("Welcome");
  const[countryIs, setcountryIs] = useState("");

  const getCompanyProfileDetails = async () => {
    try {
      const res = await getCompanyProfileReq();
      const company = res?.data?.company;
      
      setcountryIs(company?.companyCountry)
      // console.log("compony log", formValues);
    } catch (error) {
      console.log("error");
    }
  };

  const currentOpt = menuListGroupOne.find((i) =>
    i.route?.includes(`${currentPath?.split("/").slice(-1)[0]}`)
  );

  const _onPress = ({ key, data }) => {
    const fun = {
      null: (data) => {},
      menuBtn: (data) => {
        // setactiveMenu(data?.label);
        // navigation(data?.route);
        if (candidateNavigate(data.label)) {
          setactiveMenu(data.label);
          navigation(data.route);
        } else {
          if(data.label === "Company Profile" || data.label === "Manage Recruiter") {
            toast.warn("Move to next step by clicking Continue button")
          }
          else
          toast.warn("Fill all details before proceeding further");
        }
      },
    };
    fun[key](data);
  };

  const candidateNavigate = (label) => {
    const currentIndex = menuListGroupOne.findIndex(
      (opt) => opt.label === label
    );
    if (currentIndex === 0) {
      return true;
    }
    const isSignUp = localStorage.getItem('isSignup')
    const previousStep = menuListGroupOne[currentIndex - 1].label;
    switch (previousStep) {
      case "Welcome":
        return isSignUp === "true" ? false : true;
      case "Company Profile":
        return !! countryIs;
      case "Plan":
        return isSignUp === "true" ? false : true;
      default:
        return false;
    }
  };

  useEffect(() => {
    getCompanyProfileDetails();
  }, []);

  return (
    <div className={`${styles.MyAccountPage} h-100`}>
      <div className="d-flex h-100">
        <div className={`${styles.nav_bar_block} h-100`}>
          &nbsp;
          <p></p>
          &nbsp;
          {menuListGroupOne?.map((opt, idx) => {
            const active = currentOpt?.label === opt.label;

            const presentPosition = menuListGroupOne
              .map((e) => e.label)
              .indexOf(currentOpt?.label);

            const checked = idx < presentPosition;
            return (
              <MenuBox
                key={idx}
                opt={opt}
                isChecked={checked}
                isActive={active}
                onClick={() => _onPress({ key: "menuBtn", data: opt })}
              />
            );
          })}
        </div>
        <div className={`${styles.content_block} h-100`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MyAccountPage;
