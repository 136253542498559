import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SagaActions } from "../../redux/actionTypes";
import CryptoJS from "crypto-js";
import { ROUTE_NAMES } from "../../constants/routeNames";

const RedirectLoadingPage = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const Dispatch = useDispatch();
  const { token } = useParams();

  const redirectFun = async () => {
    try {
      if (location.pathname.includes("/from-padmin/loading")) {
        if (!token) return;
        const _tokenIs = token.replaceAll("$", "/");
        const bytes = CryptoJS.AES.decrypt(_tokenIs, "vmzCareerX");
        // console.log(token, "id from param");
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        console.log(data, " ---- " + data?.companyID, "decrypt token");
        localStorage.setItem("refreshToken", data?.refreshToken);
        localStorage.setItem("@role", data?.roleIs);
        localStorage.setItem("userID", `${data?.userId}`);
        const companyID = data?.companyID;
        loginFun({ companyID });
        return;
      }
    } catch (error) {
      console.log(error, "error in redirectFun");
    }
  };

  const loginFun = async (data?: any) => {
    const role = localStorage.getItem("@role");
    const roleIs = role ? Number(role) : 0;

    if (roleIs === 102) {
      Dispatch({ type: SagaActions.FETCH_CEP_COMPANY_PROFILE });
      Dispatch({
        type: SagaActions.DEFAULT_CEP_COMPANY,
        payload: { companyID: data?.companyID || null },
      });
    }
    if (roleIs === 301 || roleIs === 303) {
      Dispatch({
        type: SagaActions.UPDATE_NAVBAR_SEETINGS,
        payload: { clickableMenu: true },
      });
    }

    setTimeout(() => {
      localStorage.setItem("isSignup", "false");
      if (roleIs === 200) {
        navigation(`${ROUTE_NAMES.mainLayout}`);
        return;
      }
      if (roleIs === 102) {
        navigation(`/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.cardSetsPage}`);
      } else {
        navigation(`/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.dashboard}`);
      }
    }, 1500);
  };

  useEffect(() => {
    redirectFun();
  }, []);

  return (
    <div className=" h-100 w-100 d-flex flex-column align-items-center justify-content-center  border-primary">
      <div className="p-2" onClick={loginFun}>
        Loading..
      </div>
    </div>
  );
};

export default RedirectLoadingPage;
