import axios from "axios";
import { ROUTE_NAMES } from "../constants/routeNames";
import { globalNavigate } from "../routes/rootNavigation";

export const authAxios = axios.create({
  timeout: 20000,
  baseURL: process.env.REACT_APP__API_IP,
  // baseURL:     "https://746d-2405-201-d011-7ad0-c152-4f8a-f794-2c3b.ngrok.io/api/v0",
});

export const publicAxios = axios.create({
  timeout: 20000,
  baseURL: process.env.REACT_APP__API_IP,
  // baseURL:     "https://746d-2405-201-d011-7ad0-c152-4f8a-f794-2c3b.ngrok.io/api/v0",
});

authAxios.interceptors.request.use(async (config) => {
  const access_token = localStorage.getItem("token");
  if (access_token != null && config.headers.Authorization === undefined) {
    config.headers.Authorization = `JWT ${access_token}`;
  }
  config.timeout = 10000;
  return config;
});

publicAxios.interceptors.request.use(async (config) => {
  config.headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return config;
});

authAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const msg = error.response.data.message
    const unauthorizedMessage = msg.includes("Unauthorized")
    if(unauthorizedMessage) {
      globalNavigate(ROUTE_NAMES.LOGIN)
    }
    return Promise.reject(error);
  }
);
