import React from "react";
import styled from "styled-components";
import { publishCandidateReq } from "../../../api/candidateReq/candidateReq";
import { toast } from "react-toastify";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";

const PublishContentModal = ({ onClose }: any) => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();

  const { candidateForm, candidateCardsetList, cardset } = useSelector(
    (_state: RootState) => _state
  );

  const _candidate = candidateForm?.data?.personalDetails;
  const _resources = candidateForm?.data?.resources || [];
  const _weeklyCardsets = candidateCardsetList?.data || [];

  const onPublishCandidate = async () => {
    try {
      if (!_candidate) return;
      const body = {
        ..._candidate,
        publish: true,
        cardSets: _weeklyCardsets || [],
        resources: _resources || [],
      };
      console.log(body, "onPublishCandidate body");
      const res = await publishCandidateReq(body);
      toast.success(res?.message);
      Dispatch({ type: SagaActions.CLEAR_CANDIDATE_FORM });
      Dispatch({ type: SagaActions.CLEAR_CANDIDATE_CARDSET_FORM });
      // Dispatch({ type: SagaActions.GET_CANDIDATES });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "publishContent",
          methodName: "onPublishCandidate",
          type: "info",
        },
      });
      onClose();
      navigate(
        `/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.CANDIDATE}/${ROUTE_NAMES.allCandidate}`
      );
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "publishContent",
          methodName: "onPublishCandidate",
          type: "error",
        },
      });
    }
  };

  const onConfirm = () => {
    onPublishCandidate();
  };

  return (
    <PublishContentContainer>
      <div className="box">
        <div className="dialogue">
          <div className="overlap">
            <div className="overlap-wrapper">
              <div className="content-wrapper">
                <div className="content">
                  <div className="header">
                    <div className="frame-9">
                      <div className="text-medium-title">
                        <div className="notifications">
                          {/* Publish New Candidate ? */}
                          Issue Offer & Notify Candidate
                        </div>
                      </div>
                      <div className="div">
                        <div className="frame-4">
                          <div className="text-medium">
                            {/* <p className="name-2">
                              Do you really want to continue publish the
                              content. As it will trigger a notification in the
                              employers a mobile application
                            </p> */}
                            <p className="name-1">
                              Review details carefully before publishing.
                              <br/>
                              This action cannot be UNDONE.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="div">
                        <div className="frame-4">
                          <div className="text-medium">
                            <p className="name-1">
                              The candidate will receive an SMS and Email with
                              <br/>
                              the next steps.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button">
                    <div className="frame-8">
                      <button
                        className="button-base-wrapper"
                        onClick={() => onClose()}
                      >
                        <span className="button-base">
                          <div className="text">Cancel</div>
                        </span>
                      </button>
                      <button
                        className="sbmit_btn"
                        onClick={() => onConfirm()}
                      >
                        <span className="button-base-2">
                          <div className="text-2">Publish</div>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PublishContentContainer>
  );
};

export default PublishContentModal;

export const PublishContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .box {
    // width: 541px;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }

  .box .dialogue {
    // width: 541px;
    overflow: hidden;
  }

  .box .overlap {
    width: 501px;
    height: 253px;
  }

  .box .overlap-wrapper {
    position: absolute;
    width: 100%;
    height: 253px;
    top: 0;
    left: 0;
  }

  .box .overlap-group {
    width: 511px;
    height: 365px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .box .content {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 253px;
    align-items: flex-start;
    gap: 24px;
    position: relative;
  }

  .box .header {
    position: relative;
    width: 100%;
    height: 243px;
  }

  .box .frame {
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
    padding: 24px 8px 0px 25px;
    display: inline-flex;
    position: relative;
  }

  .box .text-medium-title {
    position: relative;
    width: 358px;
    height: 24px;
  }

  .box .notifications {
    font-size: 22px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: -1px;
    left: 0;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    white-space: nowrap;
  }

  .box .div {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .frame-2 {
    align-items: flex-start;
    gap: 26px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .frame-3 {
    align-items: center;
    gap: 14px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .radio {
    position: relative;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid;
    border-color: #c4c7c7;
  }

  .box .notifications-wrapper {
    position: relative;
    width: 74px;
    height: 24px;
  }

  .box .text-wrapper {
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    position: absolute;
    top: -1px;
    left: 0;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    white-space: nowrap;
  }

  .box .div-wrapper {
    position: relative;
    width: 69px;
    height: 24px;
  }

  .box .frame-4 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .text-medium {
    position: relative;
    align-self: stretch;
    width: 100%;
    height: 23px;
  }

  .box .name {
    width: 467px;
    position: absolute;
    top: -1px;
    left: 0;
    opacity: 0.5;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 16px;
  }

  .box .frame-5 {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .frame-6 {
    align-items: flex-start;
    gap: 8px;
    padding: 0px 0px 0px 16px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .element-checkboxes {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .box .element-checkboxes-2 {
    position: relative;
    height: 24px;
  }

  .box .overlap-group-2 {
    top: 1567px;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 2880px;
  }

  .box .icon {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 11px;
    left: 11px;
  }

  .box .states {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
  }

  .box .object {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }

  .box .name-wrapper {
    height: 24px;
    position: relative;
    width: 419px;
  }

  .box .p {
    width: 419px;
    position: absolute;
    top: -1px;
    left: 0;
    opacity: 0.5;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    color: #1d1a22;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 16px;
  }

  .box .frame-7 {
    display: inline-flex;
    align-items: flex-end;
    gap: 8px;
    padding: 0px 0px 0px 16px;
    position: relative;
    flex: 0 0 auto;
  }

  .box .overlap-group-3 {
    top: 1535px;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 2880px;
  }

  .box .text-medium-2 {
    height: 21px;
    position: relative;
    width: 419px;
  }

  .box .overlap-group-4 {
    top: 1503px;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 2880px;
  }

  .box .button {
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    gap: 10px;
    padding: 14px 27px;
    flex: 0 0 auto;
    background-color: #ffffff;
    display: flex;
    position: relative;
  }

  .box .frame-8 {
    align-items: flex-start;
    gap: 12px;
    flex: 0 0 auto;
    display: inline-flex;
    position: relative;
  }

  .box .button-base-wrapper {
    width: 120px;
    align-items: flex-start;
    border-radius: 8px;
    /* 
    display: flex;
    position: relative;
    */
    /* all: unset; */
    outline: none;
    border: 0px;
    background: none;
    padding: 0px;
    box-sizing: border-box;
  }

  .box .button-base {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 18px;
    border-radius: 8px;
    border: 1px solid #002c71 !important;
    min-width: 100px;
    /* z-index: -10; */

    // align-items: center;
    // justify-content: center;
    // gap: 8px;
    // padding: 10px 18px;
    // flex: 1;
    // flex-grow: 1;
    // border-radius: 8px;
    // overflow: hidden;
    // border-color: #3a93f4;
    // box-shadow: 0px 1px 2px #1018280d;
    // display: flex;
    // position: relative;
    // all: unset;
    // box-sizing: border-box;
  }

  .box .text {
    color: #1d1a22;
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 20px;
    white-space: nowrap;
  }

  .box .button-base-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 18px;
    border-radius: 8px;
    min-width: 100px;

    // gap: 8px;
    // position: relative;
    // flex: 1;
    // flex-grow: 1;
    // background-color: #dc393a;
    // overflow: hidden;
    // border: 1px solid;
    // box-shadow: 0px 1px 2px #1018280d;
  }

  .box .text-2 {
    color: #ffffff;
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 20px;
    white-space: nowrap;
  }

  .box .content-wrapper {
    /* width: 511px; */
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }

  .box .frame-9 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 27px;
    padding: 24px 15px 0px 25px;
    position: relative;
  }

  .box .name-2 {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    font-family: "Poppins-Medium", Helvetica;
    font-weight: 400;
    color: #1d1a22;
    width: 80%;
  }

  .box .frame-10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
`;
