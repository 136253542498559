import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { ProfileType } from "../../types/userTypes";
import { checkTokenValidation } from "../auth/tokenReq";
import { HrActionType } from "../../types/hrTypes";

export const fetchHrActionsReq = async () => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": hrActionsReq->fetchHrActionsReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.getHrActionsKey}`);
    //  logfun(res.data, "res");
    const resData = res?.data;
    const _msg = resData.message || "";
    const _data = resData?.map((item: HrActionType) => ({
      notificationID: item?.notificationID || "",
      notificationMessage: item?.notificationMessage || "",
      candidateName: item?.candidateName || "",
      timeStamp: item?.timeStamp || "",
      emoticon: item?.emoticon || "",
      createdAt: item?.createdAt || "",
      updatedAt: item?.updatedAt || "",
      activityID: item?.activityID || "",
      employeeID: item?.employeeID || "",
      $activityID: item?.ActivityAction?.activityID || "",
      $activityName: item?.ActivityAction?.activityName || "",
      $candidateActionDate: item?.ActivityAction?.candidateActionDate || "",
      $candidateStatus: item?.ActivityAction?.candidateStatus || "",
      $candidateRemark: item?.ActivityAction?.candidateRemark || "",
      $recruiterStatus: item?.ActivityAction?.recruiterStatus || "",
      $recruiterRemark: item?.ActivityAction?.recruiterRemark || "",
      $createdAt: item?.ActivityAction?.createdAt || "",
      $updatedAt: item?.ActivityAction?.updatedAt || "",
      $offerID: item?.ActivityAction?.offerID || "",
      $candidateCardSetID: item?.ActivityAction?.candidateCardSetID || "",
      $candidateID: item?.ActivityAction?.candidateID || "",
      $employeeID: item?.ActivityAction?.employeeID || "",
    }));
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateHrActionByIDReq = async (
  params: { candidateID: number; activityID: number },
  data: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": hrActionsReq->updateHrActionByIDReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    const res = await authAxios.post(
      `${ApiKey.updateHrActionByIDKey}/${params?.candidateID}/hrAction/${params?.activityID}`,
      body
    );
    //  logfun(res.data, "res");
    const resData = res?.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

// export const changeDojReq = async (data: any) => {
//   const logfun = (d: any, t: string) =>
//     console.log(
//       JSON.stringify(d, null, 2),
//       new Date() + ": hrActionsReq->changeDojReq " + t
//     );
//   try {
//     await checkTokenValidation();
//     const body = data;
//     const res = await authAxios.post(`${ApiKey.changeDojKey}`, body);
//     logfun(res.data, "res");
//     const resData = res?.data;
//     const _msg = resData.message || "";
//     const _data = null;
//     return { error: false, data: _data, message: _msg, errorMsg: "" };
//   } catch (err: any) {
//     let error;
//     if (err.response) error = err.response.data.message || "Response error";
//     else if (err.request) error = "Request error" || err.request;
//     else error = "Something went wrong please try again later";
//     logfun(error, "ERROR");
//     throw { error: true, data: "", message: "", errorMsg: error };
//   }
// };
