import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/container";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/col";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineModeEditOutline } from "react-icons/md";

import styles from "./contentStyles.module.scss";
import { SagaActions } from "../../../redux/actionTypes";
import infoIcon from "../../../assets/svgIcons/Info-icon.svg";
import noEmoji from "../../../assets/emoji/no-emoji.svg";
import giftImg from "../../../assets/svgImages/gift.svg";
import hmmEmoji from "../../../assets/emoji/hmm-emoji.svg";
import maybeEmoji from "../../../assets/emoji/maybe-emoji.svg";
import yesEmoji from "../../../assets/emoji/yes-emoji.svg";
import veryMuchEmoji from "../../../assets/emoji/verymuch-emoji.svg";
import companycard from "../../../assets/svgImages/company-card.svg";
import addIcon from "../../../assets/svgIcons/add-icon.svg";
import {
  CandidateCardsetType,
  CandidateInfoType,
  CardsetWeekType,
} from "../../../types/candidateTypes";
import dayjs from "dayjs";
import { generateCardSetWeeks } from "../../../constants/weeksDateGenerate";
import { RootState } from "../../../redux/store";
import RenderIf from "../../../components/renderIf";
import { updateCandidateCardSetReq } from "../../../api/candidateReq/candidateCardSetReq";
import { toast } from "react-toastify";
// import VeryMuchEmoji from "../../../assets/emoji/verymuch-emoji.svg";
import NoEmoji from "../../../assets/emoji/no-emoji.svg";
import HmmEmoji from "../../../assets/emoji/hmm-emoji.svg";
import MaybeEmoji from "../../../assets/emoji/maybe-emoji.svg";
import YesEmoji from "../../../assets/emoji/yes-emoji.svg";

const CardSetsContent = (props: {
  data: CandidateCardsetType[];
  candidateDetails?: CandidateInfoType;
  onUpdate: any;
}) => {
  const { data, candidateDetails, onUpdate } = props;
  const Dispatch = useDispatch();
  const { candidateCardsetList, cardset, candidates } = useSelector(
    (_state: RootState) => _state
  );

  const [cardsetList, setcardsetList] = useState<any>({});
  const [weeksLabels, setweeksLabels] = useState<any>([]);
  const [editing, setEditing] = useState(false);

  const _createdDate = candidateDetails?.CandidateOffer?.createdAt;
  const _DOJ = candidateDetails?.CandidateOffer?.dateOfJoining;
  const _candidateID = candidateDetails?.Candidate?.candidateID;
  const _localCandidateCardSets = candidateCardsetList?.data || [];
  const _cardset = cardset?.data || [];
  const _candidateStatus =
    candidates.selectedCandidatesDetails.CandidateOffer.offerStatus;
  console.log("_candidateStatus", _candidateStatus);

  const updateCardSetDetails = async () => {
    if (_localCandidateCardSets?.length < 1) return setEditing(false);
    try {
      const restructureCardset = [];
      for (let i = 0; i < _localCandidateCardSets?.length; i++) {
        const $cardSet: any = _localCandidateCardSets[i];
        const noCardsetsInWeek = data?.filter(
          (item) => item?.weekNumber === $cardSet?.weekNumber
        );
        restructureCardset.push({
          ...$cardSet,
          cardSetOrder: noCardsetsInWeek?.length + $cardSet?.cardSetOrder,
          candidateID: _candidateID,
        });
      }

      console.log(restructureCardset, "restructureCardset");
      const res = await updateCandidateCardSetReq(restructureCardset);
      toast.success(res?.message);
      setEditing(false);
      Dispatch({ type: SagaActions.CLEAR_CANDIDATE_CARDSET_FORM });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "prod",
          message: res?.message,
          fileName: "cardSetsContent",
          methodName: "updateCardSetDetails",
          type: "info",
        },
      });
      onUpdate();
    } catch (error: any) {
      setEditing(false);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "cardSetsContent",
          methodName: "updateCardSetDetails",
          type: "error",
        },
      });
    }
  };

  const groupByWeekFun = () => {
    const allCardSets = [...data, ..._localCandidateCardSets];
    const result = allCardSets?.reduce((r: any, a) => {
      const fieldName = `${a?.weekNumber}`;
      r[fieldName] = r[fieldName] || [];
      r[fieldName].push(a);
      return r;
    }, {});
    setcardsetList({ ...result });
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      addCardset: (data: CardsetWeekType) => {
        Dispatch({ type: SagaActions.SHOW_CARDSET_PICKER_MODAL });
        Dispatch({
          type: SagaActions.SELECT_CARDSET_WEEK,
          payload: data,
        });
      },
    };
    fun[key](data);
  };

  useEffect(() => {
    groupByWeekFun();
  }, [data, candidateCardsetList?.data]);

  useEffect(() => {
    if (data?.length < 1) return;
    Dispatch({
      type: SagaActions.ADD_REMOTE_CANDIDATES_CARDSET_LIST,
      payload: data,
    });
  }, [data]);

  useEffect(() => {
    if (!_createdDate || !_DOJ) return;
    const weeks = generateCardSetWeeks(
      dayjs(_DOJ).format("YYYY/MM/DD"),
      dayjs(_createdDate).format("YYYY/MM/DD")
    );
    setweeksLabels(weeks);
  }, []);

  return (
    <div className={`${styles.cardSetsContent}`}>
      <div className="d-flex flex-column align-items-end">
        <div>
          {editing ? (
            <div>
              <button
                className={`sbmit_btn sm-text px-2`}
                onClick={updateCardSetDetails}
              >
                Update
              </button>
            </div>
          ) : (
            // <div
            //   className={`p-1`}
            //   style={{ cursor: "pointer" }}
            //   onClick={() => setEditing(true)}
            // >
            //   <MdOutlineModeEditOutline className={``} color="#3a93f4" />
            // </div>
            <button
              className={`${styles.edit_btn} md-text mt-2`}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setEditing(
                  _candidateStatus == "Joined"
                    ? false
                    : _candidateStatus == "Dropout"
                    ? false
                    : true
                )
              }
            >
              <MdOutlineModeEditOutline className={`me-2`} />
              Edit
            </button>
          )}
        </div>
      </div>

      <Container fluid>
        {weeksLabels?.map((item: CardsetWeekType, idx: number) => (
          <RenderIf
            key={idx}
            isShow={cardsetList[`${item?.weekNumber}`]?.length > 0 || editing}
            // isShow={true}
          >
            <Row key={idx} className={`${styles.card_sets_list_block} my-1`}>
              <Col className={`m-0 py-0`} sm={12} md={3} lg={2}>
                <label className={`x-sm-text x-light-text`}>
                  {idx > 5 ? "Last" : "Week " + item?.weekNumber}
                </label>
                <label className={`x-sm-text x-light-text`}>
                  {dayjs(item?.dateFrom).format("MMM DD")} -{" "}
                  {dayjs(item?.dateTo).format("MMM DD")}
                </label>
              </Col>
              {cardsetList[`${item?.weekNumber}`]?.map(
                (itm: any, idx: number) => {
                  const cardSetIs = _cardset.find((cs) => {
                    return cs?.cardSetID === itm?.cardSetID;
                  });
                  const hasCandidateRemark =
                    itm?.ActivityActions?.[0]?.candidateRemark;
                  return (
                    <Col
                      key={idx}
                      className={`my-1 ${styles.set_box} px-0`}
                      sm={12}
                      md={3}
                      lg={2}
                    >
                      <div className={`d-flex align-items-center w-100`}>
                        <div className={`${styles.small_icon_box}`}>
                          <img
                            src={
                              hasCandidateRemark == "Very Much"
                                ? veryMuchEmoji
                                : hasCandidateRemark == "Yes"
                                ? YesEmoji
                                : hasCandidateRemark == "Maybe"
                                ? MaybeEmoji
                                : hasCandidateRemark == "Hmm"
                                ? HmmEmoji
                                : hasCandidateRemark == "NO"
                                ? NoEmoji
                                : companycard
                            }
                          />
                        </div>
                        <label className={`mx-1 x-sm-text`}>
                          {itm?.CardSet?.cardSetName ||
                            cardSetIs?.cardSetName ||
                            ""}
                        </label>
                      </div>
                    </Col>
                  );
                }
              )}
              {/* {idx === 1 && (
                <Col className={`${styles.image_column}`} sm={12} md={3} lg={2}>
                  <img src={giftImg} alt="Your Alt Text" />
                </Col>
              )} */}
              {weeksLabels.length > 1 &&
                (idx === weeksLabels.length - 2 || idx === 1) && (
                  <Col
                    className={`my-1 ${styles.image_column}`}
                    sm={12}
                    md={3}
                    lg={2}
                  >
                    <img src={giftImg} alt="Your Alt Text" />
                  </Col>
                )}
              <RenderIf isShow={editing}>
                <Col
                  sm={1}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div
                    className={`${styles.img_block} border d-flex align-items-center justify-content-center`}
                    style={{
                      height: "25px",
                      width: "25px",
                      borderRadius: "5px",
                    }}
                    onClick={() => _onPress({ key: "addCardset", data: item })}
                  >
                    <img
                      src={addIcon}
                      style={{
                        height: "80%",
                        width: "80%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Col>
              </RenderIf>
            </Row>
          </RenderIf>
        ))}
      </Container>
    </div>
  );
};

export default CardSetsContent;
