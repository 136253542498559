import React, { useState, useEffect, memo } from "react";
import { IoClose, IoChevronDown, IoChevronUp } from "react-icons/io5";

import "./cardSets.scss";
import cardSets from "../../../assets/svgImages/cardsets.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import RenderIf from "../../renderIf";
import { SagaActions } from "../../../redux/actionTypes";

const CardSets = (rootProps: any) => {
  const { onClose } = rootProps;

  const Dispatch = useDispatch();
  const { cardset, candidateCardsetList } = useSelector(
    (_state: RootState) => _state
  );
  const [cardSetList, setcardSetList] = useState({});
  const [selectedCardSets, setselectedCardSets] = useState<number[]>([]);
  const [remoteCardSetIDs, setremoteCardSetIDs] = useState<number[]>([]);
  const [presentWeekCardSetIDs, setpresentWeekCardSetIDs] = useState<number[]>(
    []
  );

  // const _weekNumber = candidateCardsetList?.selectedWeek || 0;

  const groupCardsetFun = () => {
    const result = cardset?.data?.reduce((r: any, a: any) => {
      r[a?.cardSetCategory?.categoryName] =
        r[a?.cardSetCategory?.categoryName] || [];
      r[a?.cardSetCategory?.categoryName].push(a);
      return r;
    }, {});
    console.log(result, "cardset groups");
    setcardSetList({ ...result });
    // const initialCardSet = cardset?.data[0];
    // if (!initialCardSet) return;
    // navigation(ROUTE_NAMES.companyIntro, { state: initialCardSet });
  };

  useEffect(() => {
    if (cardset?.data?.length < 1) return;
    groupCardsetFun();
  }, [cardset?.data]);

  const listDisabledCardsets = (localIDs: number[], remoteIDs: number[]) => {
    const weekCardsets = candidateCardsetList?.data?.filter(
      (item) =>
        item?.weekNumber !== candidateCardsetList?.selectedWeek?.weekNumber
    );
    const weekCardsetIDs =
      weekCardsets?.length > 0
        ? weekCardsets?.map((item) => item?.cardSetID)
        : [];
    // const weekCardsetIDs =
    //   weekCardsets?.length > 0
    //     ? weekCardsets?.map((item) => ({
    //         id: item?.cardSetID,
    //         week: item?.weekNumber,
    //       }))
    //     : [];
    setpresentWeekCardSetIDs([...weekCardsetIDs]);
  };

  useEffect(() => {
    let _cardsetIds: number[] = [];
    let _remoteCardsetIds: number[] = [];
    if (candidateCardsetList?.data?.length > 0) {
      _cardsetIds = candidateCardsetList?.data?.map((item) => item?.cardSetID);
      setselectedCardSets([..._cardsetIds]);
    }

    if (candidateCardsetList?.remoteData?.length > 0) {
      _remoteCardsetIds = candidateCardsetList?.remoteData?.map(
        (item) => item?.cardSetID
      );
      setremoteCardSetIDs([..._remoteCardsetIds]);
    }

    listDisabledCardsets(_cardsetIds, _remoteCardsetIds);
  }, [candidateCardsetList?.selectedWeek]);

  // useEffect(() => {
  //   if (candidateCardsetList?.remoteData?.length < 1) return;
  //   // const weekCardsets = candidateCardsetList?.remoteData?.filter(
  //   //   (item) =>
  //   //     item?.weekNumber === candidateCardsetList?.selectedWeek?.weekNumber
  //   // );
  //   const weekCardsets = candidateCardsetList?.remoteData || [];

  //   const cardsetIds =
  //     weekCardsets?.length > 0
  //       ? weekCardsets?.map((item) => item?.cardSetID)
  //       : [];
  //   setremoteCardSetIDs([...cardsetIds]);
  // }, [candidateCardsetList?.selectedWeek]);

  const CheckBoxItem = memo(({ item }: any) => {
    const [checked, setchecked] = useState(false);
    const onChecked = (e: any) => {
      console.log(e.target.checked, "e.target.checked");
      if (e.target.checked) {
        setselectedCardSets((pre) =>
          pre?.length > 0 ? [...pre, item?.cardSetID] : [item?.cardSetID]
        );
        if (
          candidateCardsetList?.selectedWeek?.weekNumber !== undefined ||
          candidateCardsetList?.selectedWeek?.weekNumber !== null
        ) {
          Dispatch({
            type: SagaActions.UPDATE_CANDIDATES_CARDSET_LIST,
            payload: {
              ...candidateCardsetList?.selectedWeek,
              cardSetOrder: selectedCardSets?.length + 1,
              cardSetID: item?.cardSetID,
            },
          });
          Dispatch({
            type: SagaActions.ADDED_DEFAULT_CANDIDATES_CARDSET_LIST,
            payload: false,
          });
        }
      }
      if (!e.target.checked) {
        const newList = selectedCardSets.filter(
          (itm) => itm !== item?.cardSetID
        );
        setselectedCardSets([...newList]);

        Dispatch({
          type: SagaActions.DELETE_CANDIDATES_CARDSET_LIST,
          payload: {
            ...candidateCardsetList?.selectedWeek,
            cardSetID: item?.cardSetID,
          },
        });
      }
    };
    return (
      <div className="card-set-box">
        <input
          disabled={
            remoteCardSetIDs.includes(item?.cardSetID) ||
            presentWeekCardSetIDs.includes(item?.cardSetID)
          }
          type={"checkbox"}
          value={item?.cardSetID + ""}
          checked={
            selectedCardSets.includes(item?.cardSetID) ||
            remoteCardSetIDs.includes(item?.cardSetID)
          }
          onChange={onChecked}
        />
        <p className="mx-2">{item?.cardSetName}</p>
      </div>
    );
  });

  const CategorySelectBlock = memo((props: any) => {
    const { item, cardSetList } = props;

    const [showList, setshowList] = useState(true);

    return (
      <div className="CategorySelectBlock">
        <header className="cat-header" onClick={() => setshowList(!showList)}>
          <p className="bold">{item}</p>
          {showList ? <IoChevronUp /> : <IoChevronDown />}
        </header>
        {showList ? (
          <div>
            {cardSetList[item]?.map((itm: any, idx: number) => (
              <CheckBoxItem item={itm} />
            ))}
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <div className=" h-100 align-items-end cardSets">
      <div className="h-100  content-block">
        <header className="header">
          {/* <div className="icon-box">
            <img src={cardSets} />
          </div> */}
          <p className="lg-text">Add CardSets</p>
          <div style={{ flex: 1 }}></div>
          <IoClose onClick={() => onClose()} />
        </header>
        <div>
          <div className="mx-2 my-3 py-3">
            <p>Choose the CardSet or sets to add to this week</p>
          </div>
          <RenderIf isShow={Object.keys(cardSetList)?.length > 0}>
            <div>
              {Object.keys(cardSetList)?.map((item, idx) => (
                <CategorySelectBlock
                  key={idx}
                  item={item}
                  cardSetList={cardSetList}
                />
              ))}
            </div>
          </RenderIf>
        </div>
      </div>
    </div>
  );
};

export default CardSets;
