import React, { useState } from "react";
import { useMemo, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { BsCheckLg, BsX, BsPencil, BsTrash } from "react-icons/bs";
import deleteIcon from "../../../assets/svgIcons/delete-icon.svg";
import editIcon from "../../../assets/svgIcons/edit.svg";
import editlight from "../../../assets/svgIcons/editLight-icon.svg";

import styles from "./table.module.scss";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { HrActionModifiedType } from "../../../types/hrTypes";
import {
  // changeDojReq,
  updateHrActionByIDReq,
} from "../../../api/hrReq/hrActionsReq";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../../redux/actionTypes";
import { RootState } from "../../../redux/store";

const EditableTable = ({
  columns,
  data,
  setData,
  handleButtonClick,
  updateRecruiterFun,
}: any) => {
  const totalRecruiterLists = data.length;
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    setPageSize,
  }: any = useTable({ columns, data }, usePagination);

  const handleInputChange = (event: any, row: any, columnId: any) => {
    const newData = data.map((rowData: HrActionModifiedType) => {
      if (rowData.activityID === row.original.activityID) {
        return {
          ...rowData,
          [columnId]: event.target.value,
        };
      }
      return rowData;
    });
    setData(newData);
  };

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers.map((column: any) => (
                <th className={`sm-text`} {...column?.getHeaderProps()}>
                  {column?.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page?.map((row: any) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any, idx: number) => {
                  if (cell.column.editEnable) {
                    if (
                      cell.column.id === "$recruiterRemark" &&
                      row.original.isEditing
                    ) {
                      return (
                        <td key={idx} {...cell.getCellProps}>
                          <div className={`d-flex align-items-center`}>
                            <textarea
                              defaultValue={cell.value}
                              className="sm-text flex-grow-1"
                              onChange={(e) =>
                                handleInputChange(e, row, cell.column.id)
                              }
                            />

                            <div
                              className="py-1"
                              onClick={() => {
                                updateRecruiterFun(row.original);
                              }}
                            >
                              <BsCheckLg
                                className="mx-2"
                                color={"#002c71"}
                                size={20}
                              />
                            </div>
                            <div
                              className="py-1"
                              onClick={() => {
                                handleButtonClick("cancel", row.original);
                              }}
                            >
                              <BsX className="mx-2" color="#D92D20" size={20} />
                            </div>
                          </div>
                        </td>
                      );
                    }
                    if (cell.column.id === "$recruiterStatus") {
                      return (
                        <td key={idx} {...cell.getCellProps}>
                          <select
                            className="sm-text"
                            defaultValue={cell.value}
                            // id={cell.value}
                            disabled={!row.original.isEditing}
                            onChange={(e) =>
                              handleInputChange(e, row, cell.column.id)
                            }
                          >
                            <option className="sm-text" value={"New"}>
                              New
                            </option>
                            <option className="sm-text" value={"In Progress"}>
                              In Progress
                            </option>
                            <option className="sm-text" value={"Complete"}>
                              Completed
                            </option>
                          </select>
                        </td>
                      );
                    }

                    return (
                      <td key={idx} {...cell.getCellProps} className="sm-text">
                        {cell.render("Cell")}
                      </td>
                    );
                  }
                  return (
                    <td key={idx} {...cell.getCellProps}>
                      <p className="sm-text">{cell.render("Cell")}</p>
                    </td>
                  );
                  // return (
                  //   <td key={idx} {...cell.getCellProps}>
                  //     {cell.column.editEnable ? (
                  //       row.original.isEditing ? (
                  //         <div className={`d-flex align-items-center`}>
                  //           <input
                  //             type="text"
                  //             defaultValue={cell.value}
                  //             className="sm-text"
                  //             onChange={(e) =>
                  //               handleInputChange(e, row, cell.column.id)
                  //             }
                  //           />
                  //           {cell.column.id === "$recruiterRemark" ? (
                  //             <>
                  //               <div
                  //                 className="py-1"
                  //                 onClick={() => {
                  //                   updateRecruiterFun(row.original);
                  //                 }}
                  //               >
                  //                 <BsCheckLg
                  //                   className="mx-2"
                  //                   color={"#3A93F4"}
                  //                   size={20}
                  //                 />
                  //               </div>
                  //               <div
                  //                 className="py-1"
                  //                 onClick={() => {
                  //                   handleButtonClick("cancel", row.original);
                  //                 }}
                  //               >
                  //                 <BsX
                  //                   className="mx-2"
                  //                   color="#D92D20"
                  //                   size={20}
                  //                 />
                  //               </div>
                  //             </>
                  //           ) : (
                  //             ""
                  //           )}
                  //         </div>
                  //       ) : (
                  //         cell.render("Cell")
                  //       )
                  //     ) : (
                  //       cell.render("Cell")
                  //     )}
                  //   </td>
                  // );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex align-items-center mt-3">
        <button
          style={{ width: 35 }}
          className="sbmit_btn_outline ms-2"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <label>{"<"}</label>
        </button>{" "}
        <button
          style={{ width: 35 }}
          className="sbmit_btn_outline ms-2"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <label>{">"}</label>
        </button>{" "}
        <span className="ms-2">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
      </div>
    </div>
  );
};

{
  /* <select
            defaultValue={row.original.Status}
            id={cell.value}
            // disabled={!row.original.isEditing ? true : false}
          >
            <option value={"New"}>New</option>
            <option value={"In Progress"}>In Progress</option>
          </select> */
}

// ===========================================================
function HrActionTable({ dataIs, dataLength, onDeleteRow }: any) {
  const Dispatch = useDispatch();
  const { companyProfile } = useSelector((_state: RootState) => _state);

  const updateRecruiterFun = async (recrData: HrActionModifiedType) => {
    try {
      console.log(recrData, "HrActionModified data");

      const params = {
        candidateID: recrData?.$candidateID,
        activityID: recrData?.$activityID,
      };
      const body = {
        status: recrData?.$recruiterStatus,
        remark: recrData?.$recruiterRemark,
      };
      // updateDOJFun(recrData);
      const res = await updateHrActionByIDReq(params, body);
      toast.success(res?.message);
      handleButtonClick("save", recrData);
      Dispatch({
        type: SagaActions.ADD_HR_ACTIONS,
      });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "hrActionTable",
          methodName: "updateRecruiterFun",
          type: "info",
        },
      });
    } catch (error: any) {
      console.log(error, "error in onDeleteRecruiter");
      handleButtonClick("cancel", recrData);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "hrActionTable",
          methodName: "updateRecruiterFun",
          type: "error",
        },
      });
    }
  };

  // const updateDOJFun = async (recrData: HrActionModifiedType) => {
  //   try {
  //     const dojIs = recrData?.notificationMessage?.split(
  //       "Date of Joining (DOJ) to"
  //     );
  //     if (!dojIs[1]) return;
  //     const body = {
  //       companyID: companyProfile?.data?.company?.companyID,
  //       candidateID: recrData?.$candidateID,
  //       newDateOfJoining: new Date(dojIs[1].trim()).toDateString(),
  //     };

  //     const res = await changeDojReq(body);
  //     toast.success(res?.message);
  //     Dispatch({
  //       type: SagaActions.INFO,
  //       payload: {
  //         env: "dev",
  //         message: res?.message,
  //         fileName: "hrActionTable",
  //         methodName: "updateDOJFun",
  //         type: "info",
  //       },
  //     });
  //   } catch (error: any) {
  //     toast.error(error?.errorMsg);
  //     Dispatch({
  //       type: SagaActions.ERROR,
  //       payload: {
  //         env: "prod",
  //         message: error?.errorMsg,
  //         fileName: "hrActionTable",
  //         methodName: "updateDOJFun",
  //         type: "error",
  //       },
  //     });
  //   }
  // };

  const columnsData = [
    {
      Header: "Date",
      accessor: "timeStamp",
      editEnable: false,
      Cell: ({ cell }: any) => {
        return (
          <p className="sm-text">
            {`${dayjs(cell?.row?.original?.timeStamp).format("MMM DD, YYYY")}`}
          </p>
        );
      },
    },
    { Header: "Candidate Name", accessor: "candidateName" },
    { Header: "Request", accessor: "notificationMessage", editEnable: false },
    {
      Header: "Status",
      accessor: "$recruiterStatus",
      editEnable: true,
    },
    { Header: "Remarks", accessor: "$recruiterRemark", editEnable: true },
    // {
    //   Header: "Status",
    //   accessor: "status",
    //   Cell: ({ cell }: any) => {
    //     const status = cell?.value;
    //     return <p className="sm-text">{status ? "active" : "invited"}</p>;
    //   },
    // },
    {
      Header: "Action",
      accessor: "",
      Cell: ({ row, column, cell }: any) => {
        const _userId = cell?.row?.original?.userID;
        const value = cell?.value;

        return (
          <div className={`d-flex align-items-center`}>
            <div
              className={`${styles.small_icon_box} d-flex me-1`}
              onClick={() =>
                row.original.isEditing
                  ? {}
                  : handleButtonClick("edit", row.original)
              }
            >
              <img src={!row.original.isEditing ? editIcon : editlight} />
            </div>
          </div>
        );
      },
    },
  ];

  const [data, setdata] = useState<any>([]);

  const onDeleteRecruiter = async (id: number) => {
    try {
      // const res = await deleteRecruiterByIdReq({ id });
      // toast.success(res?.message);
      // onDeleteRow({ id });
    } catch (error: any) {
      console.log(error, "error in onDeleteRecruiter");
      if (error?.errorCode + "" === "403") {
        Swal.fire({
          icon: "warning",
          text: error?.errorMsg,
        });
        return;
      }
      toast.error(error?.errorMsg);
    }
  };

  const handleButtonClick = (action: any, row: any) => {
    const newData = data.map((rowData: HrActionModifiedType) => {
      if (rowData.activityID === row.activityID) {
        if (action === "edit") {
          return { ...rowData, isEditing: true, prevData: { ...rowData } };
        } else if (action === "cancel") {
          return { ...rowData, isEditing: false };
        } else if (action === "save") {
          const { ...updatedRowData } = rowData;
          return {
            ...updatedRowData,
            isEditing: false,
          };
        }
      }
      return rowData;
    });
    setdata(newData);
  };

  useEffect(() => {
    const completeStatusData = dataIs.filter(
      (item: any) => item.$recruiterStatus === "Complete"
    );
    const otherStatusData = dataIs.filter(
      (item: any) => item.$recruiterStatus !== "Complete"
    );
    setdata(dataIs ? [...otherStatusData, ...completeStatusData] : []);
  }, [dataIs]);

  return (
    <div className={`${styles.MyTable}`}>
      <EditableTable
        columns={columnsData}
        data={data}
        setData={setdata}
        handleButtonClick={handleButtonClick}
        updateRecruiterFun={updateRecruiterFun}
      />
    </div>
  );
}

export default HrActionTable;
