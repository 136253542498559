import { createSlice } from "@reduxjs/toolkit";
import { getNotificationReq } from "../../../api/notifications/notificationReq";

import { call, put } from "redux-saga/effects";
import { ShowAlert } from "../alertWindowSlice";
import { ApiResType } from "../../../types/apiTypes";
import { HrActionType } from "../../../types/hrTypes";
import { fetchHrActionsReq } from "../../../api/hrReq/hrActionsReq";

export interface HrActionSlicetypes {
  error: boolean;
  message: string;
  data: HrActionType[];
  loading: boolean;
}

const initialState: HrActionSlicetypes = {
  error: false,
  message: "",
  data: [],
  loading: false,
};

const HrActionSlice = createSlice({
  name: "Notification",
  initialState: initialState,
  reducers: {
    AddHrActions: (
      state: HrActionSlicetypes,
      { payload }: { payload: HrActionType[] }
    ) => {
      state.error = false;
      state.data = payload ? [...payload] : [];
      state.loading = false;
      return state;
    },
    StartLoading: (state: HrActionSlicetypes) => {
      state.loading = true;
      return state;
    },
    StopLoading: (state: HrActionSlicetypes) => {
      state.loading = false;
      return state;
    },
    ClearData: (state: HrActionSlicetypes) => {
      state = initialState;
      return state;
    },
  },
});

export const { AddHrActions, StartLoading, StopLoading, ClearData } =
  HrActionSlice.actions;

export default HrActionSlice.reducer;

export function* AddHrActionsList({ payload }: any) {
  interface ApiResType2 extends ApiResType {
    recent: any;
  }
  try {
    yield put(StartLoading());
    const res: ApiResType2 = yield call(fetchHrActionsReq);

    yield put(AddHrActions(res?.data));
  } catch (error: any) {
    yield put(AddHrActions([]));
    yield put(StopLoading());
  }
}
