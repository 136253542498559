import React, { useState } from "react";
import ReactPlayer from "react-player";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  HiOutlinePlay,
  HiOutlinePause,
  HiOutlineSpeakerWave,
  HiOutlineSpeakerXMark,
} from "react-icons/hi2";

import styles from "../../myAccount/myAccount.module.scss";
import RenderIf from "./../../../components/renderIf";
import { Col, Row, Spinner } from "react-bootstrap";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";

const WelcomePage = () => {
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const { profile } = useSelector((_state) => _state);
  const [videoState, setvideoState] = useState("loaded");
  const [inMute, setinMute] = useState(false);
  const [videoDuration, setvideoDuration] = useState(0);
  const [playedSeconds, setplayedSeconds] = useState(0);
  const [videoError, setvideoError] = useState("");
  const [showVideoControles, setshowVideoControles] = useState(false);
  const showPlayBtn = videoState === "loaded" || videoState === "paused";
  const loggedinDate = localStorage.getItem("@loggedDate");
  const [updateLoder, setupdateLoder] = useState(false);

  const _profile = profile?.data || null;

  const onReadyFun = (e) => {
    console.log(e.getDuration(), "onReady");
    setvideoError("");
    const durationIs = e.getDuration();
    setvideoDuration(durationIs.toFixed(1));
  };
  const onPlayFun = (e) => {
    setvideoState("playing");
  };
  const onPauseFun = (e) => {
    setvideoState("paused");
  };
  const onStopFun = (e) => {};
  const onEndFun = (e) => {};
  const onStartFun = (e) => {};

  const onMuteFun = (e) => {
    setinMute(!inMute);
  };

  const _onPress = ({ key, data }) => {
    const fun = {
      null: (data) => {},
      play: (e) => onPlayFun(e),
      pause: (e) => onPauseFun(e),
      stop: (e) => onStopFun(e),
      end: (e) => onEndFun(e),
      mute: (e) => onMuteFun(e),
      ready: (e) => onReadyFun(e),
      continue: (data) => {
        setupdateLoder(true);
        // const parentPath = currentPath.substring(
        //   0,
        //   currentPath.lastIndexOf("/")
        // );
        // navigate(`${parentPath}/${ROUTE_NAMES.companyProfile}`);
        setupdateLoder(false);
      },
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.WelcomePage} `}>
      <div className="d-flex justify-content-end  ">
        <p className="light-text">Logged In</p>
        <p className="xsmall-text">: {loggedinDate}</p>
      </div>
      <br />
      <div className="d-flex flex-column align-items-center">
        <h4>Hi {`${_profile?.firstName} ${_profile?.lastName}`}</h4>
        <p className="text-center">
          Welcome to PeopleX.tech
          <br /> Here's a crisp 2 minutes video to explain how things works.
        </p>
        <div className={`${styles.player_container}  w-75`}>
          <RenderIf isShow={!videoError}>
            <div
              className={`${styles.video_container} `}
              onMouseLeave={() => setshowVideoControles(!showVideoControles)}
              onMouseEnter={() => setshowVideoControles(!showVideoControles)}
            >
              <ReactPlayer
                url="https://youtu.be/7PaXNEj4LFE"
                width={"80%"}
                height={"100%"}
                playing={!showPlayBtn}
                volume={0.5}
                muted={inMute}
                controls={showVideoControles}
                playIcon={<button>play</button>}
                onReady={(e) => _onPress({ key: "ready", data: e })}
                onStart={(e) => {
                  _onPress({ key: "play" });
                }}
                onPause={(e) => {
                  _onPress({ key: "pause" });
                }}
                onEnded={(e) => {
                  _onPress({ key: "end" });
                }}
                onProgress={(e) => {
                  setplayedSeconds(e.playedSeconds.toFixed(1));
                }}
                onError={(e) => {
                  setvideoError("Something went wrong. Try after sometime.");
                  console.log(e, "video error");
                }}
              />
            </div>
          </RenderIf>
          <RenderIf isShow={!!videoError}>
            <div
              className="flex-grow-1 center d-flex"
              style={{ height: "300px" }}
            >
              <p className="x-sm-text text-danger">{videoError}</p>
            </div>
          </RenderIf>

          {/* Continue Button */}
          <Row className={`my-3`}>
            <Col className={`${styles._col} mt-3 p-0`} sm={12} md={12} lg={12}>
              <div className={`d-flex justify-content-center `}>
                <div className="w-75 d-flex justify-content-center">
                  <button
                    className={`btn btn-primary w-25 py-2 sm-text d-flex align-items-center justify-content-center`}
                    type="submit"
                    onClick={() => _onPress({ key: "continue" })}
                  >
                    Continue
                    {!!updateLoder && (
                      <div className="ms-2">
                        <Spinner animation="border" variant="white" size="sm" />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          {/* if you want to show the external controles send true to isShown property */}
          <RenderIf isShow={false}>
            <div
              className={`${styles.video_controler} mt-3 p-1 d-flex align-items-center`}
            >
              <RenderIf
                isShow={
                  (false && videoState === "loaded") || videoState === "paused"
                }
              >
                <button
                  className={`${styles.icon_btn}`}
                  onClick={() => _onPress({ key: "play" })}
                >
                  <HiOutlinePlay color="#3a93f4" />
                </button>
              </RenderIf>
              <RenderIf isShow={videoState === "playing"}>
                <button
                  className={`${styles.icon_btn}`}
                  onClick={() => _onPress({ key: "pause" })}
                >
                  <HiOutlinePause color="#3a93f4" />
                </button>
              </RenderIf>

              <div className={`${styles.progress_block}`}>
                <Col className="px-3">
                  <ProgressBar
                    now={playedSeconds}
                    style={{ height: "3px" }}
                    max={videoDuration}
                  />
                </Col>
              </div>

              <button
                className={`${styles.icon_btn}`}
                onClick={() => _onPress({ key: "mute" })}
              >
                {!inMute ? (
                  <HiOutlineSpeakerWave color="#3a93f4" />
                ) : (
                  <HiOutlineSpeakerXMark color="#3a93f4" />
                )}
              </button>
            </div>
          </RenderIf>
        </div>
      </div>
    </div>
  );
};

export default memo(WelcomePage);
