import React, { useState, useEffect, useMemo } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillInfoCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { useForm, SubmitHandler } from "react-hook-form";
import styles from "../myAccount.module.scss";

import LoginLog from "../../../components/microComponents/loginLog";
import {
  recruiterDetails,
  testRecruiterDetails,
} from "../constant/initialValues";
import { useDispatch, useSelector } from "react-redux";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useNavigate, useHistory, useLocation } from "react-router-dom";
import {
  addRecruiterReq,
  getRecruiterReq,
} from "../../../api/recruiter/recruiterReq";
import { validate } from "../../../constants/validationExp";
import RecruiterTable from "./table/recruiterTable";
import RenderIf from "../../../components/renderIf";
import { SagaActions } from "../../../redux/actionTypes";
import { COUNTRY_CODES } from "../../../constants/countryCodes";
import indianFlag from "../../../assets/icons/indian_flag.png";
import usaFlag from "../../../assets/icons/usa_flag.png";
import { FaAngleDown } from "react-icons/fa6";
import MobileNoLayout from "../../../components/compLayouts/mobileNoLayout";

const AddRecruiter = () => {
  const Dispatch = useDispatch();
  // const history = useHistory();
  const currentPath = useLocation().pathname;
  const navigation = useNavigate();
  const { candidates, companyProfile } = useSelector((_state) => _state);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return recruiterDetails;
    }, []),
  });
  const phNo = watch("mobile");
  const _numberError = errors.mobile?.message;
  const _eadminStatsStatus = companyProfile?.data?.company?.Status;

  const [offerLetterFile, setofferLetterFile] = useState();
  const [recruitersList, setrecruitersList] = useState([]);

  const [recruiterDetailsIs, setrecruiterDetailsIs] = useState(
    testRecruiterDetails || recruiterDetails
  );

  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [selectedTimeZone, setSelectedTimeZone] = useState("India (GMT+5:30)");

  const handleCountryCodeChange = (e) => {
    const selectedCountryCode = e.countryCode;
    const selectedCountry = COUNTRY_CODES.find(
      (country) => country.countryCode === selectedCountryCode
    );
    if (selectedCountry) {
      setSelectedCountryCode(selectedCountry.countryCode);
      setSelectedTimeZone(selectedCountry.countryTimeZone[0].timeZone);
    }
  };

  const selectedCountry = COUNTRY_CODES?.find(
    (country) => country.countryCode === selectedCountryCode
  );

  const handleTimeZoneChange = (e) => {
    setSelectedTimeZone(e.target.value);
  };

  const onDeleteRowFun = (rowId) => {
    const _newList = recruitersList.filter((i) => i.userID !== rowId);
    setrecruitersList([..._newList]);
  };

  const onPresonalDataChanage = (key, value) => {
    setrecruiterDetailsIs((pre) => {
      const newData = { ...pre };
      newData[key] = value;
      return newData;
    });
  };

  const getRecruitersListFun = async () => {
    try {
      const res = await getRecruiterReq();
      setrecruitersList([...res.data]);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "addRecruiter",
          methodName: "getRecruitersListFun",
          type: "info",
        },
      });
    } catch (error) {
      setrecruitersList([]);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "addRecruiter",
          methodName: "getRecruitersListFun",
          type: "error",
        },
      });
    }
  };

  const onSubmitFun = async (formData) => {
    try {
      console.log(formData, "formData AddRecruiter");

      const body = {
        ...formData,
        countryCode: selectedCountryCode,
        TimeZone: selectedTimeZone,
        companyID: 202300011,
        password: "12345",
        role: "Recruiter",
      };
      const res = await addRecruiterReq(body);
      toast.success(res?.message);
      getRecruitersListFun();
      reset();
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "addRecruiter",
          methodName: "onSubmitFun",
          type: "info",
        },
      });
    } catch (error) {
      console.log(error, "AddRecruiter->AddRecruiter->onSubmitFun error");
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "addRecruiter",
          methodName: "onSubmitFun",
          type: "error",
        },
      });
    }
  };

  const onContinue = () => {
    Dispatch({
      type: SagaActions.UPDATE_NAVBAR_SEETINGS,
      payload: { clickableMenu: true },
    });
    navigation(`/${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.dashboard}`);
  };

  const _onPress = ({ key, data }) => {
    const fun = {
      submit: (data) => onContinue(),
      invite: (data) => onSubmitFun(),
    };
    fun[key](data);
  };

  useEffect(() => {
    getRecruitersListFun();
  }, []);

  return (
    <div className={`${styles.AddRecruiter} px-3`}>
      <ToastContainer />
      <Container>
        <LoginLog />
        <Row className={`my-2`}>
          <Col className={`${styles._col} mt-2`}>
            <p className={`mb-1 lg-text`}>Adding Recruiters</p>
          </Col>
        </Row>
        <form
          onSubmit={handleSubmit((data) => {
            onSubmitFun(data);
          })}
        >
          <Row className={`my-0`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>
                First Name<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.firstName?.message && "border-danger"
                }`}
                placeholder=""
                {...register("firstName", { required: "First Name required" })}
                id="firstName"
              />
              {errors?.firstName?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.firstName?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>
                Last Name<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.lastName?.message && "border-danger"
                }`}
                placeholder=""
                {...register("lastName", { required: "Last Name required" })}
                id="lastName"
              />
              {errors?.lastName?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.lastName?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>
                Email ID<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.email?.message && "border-danger"
                }`}
                placeholder=""
                {...register("email", {
                  required: "Email required",
                  pattern: {
                    value: validate.email,
                    message: "Entered incorrect email format",
                  },
                })}
                id="email"
              />
              {errors?.email?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.email?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>
                Designation<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.designation?.message && "border-danger"
                }`}
                id="designation"
                placeholder=""
                {...register("designation", {
                  required: "Designation required",
                })}
              />
              {errors?.designation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.designation?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={5}>
              {/* <p className={`mb-1 md-text`}>
                Mobile Number<span className="text-danger">*</span>
              </p>
              <div
                className={`d-flex align-items-start dropdown_menu_container`}
              >
                <div className={`ps-1 pe-1 me-2 country_code_field`}>
                  <div className="flag_icon_box">
                    <img
                      src={
                        selectedCountry
                          ? selectedCountry?.countryImg
                          : indianFlag
                      }
                    />
                  </div>
                  <input
                    className={`md-text border-0 `}
                    placeholder="+91"
                    value={`+91`}
                    maxLength={10}
                    disabled
                    id="code"
                  />
                  <select
                    onChange={handleCountryCodeChange}
                    className={`sm-text border-0`}
                    style={{ outline: "none" }}
                    // placeholder="+91"
                    value={selectedCountryCode}
                    id="code"
                  >
                    {COUNTRY_CODES.map((codeOption) => (
                      <option
                        key={codeOption.countryCode}
                        value={codeOption.countryCode}
                      >
                        <img
                          src={codeOption.countryImg}
                          className="image_icon"
                        />
                        {codeOption.countryCode}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <input
                    type="tel"
                    maxLength={10}
                    className={`md-text ${
                      errors?.mobile?.message && "border-danger"
                    }`}
                    id="mobile"
                    placeholder=""
                    {...register("mobile", {
                      required: "Mobile number required",
                      maxLength: {
                        value: 10,
                        message: "enter 10 digit mobile number",
                      },
                      minLength: {
                        value: 10,
                        message: "enter 10 digit mobile number",
                      },
                    })}
                  />
                  {errors?.mobile?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.mobile?.message}
                    </p>
                  )}
                </div>
              </div> */}
              <MobileNoLayout
                isError={_numberError}
                mobileNumber={phNo}
                required
                onCountryCodeChange={handleCountryCodeChange}
                // countryCodeOptions={COUNTRY_CODES}
                selectedCountryCode={selectedCountryCode}
              >
                <input
                  className={`flex-grow-1 `}
                  placeholder=""
                  type="tel"
                  maxLength={10}
                  {...register("mobile", {
                    required: "Enter 10 digit mobile number",
                    maxLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                    minLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                    pattern: {
                      value: validate.phone,
                      message: "Incorrect mobile number",
                    },
                  })}
                />
              </MobileNoLayout>
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={5}>
              <p className={`mb-1 md-text`}>Time Zone</p>
              <select
                className="w-100 px-2"
                value={selectedTimeZone}
                onChange={handleTimeZoneChange}
              >
                {selectedCountryCode &&
                  COUNTRY_CODES.find(
                    (country) => country.countryCode === selectedCountryCode
                  )?.countryTimeZone.map((timezone) => (
                    <option key={timezone.zone} value={timezone.timeZone}>
                      {timezone.zone}
                    </option>
                  ))}
              </select>
            </Col>
            {/* <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>
                Designation<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.designation?.message && "border-danger"
                }`}
                id="designation"
                placeholder=""
                {...register("designation", {
                  required: "Designation required",
                })}
              />
              {errors?.designation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.designation?.message}
                </p>
              )}
            </Col> */}
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={2}>
              <p className={`mb-1 md-text`}>&nbsp;</p>

              <button
                disabled={
                  _eadminStatsStatus === "Paused" ||
                  _eadminStatsStatus === "Inactive"
                    ? true
                    : false
                }
                type="submit"
                className={`${
                  _eadminStatsStatus === "Paused" ||
                  _eadminStatsStatus === "Inactive"
                    ? `btn btn-secondary`
                    : `sbmit_btn`
                } w-100`}
              >
                Invite
              </button>
            </Col>
          </Row>
        </form>
        &nbsp;
        <p></p>
        &nbsp;
        {/* <Row className={`my-0`}>
          <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
            <p className={`mb-1 md-text`}>Date of Joining</p>
            <Row>
              <Col className={`${styles._col} pe-0`} xs={4}>
                <input className={`md-text`} placeholder="DD" />
              </Col>
              <Col className={`${styles._col} px-1`} xs={4}>
                <input className={`md-text`} placeholder="MM" />
              </Col>
            </Row>
          </Col>
        </Row> */}
        <RenderIf isShow={recruitersList?.length > 0}>
          <Row className={` flex-grow-1`}>
            <Col>
              <div
                className={`${styles.col_card} bg-white py-3 my-2 shadow-block`}
              >
                <div
                  className={`d-flex align-items-center my-1 justify-content-between px-3`}
                >
                  {/* <div className={``}>
                    <p className={`m-0 md-text`}>Recruiter List</p>
                  </div> */}
                  {/* <label className={`sm-text primary-color`}>
                    {recruitersList?.length || 0} of 8 is added
                  </label> */}
                </div>
                <div className={`${styles.table_card}`}>
                  {recruitersList?.length > 0 && (
                    <RecruiterTable
                      dataIs={recruitersList}
                      onDeleteRow={({ id }) => onDeleteRowFun(id)}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </RenderIf>
      </Container>
      <h1>&nbsp;</h1>
      <h1>&nbsp;</h1>
      <RenderIf isShow={recruitersList?.length > 0}>
        <Container className="">
          <Row className="my-3 justify-content-center">
            {/* <Col className="_col " sm={12} md={6} lg={2}>
              <button className="btn btn-outline-primary w-100">
                <label className={`sm-text text-dark`}>Cancel</label>
              </button>
            </Col> */}
            <Col className="_col p-0" sm={12} md={6} lg={2}>
              <button
                className="sbmit_btn w-100 "
                onClick={() => _onPress({ key: "submit" })}
              >
                Submit
                {/* <label className={`sm-text`}>Submit</label> */}
              </button>
            </Col>
          </Row>
        </Container>
      </RenderIf>
    </div>
  );
};

export default AddRecruiter;
