import React from "react";
import { BsCheckCircle } from "react-icons/bs";
import dayjs from "dayjs";

import styles from "./notificationCard.module.scss";

const NotificationCard = ({ notifLists }) => {
  return (
    <div>
      {notifLists.map((item) => {
        return (
          <div className={`${styles.notify_box} shadow-block`}>
            <div className={`${styles.notify_content_box}`}>
              <p className={`${styles.light_text} x-sm-text light-text`}>
                {item?.title}
              </p>
              <p className={`sm-text my-1`}>{item?.notificationMessage}</p>
              <p className={`${styles.light_text} x-sm-text light-text`}>
                {dayjs(item.createdAt).format(`MMM DD, YYYY hh.mm A`)}
              </p>
            </div>
            <div className={``}>
              <BsCheckCircle color="#00A570" />
            </div>
          </div>
        );
      })}
    </div>
    // <div className={`${styles.notify_box} shadow-block`}>
    //   <div className={`${styles.notify_content_box}`}>
    //     <p className={`${styles.light_text} x-sm-text light-text`}>
    //       George Fields
    //     </p>
    //     <p className={`sm-text my-1`}>Accepted job-offer</p>
    //     <p className={`${styles.light_text} x-sm-text light-text`}>
    //       Jan 22, 2023
    //     </p>
    //   </div>
    //   <div className={``}>
    //     <BsCheckCircle color="#00A570" />
    //   </div>
    // </div>
  );
};

export default NotificationCard;
