import React, { useState, useRef, useEffect } from "react";
import Switch from "react-switch";
import { useDrag, useDrop } from "react-dnd";
import { BsCheckLg, BsX, BsPencil, BsTrash } from "react-icons/bs";

import deleteIcon from "../../assets/svgIcons/delete-icon.svg";
import swapIcon from "../../assets/svgIcons/swap-icon.svg";
import editIcon from "../../assets/svgIcons/edit.svg";
import addPhotoIcon from "../../assets/svgIcons/add_photo.svg";
import linkIcon from "../../assets/svgIcons/link-icon.svg";

import styles from "./stackedCard.module.scss";
import RenderIf from "../renderIf";
import { toast } from "react-toastify";
import {
  updateCardSetCardReq,
  updateCEPCardSetCardReq,
} from "../../api/cardsetReq/cardSetReq";
import FileInputBlock from "../uploadFileBlock/fileInputBlock";
import { fileUploadPaths } from "../../constants/fileUploadPaths";
import { validate } from "../../constants/validationExp";
import { useDispatch, useSelector } from "react-redux";
import { SagaActions } from "../../redux/actionTypes";
import { RootState } from "../../redux/store";

type StackcardType = {
  data?: any;
  index?: number;
  moveCard?: any;
  localCard?: boolean;
  emptyState: boolean;
  resetCard?: string | undefined;
  onDelete?: (data: any) => void;
  onChange?: (data: any) => void;
  deleteBtn: boolean;
  swapBtn: boolean;
};

const StackedCard = (props: StackcardType) => {
  const {
    data = {},
    index = 0,
    moveCard,
    localCard,
    emptyState,
    onDelete,
    onChange,
    resetCard,
    deleteBtn,
    swapBtn,
  } = props;
  const {
    cardSetID,
    cardID,
    cardTitle,
    cardImage,
    cardHeading,
    cardDescription,
    cardLinkURL,
  } = data;

  // -------------------------------------
  const ref = useRef<any>(null);
  const Dispatch = useDispatch();
  const { cardset, cardsetCat, cepCompanyProfile, profile } = useSelector(
    (_state: RootState) => _state
  );

  const selectedCompany = cepCompanyProfile?.companyID;
  const roleID = localStorage.getItem("@role");
  const _isCepAdmin = 102 == Number(roleID);

  const _profileCompanyID = profile?.data?.companyID || null;

  const [addLinkChecked, setaddLinkChecked] = useState(false);

  const [editCard, seteditCard] = useState(false);
  const [isEmptyState, setisEmptyState] = useState(false);

  const [cardTitleIs, setcardTitleIs] = useState("");
  const [cardImgURLIs, setcardImgURLIs] = useState("");
  const [cardHeadingIs, setcardHeadingIs] = useState("");
  const [cardDscIs, setcardDscIs] = useState("");
  const [cardLinkIs, setcardLinkIs] = useState("");

  const id = cardID || "";

  const updateCardFun = async () => {
    try {
      const validUrl = new RegExp(validate?.url);
      if (!!cardLinkIs && !validUrl.test(cardLinkIs))
        return toast.error("Enter valid link. Include https://");
      const validTitle = new RegExp(validate?.cardTitle);
      if (cardTitleIs?.replace(/\s/g, "").length < 5)
        return toast.error(
          "Too small a card-name. Must be more than 4 characters."
        );
      const params = {
        cardSetID: cardSetID,
        cardID: cardID,
      };
      const body = {
        cardTitle: cardTitleIs,
        cardImage: cardImgURLIs,
        cardDescription: cardDscIs,
        cardHeading: cardHeadingIs,
        cardLinkURL: cardLinkIs,
      };
      const cepBody = {
        cardTitle: cardTitleIs,
        cardImage: cardImgURLIs,
        cardDescription: cardDscIs,
        cardHeading: cardHeadingIs,
        cardLinkURL: cardLinkIs,
        companyID: selectedCompany,
      };
      // const res = await updateCardSetCardReq(params, cepBody);
      // toast.success(res?.message);
      if (_isCepAdmin) {
        const res = await updateCEPCardSetCardReq(params, cepBody);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "prod",
            message: res?.message,
            fileName: "stackedCard",
            methodName: "updateCardFun",
            type: "info",
          },
        });
      } else {
        const res = await updateCardSetCardReq(params, body);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "prod",
            message: res?.message,
            fileName: "stackedCard",
            methodName: "updateCardFun",
            type: "info",
          },
        });
      }
      seteditCard(false);
      Dispatch({
        type: SagaActions.GET_CARDSET,
        payload: _isCepAdmin ? selectedCompany : _profileCompanyID,
      });
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "stackedCard",
          methodName: "updateCardFun",
          type: "error",
        },
      });
    }
  };

  const onCancelEdit = () => {
    setcardTitleIs(cardTitle);
    setcardImgURLIs(cardImage);
    setcardHeadingIs(cardHeading);
    setcardDscIs(cardDescription);
    setcardLinkIs(cardLinkURL);
    seteditCard(false);
  };

  const [{ handlerId }, drop] = useDrop({
    accept: "Div",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex: any = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      console.log(
        dragIndex,
        hoverIndex,
        hoverClientY,
        hoverMiddleY,
        "dragIndex ,hoverIndex,hoverClientY,hoverMiddleY"
      );

      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }
      // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }
      // Time to actually perform the action
      if (moveCard) {
        moveCard(dragIndex, hoverIndex);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "Div",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.2 : 1;

  drag(drop(ref));

  useEffect(() => {
    if (emptyState) return;
    setcardTitleIs(cardTitle);
    setcardImgURLIs(cardImage);
    setcardHeadingIs(cardHeading);
    setcardDscIs(cardDescription);
    setcardLinkIs(cardLinkURL);
    setisEmptyState(emptyState);
  }, [data]);

  useEffect(() => {
    if (!onChange) return;
    if (!emptyState) return;
    const timerId = setTimeout(() => {
      onChange({
        cardTitle: cardTitleIs || "",
        cardImage: cardImgURLIs || "",
        cardDescription: cardDscIs || "",
        cardHeading: cardHeadingIs || "",
        cardLinkURL: cardLinkIs || "",
      });
    }, 1000);
    return () => clearTimeout(timerId);
  }, [cardTitleIs, cardDscIs, cardImgURLIs, cardHeadingIs, cardLinkIs]);

  useEffect(() => {
    if (!!resetCard) {
      setcardTitleIs("");
      setcardImgURLIs("");
      setcardHeadingIs("");
      setcardDscIs("");
      setcardLinkIs("");
    }
  }, [resetCard]);

  return (
    <div
      ref={ref}
      className={`${styles.StackedCard} shadow-block `}
      style={{ opacity }}
    >
      <div className={`d-flex align-items-center justify-content-end px-2`}>
        <RenderIf isShow={!isEmptyState}>
          <RenderIf isShow={!editCard && !localCard}>
            <div onClick={() => seteditCard(true)}>
              <img src={editIcon} />
            </div>
          </RenderIf>
        </RenderIf>
        <RenderIf isShow={!isEmptyState && editCard}>
          <div
            onClick={() => (localCard ? seteditCard(false) : updateCardFun())}
          >
            <BsCheckLg className="mx-2" color={"#002c71"} size={20} />
          </div>
          <div onClick={onCancelEdit}>
            <BsX className="me-2" color="#D92D20" size={20} />
          </div>
        </RenderIf>
        <div className={`${styles.small_icon_box} d-flex`}>
          {swapBtn && <img src={swapIcon} />}
          <RenderIf isShow={!isEmptyState && !localCard}>
            {deleteBtn && index !== 0 && (
              <img src={deleteIcon} onClick={onDelete} />
            )}
          </RenderIf>
          <RenderIf isShow={!isEmptyState && localCard}>
            {deleteBtn && <img src={deleteIcon} onClick={onDelete} />}
          </RenderIf>
        </div>
      </div>
      <div className={`${styles.line_indicator}`}></div>
      <div className={`my-2`}>
        {editCard || isEmptyState ? (
          <input
            maxLength={40}
            type={"text"}
            id="text"
            placeholder="Card Title (Upto 40 Chars)"
            className={`w-100  sm-text`}
            value={cardTitleIs}
            onChange={(e) => setcardTitleIs(e.target.value)}
          />
        ) : (
          <div
            className={`d-flex align-items-center justify-content-between my-2`}
          >
            <p className="w-100" style={{ overflow: "hidden" }}>
              {cardTitleIs}
            </p>
          </div>
        )}
      </div>
      <RenderIf isShow={!!cardImgURLIs && !isEmptyState}>
        <RenderIf isShow={!editCard}>
          <div className={`${styles.image_container}`}>
            <img src={cardImgURLIs} />
          </div>
        </RenderIf>
      </RenderIf>
      <RenderIf isShow={!editCard && !emptyState && !cardImgURLIs}>
        <div className={`${styles.image_container}`}>
          <p className="x-sm-text">No image selected</p>
          <p className="xx-sm-text">Edit card to upload image</p>
        </div>
      </RenderIf>
      <RenderIf isShow={isEmptyState || editCard}>
        <div className={`${styles.file_input_container}`}>
          <div className={`${styles.edit_img_container}`}>
            <img src={cardImgURLIs} />
          </div>
          <FileInputBlock
            discription={`Browse or Drag and Drop
             \n to Upload Offer letter`}
            path={fileUploadPaths.companyLogo}
            uploadIcon={addPhotoIcon}
            onUpload={({ path }: { path: string }) => setcardImgURLIs(path)}
          />
        </div>
      </RenderIf>

      <div className={`my-2`}>
        {editCard || isEmptyState ? (
          <input
            maxLength={40}
            type={"text"}
            placeholder="Card Tagline (Upto 40 Chars)"
            className={`w-100  sm-text`}
            value={cardHeadingIs}
            onChange={(e) => setcardHeadingIs(e.target.value)}
          />
        ) : (
          <div className={`d-flex mt-2`}>
            <p className={`sm-text`}>{cardHeadingIs}</p>
          </div>
        )}
      </div>
      <div className={`my-2  d-flex flex-column flex-1 `}>
        {editCard || isEmptyState ? (
          <textarea
            maxLength={400}
            rows={10}
            placeholder="Card Description (Upto 40 Chars)"
            className={`w-100  sm-text text-area border rounded p-2`}
            value={cardDscIs}
            onChange={(e) => setcardDscIs(e.target.value)}
          />
        ) : (
          <div className="d-flex " style={{ flex: 1, flexGrow: 1 }}>
            <div className={`d-flex my-2 pb-2 `}>
              <p className={`me-2 sm-text`} style={{ whiteSpace: "pre-line" }}>
                {cardDscIs}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className={`d-flex align-items-center justify-content-between`}>
        <p className={`sm-text`}>Link/Url</p>
        {/* <Switch
          height={18}
          width={35}
          uncheckedIcon={false}
          checkedIcon={false}
          checked={addLinkChecked}
          onChange={() => setaddLinkChecked(!addLinkChecked)}
          onColor="#3a93f4"
          handleDiameter={15}
        /> */}
      </div>

      <div className="pt-0 ">
        {/* {addLinkChecked && ( */}
        <div className={`my-2 ${styles.link_block}`}>
          <RenderIf isShow={!isEmptyState}>
            <input
              disabled={!editCard}
              type={"text"}
              className={"d-flex sm-text  border-0"}
              placeholder="Click on edit icon to update link"
              value={cardLinkIs}
              onChange={(e) => {
                setcardLinkIs(e.target.value);
              }}
            />
          </RenderIf>
          <RenderIf isShow={isEmptyState}>
            <input
              type={"text"}
              className={"d-flex sm-text  border-0"}
              placeholder="Copy and paste the external Url"
              value={cardLinkIs}
              onChange={(e) => {
                setcardLinkIs(e.target.value);
              }}
            />
          </RenderIf>
          <div className={`${styles.link_icon_box}`}>
            <img src={linkIcon} />
          </div>
        </div>
        {/* )} */}
      </div>
      {/* <div className={`flex-1 flex-column`}></div> */}
      <div>&nbsp;</div>
      <div className={`d-flex align-items-center justify-content-end `}>
        <div className={`${styles.line_indicator}`}></div>
      </div>
    </div>
  );
};

export default StackedCard;
