export const SagaActions = {
  // ---------new-----------------
  // ---------Modal-----------------
  CLOSE_MODAL: "CLOSE_MODAL",
  SHOW_ADD_CARDSET_MODAL: "SHOW_ADD_CARDSET_MODAL",
  SHOW_CARDSET_PICKER_MODAL: "SHOW_CARDSET_PICKER_MODAL",
  SHOW_CANDIDATE_STATUS_MODAL: "SHOW_CANDIDATE_STATUS_MODAL",
  SHOW_DEFAULT_CARDSET_MODAL: "SHOW_DEFAULT_CARDSET_MODAL",
  SHOW_CANDIDATE_PUBLISH_MODAL: "SHOW_CANDIDATE_PUBLISH_MODAL",
  SHOW_NOTIFICATIONS_MODAL: "SHOW_NOTIFICATIONS_MODAL",
  SHOW_LOGOUT_MODAL: "SHOW_LOGOUT_MODAL",

  // ---------CardSet Category-----------------
  GET_CARDSET: "GET_CARDSET",
  GET_CARDSET_CAT: "GET_CARDSET_CAT",
  ADD_CARDSET_CAT: "ADD_CARDSET_CAT",
  UPDATE_CARDSET_CAT: "UPDATE_CARDSET_CAT",
  CLEAR_CARDSET_CAT: "CLEAR_CARDSET_CAT",

  // ---------Candidate-----------------
  GET_CANDIDATES: "GET_CANDIDATES",
  SELECTED_CANDIDATE_ID: "SELECTED_CANDIDATE_ID",
  GET_SELECTED_CANDIDATE_DETAILS: "GET_SELECTED_CANDIDATE_DETAILS",
  ADD_CANDIDATES_CARDSET_LIST: "ADD_CANDIDATES_CARDSET_LIST",
  ADD_REMOTE_CANDIDATES_CARDSET_LIST: "ADD_REMOTE_CANDIDATES_CARDSET_LIST",
  UPDATE_CANDIDATES_CARDSET_LIST: "UPDATE_CANDIDATES_CARDSET_LIST",
  ADDED_DEFAULT_CANDIDATES_CARDSET_LIST: "ADDED_DEFAULT_CANDIDATES_CARDSET_LIST",
  ADD_DEFAULT_COMPANY_CARDSET_LIST: "ADD_DEFAULT_COMPANY_CARDSET_LIST",
  DEFAULT_CANDIDATES_CARDSET_LIST: "DEFAULT_CANDIDATES_CARDSET_LIST",
  DELETE_CANDIDATES_CARDSET_LIST: "DELETE_CANDIDATES_CARDSET_LIST",
  SELECT_CARDSET_WEEK: "SELECT_CARDSET_WEEK",
  ADD_FORM_CANDIDATE_PERSONAL: "ADD_FORM_CANDIDATE_PERSONAL",
  ADD_FORM_CANDIDATE_RESOURCES: "ADD_FORM_CANDIDATE_RESOURCES",
  CLEAR_CANDIDATE_FORM: "CLEAR_CANDIDATE_FORM",
  CLEAR_CANDIDATE_CARDSET_FORM: "CLEAR_CANDIDATE_CARDSET_FORM",

  // ---------User-----------------
  FETCH_PROFILE: "FETCH_PROFILE",
  // ---------HR-----------------
  ADD_HR_ACTIONS: "ADD_HR_ACTIONS",
  // ---------User setting-----------------
  UPDATE_NAVBAR_SEETINGS: "UPDATE_NAVBAR_SEETINGS",
  // ---------Comapny-----------------
  DEFAULT_CEP_COMPANY: "DEFAULT_CEP_COMPANY",
  SELECTED_CEP_COMPANY: "SELECTED_CEP_COMPANY",
  FETCH_CEP_COMPANY_PROFILE: "FETCH_CEP_COMAPNY_PROFILE",
  FETCH_COMAPNY_PROFILE: "FETCH_COMAPNY_PROFILE",
  // ---------log Acctions---------
  INFO: "INFO",
  ERROR: "ERROR",
  WARN: "WARN",
  ADD_NEW_UUID: "ADD_NEW_UUID",
  CLEAR_LOGS: "CLEAR_LOGS",
  ADD_USERID: "ADD_USERID",
  // ---------/new-----------------

  // ========================================================
  FETCH_OFFERS: "FETCH_OFFERS",
  FETCH_OFFER_DETAILS: "FETCH_OFFER_DETAILS",
  UPDATE_OFFER_DETAILS: "UPDATE_OFFER_DETAILS",
  FETCH_CARD_SET: "FETCH_CARD_SET",
  UPDATE_SELECTED_OFFER: "UPDATE_SELECTED_OFFER",
  IS_VALID_USER: "IS_VALID_USER",
  UPDATE_APP_INFO: "UPDATE_APP_INFO",

  // ---------Clear Acctions---------
  CLEAR_OFFERS_SLICE: "CLEAR_OFFERS_SLICE",
  CLEAR_OFFER_DETAILS: "CLEAR_OFFER_DETAILS",

  // ---------Notificatons---------
  GET_NOTIFICATION_LISTS: "GET_NOTIFICATION_LISTS",
};
