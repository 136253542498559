import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { ProfileType } from "../../types/userTypes";
import { checkTokenValidation } from "../auth/tokenReq";

type getCompanyProfileReqPropType = {};

export const getCompanyProfileReq = async (
  data?: getCompanyProfileReqPropType
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": comapnyProfileReq->getCompanyProfileReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.getCompanyProfileKey}`);
    //  logfun(res.data, "res");
    const resData = res.data?.data;
    const _msg = res?.data?.message || "";
    const _data = {
      totalOffers: resData?.totalOffers || 0,
      candidatesCount: resData?.candidatesCount || 0,
      companyAssigned: resData?.companyAssigned || 0,
      totalCardSets: resData?.totalCardSets || 0,
      joinedCandidatesCount: resData?.joinedCandidatesCount || 0,
      joinedPercentage: resData?.joinedPercentage || 0,
      acceptanceRate: resData?.acceptanceRate || 0,
      dropoutCandidatesCount: resData?.dropoutCandidatesCount || 0,
      dropoutPercentage: resData?.dropoutPercentage || 0,
      userCount: resData?.userCount || 0,
      activeOfferedCandidatesCount: resData?.activeOfferedCandidatesCount || 0,
      company: {
        companyID: resData?.company?.companyID || "",
        companyName: resData?.company?.CompanyName || "",
        comp1LinerHeadline: resData?.company?.comp1LinerHeadline || "",
        comp1LinerDesc: resData?.company?.comp1LinerDesc || "",
        GSTNo: resData?.company?.GSTNo || "",
        companyAddressLine1: resData?.company?.companyAddressLine1 || "",
        companyAddressLine2: resData?.company?.companyAddressLine2 || "",
        companyCity: resData?.company?.companyCity || "",
        companyState: resData?.company?.companyState || "",
        companyPinCode: resData?.company?.companyPinCode || "",
        companyCountry: resData?.company?.companyCountry || "",
        companyLogoURL: resData?.company?.companyLogoURL || "",
        companyWebLogoURL: resData?.company?.companyWebLogoURL || "",
        subscriptionActiveDate: resData?.company?.subscriptionActiveDate || "",
        subscriptionValidUpto: resData?.company?.subscriptionValidUpto || "",
        lastAmtPaid: resData?.company?.lastAmtPaid || "",
        lastPaymentDate: resData?.company?.lastPaymentDate || "",
        Status: resData?.company?.Status || "",
        createdAt: resData?.company?.createdAt || "",
        updatedAt: resData?.company?.updatedAt || "",
        userID: resData?.company?.userID || "",
        subscriptionID: resData?.company?.subscriptionID || "",
      },
      employee: {
        employeeID: resData?.employee?.employeeID || "",
        firstName: resData?.employee?.firstName || "",
        lastName: resData?.employee?.lastName || "",
        designation: resData?.employee?.designation || "",
        workLocation: resData?.employee?.workLocation || "",
        createdAt: resData?.employee?.createdAt || "",
        updatedAt: resData?.employee?.updatedAt || "",
        companyID: resData?.employee?.companyID || "",
        userID: resData?.employee?.userID || "",
        CEPPartnerID: resData?.employee?.CEPPartnerID || "",
      },
      user: {
        userID: resData?.user?.userID || "",
        mobileNumber: resData?.user?.mobileNumber || "",
        email: resData?.user?.email || "",
        roleID: resData?.user?.roleID || "",
        roleName: resData?.user?.roleName || "",
      },
    };
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateCompanyProfileReq = async (data: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": comapnyProfileReq->updateCompanyProfileReq " + t
    );
  try {
    await checkTokenValidation();

    const body = { ...data };
    //  logfun(body, "res body");

    const res = await authAxios.post(`${ApiKey.updateCompanyProfileKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData?.message || "Profile created*";
    const _data = {};
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

type companyProfileType = {};

export const createCompanyProfileReq = async (data?: companyProfileType) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": comapnyProfileReq->createCompanyProfileReq " + t
    );
  try {
    await checkTokenValidation();

    const body = { ...data };
    const res = await authAxios.post(`${ApiKey.createCompanyProfileKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData?.message || "Profile created*";
    const _data = {};
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
