import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./loginPage.module.scss";
import assistentSupportSvg from "../../assets/svgIcons/assistent_support.svg";
import prodLogo from "../../assets/images/logo_prod_1.png";
import devLogo from "../../assets/images/logo_dev.png";
import stageLogo from "../../assets/images/logo_stage.png";
import Illustration from "../../assets/images/image-1.png";
import appName from "../../assets/images/name-1.png";
import SignUpBlock from "./components/signUpBlock";
import RenderIf from "../../components/renderIf";
import OtpBlock from "./components/otpBlock";
import CreatePswdBlock from "./components/createPswdBlock";
import LoginBlock from "./components/loginBlock";
import { Route, useNavigate, useLocation } from "react-router-dom";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { LogWarn } from "../../constants/logFunctions";

import { useDispatch } from "react-redux";
import {
  getOtpByMailReq,
  resendOtpReq,
  resendSignUpOtpReq,
} from "../../api/auth/otpRequests";
import { loginWithMailReq } from "../../api/auth/loginRequest";
import SmsOtpBlock from "./components/smsOtpBlock";
import { AnyARecord } from "dns";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { SagaActions } from "../../redux/actionTypes";

const SignupPage = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const data = location.state;
  const Dispatch = useDispatch();
  const [authStage, setauthStage] = useState("signup");
  const [userId, setuserId] = useState<any>();
  const [userMailId, setuserMailId] = useState("");
  const [otpIs, setotpIs] = useState();
  const [passwordIs, setpasswordIs] = useState();
  localStorage.setItem("@loggedDate", `${dayjs().format("MMMM DD, YYYY")}`);
  const _userID = localStorage.getItem("@userId") || 0;

  // const getOtpFun = async (data: any) => {
  //   try {
  //     const body = { role: "Candidate", email: data.emailIs };
  //     const res = await getOtpByMailReq(body);
  //     setuserId(res.data.userId);
  //     setotpIs(res.data.otp);
  //     setauthStage("otp");
  //   } catch (error) {
  //     console.log(error, "loginPage->LoginPage->getOtpFun error");
  //   }
  // };

  const resendOtpFun = async (typesIs: string) => {
    try {
      const res = await resendSignUpOtpReq({
        userId: Number(_userID),
        type: typesIs,
      });
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "signupPage",
          methodName: "resendOtpFun",
          type: "info",
        },
      });
      toast.success(res?.message);
    } catch (error: any) {
      console.log(error, "loginPage->LoginPage->resendOtpFun error");
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "signupPage",
          methodName: "resendOtpFun",
          type: "error",
        },
      });
    }
  };

  const loginFun = async (data: any) => {
    try {
      const body = {
        emailIs: data.emailIs,
        passwordIs: data.passwordIs,
      };
      const res = await loginWithMailReq(body);
      // setuserId(res.data.id);
      toast.success(res?.message);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "signupPage",
          methodName: "loginFun",
          type: "info",
        },
      });
      navigation(`${ROUTE_NAMES.mainLayout}/${ROUTE_NAMES.dashboard}`);
    } catch (error: any) {
      console.log(error, "loginPage->LoginPage->resendOtpFun error");
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "signupPage",
          methodName: "loginFun",
          type: "error",
        },
      });
    }
  };

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      sendOtp: (data: any) => {
        setauthStage("otp");
      },
      verifyMailOtp: (data: any) => {
        setauthStage("smsOtp");
      },
      resendOtp: (data: any) => resendOtpFun(data?.typesIs || "Mail"),
      verifySMSOtp: (data: any) => {
        setauthStage("password");
      },
      login: (data: any) => {
        setauthStage("login");
      },
      authenticate: (data: any) => {
        loginFun(data);
        // setauthStage("signup");
        // localStorage.setItem("@auth", "qwertyuiop");
        // window.location.reload();
      },
      null: () => {},
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.login_page} h-100`}>
      <Container fluid className={`Container h-100`}>
        <Row className={``} style={{ height: "90%" }}>
          <Col
            sm={12}
            md={12}
            lg={7}
            className={`Col m-0 p-0 ${styles.image_block_col}`}
          >
            <p>&nbsp;</p>
            <div className={`${styles.imageBlock}`}>
              <img src={Illustration} />
            </div>
          </Col>
          <Col
            sm={12}
            md={12}
            lg={5}
            className={`Col m-0 p-0 pb-2 ${styles.content_block_col} `}
          >
            <div
              className={`d-flex flex-column align-items-center justify-content-end ${styles.flex_12} my-2`}
            >
              <div className={`${styles.img_logo}`}>
                {process.env.REACT_APP__ENV_IS === "prod" && (
                  <img src={prodLogo} />
                )}
                {process.env.REACT_APP__ENV_IS === "dev" && (
                  <img src={devLogo} />
                )}
                {process.env.REACT_APP__ENV_IS === "stage" && (
                  <img src={stageLogo} />
                )}
              </div>
            </div>
            <div className={`d-flex flex-column ${styles.content_block}`}>
              <RenderIf isShow={authStage === "signup"}>
                <SignUpBlock
                  onSubmit={(data: any) => {
                    _onPress({ key: "sendOtp", data });
                    setuserMailId(data.emailIs);
                  }}
                  forgotPassword={data ? data : false}
                />
              </RenderIf>
              <RenderIf isShow={authStage === "otp"}>
                <OtpBlock
                  onSubmit={(data: any) =>
                    _onPress({ key: "verifyMailOtp", data })
                  }
                  onResendOtp={() =>
                    _onPress({ key: "resendOtp", data: { typesIs: "Mail" } })
                  }
                  otherData={{ email: userMailId }}
                />
              </RenderIf>
              <RenderIf isShow={authStage === "smsOtp"}>
                <SmsOtpBlock
                  onSubmit={(data: any) =>
                    _onPress({ key: "verifySMSOtp", data })
                  }
                  onResendOtp={() =>
                    _onPress({ key: "resendOtp", data: { typesIs: "SMS" } })
                  }
                  otherData={{ email: userMailId }}
                />
              </RenderIf>
              <RenderIf isShow={authStage === "password"}>
                <CreatePswdBlock
                  onSubmit={() => _onPress({ key: "login" })}
                  forgotPassword={data ? data : false}
                />
              </RenderIf>
              {/* <RenderIf isShow={authStage === "login"}>
                <LoginBlock
                  onSubmit={(data: any) =>
                    _onPress({ key: "authenticate", data })
                  }
                  onRegister={() => setauthStage("signup")}
                />
              </RenderIf> */}
            </div>
          </Col>
        </Row>
        <Row className={`${styles.footer_block} px-lg-5 px-md-3`}>
          <Col>
            <div className={`${styles.name_logo_container}`}>
              <p className={`light-text x-sm-text`}>Powered by</p>
              <div className={`${styles.name_logo}`}>
                <img src={appName} />
              </div>
            </div>
          </Col>
          <Col>
            <div className={`d-flex justify-content-end ${styles.flex_1}`}>
              <div className={`d-flex align-items-end ${styles.support_block}`}>
                <div className={`me-3`}>
                  <img src={assistentSupportSvg} />
                </div>
                <div>
                  <p className={`light-text md-text m-0`}>For assisstance</p>
                  <p className={`md-text m-0`}>support@peoplex.tech</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignupPage;
