import React from "react";

import styles from "./reportPage.module.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import Switch from "react-switch";
import LoginLog from "./../../components/microComponents/loginLog";

const ReportPage = () => {
  return (
    <div className={`${styles.ReportPage} px-3 py-1`}>
      <LoginLog />
      &nbsp;
      <Container>
        <Row>
          <Col sm={12} md={12} lg={4} className="my-2">
            <Card className="shadow-block border-0">
              <Card.Body className="p-0">
                <Card.Title className="p-3 pb-0">
                  <p className={`md-text`}>Configure Automated Report</p>
                </Card.Title>
                <Card.Text className="pb-3 ">
                  <table>
                    <thead>
                      <tr>
                        <td>
                          <p className={`x-sm-text light-text `}>Frequency </p>
                        </td>
                        <td>
                          <p className={`x-sm-text light-text `}>Action</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p className={`sm-text`}>Daily</p>{" "}
                          <p className={`xx-sm-text light-text `}>
                            Time: 11.59 PM
                          </p>
                        </td>
                        <td>
                          <Switch
                            height={18}
                            width={35}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={true}
                            onChange={() => {}}
                            onColor="#3a93f4"
                            handleDiameter={15}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className={`sm-text`}>Weekly</p>{" "}
                          <p className={`xx-sm-text light-text `}>
                            Friday 11.59PM
                          </p>
                        </td>
                        <td>
                          <Switch
                            height={18}
                            width={35}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={true}
                            onChange={() => {}}
                            onColor="#3a93f4"
                            handleDiameter={15}
                          />
                        </td>
                      </tr>
                      <tr className={`${styles.last_child}`}>
                        <td>
                          <p className={`sm-text`}>Monthly</p>{" "}
                          <p className={`xx-sm-text light-text `}>
                            30th Day of the Month 11.59PM
                          </p>
                        </td>
                        <td>
                          <Switch
                            height={18}
                            width={35}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={true}
                            onChange={() => {}}
                            onColor="#3a93f4"
                            handleDiameter={15}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={12} lg={8} className="my-2">
            <Card className="shadow-block border-0 h-100">
              <Card.Body className="p-0">
                <Card.Title className="p-3">
                  <p className={`md-text`}>Customized Report</p>
                </Card.Title>
                <Row className="px-3">
                  <Col sm={12} md={6} lg={5} className="my-2">
                    <div className="w-100 d-flex">
                      <p className={`${styles.label} md-text`}>DOJ</p>
                      <div className={`${styles.dropdown_block}`}>
                        <select>
                          <option value={""}>Select</option>
                          <option value={1}>one</option>
                        </select>
                        <p className={`x-sm-text light-text mt-1`}>
                          Calculated as per today’s date
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={6} className="my-2">
                    <div className="w-100 d-flex">
                      <p className={`${styles.label} md-text`}>Candidates</p>
                      <div className={`${styles.dropdown_block}`}>
                        <select>
                          <option value={""}>All</option>
                          <option value={1}>one</option>
                        </select>
                        <p className={`x-sm-text light-text mt-1`}>
                          Search and add individual candidate
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={5} className="my-2">
                    <div className="w-100 d-flex">
                      <p className={`${styles.label} md-text`}>Status</p>
                      <div className={`${styles.dropdown_block}`}>
                        <select>
                          <option value={""}>All</option>
                          <option value={1}>one</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} md={6} lg={5} className="my-2">
                    <div className="w-100 d-flex">
                      <p className={`${styles.label} md-text`}>Display as</p>
                      <div className={`${styles.dropdown_block}`}>
                        <select>
                          <option value={""}>On Screen</option>
                          <option value={1}>one</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <div className={`${styles.card_footer} my-3`}>
                <button className={`btn px-4`}>
                  <p className={`sm-text`}>Generate Report</p>
                </button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReportPage;
