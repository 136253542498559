import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { ProfileType } from "../../types/userTypes";
import { checkTokenValidation } from "../auth/tokenReq";
import {
  CandidateActivityType,
  CandidateCardsetType,
  CandidateInInfoType,
  CandidateInfoType,
  CandidateOfferType,
  CandidateType,
  UserInInfoType,
  ActivityActionsList,
} from "../../types/candidateTypes";
import { ResourceCreateType, ResourceGetType } from "../../types/otherTypes";

export const addCandidateReq = async (data?: any, cancleProfileToken?: any) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidatesReq->addCandidateReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    //  logfun(body, "body");

    const res = await authAxios.post(`${ApiKey.addCandidateKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const getCandidatesReq = async () => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidatesReq->getCandidatesReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.getCandidatesKey}`);
    //  logfun(res.data, "res");
    const resData = res.data?.data || [];
    const _msg = resData.message || "";
    const _data = resData?.map((item: CandidateType) => ({
      candidateID: item?.candidateID || "",
      userID: item?.userID || "",
      firstName: item?.firstName || "",
      lastName: item?.lastName || "",
      User: {
        mobileNumber: item?.User.mobileNumber || "",
      },
      CandidateOffers: item?.CandidateOffers?.map((itm: any) => ({
        userID: itm?.userID || "",
        jobLevel: itm?.jobLevel || "",
        dateOfJoining: itm?.dateOfJoining || "",
        designation: itm?.designation || "",
        offerStatus: itm?.offerStatus || "",
      })),
    }));
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const getCandidateDetailsReq = async (params: {
  candidateID: number;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidatesReq->getCandidateDetailsReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(
      `${ApiKey.getCandidateDetailsKey}/${params?.candidateID}`
    );
    //  logfun(res.data, "res");
    const resData = res.data?.data || [];
    const _msg = resData.message || "";
    const _data = {
      email: resData?.email || "",
      PhoneCountryCode: resData?.PhoneCountryCode || "",
      mobileNumber: resData?.mobileNumber || "",
      firstName: resData?.firstName || "",
      lastName: resData?.lastName || "",
      yearOfExp: resData?.yearOfExp || "",
      jobLevel: resData?.jobLevel || "",
      dateOfJoining: resData?.dateOfJoining || "",
      designation: resData?.designation || "",
      department: resData?.department || "",
      team: resData?.team || "",
      baseLocation: resData?.baseLocation || "",
      workLocation: resData?.workLocation || "",
      workModel: resData?.workModel || "",
      offerExpiryDate: resData?.offerExpiryDate || "",
      offerLetterURL: resData?.offerLetterURL || "",
      salaryCtcPA: resData?.salaryCtcPA || "",
      fixedComponentPA: resData?.fixedComponentPA || "",
      variableComponentPA: resData?.variableComponentPA || "",
      approxTakeHomePM: resData?.approxTakeHomePM || "",
    };
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const getCandidateAllDetailsReq = async (params: {
  candidateID: number;
}) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidatesReq->getCandidateAllDetailsReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(
      `${ApiKey.getCandidateAllDetailsKey}/${params?.candidateID}`
    );
    //  logfun(res.data, "res");
    const resData = res.data || {};
    const _msg = resData.message || "";

    const _data: CandidateInfoType = {
      User: {
        email: resData?.User?.email || "",
        phoneCountryCode: resData?.User?.phoneCountryCode || "",
        mobileNumber: resData?.User?.mobileNumber || "",
        isActive: resData?.User?.isActive || "",
      },
      Candidate: {
        candidateID: resData?.Candidate?.candidateID || "",
        firstName: resData?.Candidate?.firstName || "",
        lastName: resData?.Candidate?.lastName || "",
        profilePicURL: resData?.Candidate?.profilePicURL || "",
        isPublished: resData?.Candidate?.isPublished || "",
        employeeID: resData?.Candidate?.employeeID || "",
        companyID: resData?.Candidate?.companyID || "",
        userID: resData?.Candidate?.userID || "",
        engagementScore: resData?.Candidate?.engagementScore || "",
      },
      CandidateOffer: {
        offerID: resData?.CandidateOffer?.offerID || "",
        yearOfExp: resData?.CandidateOffer?.yearOfExp || "",
        jobLevel: resData?.CandidateOffer?.jobLevel || "",
        dateOfJoining: resData?.CandidateOffer?.dateOfJoining || "",
        designation: resData?.CandidateOffer?.designation || "",
        department: resData?.CandidateOffer?.department || "",
        team: resData?.CandidateOffer?.team || "",
        baseLocation: resData?.CandidateOffer?.baseLocation || "",
        workLocation: resData?.CandidateOffer?.workLocation || "",
        workModel: resData?.CandidateOffer?.workModel || "",
        offerExpiryDate: resData?.CandidateOffer?.offerExpiryDate || "",
        offerStatus: resData?.CandidateOffer?.offerStatus || "",
        offerLetterURL: resData?.CandidateOffer?.offerLetterURL || "",
        signatureURL: resData?.CandidateOffer?.signatureURL || "",
        salaryCtcPA: resData?.CandidateOffer?.salaryCtcPA || "",
        fixedComponentPA: resData?.CandidateOffer?.fixedComponentPA || "",
        variableComponentPA: resData?.CandidateOffer?.variableComponentPA || "",
        variablePayoutFreq: resData?.CandidateOffer?.variablePayoutFreq || "",
        approxTakeHomePM: resData?.CandidateOffer?.approxTakeHomePM || "",
        userID: resData?.CandidateOffer?.userID || "",
        employeeID: resData?.CandidateOffer?.employeeID || "",
        companyID: resData?.CandidateOffer?.companyID || "",
        createdAt: resData?.CandidateOffer?.createdAt || "",
      },
      CandidateCardSets: resData?.CandidateCardSets?.map(
        (item: CandidateCardsetType) => ({
          candidateCardSetID: item?.candidateCardSetID || "",
          weekNumber: item?.weekNumber ?? "",
          dateFrom: item?.dateFrom || "",
          dateTo: item?.dateTo || "",
          cardSetOrder: item?.cardSetOrder || "",
          cardSetID: item?.cardSetID || "",
          companyID: item?.companyID || "",
          userID: item?.userID || "",
          CardSet: {
            cardSetID: item?.CardSet?.cardSetID || "",
            cardSetName: item?.CardSet?.cardSetName || "",
            cardSetURL: item?.CardSet?.cardSetURL || "",
            isActive: item?.CardSet?.isActive || "",

            categoryID: item?.CardSet?.categoryID || "",
            companyID: item?.CardSet?.companyID || "",
            CardSetCategory: {
              cardSetURL: item?.CardSet?.CardSetCategory?.cardSetURL || "",
            },
          },
          ActivityActions: item?.ActivityActions?.map(
            (i: ActivityActionsList) => ({
              candidateRemark: i?.candidateRemark || "",
            })
          ),
        })
      ),
      CandidateResources: resData?.CandidateResources?.map(
        (item: ResourceGetType) => ({
          resourceID: item?.resourceID,
          resourceName: item?.resourceName,
          resourceType: item?.resourceType,
          resourceURL: item?.resourceURL,
          companyID: item?.companyID,
          userID: item?.userID,
        })
      ),
      CandidateEngagement: {
        cardSetTotalCount: resData?.CandidateEngagement?.cardSetTotalCount || 0,
        cardSetCompletedCount:
          resData?.CandidateEngagement?.cardSetCompletedCount || 0,
        completedPercentage:
          resData?.CandidateEngagement?.completedPercentage || 0,
        claimedRewards: resData?.CandidateEngagement?.claimedRewards || 0,
        unClaimedRewards: resData?.CandidateEngagement?.unClaimedRewards || 0,
        engagementScore: resData?.CandidateEngagement?.engagementScore || 0,
        personalityMapScore:
          resData?.CandidateEngagement?.personalityMapScore || 0,
        personalityMap: resData?.CandidateEngagement?.personalityMap?.map((item: any) => ({
          categoryName: item?.categoryName || "",
          averageFeedback: item?.averageFeedback || "0",
        }))
      },
      CandidateActivity: resData?.CandidateActivity?.map(
        (item: CandidateActivityType) => ({
          activityID: item?.activityID || "",
          activityName: item?.activityName || "",
          candidateActionDate: item?.candidateActionDate || "",
          candidateStatus: item?.candidateStatus || "",
          candidateRemark: item?.candidateRemark || "",
          recruiterStatus: item?.recruiterStatus || "",
          recruiterRemark: item?.recruiterRemark || "",
          createdAt: item?.createdAt || "",
          updatedAt: item?.updatedAt || "",
          offerID: item?.offerID || "",
          candidateCardSetID: item?.candidateCardSetID || "",
          candidateID: item?.candidateID || "",
          employeeID: item?.employeeID || "",
        })
      ),
    };
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const publishCandidateReq = async (
  data?: any,
  cancleProfileToken?: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": candidatesReq->publishCandidateReq " + t
    );
  try {
    await checkTokenValidation();
    const body = data;
    //  logfun(body, "body");

    const res = await authAxios.post(`${ApiKey.publishCandidateKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = null;
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
