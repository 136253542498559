import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OtpInput from "react-otp-input";

import styles from "../loginPage.module.scss";
import mailIcon from "../../../assets/svgIcons/mail-icon.svg";
import phoneIcon from "../../../assets/svgIcons/telephone-icon.svg";
import Timer from "../../../components/timer/timer";
import { loginWithOtpReq } from "../../../api/auth/loginRequest";
import ActivityIndicator from "../../../components/activityIndicator/activityIndicator";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useNavigate } from "react-router-dom";
import { verifyOtpReq } from "../../../api/auth/otpRequests";
import { toast } from "react-toastify";
import { SagaActions } from "../../../redux/actionTypes";
import { useDispatch } from "react-redux";

const OtpBlock = (props) => {
  const { onSubmit, onResendOtp, otherData } = props;
  const Dispatch = useDispatch();
  const navigation = useNavigate();

  const [otp, setOtp] = useState("");
  const [timerRunning, settimerRunning] = useState(true);
  const [resetTimer, setresetTimer] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");

  const verifyOtp = async (data) => {
    try {
      if (!otp || otp.length < 6) return seterrorMsg("Invalid OTP");
      setbtnLoading(true);
      const body = {
        userID: localStorage.getItem("@userId"),
        otp: otp,
        typeIs: "Mail",
      };
      const res = await verifyOtpReq(body);
      setbtnLoading(false);
      toast.success(res?.message);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "otpBlock",
          methodName: "verifyOtp",
          type: "info",
        },
      });
      onSubmit();
    } catch (error) {
      setbtnLoading(false);
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "otpBlock",
          methodName: "verifyOtp",
          type: "error",
        },
      });
    }
    seterrorMsg("");
  };

  const _onPress = ({ key, data }) => {
    const fun = {
      null: (data) => {},
      submit: (data) => verifyOtp({ otp }),
      resend: () => {
        setresetTimer(!resetTimer);
        onResendOtp();
      },
    };
    fun[key](data);
  };

  return (
    <div className={`${styles.OtpBlock} flex-1 d-flex flex-column`}>
      <div className={` flex-1 d-flex flex-column`}>
        <h4 className={`x-lg-text`}>Verify email OTP</h4>
        <p className={`md-text`}>
          Please enter the OTP sent to your mail ID{" "}
          {localStorage.getItem("@email")}
        </p>
        <p>&nbsp;</p>
        <p></p>
        <div>
          <div className={`${styles.input_block}`}>
            {/* <label>OTP</label> */}
            <div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>&nbsp;</span>}
                renderInput={(props, index) => (
                  <input
                    type="number"
                    id={index}
                    className={`${styles.otpInputStyle}`}
                    {...props}
                  />
                )}
                inputStyle={`${styles.otp_cell}`}
                placeholder="000000"
              />
            </div>
            <div className={`d-flex`}>
              <p className={`x-sm-text text-danger mt-1`}>
                {errorMsg ? errorMsg : ``}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`d-flex align-items-center justify-content-between`}>
        <button
          type="button"
          className={`${styles.forgot_password_btn} `}
          onClick={() => _onPress({ key: "resend" })}
        >
          <p className={`primary-color x-sm-text`}>Resend OTP</p>
        </button>
        <Timer
          runTimer={timerRunning}
          reset={resetTimer}
          onFinish={() => settimerRunning(false)}
          onStart={() => console.log("started")}
          customeTimer={(min, sec) => (
            <p className={`primary-color x-sm-text`}>
              {`0${min}:${sec < 10 ? "0" + sec : sec}`}
            </p>
          )}
        />
      </div>
      &nbsp;
      <button
        className={`${styles.button} md-text`}
        type={`button`}
        onClick={() => _onPress({ key: "submit" })}
      >
        Login
        <div className={`ms-2`}>
          {btnLoading && <ActivityIndicator size={20} />}
        </div>
      </button>
    </div>
  );
};

export default OtpBlock;
