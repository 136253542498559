import React, { useState, useMemo, useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useForm, SubmitHandler } from "react-hook-form";
import styles from "../myAccount.module.scss";

import indianFlag from "../../../assets/icons/indian_flag.png";
import UploadFile from "../../../components/uploadFileBlock";
import LoginLog from "../../../components/microComponents/loginLog";
import { useDispatch, useSelector } from "react-redux";
import { createCompanyProfileReq } from "../../../api/user/profileReq";
import { companyProfile } from "../constant/initialValues";
import { validate } from "../../../constants/validationExp";
import {
  getCompanyProfileReq,
  updateCompanyProfileReq,
} from "../../../api/company/companyProfileReq";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { SagaActions } from "../../../redux/actionTypes";
import { fileUploadPaths } from "../../../constants/fileUploadPaths";

const CompanyProfile = () => {
  const Dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const roleID = localStorage.getItem("@role");
  const _isCepAdmin = 102 == Number(roleID);
  const { cepCompanyProfile } = useSelector((_state) => _state);

  const [formValues, setformValues] = useState(companyProfile);
  const [companyDetails, setcompanyDetails] = useState({});
  const [updateLoder, setupdateLoder] = useState(false);

  const _cepCompanyProfile = cepCompanyProfile?.data || null;
  const selectedCompany = cepCompanyProfile?.companyID;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    values: formValues,
  });

  const [logoPath, setlogoPath] = useState("");
  const [webLogoPath, setwebLogoPath] = useState("");
  const [logoError, setlogoError] = useState("");
  const [webLogoError, setwebLogoError] = useState();
  const [userRoleId, setUserRoleId] = useState(null);

  const getCEPCompanyProfileDetails = async () => {
    const selectedCompanyID = _cepCompanyProfile?.company.find(
      (item) => item.companyID === selectedCompany
    );
    if (selectedCompanyID) {
      setlogoPath(selectedCompanyID?.companyLogoURL || "");
      setwebLogoPath(selectedCompanyID?.companyWebLogoURL || "");
    }
    setformValues({
      firstName: selectedCompanyID?.firstName || "",
      lastName: selectedCompanyID?.lastName || "",
      roleID: selectedCompanyID?.role || "",
      mobileNumber: selectedCompanyID?.mobileNumber || "",
      email: selectedCompanyID?.email || "",
      designation: selectedCompanyID?.designation || "",
      workLocation: selectedCompanyID?.workLocation || "",
      roleName: _cepCompanyProfile?.roleName || "",
      companyName: selectedCompanyID?.companyName || "",
      GSTNo: selectedCompanyID?.GSTNo || "",
      companyAddressLine1: selectedCompanyID?.addressLine1 || "",
      companyCountry: selectedCompanyID?.country || "",
      companyState: selectedCompanyID?.state || "",
      companyCity: selectedCompanyID?.city || "",
      companyPinCode: selectedCompanyID?.zipcode || "",
    });
  };

  const getCompanyProfileDetails = async () => {
    try {
      const res = await getCompanyProfileReq();
      const employee = res?.data?.employee;
      const company = res?.data?.company;
      const user = res?.data?.user;
      setcompanyDetails(res?.data);
      setlogoPath(company?.companyLogoURL || "");
      setwebLogoPath(company?.companyWebLogoURL || "");
      setUserRoleId(user?.roleID);
      setformValues({
        firstName: employee?.firstName || "",
        lastName: employee?.lastName || "",
        roleID: user?.roleID || "",
        mobileNumber: user?.mobileNumber || "",
        email: user?.email || "",
        designation: employee?.designation || "",
        workLocation: employee?.workLocation || "",
        roleName: user?.roleName || "",
        companyName: company?.companyName || "",
        GSTNo: company?.GSTNo || "",
        companyAddressLine1: company?.companyAddressLine1 || "",
        companyCountry: company?.companyCountry || "",
        companyState: company?.companyState || "",
        companyCity: company?.companyCity || "",
        companyPinCode: company?.companyPinCode || "",
      });
      // console.log("compony log", formValues);
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "companyProfile",
          methodName: "getCompanyProfileDetails",
          type: "info",
        },
      });
    } catch (error) {
      console.log("error");
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "companyProfile",
          methodName: "getCompanyProfileDetails",
          type: "error",
        },
      });
    }
  };

  const onSubmitFun = async (formData) => {
    setupdateLoder(true);
    try {
      if (!logoPath) {
        setlogoError(`Company logo required`);
        toast.error("Upload WHITE themed company logo (for Mobile App)");
        setupdateLoder(false);
        return;
      }
      setlogoError("");
      if (!webLogoPath) {
        setwebLogoError(`Web company logo required`);
        toast.error("Upload DARK themed company logo (for WebApp)");
        setupdateLoder(false);
        return;
      }
      setwebLogoError("");

      const profileDetailsBody = {
        ...formData,
        employeeID: companyDetails?.employee?.employeeID,
        workLocation: formData?.workLocation,
        CompanyName: formData?.companyName,
        companyLogoURL: logoPath,
        companyWebLogoURL: webLogoPath,
        companyID: companyDetails?.company?.companyID || "",
      };
      delete profileDetailsBody.companyName;
      delete profileDetailsBody.mobileNumber;
      delete profileDetailsBody.email;
      console.log(profileDetailsBody);

      const res = await updateCompanyProfileReq(profileDetailsBody);
      toast.success(res.message);
      setupdateLoder(false);
      Dispatch({ type: SagaActions.FETCH_COMAPNY_PROFILE });
      const parentPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
      Dispatch({
        type: SagaActions.INFO,
        payload: {
          env: "dev",
          message: res?.message,
          fileName: "companyProfile",
          methodName: "onSubmitFun",
          type: "info",
        },
      });
      navigate(`${parentPath}/${ROUTE_NAMES.plan}`);
    } catch (error) {
      console.log(error, "CompanyProfile->CompanyProfile->onSubmitFun error");
      toast.error(error?.errorMsg);
      setupdateLoder(false);
      setlogoError("");
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "prod",
          message: error?.errorMsg,
          fileName: "companyProfile",
          methodName: "onSubmitFun",
          type: "error",
        },
      });
    }
  };

  const _onPress = ({ key, data }) => {
    const fun = { submit: (data) => {} };
    fun[key](data);
  };

  useEffect(() => {
    if (_isCepAdmin && selectedCompany) {
      getCEPCompanyProfileDetails();
    } else {
      getCompanyProfileDetails();
    }
  }, [selectedCompany]);

  return (
    <div className={`${styles.CompanyProfile} px-3`}>
      <Container>
        <LoginLog />
        <form
          onSubmit={handleSubmit((data) => {
            onSubmitFun(data);
          })}
        >
          <Row className={`my-2`}>
            <Col className={`${styles._col} mt-2`}>
              <p className={`mb-1 lg-text`}>Setup the corporate profile</p>
            </Col>
          </Row>
          <Row className={`my-0`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>
                First Name<span className="text-danger">*</span>
              </p>
              <input
                {...register("firstName", { required: "First Name required" })}
                className={`md-text ${
                  errors?.firstName?.message && "border-danger"
                }`}
                placeholder=""
                disabled={
                  userRoleId === 301 || userRoleId === 303 ? false : true
                }
                name="firstName"
              />
              {errors?.firstName?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.firstName?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>
                Last Name<span className="text-danger">*</span>
              </p>
              <input
                {...register("lastName", { required: "Last Name required" })}
                className={`md-text ${
                  errors?.lastName?.message && "border-danger"
                }`}
                placeholder=""
                disabled={
                  userRoleId === 301 || userRoleId === 303 ? false : true
                }
                name="lastName"
              />
              {errors?.lastName?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.lastName?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row className={`my-0`}>
            {/* <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>
                Mobile Number<span className="text-danger">*</span>
              </p>
              <div className={`d-flex`}>
                <div className={`ps-2 me-2 country_code_field`}>
                  <div className="flag_icon_box"></div>
                  <input
                    className={`md-text border-0 `}
                    id="code"
                    placeholder="+91"
                    value={`+91`}
                  />
                </div>
                <input
                  className={`md-text ${
                    errors?.mobileNumber?.message && "border-danger"
                  }`}
                  placeholder=""
                  maxLength={10}
                  type="tel"
                  name="mobileNumber"
                  disabled
                  {...register("mobileNumber", {
                    required: "Mobile number required",
                    maxLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                    minLength: {
                      value: 10,
                      message: "Enter 10 digit mobile number",
                    },
                  })}
                />
              </div>
              {errors?.mobileNumber?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.mobileNumber?.message}
                </p>
              )}
            </Col> */}
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={5}>
              <p className={`mb-1 md-text`}>
                Mobile Number<span className="text-danger">*</span>
              </p>
              <div className={`d-flex align-items-start`}>
                <div className={`ps-2 me-2 country_code_field`}>
                  <div className="flag_icon_box">
                    <img
                      src={
        
                          indianFlag
                      }
                    />
                  </div>
                  <input
                    className={`md-text border-0 `}
                    placeholder="+91"
                    value={`+91`}
                    maxLength={10}
                    disabled
                    id="code"
                  />
                  {/* <select
                    onChange={handleCountryCodeChange}
                    className={`sm-text border-0`}
                    style={{ outline: "none" }}
                    // placeholder="+91"
                    value={selectedCountryCode}
                    id="code"
                  >
                    {COUNTRY_CODES.map((codeOption) => (
                      <option
                        key={codeOption.countryCode}
                        value={codeOption.countryCode}
                      >
                        <img
                          src={codeOption.countryImg}
                          className="image_icon"
                        />
                        {codeOption.countryCode}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div>
                  <input
                    type="tel"
                    maxLength={10}
                    className={`md-text ${
                      errors?.mobileNumber?.message && "border-danger"
                    }`}
                    id="mobile"
                    disabled
                    placeholder=""
                    {...register("mobileNumber", {
                      required: "Mobile number required",
                      maxLength: {
                        value: 10,
                        message: "enter 10 digit mobile number",
                      },
                      minLength: {
                        value: 10,
                        message: "enter 10 digit mobile number",
                      },
                    })}
                  />
                  {errors?.mobileNumber?.message && (
                    <p className={`xx-sm-text text-danger`}>
                      {errors?.mobileNumber?.message}
                    </p>
                  )}
                </div>
              </div>
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>
                Email ID<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.email?.message && "border-danger"
                }`}
                placeholder=""
                disabled
                {...register("email", {
                  required: "Email required",
                  pattern: {
                    value: validate.email,
                    message: "Entered incorrect email format",
                  },
                })}
                name="email"
              />
              {errors?.email?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.email?.message}
                </p>
              )}
            </Col>
          </Row>
          <Row className={`my-0`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>
                Designation<span className="text-danger">*</span>
              </p>
              <div className={`d-flex`}>
                <input
                  className={`md-text ${
                    errors?.designation?.message && "border-danger"
                  }`}
                  placeholder=""
                  disabled={_isCepAdmin ? true : false}
                  {...register("designation", {
                    required: "Designation required",
                  })}
                  name="designation"
                />
              </div>
              {errors?.designation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.designation?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3 `} sm={12} md={8} lg={4}>
              <p className={`mb-1 md-text`}>
                Work Location<span className="text-danger">*</span>
              </p>
              <div className={`d-flex`}>
                <input
                  className={`md-text ${
                    errors?.workLocation?.message && "border-danger"
                  }`}
                  placeholder=""
                  disabled={_isCepAdmin ? true : false}
                  {...register("workLocation", {
                    required: "Work Location required",
                  })}
                  name="workLocation"
                />
              </div>
              {errors?.workLocation?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.workLocation?.message}
                </p>
              )}
            </Col>
          </Row>
          {/* <Row className={`my-4 mb-0`}>
            <Col className={``}>
              <p className={`mb-0`}>
                In what role/capacity, would you use this software application?
                <span className="text-danger">*</span>
              </p>
              <div className={`d-flex align-items-center mt-1`}>
                <div>
                  <input
                    type="radio"
                    className={`me-2`}
                    {...register("roleName")}
                    value={"EAdmin"}
                    // checked={profileDetailsIs.roleID === "303"}
                    // onChange={() => {
                    //   // setselectedRole("303");
                    // }}
                  />
                </div>
                <div>
                  <p className={`sm-text`}>
                    Admin: I will Add/Edit recruiters, and let them manage
                    candidates and job-offers
                  </p>
                </div>
              </div>
              <div className={`d-flex align-items-center mt-1`}>
                <div>
                  <input
                    type="radio"
                    className={`me-2`}
                    {...register("roleName")}
                    value={"Eadmin+Rec"}
                    // checked={profileDetailsIs.roleID === "304"}
                    // onChange={() => {
                    //   // setselectedRole("304");
                    // }}
                  />
                </div>
                <div>
                  <p className={`sm-text`}>
                    Admin + Recruiter: I’ll be the Admin & also manage
                    candidates and job-offers
                  </p>
                </div>
              </div>
            </Col>
          </Row> */}
          <Row className={`my-0 mt-2`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>
                Registered Company Name<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.companyName?.message && "border-danger"
                }`}
                // disabled
                placeholder=""
                disabled={_isCepAdmin ? true : false}
                {...register("companyName", {
                  required: "Company Name required",
                })}
                name="companyName"
              />
              {errors?.companyName?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.companyName?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={4}>
              <p className={`mb-1 md-text`}>{`Company GST no(Optional)`}</p>
              <input
                className={`md-text ${
                  errors?.GSTNo?.message && "border-danger"
                }`}
                placeholder=""
                disabled={_isCepAdmin ? true : false}
                {...register("GSTNo", {
                  pattern: {
                    value: validate.gstNo,
                    message: "GST number must be 15 characters",
                  },
                })}
                name="GSTNo"
              />
              {errors?.GSTNo?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.GSTNo?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={8}>
              <p className={`mb-1 md-text`}>
                Company Registered/Billing Address
                <span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.companyAddressLine1?.message && "border-danger"
                }`}
                placeholder=""
                disabled={_isCepAdmin ? true : false}
                {...register("companyAddressLine1", {
                  required: "Company Address required",
                })}
                name="companyAddressLine1"
              />
              {errors?.companyAddressLine1?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.companyAddressLine1?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={0} md={6} lg={4}></Col>
            <Col className={`${styles._col} mt-3 `} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>
                Country<span className="text-danger">*</span>
              </p>
              {/* <div
                className={`d-flex  flex-grow-1 ${
                  errors?.companyCountry?.message && "border-danger"
                }`}
              > */}
              <input
                className={`md-text ${
                  errors?.companyCountry?.message && "border-danger"
                }`}
                placeholder=""
                disabled={_isCepAdmin ? true : false}
                {...register("companyCountry", {
                  required: "Country required",
                })}
                name="companyCountry"
              />
              {/* <select
                  className={``}
                  {...register("companyCountry", {
                    required: "Country required",
                  })}
                >
                  <option>select</option>
                  <option value={"India"}>India</option>
                </select> */}
              {/* <input
                  style={{
                    width: "0px",
                    margin: "0px",
                    border: "none",
                    padding: "9px 0px 9px 0px",
                  }}
                /> */}
              {/* </div> */}
              {errors?.companyCountry?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.companyCountry?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text`}>
                City<span className="text-danger">*</span>
              </p>
              <input
                className={`md-text ${
                  errors?.companyCity?.message && "border-danger"
                }`}
                placeholder=""
                disabled={_isCepAdmin ? true : false}
                {...register("companyCity", {
                  required: "City required",
                })}
                name="companyCity"
              />
              {errors?.companyCity?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.companyCity?.message}
                </p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 md-text `}>
                Pin Code<span className="text-danger">*</span>
              </p>
              <input
                type={"number"}
                className={`md-text ${
                  errors?.companyPinCode?.message && "border-danger"
                }`}
                placeholder=""
                disabled={_isCepAdmin ? true : false}
                {...register("companyPinCode", {
                  required: "PinCode required",
                })}
                name="companyPinCode"
              />
              {errors?.companyPinCode?.message && (
                <p className={`xx-sm-text text-danger`}>
                  {errors?.companyPinCode?.message}
                </p>
              )}
            </Col>
          </Row>
          &nbsp;
          <Row className={`my-3`}>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <p className={`mb-1 my-1 md-text b`}>Add Company Logo</p>
              <p className={`m-1 x-sm-text light-text`}>
                Upload a PNG image in
                <br />
                5:3 aspect ratio.
              </p>
              {/* <p className={`mb-1 my-1 x-sm-text light-text`}>
                Jpg, Png Max 200Kb of 5:3 <br />
                ratio
              </p> */}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <UploadFile
                disabled={_isCepAdmin ? true : false}
                imagePath={logoPath}
                accept={"image/*"}
                discription="Upload White Logo for Mobile App"
                backgroundColor={"#023079"}
                reUploadMsg={"Re-Upload White Logo for Mobile App"}
                path={fileUploadPaths.companyLogo}
                onUpload={(data) => {
                  setlogoPath(data?.path || "");
                  setlogoError("");
                }}
              />
              {!!logoError && (
                <p className={`xx-sm-text text-danger`}>{logoError}</p>
              )}
            </Col>
            <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
              <UploadFile
                disabled={_isCepAdmin ? true : false}
                imagePath={webLogoPath}
                accept={"image/*"}
                discription="Upload Dark Logo for WebApp"
                reUploadMsg="Re-Upload Dark Logo for WebApp"
                path={fileUploadPaths.companyLogo}
                onUpload={(data) => {
                  setwebLogoPath(data?.path || "");
                  setwebLogoError("");
                }}
              />
              {!!webLogoError && (
                <p className={`xx-sm-text text-danger`}>{webLogoError}</p>
              )}
            </Col>

            {/* <Col className={`${styles._col} mt-3`} sm={12} md={6} lg={3}>
            <UploadFile discription="Logo in color" />
          </Col> */}
          </Row>
          <Row className={`my-3`}>
            <Col className={`${styles._col} mt-3 p-0`} sm={12} md={12} lg={12}>
              <div className={`d-flex justify-content-center `}>
                <div className="w-75 d-flex justify-content-center">
                  <button
                    className={`sbmit_btn w-25 py-2 sm-text d-flex align-items-center justify-content-center`}
                    type="submit"
                    // onClick={() => _onPress({ key: "submit" })}
                  >
                    Continue
                    {!!updateLoder && (
                      <div className="ms-2">
                        <Spinner animation="border" variant="white" size="sm" />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default CompanyProfile;
