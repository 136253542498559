import React, { useCallback, useState, useEffect } from "react";
import update from "immutability-helper";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { IoIosSearch, IoMdAdd } from "react-icons/io";

import styles from "../cardSetsPage.module.scss";

import infoIcon from "../../../assets/svgIcons/Info-icon.svg";
import editIcon from "../../../assets/svgIcons/edit.svg";
import addIcon from "../../../assets/svgIcons/add-icon.svg";
import { EmojiCard, StackedCard } from "../../../components/cardComponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SagaActions } from "../../../redux/actionTypes";
import {
  addNewCardToCardSetReq,
  addNewCardToCEPCardSetReq,
  deleteCardSetCardReq,
  deleteCEPCardSetCardReq,
  updateCardSetReq,
  updateCEPCardSetReq,
} from "../../../api/cardsetReq/cardSetReq";
import { toast } from "react-toastify";
import EmptyStackedCard from "../../../components/cardComponents/emptyStackCard";
import { validate } from "../../../constants/validationExp";
import rightArrow from "../../../assets/icons/double_right.png";

const CardsetDetails = (props: any) => {
  const { data } = props;
  const { cardsetCat, modals, cepCompanyProfile, profile } = useSelector(
    (_state: RootState) => _state
  );
  const Dispatch = useDispatch();
  const _catData = cardsetCat.data || [];
  const selectedCompany = cepCompanyProfile?.companyID;
  const roleID = localStorage.getItem("@role");
  const _isCepAdmin = 102 == Number(roleID);
  const _profileCompanyID = profile?.data?.companyID || null;

  const [Cards, setCards] = useState<any[]>([]);
  const [cardsetName, setcardsetName] = useState("");
  const [selectedCat, setselectedCat] = useState("");
  const [updateLoader, setupdateLoader] = useState(false);
  const [newCardData, setnewCardData] = useState<any>({});
  const [resetCard, setresetCard] = useState<any>("false");

  const addCardFun = async () => {
    // if (!newCardData?.cardTitle) return toast.error("Enter card Title");

    const validTitle = new RegExp(validate?.cardTitle);
    if (newCardData?.cardTitle.replace(/\s/g, "").length < 5)
      return toast.error(
        "Too small a card-name. Must be more than 4 characters."
      );
    const validUrl = new RegExp(validate?.url);
    if (!!newCardData?.cardLinkURL && !validUrl.test(newCardData?.cardLinkURL))
      return toast.error("Enter valid link. Include https://");
    if (Cards.length < 1) return toast.error("Unable to add the card");
    try {
      if (_isCepAdmin) {
        const res = await addNewCardToCEPCardSetReq(
          { cardSetID: Cards[0].cardSetID },
          {
            ...newCardData,
            cardOrder: Cards?.length + 1,
            categoryID: Cards[0].categoryID,
            companyID: selectedCompany,
          }
        );
        const _newCardData = res?.data;
        toast.success(res?.message);
        let index = Cards.length - 1;
        const reOrderdArray = [...Cards];
        reOrderdArray.splice(index, 0, _newCardData);
        setCards(reOrderdArray);
      } else {
        const res = await addNewCardToCardSetReq(
          { cardSetID: Cards[0].cardSetID },
          {
            ...newCardData,
            cardOrder: Cards?.length + 1,
            categoryID: Cards[0].categoryID,
          }
        );
        const _newCardData = res?.data;
        toast.success(res?.message);
        let index = Cards.length - 1;
        const reOrderdArray = [...Cards];
        reOrderdArray.splice(index, 0, _newCardData);
        setCards(reOrderdArray);
      }
      // const res = await addNewCardToCardSetReq(
      //   { cardSetID: Cards[0].cardSetID },
      //   {
      //     ...newCardData,
      //     cardOrder: Cards?.length + 1,
      //     categoryID: Cards[0].categoryID,
      //   }
      // );
      // const _newCardData = res?.data;
      // toast.success(res?.message);
      // let index = Cards.length - 1;
      // const reOrderdArray = [...Cards];
      // reOrderdArray.splice(index, 0, _newCardData);
      // setCards(reOrderdArray);
      Dispatch({
        type: SagaActions.GET_CARDSET,
        payload: _isCepAdmin ? selectedCompany : _profileCompanyID,
      });

      setnewCardData({});
      setresetCard(newCardData?.cardTitle);
    } catch (error: any) {
      toast.error(error?.errorMsg);
    }
  };

  const updateTheCardsetFun = async () => {
    try {
      if (!cardsetName || !selectedCat)
        return toast.error(`CardSet name and Category, are both required`);
      setupdateLoader(true);

      const reArrangeCards = Cards?.map((item: any, idx) => ({
        ...item,
        cardOrder: item?.cardOrder > 998 ? item?.cardOrder : `${idx + 1}`,
      }));

      const body = {
        categoryID: Number(selectedCat),
        cardSetName: cardsetName,
        cards: reArrangeCards,
      };

      const cepBody = {
        categoryID: Number(selectedCat),
        cardSetName: cardsetName,
        cards: reArrangeCards,
        companyID: selectedCompany,
      };

      console.log(body, "updateCardSetReq body");

      const params = {
        cardSetID: data?.cardSetID,
      };

      if (_isCepAdmin) {
        const res = await updateCEPCardSetReq(params, cepBody);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "dev",
            message: res?.message,
            fileName: "cardsetDetails",
            methodName: "updateTheCardsetFun",
            type: "info",
          },
        });
      } else {
        const res = await updateCardSetReq(params, body);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "dev",
            message: res?.message,
            fileName: "cardsetDetails",
            methodName: "updateTheCardsetFun",
            type: "info",
          },
        });
      }

      // const res = await updateCardSetReq(params, body);
      // toast.success(res?.message);
      setupdateLoader(false);
      Dispatch({
        type: SagaActions.GET_CARDSET,
        payload: _isCepAdmin ? selectedCompany : _profileCompanyID,
      });
      // Dispatch({
      //   type: SagaActions.INFO,
      //   payload: {
      //     env: "dev",
      //     message: res?.message,
      //     fileName: "cardsetDetails",
      //     methodName: "updateTheCardsetFun",
      //     type: "info",
      //   },
      // });
    } catch (error: any) {
      toast.error(error?.errorMsg);
      setupdateLoader(false);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "cardsetDetails",
          methodName: "updateTheCardsetFun",
          type: "error",
        },
      });
    }
  };

  const deleteCard = async (card: any) => {
    if (card?.localCardID) {
      const cardId = card?.localCardID;
      const indexToRemove = Cards.findIndex((i: any) =>
        i?.localCardID ? i.localCardID === cardId : false
      );
      if (indexToRemove !== -1) {
        const oldCards = [...Cards];
        oldCards.splice(indexToRemove, 1);
        // Use the splice method to remove the object from the array
        setCards([...oldCards]);
      }
      return;
    }
    try {
      const params = {
        cardSetID: card?.cardSetID,
        cardID: card?.cardID,
      };
      const body = {
        companyID: selectedCompany.toString(),
      };
      // const res = await deleteCardSetCardReq({
      //   cardSetID: card?.cardSetID,
      //   cardID: card?.cardID,
      // });
      if (_isCepAdmin) {
        const res = await deleteCEPCardSetCardReq(params, body);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "dev",
            message: res?.message,
            fileName: "cardsetDetails",
            methodName: "deleteCard",
            type: "info",
          },
        });
      } else {
        const res = await deleteCardSetCardReq(params);
        toast.success(res?.message);
        Dispatch({
          type: SagaActions.INFO,
          payload: {
            env: "dev",
            message: res?.message,
            fileName: "cardsetDetails",
            methodName: "deleteCard",
            type: "info",
          },
        });
      }

      const _newCardData = Cards.filter((i: any) => i.cardID !== card?.cardID);
      setCards([..._newCardData]);
      Dispatch({
        type: SagaActions.GET_CARDSET,
        payload: _isCepAdmin ? selectedCompany : _profileCompanyID,
      });
    } catch (error: any) {
      toast.error(error?.errorMsg);
      Dispatch({
        type: SagaActions.ERROR,
        payload: {
          env: "dev",
          message: error?.errorMsg,
          fileName: "cardsetDetails",
          methodName: "deleteCard",
          type: "error",
        },
      });
    }
  };

  const moveCard = useCallback((dragIndex: any, hoverIndex: any) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [hoverIndex, 1],
          [dragIndex, 0, prevCards[hoverIndex]],
        ],
      })
    );
    // setCards((prevCards) =>
    //   update(prevCards, {
    //     $splice: [
    //       [dragIndex, 1],
    //       [hoverIndex, 0, prevCards[dragIndex]],
    //     ],
    //   })
    // );
  }, []);

  console.log(Cards, "cards position");

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      addNewCardset: () => {
        Dispatch({ type: SagaActions.SHOW_ADD_CARDSET_MODAL });
      },
      cardDelete: (data: any) => deleteCard(data),
      cardUpdate: (data: any) => updateTheCardsetFun(),
    };
    fun[key](data);
  };

  useEffect(() => {
    setCards(data?.cards);
    setcardsetName(data?.cardSetName);
    setselectedCat(`${data?.cardSetCategory?.categoryID}`);
  }, [data]);

  console.log(data, "Selected CardsetDetails");

  return (
    <div
      className={`${styles.shadow_block} ${styles.CardsetDetails} shadow-block py-4`}
    >
      <Container>
        <Row className={`my-2`}>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`light-text md-text`}>Card Set</p>
            <input
              className={`md-text ${styles.input_box}`}
              id="text"
              value={cardsetName}
              onChange={(e) => setcardsetName(e.target.value)}
            />
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={3}
          >
            <p className={`light-text md-text`}>Category</p>
            <select
              className={`${styles.dropdown_box}`}
              id="category"
              onChange={(e) => setselectedCat(e.target.value)}
              defaultValue={`${selectedCat}`}
              value={`${selectedCat}`}
            >
              <option value={""}>Select</option>
              {_catData?.map((item: any, idx) => (
                <option key={idx} value={`${item?.categoryID}`}>
                  {item?.categoryName}
                </option>
              ))}
            </select>
          </Col>
          <Col
            className={`${styles._col} ${styles.fields}`}
            sm={12}
            md={6}
            lg={4}
          ></Col>

          <Col
            className={`${styles._col} ${styles.fields} d-flex align-items-center justify-content-end`}
            sm={12}
            md={6}
            lg={2}
          >
            <button
              className={`d-flex align-items-center sbmit_btn py-2`}
              onClick={() => _onPress({ key: "cardUpdate" })}
            >
              <p className={`mx-2 sm-text`}>Save Changes</p>
              {!!updateLoader && (
                <Spinner animation="border" variant="white" size="sm" />
              )}
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={`d-flex align-items-start my-1`}>
              <img src={infoIcon} className={`me-2`} />
              <p className={`x-sm-text light-text`}>
                <i>
                  {/* Some Card sets has prefilled content which can be edited with
                  the <img src={editIcon} /> button and other Card sets content
                  needs to be updated. */}
                  Add a new card to the set, or edit <img src={editIcon} /> an
                  existing card
                </i>
              </p>
            </div>
          </Col>
        </Row>
        <div className={`my-2 d-flex w-100 ${styles.cardsContainer}`}>
          {Cards?.map((item: any, idx) => {
            if (item?.cardOrder > 998) {
              return <EmojiCard editCard={false} data={item} />;
            }
            return (
              <StackedCard
                emptyState={false}
                localCard={item?.localCardID}
                data={item}
                deleteBtn={true}
                swapBtn={true}
                index={idx}
                moveCard={moveCard}
                onDelete={() => _onPress({ key: "cardDelete", data: item })}
              />
            );
          })}
          <div
            className={`mx-4 d-flex align-items-center justify-content-center`}
          >
            <div
              className={`${styles.add_card_box}`}
              onClick={() => addCardFun()}
              style={{ maxWidth: "120px" }}
            >
              <div
                className={`mb-2 p-2`}
                style={{ height: "70px", width: "70px" }}
              >
                <img
                  src={rightArrow}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
              <p className={`x-sm-text`} style={{ textAlign: "center" }}>
                Push the card to CardSet
              </p>
            </div>
          </div>
          <div>
            <EmptyStackedCard
              emptyState={true}
              deleteBtn={true}
              swapBtn={true}
              onChange={(cardData: any) => setnewCardData(cardData)}
              resetCard={resetCard}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CardsetDetails;
