import { ApiKey } from "../apiKeys";
import { authAxios } from "../config";
import { ProfileType } from "../../types/userTypes";
import { checkTokenValidation } from "../auth/tokenReq";

type getProfileReqPropType = {};

export const getProfileReq = async (
  data?: getProfileReqPropType,
  cancleProfileToken?: any
) => {
  const logfun = (d: any, t: string) =>
    console.log(
      JSON.stringify(d, null, 2),
      new Date() + ": profileReq->getProfileReq " + t
    );
  try {
    await checkTokenValidation();

    const res = await authAxios.get(`${ApiKey.profileKey}`);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData.message || "";
    const _data = {
      firstName: resData?.user?.firstName || "",
      lastName: resData?.user?.lastName || "",
      designation: resData?.user?.designation || "",
      workLocation: resData?.user?.workLocation || "",
      companyID: resData?.user?.companyID || "",
      userID: resData?.user?.userID || "",
      email: resData?.user?.email || "",
      mobile: resData?.user?.mobile || "",
      role: resData?.user?.role || "",
    };
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};

export const updateProfileReq = async (data?: ProfileType | any) => {
  const logfun = (d: any, t: string) =>
    console.log(d, new Date() + ": profileReq->updateProfileReq " + t);
  try {
    await checkTokenValidation();

    const body = {
      user: {
        ...(!!data?.firstName && { firstName: data?.firstName }),
        ...(!!data?.lastName && { lastName: data?.lastName }),
        ...(!!data?.profilePicURL && { profilePicURL: data?.profilePicURL }),
      },
      address: {
        ...(!!data?.addressLine1 && { addressLine1: data?.addressLine1 }),
        ...(!!data?.addressLine2 && { addressLine2: data?.addressLine2 }),
        ...(!!data?.country && { country: data?.country }),
        ...(!!data?.city && { city: data?.city }),
        ...(!!data?.state && { state: data?.state }),
        ...(!!data?.zipcode && { zipcode: data?.zipcode }),
      },
    };

    const res = await authAxios.post(`${ApiKey.updateProfileKey}`, body);
    //  logfun(res.data, "res");
    const resData = res.data;
    const _msg = resData?.message;
    const _data = {};
    return { error: false, data: _data, message: _msg, errorMsg: "" };
  } catch (err: any) {
    let error;
    if (err.response) error = err.response.data.message || "Response Error";
    else if (err.request) error = "Request error" || err.request;
    else error = "Something went wrong please try again later";
    //  logfun(error, "ERROR");
    throw { error: true, data: "", message: "", errorMsg: error };
  }
};
