import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";

import styles from "./candidatePage.module.scss";
import { ROUTE_NAMES } from "../../constants/routeNames";
import MenuBox from "./components/menuBox";

const menuListGroupOne = [
  {
    label: "Basic Information",
    icon: "",
    route: ROUTE_NAMES.basic_info_form,
  },
  {
    label: "Add CardSets",
    icon: "",
    route: ROUTE_NAMES.cardSetForm,
  },
  {
    label: "Resources",
    icon: "",
    route: ROUTE_NAMES.resourcesForm,
  },
  {
    label: "Review and Update",
    icon: "",
    route: ROUTE_NAMES.reviewForm,
  },
];

const AddCandidatePage = () => {
  const navigation = useNavigate();
  const currentPath = useLocation().pathname;
  const { candidateForm, candidateCardsetList } = useSelector(
    (_state: RootState) => _state
  );
  const _formValues = candidateForm?.data?.personalDetails?.email;
  const _cardSetValues = candidateCardsetList?.data?.length;
  const _resourceValues = candidateForm?.data?.resources?.length;

  const [activeMenu, setactiveMenu] = useState("Basic Information");

  const currentOpt: any = menuListGroupOne.find((i) =>
    i.route?.includes(`${currentPath?.split("/").slice(-1)[0]}`)
  );

  const _onPress = ({ key, data }: { key: string; data?: any }) => {
    const fun: any = {
      null: () => {},
      menuBtn: (data: any) => {
        // setactiveMenu(data?.label);
        // navigation(data?.route);
        if (candidateNavigate(data.label)) {
          setactiveMenu(data.label);
          navigation(data.route);
        } else {
          toast.warn("Fill all details before proceeding further");
        }
      },
    };
    fun[key](data);
  };

  const candidateNavigate = (label: string) => {
    const currentIndex = menuListGroupOne.findIndex(
      (opt) => opt.label === label
    );
    if (currentIndex === 0) {
      return true;
    }
    const previousStep = menuListGroupOne[currentIndex - 1].label;
    switch (previousStep) {
      case "Basic Information":
        return !!_formValues;
      case "Add Card Sets":
        return !!_cardSetValues;
      case "Resources":
        return !!_resourceValues;
      default:
        return false;
    }
  };

  useEffect(() => {
    navigation(ROUTE_NAMES.basic_info_form);
  }, []);

  return (
    <div className={`${styles.candidate} h-100`}>
      <div className={`d-flex h-100`}>
        <div className={`${styles.nav_bar_block} h-100`}>
          &nbsp;
          <p></p>
          &nbsp;
          {menuListGroupOne?.map((opt, idx) => {
            // const active = activeMenu === opt.label;
            const active = currentOpt?.label === opt.label;

            const presentPosition = menuListGroupOne
              .map((e) => e.label)
              .indexOf(currentOpt?.label);

            const checked = idx < presentPosition;
            return (
              <MenuBox
                key={idx}
                opt={opt}
                isChecked={checked}
                isActive={active}
                onClick={() => _onPress({ key: "menuBtn", data: opt })}
              />
            );
          })}
        </div>
        <div className={`${styles.content_block} h-100`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AddCandidatePage;
